import { Box, Divider, Typography } from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toastMessage } from "../../redux/actions";
import CopyAnswerButton from "./CopyAnswerButton";
import LikeQueryButton from "./LikeQueryButton";
import QueryAnswer from "./QueryAnswer";
import QueryInput from "./QueryInput";
import QuerySources from "./QuerySources";
import RelatedQuestions from "./RelatedQuestions";
import ReportAnswer from "./ReportAnswer";
import ShareChatButton from "./ShareChatButton";
import { useChatStyles } from "./chat.style";
import { ReactComponent as BarsIcon } from "../../assets/images/bars.svg";
import ThreeDotsLoader from "../Common/ThreeDotsLoader";
import { getUserInformation } from "../../redux/auth/action";

const getAnswer = (answer) => {
  if (answer && typeof answer === "string") {
    const parsedAnswer = JSON.parse(answer);

    if (!parsedAnswer) return null;

    if (Array.isArray(parsedAnswer)) {
      return {
        answers: parsedAnswer,
      };
    }

    return parsedAnswer;
  }
  return null;
};

const getSources = (answer) => {
  if (!answer) return { sources: [], sourceNumber: {} };

  const { worker, ticker } = getWorker(answer.workers);
  const sources = [];
  const sourceNumber = {};

  if (worker === "SQL") {
    // If the worker is SQL, we need to use the worker's tickers as sources instead of provided sources.
    const tickers =
      ticker && typeof ticker === "string" ? ticker.split(", ") : [];
    tickers.forEach((ticker) => {
      const source = `${window.origin}/quote/${ticker}/summary`;

      sources.push(source);
      sourceNumber[source] = sources.length;
    });
  } else {
    answer?.answers?.forEach(({ source }) => {
      const sourceList = source
        ? Array.isArray(source)
          ? [...new Set(source)] // Sometimes BE returns duplicate sources.
          : [source]
        : [];
      if (source && sourceList.every((sourceUrl) => !sourceNumber[sourceUrl])) {
        sourceList.forEach((sourceUrl) => {
          sources.push(sourceUrl);
          sourceNumber[sourceUrl] = sources.length;
        });
      }
    });
  }

  return {
    sources,
    sourceNumber,
  };
};

const getWorker = (workers) => {
  if (workers?.length) return workers[0];

  return {};
};

const QueryList = ({
  addQuery,
  makeShareable,
  queries,
  shareable,
  chatOwner,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [askedQuery, setAskedQuery] = useState();

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useChatStyles();
  const { idToken, userInfo } = useSelector((state) => state.signIn.loggedUser);
  const ownChat = userInfo?.username === chatOwner;

  const { chatId } = useParams();

  const totalQueries = queries?.length || 0;

  const askQuestion = async ({
    query,
    source = "auto",
    onSuccess = () => {},
  }) => {
    if (!idToken) {
      history.push("/sign-up");
      return;
    }

    if (isLoading) return;

    const payload = {
      method: "chat",
      chatId,
      query,
      previous_queries: queries.map(({ query }) => query),
      source: [source],
    };

    try {
      setIsLoading(true);
      setAskedQuery(query);
      // To scroll after the loading is displayed to the UI
      setTimeout(() => window.scrollTo(0, document.body.scrollHeight + 200), 0);
      const { data } = await axios.post(
        process.env.REACT_APP_CHAT_API_URL,
        payload,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: idToken,
            Accept: "application/json",
          },
        }
      );
      onSuccess();
      addQuery({
        answer: JSON.stringify(data.response),
        chatId: data.chatId,
        query: data.query,
        queryId: data.queryId,
        queryDate: new Date().toISOString(),
        liked: 0,
      });

      setIsLoading(false);
      setAskedQuery("");
      dispatch(getUserInformation({ hideLoader: true }));
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message:
            error.response?.data?.body ||
            "Something went wrong. Please try again.",
          type: "error",
        })
      );

      setIsLoading(false);
      setAskedQuery("");
    }
  };

  return (
    <>
      {queries?.map(({ query, answer, queryId, liked }, index) => {
        const ans = getAnswer(answer);
        const { sources, sourceNumber } = getSources(ans);

        return (
          <Fragment key={queryId}>
            <Box py={4}>
              <Typography variant="h4" className={classes.title}>
                <BarsIcon /> {query}
              </Typography>
              <Box mb={4}>
                <QueryAnswer
                  sourceNumber={sourceNumber}
                  answers={ans?.answers}
                />
                <Box className={classes.answerActions}>
                  <ShareChatButton
                    query={query}
                    shareable={shareable}
                    makeShareable={makeShareable}
                  />
                  <CopyAnswerButton
                    answer={
                      ans?.answers?.map(({ answer }) => answer).join("") || ""
                    }
                  />
                  {!!idToken && ownChat && (
                    <>
                      <LikeQueryButton liked={liked} queryId={queryId} />
                      <ReportAnswer queryId={queryId} />
                    </>
                  )}
                </Box>
              </Box>
              <QuerySources sources={sources} />

              {!!ans?.questions?.length && (
                <Box mt={3.5}>
                  <RelatedQuestions
                    questions={ans.questions}
                    onAskQuestion={askQuestion}
                    ownChat={ownChat}
                  />
                </Box>
              )}
            </Box>
            {index !== totalQueries - 1 && <Divider />}
          </Fragment>
        );
      })}

      {/* UI to show when asked query is loading */}
      {isLoading && (
        <Fragment>
          <Divider />
          <Box pt={4} mb={2}>
            <Typography variant="h4" className={classes.title}>
              {askedQuery}
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="start"
            gridColumnGap={4}
            sx={{ opacity: isLoading ? 1 : 0 }}
            mt={1.5}
            bgcolor="#eee"
            maxWidth="max-content"
            pt={0.5}
            px={2}
            borderRadius={15}
          >
            Searching <ThreeDotsLoader />
          </Box>
        </Fragment>
      )}

      <Box mt={2} mb={4}>
        <QueryInput
          isLoading={isLoading}
          onSubmit={askQuestion}
          fullWidth
          ownChat={ownChat}
        />
      </Box>
    </>
  );
};

export default QueryList;
