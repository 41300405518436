import axios from "axios";
import { isLoggedIn } from "../../utils/authentication";

export const getSubscriptionPlans = async () => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const response = await axios.get(
    // "https://49a8-115-246-28-82.in.ngrok.io/stripe/plans",
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/plans`,
    config
  );
  return response;
};

export const addSubscriptionPlans = async (idToken, body) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/addSubscription`,
    body,
    config
  );
  return response;
};
export const stripeBillingRedirect = async (idToken, body) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.post(
    // "https://4c45-115-246-28-82.in.ngrok.io/stripe/portal",
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/portal`,
    body,
    config
  );
  return response;
};

export const reSubscriptionPlans = async (idToken, body) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/reSubscribe`,
    body,
    config
  );
  return response;
};

export const cancelSubscriptionPlansApi = async (idToken, cancelReason) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/cancelSubscription`,
    {
      cancelReason:
        cancelReason?.reason === "Other"
          ? `Other - ${cancelReason?.other}`
          : cancelReason?.reason === "Difficulty in using the app"
          ? `Difficulty - ${cancelReason?.other}`
          : cancelReason?.reason === "Features not meeting expectations"
          ? `Feature - ${cancelReason?.other}`
          : cancelReason?.reason === "Technical issues"
          ? `Tech - ${cancelReason?.other}`
          : cancelReason?.reason,
      cancelPositive: cancelReason?.like,
      cancelFeedback: cancelReason?.features,
    },
    config
  );
  return response;
};

export const applyCouponCodeApi = async (idToken, code, id) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.get(
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/coupon?code=${code}&priceId=${id}`,
    config
  );
  return response;
};

export const getSubscriptionDetailsApi = async (idToken) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/subcription`,
    {},
    config
  );
  return response;
};

export const upgradeSubscriptionApi = async (idToken) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.put(
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/upgradeSubscription`,
    null,
    config
  );
  return response;
};

export const getPaymentCardDetailsApi = async (idToken) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.get(
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/paymentMethod`,
    config
  );
  return response;
};

export const updateCardDetailsApi = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/createIntent`,
    payload,
    config
  );
  return response;
};

export const upgradePlanApi = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.put(
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/upgradeSubscription`,
    payload,
    config
  );
  return response;
};

export const addStripeCustomerApi = async (idToken) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/customer`,
    null,
    config
  );
  return response;
};

export const sendPaymentConfirmationApi = async (payload) => {
  const isLogin = isLoggedIn();
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: isLogin.idToken,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/payment`,
    payload,
    config
  );
  return response;
};

export const updateCardApi = async (idToken, body) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.put(
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/paymentMethod`,
    body,
    config
  );
  return response;
};

export const getSecureActionApi = async (idToken) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.get(
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/getSecureActionDetails`,
    config
  );
  return response;
};

export const getCancelPaymentMethodApi = async (payload) => {
  const isLogin = isLoggedIn();
  if (isLogin.idToken) {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: isLogin.idToken,
      },
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/cancelPaymentMethodUpdate`,
      payload,
      config
    );
    return response;
  } else {
    return {};
  }
};

export const cancelPaymentApi = async (payload) => {
  const isLogin = isLoggedIn();
  if (isLogin.idToken) {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: isLogin.idToken,
      },
    };

    const response = await axios.put(
      `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/cancelPayment`,
      payload,
      config
    );
    return response;
  } else {
    return {};
  }
};

export const validatePaymentApi = async (payload) => {
  const isLogin = isLoggedIn();
  if (isLogin.idToken) {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: isLogin.idToken,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/validatePayment`,
      payload,
      config
    );
    return response;
  } else {
    return {};
  }
};

export const deleteStripeCustomerApi = async (idToken) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.delete(
    `${process.env.REACT_APP_API_STRIPE_SERVER_URL}/${process.env.REACT_APP_API_STRIPE_BASE_URL}/${process.env.REACT_APP_API_STRIPE_BASE_PATH}/removeCustomer`,
    config
  );
  return response;
};
