export const isLoggedIn = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return userData || false;
};

export const setLoggedUser = (data) => {
  const userData = JSON.stringify(data);
  localStorage.setItem("userData", userData);
};

export const loggedOutUser = async () => {
  const redirect = localStorage.getItem("redirectTo");
  localStorage.clear();
  localStorage.setItem("redirectTo", redirect);
};

export const setDeepLinkUrl = (path, param) => {
  localStorage.setItem(
    "redirectTo",
    path === null ? null : JSON.stringify({ path, param })
  );
};

export const reloadFn = () => window.location.reload();
