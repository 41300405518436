import React from "react";
import { withStyles, Button, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

export default function CustomButton({
  borderColor,
  fontColor,
  fontHoverColor,
  radius,
  boxShadow,
  children,
  bgColor,
  bgHoverColor,
  fontSize,
  btnPadding,
  resBtnWidth,
  hoverEffect,
  ...props
}) {
  const { userInfo } = useSelector((state) => state.signIn.loggedUser);
  const isLanding = userInfo?.email ? false : true;

  const StyledButton = withStyles((theme) => ({
    root: {
      border: `${
        borderColor ? `2px solid  ${borderColor}` : "2px solid transparent"
      }`,
      padding: btnPadding ? `${btnPadding}` : 20,
      width: resBtnWidth ? `${resBtnWidth}` : "auto",
      borderRadius: `${radius}px`,
      fontWeight: "700",
      boxShadow: boxShadow ? `${boxShadow}` : "none",
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        minWidth: "auto",
      },
      background:
        bgColor ||
        (isLanding ? theme.palette.primary.main : theme.palette.secondary.main),
      backgroundImage:
        props.variant !== "contained" || bgColor || !isLanding
          ? undefined
          : `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      "&:hover": {
        background: bgHoverColor || theme.palette.primary.main, // "linear-gradient(180deg, #1FEE8B 0%, #027a65 98.1%)",
        boxShadow: "none",
        "& .MuiButton-label": {
          color: hoverEffect
            ? theme.palette.primary.main
            : fontHoverColor ?? fontColor,
        },
        ...(hoverEffect && {
          ...{
            background: "none",
            border: `2px solid ${theme.palette.primary.main}`,
          },
        }),
      },
      "&:disabled": {
        background: "#F5F5F5 !important",
        border: "2px solid #D4D6D8",
        "& .MuiButton-label": {
          color: "#797979",
        },
      },
    },
    label: {
      textTransform: "capitalize",
      fontSize: fontSize ? `${fontSize}px` : 16,
      [theme.breakpoints.down("xs")]: {
        fontSize: 16,
      },
      lineHeight: "1",
      color: fontColor || "#fff",
    },
  }))(Button);

  return <StyledButton {...props}>{children}</StyledButton>;
}

export const useButtonStyles = makeStyles((theme) => ({
  outlined: {
    "&.MuiButton-root": {
      background: "transparent",
      border: `2px solid ${theme.palette.primary.main}`,
      "& .MuiButton-label": {
        color: theme.palette.primary.main,
      },
      "&:hover": {
        "& .MuiButton-label": {
          color: "#fff",
        },
      },
    },
  },
}));
