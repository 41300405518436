import React from "react";
import { withStyles, Select } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiSelect-icon": {
      color: "#000",
      "&.Mui-disabled": {
        opacity: 0.3,
      },
    },
  },
  input: {
    cursor: "pointer",
    borderRadius: 5,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    textTransform: "capitalize",
    padding: "10px 26px 10px 15px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "transparent",
      borderRadius: 5,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      padding: "9px 26px 9px 15px",
    },
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase);

export default function CustomSelect({ children, ...props }) {
  return (
    <Select input={<BootstrapInput disabled={props.disabled} />} {...props}>
      {children}
    </Select>
  );
}
