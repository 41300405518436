import { Fab, makeStyles } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import React from "react";

const useStyles = makeStyles((theme) => ({
  startTour: {
    color: "#FFFFFF",
    right: 20,
    width: "auto",
    bottom: 105,
    height: "auto",
    padding: "0 10px",
    zIndex: 999,
    position: "fixed",
    borderRadius: 4,
    backgroundColor: "#808080",
    [theme.breakpoints.down("sm")]: {
      bottom: 140,
      right: 25,
    },
  },
}));

const StartTour = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Fab className={classes.startTour} id="start-tour" onClick={onClick}>
      <HelpIcon />
    </Fab>
  );
};

export default StartTour;
