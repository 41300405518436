import { FormGroup, MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoaderAction } from "../../redux/loader/action";
import CustomSelect from "../Common/CustomSelect";
import CustomTextField from "../Common/CustomTextField";
import { DashboardStyle } from "../Dashboard/DashboardStyle";
import { toastMessage } from "../../redux/actions";
import axios from "axios";

const initialState = {
  jobFunction: "",
  source: "",
  other: "",
};
const sourceList = [
  "Google",
  "Twitter",
  "LinkedIn",
  "Facebook",
  "Podcast",
  "Youtube",
  "TikTok",
  "Instagram",
  "Friends",
  "Other",
];
const jobFunctionList = [
  "Investment Professional",
  "Personal Investor",
  "Day Trader",
  "Banker/Hedge Fund",
  "New to Investment",
];
const initialText = {
  Other: "Please share the source",
  Podcast: "Please share the podcast name",
  Youtube: "Please share the youtube video/channel",
};
const attributes = {
  USER_ROLE: "custom:userrole",
  USER_SOURCE: "custom:usersource",
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialog: {
    zIndex: "2002 !important",
  },
  dialogContent: {
    "& .MuiFormGroup-root": {
      marginBottom: 15,
      "& p": {
        marginTop: 0,
      },
    },
    "& .MuiInputBase-input": {
      padding: [[5, 15]],
      height: 26,
      lineHeight: "26px",
      "&::placeholder": {
        color: "#999",
        opacity: 1,
      },
    },
  },
});
const useStyles = makeStyles(styles);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function UserInfoModal() {
  const [formData, setFormData] = useState(initialState);
  const [open, setOpen] = useState(false);
  const [formError, setErrors] = useState(initialState);
  const arrowClasses = DashboardStyle();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userInfo, idToken } = useSelector((state) => state.signIn.loggedUser);

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const formValidate = (name, value) => {
    switch (name) {
      case "jobFunction":
      case "source":
        if (value?.length === 0) {
          return "Please select";
        }
        return "";

      case "other":
        if (
          ["Other", "Podcast", "Youtube"].includes(formData?.source) &&
          !value
        ) {
          return "Please mention";
        }
        return "";

      default:
        return "";
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    event.preventDefault();
    const { name, value } = event.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));

    setErrors((formError) => ({
      ...formError,
      [name]: formValidate(name, value),
    }));
  };

  const updateUserAttributes = async () => {
    try {
      dispatch(LoaderAction(true));
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        [attributes.USER_ROLE]: formData.jobFunction,
        [attributes.USER_SOURCE]: initialText[formData.source]
          ? `${formData.source}-${formData.other}`
          : formData.source,
      });
      handleCloseModal();
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: "Could not update user attributes. Please try again.",
          type: "error",
        })
      );
    } finally {
      dispatch(LoaderAction(false));
    }
  };

  const handleSave = async () => {
    let validationErrors = {};

    Object.keys(formData).forEach((name) => {
      const error = formValidate(name, formData[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    await updateUserAttributes();
    try {
      await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}/user`,
        {
          method: "userprofile",
          role: formData.jobFunction,
          source: initialText[formData.source]
            ? `${formData.source}-${formData.other}`
            : formData.source,
        },
        {
          headers: {
            Authorization: idToken,
          },
        }
      );
    } catch (error) {
      console.log("Failed to update user role and source");
    }

    setErrors({});
  };

  useEffect(() => {
    try {
      if (!userInfo) {
        handleCloseModal();
        return;
      }

      if (!userInfo.role || !userInfo.source) {
        handleOpenModal();
      } else {
        handleCloseModal();
      }
    } catch (error) {
      handleCloseModal();
    }
  }, [userInfo?.role, userInfo?.source]);

  return (
    <Dialog
      open={open}
      style={{
        maxWidth: 500,
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        zIndex: "2001 !important",
      }}
      fullWidth
      className={classes.dialog}
    >
      <DialogTitle>Tell Us About Yourself!</DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <FormGroup>
          <p>Job Function</p>
          <CustomSelect
            fullWidth
            displayEmpty
            variant="outlined"
            MenuProps={{
              style: {
                zIndex: 2002,
              },
            }}
            value={formData?.jobFunction}
            IconComponent={() => (
              <ExpandMoreRoundedIcon className={arrowClasses.selectArrow} />
            )}
            name="jobFunction"
            onChange={handleInputChange}
          >
            <MenuItem value="" disabled>
              Select
            </MenuItem>
            {jobFunctionList.map((item, ind) => {
              return (
                <MenuItem value={item} key={ind}>
                  {item}
                </MenuItem>
              );
            })}
          </CustomSelect>
          {formError.jobFunction && (
            <Typography variant="body2" component="p" color="error">
              {formError.jobFunction}
            </Typography>
          )}
        </FormGroup>
        <FormGroup>
          <p>How did you hear about us?</p>
          <CustomSelect
            fullWidth
            displayEmpty
            variant="outlined"
            MenuProps={{
              style: {
                zIndex: 2002,
              },
            }}
            value={formData?.source}
            IconComponent={() => (
              <ExpandMoreRoundedIcon className={arrowClasses.selectArrow} />
            )}
            name="source"
            onChange={handleInputChange}
          >
            <MenuItem value="" disabled>
              Select
            </MenuItem>
            {sourceList.map((item, ind) => {
              return (
                <MenuItem value={item} key={ind}>
                  {item}
                </MenuItem>
              );
            })}
          </CustomSelect>
          {formError.source && (
            <Typography variant="body2" component="p" color="error">
              {formError.source}
            </Typography>
          )}
        </FormGroup>

        {(formData?.source === "Other" ||
          formData?.source === "Podcast" ||
          formData?.source === "Youtube") && (
          <FormGroup style={{ marginTop: 20 }}>
            <CustomTextField
              fullWidth
              name="other"
              value={formData.other}
              onChange={handleInputChange}
              placeholder={initialText?.[formData?.source]}
              variant="outlined"
            />
            {formError.other && (
              <Typography variant="body2" component="p" color="error">
                {formError.other}
              </Typography>
            )}
          </FormGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleSave}>
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
