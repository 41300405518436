import constants from "../constants";

const initialState = {
  isSuccess: false,
  adminList: []
};

const adminListReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ADMIN_LIST_REQUEST:
      return {
        ...state,
        adminList: []
      };
    case constants.GET_ADMIN_LISTS:
      return {
        ...state,
        isSuccess: true,
        adminList: action.payload,
      };
    case constants.REMOVE_ADMIN_RECORD:
      let cloneData = [...state.adminList]
      const index = cloneData.findIndex(p => p.ID === action.payload)
      if (index > -1) {
        cloneData.splice(index, 1);
      }
      return {
        ...state,
        isSuccess: true,
        adminList: cloneData,
      };
    case constants.EDIT_ADMIN_RECORD:
      const id = action?.payload?.payload?.id
      let clone = [...state.adminList]
      const ind = clone.findIndex(p => p.ID === id)
      if(action?.payload?.type === "class"){
        clone[ind].class = action?.payload?.payload?.class
      }else{
        clone[ind].processed = action?.payload?.payload?.processed
      }
      return {
        ...state,
        isSuccess: true,
        adminList: clone
      };
    default:
      return state;
  }
};
export default adminListReducer;
