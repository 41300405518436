import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastMessage } from "../../redux/actions";
import { LoaderAction } from "../../redux/loader/action";
import Modal, { DialogContent } from "../../shared/Modal";
import CustomSelect from "../Common/CustomSelect";
import TickerSearchSelect from "../Common/TickerSearchSelect";
import { alertTypes, channels, conditions } from "./alertSettings.description";

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: 4,
    "&.disabled": {
      opacity: 0.4,
    },
  },
  selectMenu: {
    "&.MuiPopover-root": {
      zIndex: "1600 !important",
    },
  },
  tickerSearchMenuPortal: {
    "&.select__menu-portal": {
      zIndex: 1600,
    },
  },
}));

const initialAlert = {
  alertType: "",
  ticker: "",
  channel: "",
  value: "",
  condition: "",
};

const CreateAlert = ({ ticker, open, onClose, onAdd = () => {} }) => {
  const [alert, setAlert] = useState(
    ticker ? { ...initialAlert, ticker } : initialAlert
  );
  const [errors, setErrors] = useState({});

  const classes = useStyles();
  const dispatch = useDispatch();
  const { idToken } = useSelector((state) => state.signIn.loggedUser);

  const showValue =
    alert.alertType === "insider" || alert.alertType === "price";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAlert((prev) => ({ ...prev, [name]: value }));
    validateField({ name, value });
  };

  const validateField = ({ name, value }) => {
    const errors = {};

    if (!value) {
      if (name === "condition" || name === "value") {
        if (alert.alertType === "price" || alert.alertType === "insider")
          errors[name] = "This field is required";
        else errors[name] = "";
      } else {
        errors[name] = "This field is required";
      }
    } else {
      if (name === "alertType") {
        if (value !== "price" && value !== "insider") {
          errors.condition = "";
          errors.value = "";
        }
      }
      errors[name] = "";
    }

    setErrors((prev) => ({ ...prev, ...errors }));

    return !errors[name];
  };

  const validate = (alert) => {
    let isValid = true;

    Object.keys(initialAlert).forEach((key) => {
      const isFieldValid = validateField({ name: key, value: alert[key] });
      if (!isFieldValid) isValid = false;
    });

    return isValid;
  };

  const createAlert = async () => {
    const isValid = validate(alert);
    if (!isValid) return;

    const url = `${process.env.REACT_APP_API_SERVER_URL}/user`;

    try {
      dispatch(LoaderAction(true));
      const { data } = await axios.post(
        url,
        {
          method: "createalert",
          ...alert,
        },
        {
          headers: {
            Authorization: idToken,
          },
        }
      );
      if (+data?.statusCode === 400) {
        dispatch(
          toastMessage({
            status: true,
            message: data.message,
            type: "error",
          })
        );
        return;
      }

      // On success
      onAdd(alert);
      onClose();
      setAlert(initialAlert);
      dispatch(
        toastMessage({
          status: true,
          message: "Alert created",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: "Failed to create an alert. Please try again later.",
          type: "error",
        })
      );
    } finally {
      dispatch(LoaderAction(false));
    }
  };

  return (
    <Modal
      title="New Alert"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { width: "100%", maxWidth: 500 },
      }}
    >
      <DialogContent>
        <Box mb={2}>
          <SelectControl
            name="alertType"
            error={errors.alertType}
            label="Alert Type"
            options={alertTypes}
            value={alert.alertType}
            onChange={handleChange}
          />
        </Box>
        <Box mb={2}>
          <FormControl fullWidth error={!!errors.ticker}>
            <Typography className={classes.label}>Ticker</Typography>
            <TickerSearchSelect
              menuPortalClassName={classes.tickerSearchMenuPortal}
              value={{ value: alert.ticker }}
              onChange={({ value }) => {
                setAlert((prev) => ({
                  ...prev,
                  ticker: value,
                }));
                validateField({ name: "ticker", value });
              }}
              isDisabled={!!ticker}
            />
            {!!errors.ticker && (
              <FormHelperText>{errors.ticker}</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box mb={2}>
          <SelectControl
            name="condition"
            error={errors.condition}
            label="Condition"
            options={conditions}
            value={alert.condition}
            onChange={handleChange}
            disabled={!showValue}
          />
        </Box>
        <Box mb={2}>
          <FormControl fullWidth error={!!errors.value}>
            <Typography
              className={`${classes.label} ${showValue ? "" : "disabled"}`}
            >
              Value
            </Typography>
            <TextField
              name="value"
              type="number"
              fullWidth
              variant="outlined"
              size="small"
              value={alert.value}
              onChange={handleChange}
              disabled={!showValue}
            />
            {!!errors.value && <FormHelperText>{errors.value}</FormHelperText>}
          </FormControl>
        </Box>
        <Box mb={2}>
          <SelectControl
            name="channel"
            error={errors.channel}
            label="Channel"
            options={channels}
            value={alert.channel}
            onChange={handleChange}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={createAlert}
        >
          Save
        </Button>
      </DialogContent>
    </Modal>
  );
};

const SelectControl = ({
  name,
  label,
  value,
  error,
  onChange,
  options,
  ...props
}) => {
  const classes = useStyles();

  const MenuProps = {
    className: classes.selectMenu,
  };

  return (
    <FormControl fullWidth error={error}>
      {label && (
        <Typography
          className={`${classes.label} ${props.disabled ? "disabled" : " "}`}
        >
          {label}
        </Typography>
      )}
      <CustomSelect
        name={name}
        fullWidth
        value={value || ""}
        onChange={onChange}
        MenuProps={MenuProps}
        {...props}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </CustomSelect>
      {!!error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default CreateAlert;
