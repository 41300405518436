import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { getWatchListApi } from "../../redux/api/watchlistApis";
import { debounce } from "../../utils/common";
import { useStyles as useSearchTickerStyles } from "../Common/SearchTicker";
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import axios from "axios";

export const useStyles = makeStyles(() => ({
  tickerSearch: {
    textAlign: "left",
    "& .select__control": {
      cursor: "text",
      borderRadius: 5,
    },
    "& .select__indicators": {
      marginRight: 6,
      "& svg path": {
        fill: "#898989",
      },
    },
    "& .select__multi-value__remove, & .select__clear-indicator ": {
      cursor: "pointer",
    },
  },
  menuPortal: {
    "&.select__menu-portal": {
      zIndex: 5,
    },
  },
}));

const getSearchResults = async (search, cb, idToken, ignoreEtf) => {
  let res;
  if (idToken) {
    res = await getWatchListApi(idToken, {
      search,
    });
  } else {
    const url = `https://public.fintwit.us/search?term=${search}`;
    res = await axios.get(url);
  }

  if (res.status === 200) {
    cb(
      res.data && Array.isArray(res.data)
        ? res.data
            .filter(({ isTicker, isETF }) =>
              ignoreEtf ? isETF !== 1 && isTicker === 1 : isTicker === 1
            )
            .map(({ ticker, company }) => ({
              value: ticker,
              label: company,
            }))
        : []
    );
  }

  cb([]);
};
const getSearchResultsDebounced = debounce(getSearchResults);

const TickerSearchSelect = ({
  className = "",
  menuPortalClassName = "",
  classNames,
  ignoreEtf,
  ...props
}) => {
  const classes = useStyles();
  const searchClasses = useSearchTickerStyles();
  const { idToken } = useSelector((state) => state.signIn.loggedUser);

  return (
    <AsyncSelect
      className={`${classes.tickerSearch} ${className}`}
      classNamePrefix="select"
      menuPortalTarget={document.body}
      components={{
        Option: ({ innerProps, isDisabled, data }) => {
          return !isDisabled ? (
            <div {...innerProps} className={searchClasses.searchListItem}>
              <div className={searchClasses.searchTitle}>{data.value}</div>
              <div className={searchClasses.searchSubTitle}>{data.label}</div>
            </div>
          ) : null;
        },
        SingleValue: ({ children, ...props }) =>
          props.selectProps.menuIsOpen ? null : (
            <components.SingleValue {...props}>
              {props.data.value}
            </components.SingleValue>
          ),
        IndicatorSeparator: null,
        DropdownIndicator: () => {
          return <SearchIcon />;
        },
        MultiValue: ({ children, ...props }) => (
          <components.MultiValue {...props}>
            {props.data.value}
          </components.MultiValue>
        ),
        Menu: (props) =>
          props.selectProps.inputValue ? <components.Menu {...props} /> : null,
      }}
      noOptionsMessage={() => "No Match"}
      name="ticker"
      placeholder="Search ticker"
      cacheOptions
      loadOptions={(...props) =>
        getSearchResultsDebounced(...props, idToken, ignoreEtf)
      }
      classNames={{
        menuPortal: () => `${classes.menuPortal} ${menuPortalClassName}`,
        ...classNames,
      }}
      {...props}
    />
  );
};

export default TickerSearchSelect;
