import {
  Box,
  Container,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SetHelmet from "../Common/SetHelmet";
import PublicChatList from "./PublicChatList";
import Loader from "../Common/Loader";
import ButtonWithArrow from "../Common/ButtonWithArrow";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 140,
    paddingBottom: 100,
    [theme.breakpoints.down("767")]: {
      paddingTop: 88,
    },
    [theme.breakpoints.down("600")]: {
      paddingTop: 88,
    },
  },
  title: {
    fontSize: 56,
    marginTop: 20,
    marginBottom: 30,
    fontWeight: 800,
    maxWidth: 950,
    marginLeft: "auto",
    marginRight: "auto",
    "& span": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
  },
  info: {
    fontSize: 22,
    maxWidth: 550,
    margin: "auto",
    marginBottom: 35,
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  chatButton: {},
  featureList: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  chatMediaWrapper: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  videoContainer: {
    position: "relative",
    width: "100%",
    paddingBottom: "56.25%",
    overflow: "hidden",
    "& iframe": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: 0,
    },
  },
  featuresWrapper: {
    display: "flex",
    alignItems: "flex-start",
    gap: 32,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  feature: {
    paddingTop: 18,
    paddingBottom: 18,
    "&:first-child": {
      paddingTop: 0,
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 15,
      paddingBottom: 15,
    },
  },
  featureName: {
    fontWeight: 700,
    marginBottom: 4,
    fontSize: 24,
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  featureDescription: {
    fontSize: 17,
    color: "#333",
  },
}));

const features = [
  {
    name: "Real-Time Information",
    description: `Get the latest news and information on US public companies
    instantly.`,
  },
  {
    name: "Cited Sources",
    description: `Trust in the accuracy of the information provided through
    direct citations, ensuring reliability and transparency.`,
  },
  {
    name: "Comprehensive Coverage",
    description: `Search across SEC filings (10-K, 10-Q), earnings transcripts, market data or web search.`,
  },
  {
    name: "Powerful AI Models",
    description: `Powered by advanced AI models such as GPT-4o and Gemini 1.5 Pro for unparalleled accuracy and speed.`,
  },
  {
    name: "User-Friendly Interface",
    description: `Ask questions naturally and receive information neatly
    organized for quick understanding.`,
  },
];

const ChatNowButton = (props) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <ButtonWithArrow
        radius="30"
        btnPadding="15px 40px"
        variant="contained"
        className={classes.chatButton}
        onClick={() => history.push("/sign-up")}
        hoverEffect
        fontSize={18}
      >
        Chat Now
      </ButtonWithArrow>
    </>
  );
};

const PublicChat = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [chats, setChats] = useState();
  const [demoVideo, setDemoVideo] = useState({ url: null, show: false });

  const classes = useStyles();

  const getChats = async () => {
    const url = `${process.env.REACT_APP_PUBLIC_CHAT_API_URL}?start=0&limit=20`;

    try {
      setIsLoading(true);
      const { data } = await axios.get(url);
      setChats(data?.chats);
      setDemoVideo({ show: data?.showdemo, url: data?.demo });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getChats();
  }, []);

  return (
    <>
      <SetHelmet
        title="AI Stock Market Analyst - Fintwit Chat"
        description="AI Stock Market Analyst - Fintwit Chat"
      />
      <Loader isLoad={isLoading} />
      <div className={classes.container}>
        <Container maxWidth="lg">
          <Box mb={12} textAlign="center">
            <Typography variant="h4" className={classes.title}>
              Get <span>Real-Time</span> Financial Intelligence at Your
              Fingertips
            </Typography>
            <Typography className={classes.info}>
              Supercharge your research with our AI assistant and save time
              finding your next great investment idea
            </Typography>
            <ChatNowButton />
          </Box>

          <Box className={classes.featuresWrapper}>
            <Box className={classes.featureList}>
              {features.map(({ name, description }) => (
                <Fragment key={name}>
                  <Box className={classes.feature}>
                    <Typography variant="h5" className={classes.featureName}>
                      {name}
                    </Typography>
                    <Typography className={classes.featureDescription}>
                      {description}
                    </Typography>
                  </Box>
                  <Divider light />
                </Fragment>
              ))}
            </Box>
            <Box className={classes.chatMediaWrapper}>
              {isLoading ? null : demoVideo.show && demoVideo.url ? (
                <Box className={classes.videoContainer}>
                  <iframe
                    width="100%"
                    height="100%"
                    src={demoVideo?.url}
                    title="AI top picks"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </Box>
              ) : (
                <img src="/chat-example.png" alt="" />
              )}
            </Box>
          </Box>
          {(!isLoading || !!chats?.length) && <PublicChatList chats={chats} />}
        </Container>
      </div>
    </>
  );
};

export default PublicChat;
