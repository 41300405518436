import { makeStyles } from "@material-ui/core";

export const useChatStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 140,
    paddingBottom: 60,
    lineHeight: 1.4,
    [theme.breakpoints.down("767")]: {
      paddingTop: 88,
    },
    [theme.breakpoints.down("600")]: {
      paddingTop: 88,
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 28,
    marginBottom: 24,
    letterSpacing: -1,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    display: "flex",
    gap: 16,
    "& svg": {
      height: 26,
      width: 24,
      marginTop: 6,
      flexShrink: 0,
      [theme.breakpoints.down("sm")]: {
        height: 22,
        width: 20,
      },
    },
  },
  subHeading: {
    fontWeight: 700,
    letterSpacing: -1,
    fontSize: 20,
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
    "& svg": {
      width: 20,
      height: 24,
      marginRight: 8,
    },
  },
  answer: {
    marginTop: 0,
    fontSize: 17,
    "& .MuiTypography-root": {
      fontSize: 17,
      marginBottom: 10,
      lineHeight: 1.5,
      fontFamily: "'Roboto', sans-serif",
    },
  },
  answerActions: {
    marginTop: 10,
    display: "flex",
    marginLeft: -12,
    "& .MuiButtonBase-root": {
      color: "gray",
      borderRadius: 20,
      paddingLeft: 18,
      paddingRight: 18,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
    },
  },
  source: {
    paddingLeft: 4,
    "& img": {
      marginLeft: 4,
    },
    "& a": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: "#000",
      maxWidth: "100%",
      gap: 8,
    },
  },
  relatedQuestion: {
    fontSize: 17,
    fontWeight: 500,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
  sourceNumber: {
    textDecoration: "none",
    color: "inherit",
    userSelect: "none",
    "&:hover": {
      "& span": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
      },
    },
    "& span": {
      marginLeft: 4,
      marginRight: 6,
      fontSize: 10,
      borderRadius: "50%",
      backgroundColor: "lightgray",
      padding: "2px 5px",
      verticalAlign: "text-top",
    },
  },
}));
