import constants from "../constants";

const initialState = {
  redirectUrl: localStorage.getItem("deepLinkingUrl") || "/home",
};

const redirectToReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_REDIRECT_URL:
      return {
        redirectUrl: action.payload,
      };
    case constants.RESET_REDIRECT_URL:
      return {
        redirectUrl: "/",
      };
    default:
      return state;
  }
};

export default redirectToReducer;
