import constants from "../constants";

const initialState = {
  show: false,
  message: null,
  link: null,
};

const notificationBannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SHOW_NOTIFICATION_BANNER:
      document.body.classList.add("notification-banner");
      return { ...action.payload, show: true };

    case constants.HIDE_NOTIFICATION_BANNER:
      document.body.classList.remove("notification-banner");
      return { show: false };

    default:
      return state;
  }
};

export default notificationBannerReducer;
