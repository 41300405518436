import axios from "axios";

export const invokeAppGuideApi = ({ idToken, page }) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const payload = { method: "appguide", page, action: "start" };

  const response = axios.post(
    `${process.env.REACT_APP_API_SERVER_URL}/user`,
    payload,
    config
  );
  return response;
};

export const finishAppGuideApi = ({ idToken, page }) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const payload = { method: "appguide", page, action: "finish" };

  const response = axios.post(
    `${process.env.REACT_APP_API_SERVER_URL}/user`,
    payload,
    config
  );
  return response;
};

export const cancelAppGuideApi = ({ idToken, page, sequence }) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const payload = { method: "appguide", page, sequence, action: "cancel" };

  const response = axios.post(
    `${process.env.REACT_APP_API_SERVER_URL}/user`,
    payload,
    config
  );
  return response;
};
