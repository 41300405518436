import React, { useEffect, useState } from "react";
import { Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import { debounce } from "lodash";
import { getWatchListApi } from "../../redux/api/watchlistApis";
import { signInClearAction } from "../../redux/auth/action";
import { useDispatch, useSelector } from "react-redux";
import CustomInputField from "../Common/CustomInputField";
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import { intToString } from "../../utils/common";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  searchInstitutionAction,
  searchStockTickerAction,
} from "../../redux/watchList/action";
import { floatWithTwoDecimal } from "../../utils/regex";
import { getPopularData } from "../../redux/actions";
import { LoaderAction } from "../../redux/loader/action";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

export const useStyles = makeStyles((theme) => ({
  searchBox: {
    position: "relative",
    maxWidth: 210,
    width: "100%",
    transition: "all 0.5s ease-in-out",
    "& .MuiFormControl-root": {
      "& input": {
        "&.MuiInputBase-input": {
          paddingLeft: 45,
          height: 22,
        },
      },
      "& .MuiInputBase-root": {
        transition: "all 0.5s ease-in-out",
        maxWidth: "100%",
        "&:not(.Mui-focused)": {
          [theme.breakpoints.up("md")]: {
            maxWidth: 360,
          },
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        [theme.breakpoints.down("480")]: {
          opacity: 0,
        },
      },
    },
    "& label": {
      margin: 0,
      position: "absolute",
      left: 15,
      top: 12,
      zIndex: 9,
      "& img": {
        width: "20px",
      },
      "& svg": {
        width: "20px",
      },
    },
  },
  applyIndex: {
    zIndex: "8 !important",
  },
  searchTickerAnalyst: {
    zIndex: "8 !important",
    [theme.breakpoints.down("sm")]: {
      "&$searchListInfo": {
        maxHeight: "initial",
        "& .MuiGrid-root": {
          "& .MuiGrid-root": {
            maxHeight: 180,
            overflowY: "auto",
          },
        },
      },
    },
  },
  serachList: {
    display: "none",
    position: "absolute",
    right: 0,
    left: 0,
    backgroundColor: "#fff",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 10,
    marginTop: 5,
    overflow: "hidden",
    zIndex: 10,
    padding: 0,
    maxHeight: 500,
    overflow: "auto",
    [theme.breakpoints.up("md")]: {
      minWidth: 700,
    },
    "& .MuiGrid-root": {
      "& .MuiGrid-item": {
        marginTop: 10,
        marginBottom: 10,
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  searchticker: {
    borderRight: "1px solid #d4d6d8",
    position: "relative",
  },
  searchCompany: {
    textAlign: "left",
    width: "100%",
  },
  searchAnalyst: {
    textAlign: "left",
    borderLeft: "solid 1px #e4e4e4",
    flexGrow: 0,
    [theme.breakpoints.down("sm")]: {
      borderTop: "solid 1px #e4e4e4",
      borderLeft: 0,
    },
  },
  searchOpen: {
    display: "block",
  },
  searchListItem: {
    display: "flex",
    alignItems: "center",
    padding: [[8, 15]],
    cursor: "pointer",
    userSelect: "none",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  searchTitle: {
    width: 70,
    flex: "0 0 70px",
    fontWeight: "bold",
    color: "#0f69ff",
    fontSize: 14,
    "& img": {
      position: "relative",
      left: "0 !important",
      height: 50,
      width: 50,
      borderRadius: 50,
      top: 5,
    },
  },
  searchSubTitle: {
    padding: "0",
    display: "block",
    fontWeight: "normal",
    userSelect: "none",
    "&:nth-child(2)": {
      width: "40%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "#5b636a",
      fontSize: 14,
    },
    "&:nth-child(3)": {
      width: "20%",
      textAlign: "right",
      fontSize: 14,
    },
    "&:nth-child(4)": {
      width: "20%",
      textAlign: "right",
      fontSize: 14,
    },
  },
  userName: {
    fontWeight: "bold",
    textAlign: "justify",
    "& span": {
      color: "rgba(0, 0, 0, 0.54)",
      "& svg": {
        verticalAlign: "middle",
      },
      "& span": {
        verticalAlign: "-1px",
      },
    },
  },
  header: {
    fontWeight: "bold",
    marginBottom: "10px",
    borderBottom: "solid 1px #d4d6d8",
    marginTop: 10,
    padding: [[10, 20]],
    "& svg": {
      verticalAlign: "middle",
      marginRight: 5,
    },
  },
  divider: {
    width: "90%",
    marginLeft: 10,
  },
  searchClose: {
    display: "none",
    position: "absolute",
    right: 10,
    top: 10,
    backgroundColor: "#fff",
  },
  searchVisible: {
    [theme.breakpoints.down("600")]: {
      width: "calc(100% - 20px) !important",
    },
    "& .MuiFormControl-root": {
      [theme.breakpoints.down("480")]: {
        "& .MuiOutlinedInput-notchedOutline": {
          opacity: 1,
        },
      },
    },
  },
  closeVisible: {
    [theme.breakpoints.down("600")]: {
      display: "block",
    },
  },
  searchListInfo: {
    maxHeight: 300,
    overflow: "auto",
  },
  searchFocus: {
    maxWidth: "700px !important",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "570px !important",
    },
  },
  institutionTitle: {
    width: "100%",
    flex: "none",
  },
  popularTickers: {
    borderLeft: "solid 1px #d4d6d8",
    [theme.breakpoints.down("md")]: {
      border: 0,
    },
  },
  searchTickersData: {
    "& .MuiGrid-root": {
      "&.MuiGrid-item": {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
}));
const SearchTicker = ({
  isLanding = false,
  extraClass,
  dropdownClass,
  isClickNeeded = false,
  popular,
  onTickerClick,
  onFocus = () => {},
  id,
  ...props
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchFocus, setSearchFocus] = React.useState(false);
  const [searchHandleChange, setSearchHandleChange] = React.useState("");
  const [searchLoader, setSearchLoader] = React.useState(false);
  const [searchList, setSearchList] = React.useState([]);
  const isLog = useSelector((state) => state.signIn.loggedUser);
  const [analystData, setAnalystData] = useState([]);
  const [institutionData, setInstitutionData] = useState([]);
  const [tickerData, setTickerData] = useState([]);
  const [searchVisible, setSearchVisible] = React.useState(false);
  const [isPopular, setIsPopular] = useState(false);
  const { popularData, recentlyViewedData } = useSelector(
    (state) => state.watchListReducer
  );
  const handleSearch = async (val) => {
    if (!isLanding) {
      if (val?.length > 0) {
        try {
          const apiResponse = await getWatchListApi(isLog.idToken, {
            search: val,
          });
          if (apiResponse.status === 200) {
            let data = apiResponse?.data || [];
            setSearchList(data);
            setSearchLoader(false);
          }
        } catch (error) {
          setSearchLoader(false);
          if (error?.response?.status === 401) {
            dispatch(signInClearAction(true));
          }
        }
      }
    } else {
      try {
        const gitHubUrl = `https://public.fintwit.us/search?term=${val}`;
        const apiResponse = await axios.get(gitHubUrl);
        if (apiResponse.status === 200) {
          let data = apiResponse?.data || [];
          setSearchList(data);
          setSearchLoader(false);
        }
      } catch (error) {
        setSearchLoader(false);
        if (error?.response?.status === 401) {
          dispatch(signInClearAction(true));
        }
      }
    }
  };

  /* eslint-disable */
  const setDisplayedContacts = React.useCallback(
    debounce((searchValue) => {
      handleSearch(searchValue);
    }, 400),
    []
  );

  const handleChange = ({ target: { value } }) => {
    setIsPopular(false);
    setSearchLoader(true);
    setDisplayedContacts(value);
    setSearchHandleChange(value);
  };

  const handleRightClick = (e, item) => {
    if (item["ticker"]) {
      window.open(`/quote/${item.ticker}/summary`, "_blank").focus();
    } else {
      window.open(`/analyst?id=${item.tUserID}`, "_blank").focus();
    }
    e.preventDefault();
    return false;
  };

  const handleClick = (item) => {
    if (onTickerClick) {
      onTickerClick(item.ticker);
      return;
    }

    if (item["ticker"]) {
      history.push("/quote/" + item.ticker + "/summary");
      dispatch(searchStockTickerAction(item.ticker));
    } else {
      history.push("/analyst?id=" + item.tUserID);
    }
    return false;
  };

  useEffect(() => {
    if (searchList.length > 0) {
      setTickerData(searchList?.filter((item) => item.isTicker === 1));
      setAnalystData(
        searchList?.filter(
          (item) => item.isTicker === 0 && !item?.isInstitution
        )
      );
      setInstitutionData(
        searchList?.filter((item) => item.isInstitution === 1)
      );
    }
  }, [searchList]);

  /* const getPopularListData = async () => {
    const gitHubUrl = "https://public.fintwit.us/quote/mention";

    dispatch(LoaderAction(true));
    const response = await axios.get(gitHubUrl);

    if (response.status === 200) {
      dispatch(getPopularData(response.data));
      dispatch(LoaderAction(false));
    } else {
      dispatch(LoaderAction(false));
    }
  };
  useEffect(() => {
    getPopularListData();
  }, []); */

  const searchClassName = searchVisible ? classes.searchVisible : "";
  const onClickInput = () => {
    setIsPopular(true);
  };
  return (
    <div
      className={`${
        searchFocus || searchHandleChange !== ""
          ? `${classes.searchBox} ${
              !isLanding ? classes.searchFocus : ""
            } ${searchClassName}`
          : classes.searchBox
      } ${extraClass} ${searchClassName}`}
      id={id}
    >
      <label>
        <SearchIcon
          onClick={
            !isLog?.idToken || !isLanding ? () => setSearchVisible(true) : null
          }
        />
      </label>
      <CustomInputField
        fullWidth
        variant="outlined"
        placeholder={
          props.placeholder
            ? props.placeholder
            : innerWidth < 430 && isLog?.idToken
            ? "Search by stock, ETF or institution"
            : innerWidth < 430 && !isLog?.idToken
            ? "Search stock or ETF"
            : !isLog?.idToken
            ? "Search stock or ETF"
            : "Search by stock, ETF or institution"
        }
        value={props.value ?? searchHandleChange}
        onChange={(e) => {
          if (!props.handleChange) handleChange(e);
          else {
            props.handleChange(e);
            if (props.handleChangeWithSearch) handleChange(e);
          }
        }}
        onFocus={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setSearchFocus(!searchFocus);
          }
          onFocus();
        }}
        onClick={isClickNeeded && onClickInput}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setTimeout(() => {
              setSearchHandleChange("");
              setIsPopular(false);
              setSearchFocus(false);
            }, 300);
          }
        }}
      />
      <div
        className={`${searchVisible ? classes.closeVisible : ""} ${
          classes.searchClose
        }`}
        onClick={() => setSearchVisible(false)}
      >
        <CloseIcon />
      </div>

      <div
        className={`${classes.serachList} ${
          popularData?.length > 0 ? `${classes.searchOpen}` : ""
        } ${!props.isAccount && `${classes.applyIndex}`}`}
      >
        <Grid
          container
          direction={{ xs: "column-reverse", md: "column" }}
          className={classes.searchTickersData}
        >
          <Grid item md={isLanding ? 12 : 6} xs={12}>
            <div>
              {isPopular && popularData?.length > 0 && (
                <div
                  className={`${classes.searchCompany} ${
                    !props.isAccount && dropdownClass
                  }`}
                >
                  {!props.isAccount && (
                    <div className={classes.header}>
                      <TrendingUpIcon /> Popular Tickers
                    </div>
                  )}
                  {popularData?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={classes.searchListItem}
                        onClick={() =>
                          !props.onClick
                            ? handleClick(item)
                            : props.onClick(item)
                        }
                        onContextMenu={(e) =>
                          !props.onClick
                            ? handleRightClick(e, item)
                            : props.onRightClick(item)
                        }
                      >
                        <div className={classes.searchTitle}>
                          {item?.ticker}
                        </div>
                        <div className={classes.searchSubTitle}>
                          {item?.company}
                        </div>
                        <div className={classes.searchSubTitle}>
                          ${floatWithTwoDecimal(item?.Price) || ""}
                        </div>
                        <div className={classes.searchSubTitle}>
                          <Typography
                            variant="body2"
                            component="span"
                            style={{
                              color:
                                item?.priceChangePerc === 0
                                  ? "inherit"
                                  : item?.priceChangePerc > 0
                                  ? "#008000"
                                  : "#ff0000",
                            }}
                          >
                            <span>{`${
                              Number(item?.priceChangePerc) > 0
                                ? "+" + item?.priceChangePerc
                                : item?.priceChangePerc
                            }%`}</span>
                          </Typography>{" "}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Grid>
          {!isLanding && (
            <Grid item md={6} xs={12}>
              <div className={classes.popularTickers}>
                {isPopular && !isLanding && recentlyViewedData?.length > 0 && (
                  <div
                    className={`${classes.searchCompany} ${
                      !props.isAccount && dropdownClass
                    }`}
                  >
                    {!props.isAccount && (
                      <div
                        className={classes.header}
                        style={{ paddingBottom: 14 }}
                      >
                        Recently Viewed Tickers
                      </div>
                    )}
                    {recentlyViewedData?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={classes.searchListItem}
                          onClick={() =>
                            !props.onClick
                              ? handleClick(item)
                              : props.onClick(item)
                          }
                          onContextMenu={(e) =>
                            !props.onClick
                              ? handleRightClick(e, item)
                              : props.onRightClick(item)
                          }
                        >
                          <div className={classes.searchTitle}>
                            {item?.ticker}
                          </div>
                          <div className={classes.searchSubTitle}>
                            {item?.company}
                          </div>
                          <div className={classes.searchSubTitle}>
                            ${floatWithTwoDecimal(item?.Price) || ""}
                          </div>
                          <div className={classes.searchSubTitle}>
                            <Typography
                              variant="body2"
                              component="span"
                              style={{
                                color:
                                  item?.priceChangePerc === 0
                                    ? "inherit"
                                    : item?.priceChangePerc > 0
                                    ? "#008000"
                                    : "#ff0000",
                              }}
                            >
                              <span>{`${
                                Number(item?.priceChangePerc) > 0
                                  ? "+" + item?.priceChangePerc
                                  : item?.priceChangePerc
                              }%`}</span>
                            </Typography>{" "}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </div>

      {searchLoader ? (
        <div
          className={`${classes.serachList} ${
            searchHandleChange?.length > 0
              ? `${classes.searchOpen}`
              : classes.serachList
          }`}
        >
          <div className={classes.searchListItem}>
            <div className={classes.searchSubTitle}>Loading...</div>
          </div>
        </div>
      ) : (
        <div
          className={`${classes.serachList} ${
            searchHandleChange?.length > 0
              ? `${classes.searchOpen} ${classes.searchListInfo}`
              : ""
          } ${
            !props.isAccount &&
            `${!isLanding ? classes.searchTickerAnalyst : ""}`
          }`}
        >
          {!searchLoader && searchList.length > 0 ? (
            <Grid container>
              {tickerData.length > 0 && (
                <Grid items md={isLanding ? 12 : 12} xs={12}>
                  <div
                    className={`${classes.searchCompany} ${
                      !props.isAccount && dropdownClass
                    }`}
                  >
                    {!props.isAccount && (
                      <div className={classes.header}>Stocks/ETFs</div>
                    )}
                    {tickerData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={classes.searchListItem}
                          onClick={() =>
                            !props.onClick
                              ? handleClick(item)
                              : props.onClick(item)
                          }
                          onContextMenu={(e) =>
                            !props.onClick
                              ? handleRightClick(e, item)
                              : props.onRightClick(item)
                          }
                        >
                          {item.isTicker === 1 && (
                            <>
                              <div className={classes.searchTitle}>
                                {item.ticker}
                              </div>
                              <div className={classes.searchSubTitle}>
                                {item.company}
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              )}
              {!props.isAccount && institutionData.length > 0 && (
                <Grid items xs={12}>
                  <div className={`${classes.searchAnalyst} ${dropdownClass}`}>
                    <div className={classes.header}>Institution</div>
                    {institutionData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={classes.searchListItem}
                          onClick={() => {
                            history.push(`/investor/${item?.cik}/summary`);
                            dispatch(searchInstitutionAction(item.cik));
                          }}
                          onContextMenu={(e) => handleRightClick(e, item)}
                        >
                          {item?.isTicker === 0 && (
                            <>
                              <div
                                className={`${classes.searchTitle} ${classes.institutionTitle}`}
                              >
                                {item?.name}
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              )}
              {!props.isAccount && analystData.length > 0 && (
                <Grid items xs={12}>
                  <div className={`${classes.searchAnalyst} ${dropdownClass}`}>
                    <div className={classes.header}>Analyst</div>
                    {analystData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={classes.searchListItem}
                          onClick={() => handleClick(item)}
                          onContextMenu={(e) => handleRightClick(e, item)}
                        >
                          {item.isTicker === 0 && (
                            <>
                              <div className={classes.searchTitle}>
                                <img
                                  src={item.profilePicBigger}
                                  alt={item.tUserName}
                                />
                              </div>
                              <div className={classes.userName}>
                                {item.name}
                                <span className={classes.searchSubTitle}>
                                  @{item.tUserName}
                                </span>
                                <span className={classes.searchSubTitle}>
                                  <PersonIcon />
                                  <span>
                                    {intToString(item.followerCount)}
                                  </span>{" "}
                                  <span>Followers</span>
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              )}
            </Grid>
          ) : (
            <div className={classes.searchListItem}>
              <div className={classes.searchSubTitle}>Data Not Found</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(SearchTicker);
