import { Box, Button, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import leftIcon from "../../assets/images/left-icon.svg";
import rightIcon from "../../assets/images/right-icon.svg";

const useStyles = makeStyles(() => ({
  disabled: {
    opacity: 0.3,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    "& .MuiButton-root": {
      marginLeft: 8,
      padding: [[8]],
      minWidth: "auto",
      "& img": {
        height: 14,
        minWidth: 8,
      },
    },
  },
}));

const PaginationActions = ({
  page,
  count = 0,
  rowsPerPage,
  onPageChange,
  isDisabled,
}) => {
  const classes = useStyles();

  const isPrevButtonDisabled = isDisabled || page === 0;
  const isNextButtonDisabled = isDisabled || count < rowsPerPage;

  return (
    <Box className={classes.actions}>
      <Button disabled={isPrevButtonDisabled} onClick={() => onPageChange(0)}>
        <img
          src={leftIcon}
          alt="first"
          className={classNames({
            [classes.disabled]: isPrevButtonDisabled,
          })}
        />
        <img
          src={leftIcon}
          alt="first"
          className={classNames({
            [classes.disabled]: isPrevButtonDisabled,
          })}
        />
      </Button>
      <Button
        disabled={isPrevButtonDisabled}
        onClick={() => onPageChange(page - 1)}
      >
        <img
          src={leftIcon}
          alt="first"
          className={classNames({
            [classes.disabled]: isPrevButtonDisabled,
          })}
        />
      </Button>
      <Button
        disabled={isNextButtonDisabled}
        onClick={() => onPageChange(page + 1)}
      >
        <img
          src={rightIcon}
          alt="first"
          className={classNames({
            [classes.disabled]: isNextButtonDisabled,
          })}
        />
      </Button>
    </Box>
  );
};

export default PaginationActions;
