import { Dialog, DialogTitle, Typography, Box } from "@material-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useStyles } from "../SignUp/SignUp";
import CustomButton from "./CustomButton";
import logo from "../../assets/images/logo-dark.png";
import CloseIcon from "@material-ui/icons/Close";

const SignUpModel = ({ handleClose, open }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Dialog onClose={handleClose} open={open} className={classes.signUpModel}>
      <Link to="/" variant="h6" className={classes.logo}>
        <img src={logo} alt="logo" />
      </Link>
      <CloseIcon onClick={handleClose} className={classes.closeIcon} />
      <Typography variant="h5" component="h5" className={classes.title}>
        <DialogTitle>Stay Ahead, Trade Smart</DialogTitle>
      </Typography>
      <div className={classes.loginSignupBottom}>
        <Box mb={4}>
          <Typography variant="body1" component="p">
            Join thousands of investors who are using our AI powered stock
            analysis and recommendations to make smarter investment decisions.
          </Typography>
        </Box>
        <CustomButton
          radius="5"
          btnPadding="10px 20px"
          variant="contained"
          onClick={() => {
            history.push("/sign-up");
            handleClose();
          }}
        >
          Sign Up for Free
        </CustomButton>

        <Typography variant="body1" component="p">
          <p> No credit card required</p>
        </Typography>
        <Typography
          className={classes.registerText}
          variant="body1"
          component="p"
          mb={0}
        >
          {" "}
          Already a member?
          <div onClick={handleClose}>
            <Link to="/sign-in">Sign in Here</Link>
          </div>
        </Typography>
      </div>
    </Dialog>
  );
};

export default SignUpModel;
