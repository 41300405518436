import constants from "../constants";

const initialState = {
  run: false,
  stepIndex: 0,
  isTourActive: false,
};

const dashboardGuideReducer = (
  state = initialState,
  { type, payload } = {}
) => {
  switch (type) {
    case constants.UPDATE_DASHBOARD_GUIDE_STATE:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default dashboardGuideReducer;
