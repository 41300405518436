import { makeStyles } from "@material-ui/core";
import borderTop from "../../../assets/images/card-border-top.png";
import shadow from "../../../assets/images/premium-shadow.png";
import bannerImage from "../../../assets/images/banner-bg.png";
import { asSeenOn } from "../../../utils/constants";

export default makeStyles((theme) => ({
  marketPageContainer: {
    maxWidth: 1440,
  },
  termConditionWrap: {
    padding: [[150, 0, 40]],
    [theme.breakpoints.down("767")]: {
      padding: [[80, 0, 40]],
    },
  },
  savings: {
    borderRadius: 4,
    marginTop: 8,
  },
  bannerImg: {
    position: "relative",
    alignItems: "center",
    paddingTop: 130,
    [theme.breakpoints.down("767")]: {
      paddingTop: 80,
    },
  },
  searchBottomList: {
    marginBottom: 20,
    maxWidth: "70%",
    display: "flex",
    [theme.breakpoints.down("991")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.down("380")]: {
      display: "block",
    },
    "& > .MuiBox-root": {
      paddingRight: 15,
      marginRight: 15,
      "&:not(:last-child)": {
        borderRight: "1px solid #ddd",
      },
      "& a": {
        fontWeight: "bold",
        fontSize: 14,
      },
      "& .swiper": {
        // padding:[[0,20]]
        "& .swiper-button-prev": {
          height: 30,
          width: 30,
          border: "2px solid #C4C4C4",
          borderRadius: "100%",
          backgroundColor: "#fff",
          padding: 10,
          left: 0,
          "&:after": {
            fontSize: 12,
            color: "#000",
            fontWeight: "bold",
          },
        },
        "& .swiper-button-next": {
          height: 30,
          width: 30,
          border: "2px solid #C4C4C4",
          borderRadius: "100%",
          backgroundColor: "#fff",
          right: 22,
          [theme.breakpoints.down(375)]: {
            right: 0,
          },
          "&:after": {
            fontSize: 12,
            color: "#000",
            fontWeight: "bold",
          },
        },
        "& .swiper-button-disabled": {
          opacity: 0,
        },
      },
    },
  },
  tickerNameP: {
    color: "#0f69ff",
    textAlign: "left",
    margin: [[0, 0, 8, 0]],
  },
  tickerNameP2: {
    fontSize: 15,
    margin: [[0, 0, 10, 0]],
  },
  tickerRedGreen: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  subHeader: {
    fontSize: 21,
    lineHeight: "28px",
    maxWidth: 650,
    margin: [[0, 0, 50, 0]],
    color: "#595959",
    fontWeight: 600,
    fontFamily: "Mulish",
    minHeight: 84,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "24px",
      textAlign: "center",
      marginBottom: 30,
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 400,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 17,
      lineHeight: "22px",
    },
  },
  tickerDatas: {
    fontSize: 15,
    margin: [[0, 0, 0, 4]],
  },
  bannerContent: {
    // textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    "& h1": {
      marginBottom: 24,
      fontSize: 80,
      fontWeight: 700,
      lineHeight: "80px",
      [theme.breakpoints.down("1366")]: {
        fontSize: 64,
        lineHeight: "68px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 59,
        lineHeight: 1.2,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 48,
        lineHeight: 1.2,
        marginBottom: 15,
        fontWeight: 800,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 44,
        lineHeight: 1.2,
      },
      "& .gradientText": {
        backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        WebkitBackgroundClip: "text",
        color: "transparent",
        display: "inline-block",
        lineHeight: 1.2,
      },
      "& span.subHeader": {
        display: "block",
        fontWeight: 400,
        fontSize: 30,
        marginTop: 10,
        color: "#404040",
        [theme.breakpoints.down("1366")]: {
          fontSize: 28,
        },
        [theme.breakpoints.down("md")]: {
          fontSize: 22,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 20,
          marginTop: 10,
          lineHeight: "30px",
        },
      },
    },
  },
  bannerPInfo: {
    lineHeight: "28px",
    maxWidth: 650,
    margin: [[0, 0, 30, 0]],
    color: "#898989",
    textAlign: "left",
    [theme.breakpoints.down("1366")]: {
      maxWidth: 550,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "22px",
      fontWeight: 400,
      textAlign: "center",
      marginBottom: 30,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  bannerSearch: {
    marginBottom: 30,
    maxWidth: 500,
    boxShadow: "0 0 10px rgba(0,0,0,0.2)",
    width: "100%",
    borderRadius: 50,
    [theme.breakpoints.down("sm")]: {
      margin: [[0, "auto", 20]],
    },
    "& .MuiFormControl-root": {
      "& .MuiInputBase-root:not(.Mui-focused)": {
        maxWidth: "100%",
      },
      "& .MuiInputBase-input": {
        height: "38px !important",
        paddingLeft: "65px !important",
        [theme.breakpoints.down("sm")]: {
          height: "32px !important",
        },
      },
      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: 2,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 40,
        borderWidth: 2,
      },
    },
    "& label": {
      borderRadius: 40,
      width: 40,
      height: 40,
      top: 13,
      left: 10,
      lineHeight: "45px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        top: 10,
        left: 7,
      },
      "& svg": {
        width: 50,
        height: 25,
        "& path": {
          fill: "#E9E9E9",
        },
      },
    },
  },
  bannerSearchDropdown: {
    maxWidth: "100%",
    flexBasis: "100%",
  },
  mainContent: {
    display: "block",
    padding: [[20, 0, 100]],
    [theme.breakpoints.down("767")]: {
      paddingBottom: 30,
    },
  },
  bannerBg: {
    marginTop: "-10%",
  },
  contentBg: {
    padding: [[0, 32]],
  },
  aboutBg: {
    minHeight: "calc(100vh - 62px)",
    backgroundColor: "#000",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  aboutHeading: {
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 48,
    marginBottom: 30,
    color: theme.palette.primary.main,
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
  },
  contentResBg: {
    backgroundColor: "#021420",
    padding: "0 20px",
  },
  contentTxtLanding: {
    fontSize: 23,
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 400,
    textAlign: "center !important",
    "& span": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  contentTxt: {
    fontSize: 20,
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 400,
    textAlign: "left",
    "& span": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  textWhite: {
    color: "#fff",
  },
  contentContainer: {
    padding: "150px 0px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "88px 0px 0",
    },
  },
  contentContainerAfterLogin: {
    padding: "0px 0px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 0px 40px",
    },
  },
  dashboardDetail: {
    paddingBottom: 75,
    paddingTop: 75,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  dashboardPadding: {
    [theme.breakpoints.down("sm")]: {
      padding: "25px 20px 0 !important",
    },
  },
  dashboardTxt: {
    fontSize: 38,
    fontFamily: '"Mulish", sans-serif',
    "&.MuiTypography-root": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      textAlign: "center",
    },
  },
  dashboardBox: {
    margin: 0,
    padding: 0,
    "& li": {
      fontSize: 18,
      listStyle: "none",
      color: "#FFFFFF",
      fontFamily: "'Roboto', sans-serif",
      border: "1px solid #0B6A3C",
      borderRadius: 5,
      padding: 10,
      cursor: "pointer",
      userSelect: "none",
      marginBottom: 10,
      boxShadow: "0px 10px 25px #20ee8b1a",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        margin: " 0 auto 10px",
      },
      "& svg": {
        color: "#fff",
        fontSize: 16,
        marginRight: 5,
      },
    },
  },
  contentMb: {
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  dashboardImg: {
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    borderRadius: 10,
    overflow: "hidden",
    "& img": {
      width: "100%",
      borderRadius: "unset",
      display: "block",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },
  },
  subscriptionPrice: {
    fontSize: 32,
    fontWeight: "900",
    marginTop: 20,
    lineHeight: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,
    },
  },
  subscriptionLabel: {
    display: "block",
    fontSize: 24,
    lineHeight: "normal",
    margin: "20px 0px 5px",
    [theme.breakpoints.down("600")]: {
      fontSize: 30,
      fontWeight: "600",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,
    },
  },
  subscriptionPlan: {
    paddingBottom: 100,
    [theme.breakpoints.down("md")]: {
      paddingTop: 30,
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 50,
    },
    "& h3": {
      fontSize: 32,
      marginBottom: 60,
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        marginTop: 50,
        marginBottom: 50,
        fontSize: 24,
      },
    },
    "& .MuiTableContainer-root": {
      marginTop: 30,
      borderRadius: 0,
      backgroundColor: "rgba(0,0,0,0)",
      overflow: "inherit",
      "& .MuiTable-root": {
        "& .MuiTableCell-head": {
          fontWeight: "600",
          padding: "5px",
          color: "#fff",
          borderColor: "rgba(255,255,255,0.5)",
          border: 0,
          [theme.breakpoints.down("sm")]: {
            padding: 10,
          },
          "&:first-child": {
            textAlign: "left",
            borderLeft: "0",
          },
          "&:nth-child(2)": {
            width: "28%",
          },
          "&:nth-child(3)": {
            width: "28%",
          },
        },
        "& .MuiTableHead-root": {
          position: "-webkit-sticky",
          position: "sticky",
          top: 0,
          "& .MuiTableRow-root": {
            backgroundColor: "#021420",
            "&:last-child": {
              "& .MuiTableCell-root": {
                paddingBottom: 30,
              },
            },
          },
        },
        "& .MuiTableBody-root": {
          borderTop: "1px solid rgba(255,255,255,0.5)",
          "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
              padding: "10px 15px",
              color: "#fff",
              borderColor: "rgba(255,255,255,0.5)",
              fontSize: 16,
              "&:first-child": {
                textAlign: "left",
                borderLeft: "0",
              },
              "&:nth-child(2)": {
                width: "28%",
              },
              "&:nth-child(3)": {
                width: "28%",
              },
            },
          },
        },
      },
    },
  },
  free: {
    "& svg": {
      color: "#07846f",
      verticalAlign: "middle",
    },
  },
  paid: {
    "& svg": {
      color: "#CD0909",
      verticalAlign: "middle",
    },
  },
  popular: {
    color: "#0C0B0B",
    fontSize: 20,
  },
  features: {
    color: "#1FEE8B",
  },
  titleCenter: {
    textAlign: "center",
  },
  mb10: {
    marginBottom: 10,
  },
  mb20: {
    marginBottom: 20,
  },
  freeBlock: {
    borderBottom: "1px solid rgba(255,255,255,0.5)",
    marginBottom: 15,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 40,
      paddingBottom: 40,
    },
  },
  advanceFeature: {
    margin: "12px 0",
  },
  upgradeMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  featuresDetails: {
    paddingBottom: 20,
  },
  upgradeWeb: {
    display: "block",
    paddingTop: 130,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 30,
    },
  },
  modelMargin: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiDialog-paper": {
        margin: 15,
      },
    },
  },
  planContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  creditFont: {
    fontSize: 17,
    fontWeight: 500,
    color: "#0C0B0B",
    display: "block",
    minHeight: 40,
  },
  floor: {
    backgroundImage: `url(${shadow})`,
    position: "absolute",
    left: "-160px",
    right: "-160px",
    bottom: "-105px",
    height: "120px",
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    zIndex: "0",
    [theme.breakpoints.down("md")]: {
      left: "-100px",
      right: "-100px",
      bottom: "-73px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "0",
      right: "0",
      bottom: "-64px",
    },
  },
  basicFloor: {
    bottom: "-25px",
    opacity: 0.5,
    [theme.breakpoints.down("md")]: {
      left: "-100px",
      right: "-100px",
      bottom: "unset !important",
      height: "80px",
    },
    [theme.breakpoints.up("xs")]: {
      left: "0",
      right: "0",
      bottom: "unset !important",
    },
  },
  premiumSection: {
    padding: "20px 16px",
    background: "rgba(255,255,255,1)",
    borderRadius: " 20px",
    height: "100%",
    position: "relative",
    transition: "all 0.35s ease-in",
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0px",
    },
  },
  cradBgImg: {
    pointerEvents: "none",
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundSize: "contain",
    backgroundRepeat: " no-repeat",
  },

  cradBgBor: {
    pointerEvents: "none",
    position: "absolute",
    backgroundSize: "contain",
    backgroundRepeat: " no-repeat",
  },

  premiumBorder: {
    "&:before": {
      content: "''",
      position: "absolute",
      backgroundImage: `url(${borderTop})`,
      right: "0",
      left: "0",
      top: "0",
      height: "3px",
      width: "100%",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      backgroundImage: `url(${borderTop})`,
      right: "0",
      left: "0",
      bottom: "0",
      height: "1px",
      width: "100%",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
  },
  basicPremium: {
    marginBottom: "0",
    height: "100%",
  },
  pro: {
    boxShadow: "6px 4px 20px 3px rgba(0, 0, 0, 0.1)",
  },
  pricingHeader: {
    textAlign: "center",
    fontWeight: 700,
    margin: "20px 0px",
    lineHeight: "1.25rem",
    "& button": {
      margin: "24px 0px",
    },
  },
  pricingBody: {
    margin: "0 auto",
    width: "max-content",
    "& div": {
      marginBottom: "8px",
      "& span": {
        fontSize: 15,
        textAlign: "center",
        marginRight: "5px",
      },
    },
  },
  row: {
    flexDirection: "row",
  },
  opacity: {
    opacity: 1,
  },
  boxMargin: {
    margin: "145px 0 100px",
    [theme.breakpoints.down("767")]: {
      margin: "80px 0 30px",
    },
  },
  trendPageTitle: {
    maxWidth: "100px !important",
    display: "block",
  },
  tableTitle: {
    fontWeight: "bold",
    color: "#000",
    textAlign: "center",
    "& img": {
      verticalAlign: "middle",
      marginLeft: 10,
    },
    "& svg": {
      fontSize: 18,
      verticalAlign: "-3px",
      marginLeft: 5,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  trendingMarketCapT2: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiTabs-root": {
        margin: [[0, -20]],
        padding: [[0, 20]],
      },
    },
  },
  hrBorder: {
    border: "1px solid #E1E3EA",
  },
  homepageBanner: {
    paddingBottom: 20,
    marginTop: 100,
    [theme.breakpoints.down("sm")]: {
      marginTop: 50,
    },
  },
  homeMainSBtn: {
    "&.MuiButtonBase-root": {
      [theme.breakpoints.down(400)]: {
        width: "100%",
      },
    },
  },
  trendingTickers: {
    boxShadow: " 0px 4px 83.3px 0px #0000001C",
    padding: [[26, 30]],
    borderRadius: 32,
    "&.ai-top-picks": {
      "& .MuiTable-root": {
        "& .MuiTableHead-root": {
          "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
              fontWeight: "normal",
              paddingBottom: 6,
              paddingLeft: 6,
              "&:nth-child(2)": {
                textAlign: "center",
              },
              "&:last-child": {
                width: 0,
                paddingRight: 0,
              },
            },
          },
        },
        "& .MuiTableBody-root": {
          "& .MuiTableRow-root": {
            "&:last-child": {
              "& .MuiTableCell-body": {
                borderBottom: "none",
              },
            },
            "& .MuiTableCell-head": {
              paddingRight: 0,
            },
            "& .MuiTableCell-body": {
              padding: 0,
              paddingLeft: 6,
              verticalAlign: "middle",
              "&:first-child": {
                maxWidth: 80,
                paddingTop: 6,
              },
              "&:nth-child(2)": {
                textAlign: "center",
              },
            },
          },
        },
      },
    },
    "& .MuiTable-root": {
      "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
          "& .MuiTableCell-body": {
            "&:nth-child(2)": {
              paddingLeft: 10,
              "& .MuiBox-root": {
                maxWidth: 200,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 150,
                },
                [theme.breakpoints.down("1199")]: {
                  maxWidth: 120,
                },
              },
            },
          },
        },
      },
    },
    ".MuiTableRow-root": {
      ".MuiTableCell-head": {
        ":first-child": {
          paddingLeft: 0,
        },
      },
    },
    "& .MuiTableCell-sizeSmall": {
      padding: 0,
    },
    "& .MuiTableCell-body": {
      padding: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
  },

  customeTab: {
    justifyContent: "center",
    minHeight: 32,
    "& .MuiTabs-scrollable": {
      flexGrow: "0",
      "& .MuiTabs-flexContainer": {
        alignItems: "center",
        height: "100%",
        "& .MuiTab-root": {
          borderRadius: 30,
          marginRight: 0,
          padding: [[8, 20]],
          lineHeight: "normal",
          minHeight: "auto",
          "&.Mui-selected": {},
        },
      },
    },
    "& .MuiTab-root": {
      textTransform: "capitalize",
      fontSize: 17,
      [theme.breakpoints.down("767")]: {
        minWidth: "auto",
        fontSize: 16,
      },
      [theme.breakpoints.down("380")]: {
        minWidth: "auto",
      },
      "& p": {
        position: "relative",
        color: "#000000",
        margin: 0,
        [theme.breakpoints.down("sm")]: {
          marginTop: 0,
        },
        "&:hover": {
          [theme.breakpoints.up("md")]: {
            color: theme.palette.primary.main,
          },
        },
        "&:before": {
          content: "normal",
          position: "absolute",
          right: 0,
          left: 0,
          bottom: 0,
          height: 2,
          backgroundColor: "transparent",
          width: 30,
        },
      },
      "&.Mui-selected": {
        "& p": {
          "&:before": {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
      "& .MuiTouchRipple-root": {
        display: "none",
      },
    },
    "& .MuiTabScrollButton-root": {
      height: 40,
      boxShadow: "0 0 8px rgba(0,0, 0, 0.3)",
      borderRadius: "100%",
      margin: 5,
    },
  },
  customListTab: {
    display: "flex",
    justifyContent: "center",
    "& .MuiListItem-root": {
      width: "auto",
      padding: 0,
      "& .MuiButtonBase-root": {
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        borderRadius: 0,
      },
    },
  },
  autoRefreshItem: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 0,
    paddingBottom: 0,
    "& .MuiListItem-root": {
      padding: 0,
      width: "auto",
      "& label": {
        whiteSpace: "nowrap",
        margin: 0,
        [theme.breakpoints.down("1199")]: {
          "&.MuiFormControlLabel-root": {
            marginRight: 10,
            "& .MuiTypography-root": {
              fontSize: 12,
              marginLeft: -10,
            },
            "& .MuiRadio-root": {
              transform: "scale(0.7)",
            },
          },
        },
        [theme.breakpoints.down("680")]: {
          position: "relative",
          left: "-10px",
        },
      },
      [theme.breakpoints.down("680")]: {
        paddingBottom: 0,
        paddingTop: 0,
      },
      "&:first-child": {
        order: 1,
        marginRight: 7,
        [theme.breakpoints.down("480")]: {
          marginRight: 0,
          marginBottom: 0,
          width: "100%",
        },
      },
      "&:nth-child(2)": {
        order: 3,
        justifyContent: "flex-end",
        "& .MuiButton-root": {
          minWidth: "auto",
        },
      },
      "&:last-child": {
        order: 2,
      },
    },
  },
  newsPageFilter: {
    "& .MuiGrid-root.MuiGrid-container": {
      "& .MuiGrid-item": {
        width: "50%",
      },
    },
  },
  newsFilterWrap: {
    borderBottom: "2px solid #E1E3EA",
    paddingBottom: 15,
  },
  analystsFilter: {
    width: "70%",
    [theme.breakpoints.down("md")]: {
      width: "63%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  analystsFilterTab: {
    marginBottom: 20,
  },
  analystsTab: {
    borderBottom: "1px solid #E1E3EA",
    marginBottom: 20,
  },
  plansTab: {
    marginBottom: 30,
  },
  autoRefreshTitle: {
    color: "#797979",
    fontSize: 14,
    fontWeight: "bold",
  },
  chatIcon: {
    "&.MuiButtonBase-root": {
      position: "fixed",
      right: 20,
      bottom: 55,
      borderRadius: 4,
      backgroundColor: "#808080",
      color: "#FFFFFF",
      height: "auto",
      width: "auto",
      zIndex: 999,
      padding: [[0, 10]],
      [theme.breakpoints.down("sm")]: {
        bottom: 90,
        right: 25,
      },
      "& svg": {
        width: 24,
        height: 24,
      },
    },
  },
  intercomMessengerFrame: {
    position: "fixed",
    right: 30,
    bottom: 70,
    backgroundColor: "#fff",
    maxHeight: "calc(100vh - 90px)",
    width: 490,
    boxShadow: "rgb(0 0 0 / 16%) 0px 5px 40px",
    borderRadius: 16,
    overflow: "hidden",
    transformOrigin: "right bottom",
    transition:
      "width 200ms ease 0s, height 200ms ease 0s, max-height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, opacity 83ms ease-out 0s",
    transform: "scale(0)",
    opacity: 0,
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      right: 20,
    },
    [theme.breakpoints.down("575")]: {
      marginLeft: 20,
      maxWidth: 470,
      width: "auto",
      maxHeight: "calc(75vh - 90px)",
    },
  },
  frameOpen: {
    opacity: 1,
    transform: "scale(1)",
  },
  frameOption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: [[8, 20, 8]],
    borderBottom: "1px solid #ddd",
    "& .MuiTypography-root": {
      margin: "0 auto",
      color: "#000",
    },
    "& svg": {
      cursor: "pointer",
      marginLeft: 5,
    },
  },
  surveyFormInfo: {
    overflowY: "auto",
    padding: [[10, 15, 15, 15]],
    "& p": {
      marginBottom: 5,
      marginTop: 5,
      fontWeight: 500,
      "& p": {
        display: "inline",
        marginLeft: 5,
      },
    },
    "& > .MuiButtonBase-root": {
      marginTop: 10,
      marginLeft: "auto",
      marginRight: "auto",
      display: "table",
    },
    "& .MuiTypography-root": {
      fontSize: 14,
    },
    "& .MuiIconButton-label svg": {
      fontSize: 14,
      marginLeft: 0,
    },
  },
  frameSelect: {
    "&.MuiPopover-root": {
      zIndex: "9999 !important",
    },
  },
  aiTopPicksContainer: {
    padding: "140px 30px 60px 30px",
    background: "#000",
    minHeight: "calc(100vh - 61px)",
    [theme.breakpoints.down("767")]: {
      paddingTop: 88,
      minHeight: "calc(100vh - 71px)",
    },
    [theme.breakpoints.down("600")]: {
      padding: "88px 16px 60px 16px",
    },
  },
  latestUpgradeTable: {
    position: "static",
    "& .MuiTable-root": {
      "& .MuiTableHead-root": {
        "& .MuiTableRow-root": {
          "& .MuiTableCell-head": {
            "&:nth-child(2)": {
              width: "12%",
            },
            "&:nth-child(7)": {
              textAlign: "left",
            },
          },
        },
      },
      "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
          "& .MuiTableCell-body": {
            "&:nth-child(2)": {
              textAlign: "left",
              "& a": {
                textAlign: "left",
              },
            },
            "&:nth-child(7)": {
              textAlign: "left",
              width: "10%",
            },
          },
        },
      },
    },
  },
  bgImage: {
    margin: "0px -30px -61px",
    [theme.breakpoints.down("600")]: {
      margin: "0px -16px -61px",
    },
    "& img": {
      verticalAlign: "bottom",
    },
  },
}));
