import {
  Table as MuiTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import classNames from "classnames";
import PaginationActions from "../StockScreener/PaginationActions";

const useStyles = makeStyles((theme) => ({
  table: {
    paddingBottom: "0",
    marginTop: 20,
    "& .MuiTableHead-root": {
      "& .MuiTableRow-root": {
        position: "relative",

        "& .MuiTableCell-head": {
          borderTop: "1px solid #e0e0e0",
          borderLeft: "1px solid #e0e0e0",
          border: "0",
          position: "relative",
          color: "#5b636a",
          whiteSpace: "nowrap",
          zIndex: "1 !important",
          [theme.breakpoints.only("md")]: {
            fontSize: 15,
          },
          "&:first-child": {
            borderLeft: 0,
          },
          "&:before": {
            content: "''",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: "0",
            zIndex: 3,
            borderBottom: "1px solid #ddd",
          },
          "& svg": {
            verticalAlign: "middle",
            fontSize: 15,
          },
        },
      },
    },
    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        "&:hover": {
          "& .MuiTableCell-body": {
            backgroundColor: "#eee",
          },
        },
        "& .MuiTableCell-body": {
          paddingRight: 10,
          whiteSpace: "nowrap",
          position: "relative",
          overflow: "visible",
          "&:before": {
            content: "''",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: "-1px",
            zIndex: 3,
            borderBottom: "1px solid #ddd",
          },
          [theme.breakpoints.down("1280")]: {
            whiteSpace: "nowrap",
          },
        },
        "&:nth-child(odd)": {
          "& .MuiTableCell-body": {
            backgroundColor: "#ffffff",
          },
        },
        "&:nth-child(even)": {
          "& .MuiTableCell-body": {
            backgroundColor: "#f5f5f7",
          },
        },
      },
    },
  },
  sortableTableHeading: {
    cursor: "pointer",
  },
  textAlignCenter: {
    textAlign: "center",
  },
}));

const getSortConfig = ({ sortConfig = {}, name }) => {
  const { order, direction } = sortConfig;
  let newDirection;

  if (!direction || order !== name) newDirection = "asc";
  else if (direction === "asc") newDirection = "desc";

  return {
    order: newDirection ? name : undefined,
    direction: newDirection,
  };
};

const Table = ({
  className,
  data,
  columns,
  hidePagination,
  noMatchText = "No data found",
  sortConfig,
  onSort,
  page,
  onPageChange,
  rowsPerPage,
  isPaginationDisabled,
  rowsPerPageOptions = [],
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <MuiTable
          size="small"
          className={classNames(classes.table, className)}
          {...props}
        >
          <TableHead>
            <TableRow>
              {columns.map(
                ({ name, heading, centered, sortable, ...props }, index) => {
                  return (
                    <TableCell
                      key={index}
                      className={classNames({
                        [classes.textAlignCenter]: centered,
                        [classes.sortableTableHeading]: sortable,
                      })}
                      onClick={() =>
                        sortable && onSort(getSortConfig({ sortConfig, name }))
                      }
                      {...props}
                    >
                      {heading}
                      {sortable &&
                        sortConfig.order === name &&
                        (sortConfig.direction === "asc" ? (
                          <ArrowDownward />
                        ) : (
                          <ArrowUpward />
                        ))}
                    </TableCell>
                  );
                }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!data?.length ? (
              data.map((row, index) => (
                <TableRow key={index}>
                  {columns.map(
                    ({ name, renderCell, renderCellBody, centered }) => {
                      if (renderCell) {
                        return renderCell({ value: row[name], row });
                      }

                      return (
                        <TableCell
                          className={classNames({
                            [classes.textAlignCenter]: centered,
                          })}
                        >
                          {renderCellBody
                            ? renderCellBody({ value: row[name], row })
                            : row[name]}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  style={{
                    textAlign: "center",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  {noMatchText}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>

      {!hidePagination && (
        <TablePagination
          component="div"
          count={data?.length === rowsPerPage ? data.length + 1 : data?.length} // Keep next button enabled if the count of total items is equal to rowsPerPage. Because backend does not return total number of items.
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          labelDisplayedRows={() => null}
          ActionsComponent={(props) => (
            <PaginationActions {...props} isDisabled={isPaginationDisabled} />
          )}
        />
      )}
    </>
  );
};

export default Table;
