import React, { useState } from "react";
import useStyles from "../LandingPage/css/index";
import CloseIcon from "@material-ui/icons/Close";
import MinimizeIcon from "@material-ui/icons/Minimize";
import { Fab, Typography } from "@material-ui/core";
import { Chat } from "@material-ui/icons";
import SurveyForm from "../../shared/SurveyForm";
import { useSelector } from "react-redux";
import { userPost } from "../../redux/api/authApis";
import CustomButton from "./CustomButton";
import { ReactComponent as FeedbackIcon } from "../../assets/images/feedback.svg";

const initialState = {
  noLonger: "",
  benefitFrom: "",
  mainBenefit: "",
  improvement: "",
  jobFunction: "",
};

const CustomSurveyModel = () => {
  const classes = useStyles();
  const [surveyData, setSurveyData] = useState(initialState);
  const { idToken, refresh, accessToken, userInfo } = useSelector(
    (state) => state.signIn.loggedUser
  );
  const [messageFrame, setMessageFrame] = useState(
    userInfo?.feedback === 1 ? true : false
  );
  const handleFrameToggle = () => {
    setMessageFrame(!messageFrame);
  };
  const handleCloseIcon = async () => {
    setMessageFrame(false);

    const res = await userPost(idToken, {
      method: "feedback",
      rating: "NA",
      role: "NA",
      targetCustomer: "NA",
      benefit: "NA",
      feedback: "NA",
    });
    if (res.status === 200) {
      setSurveyData(initialState);
    }
  };

  return (
    <>
      {" "}
      <div
        className={`${classes.intercomMessengerFrame} ${
          messageFrame ? classes.frameOpen : ""
        }`}
      >
        <div className={classes.frameOption}>
          <Typography variant="h6" component="h6">
            Feedback
          </Typography>
          <CloseIcon onClick={handleCloseIcon} />
        </div>
        <SurveyForm
          handleFrameToggle={handleFrameToggle}
          surveyData={surveyData}
          setSurveyData={setSurveyData}
          initialState={initialState}
        />
      </div>
      <Fab
        // color="primary"
        className={classes.chatIcon}
        onClick={() => handleFrameToggle()}
      >
        <FeedbackIcon />
      </Fab>
    </>
  );
};

export default CustomSurveyModel;
