import { theme } from "highcharts";

export const subscriptionActiveMode = [
  "ACTIVE",
  "PRO",
  "PRO_TRIALING",
  "MANUALLY_CANCELLED",
  "PRO_CANCELLED",
  "TRIALING",
  "FREE",
  "INCOMPLETE",
  "PRO_INCOMPLETE",
];

export const risks = {
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
  VERY_HIGH: "Very High",
};

export const disclaimer = `Hensu LLC, doing business as Fintwit.ai (“Fintwit”, "we," "our," or
"us") is not registered as an investment adviser, broker-dealer, or in
any other capacity with the U.S. Securities and Exchange Commission or
any other state or jurisdiction's securities regulatory body. We do
not claim to offer investment advice or recommendations. Our services,
including AI Insight and Recommendations on fintwit.ai, are
exclusively designed to support you or your investment advisers in
conducting research related to investments and should not be
interpreted as an invitation, solicitation, or recommendation to buy,
sell, hold, or trade any securities or other financial instruments.
For additional details about the usage of Fintwit.ai, please visit:
fintwit.ai/disclosure.`;

export const SCREENER_FILTER_TYPE = {
  FIXED: "Fixed",
  NUMBER: "Number",
  PERCENTAGE: "Percentage",
  DYNAMIC: "Dynamic",
};

export const SORT_ORDER = {
  ASCENDING: "asc",
  DESCENDING: "desc",
};

export const pathsToUntrack = ["/sign-in", "/sign-up"];

export const colors = {
  orange: {
    main: "#ff9000",
    dark: "#E58100",
  },
};

export const asSeenOn = {
  BIG: 1,
  SMALL: 2,
};

export const pages = {
  ACCOUNT: "Account",
  DASHBOARD: "Dashboard",
  AI_TOP_PICKS: "AI Top Picks",
  AI_TOP_ETFS: "AI Top ETFs",
  AI_CHAT: "AI Chat",
  PORTFOLIO: "Portfolio",
  WATCHLIST: "Watchlist",
  NEWS: "News",
  HEATMAP: "Heatmap",
  SCREENER: "Screener",
  MULTICHART: "Multichart",
  STOCKS: "Stocks",
};

export const notificationChannels = {
  INACTIVE: 0,
  WEB: 1,
  EMAIL: 2,
  BOTH: 3,
};
