// import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  tooltip: {
    "& .MuiTooltip-tooltip": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.4)",
      maxWidth: (props) => props.maxWidth ?? 220,
      fontSize: theme.typography.pxToRem(12),
      //border: "1px solid #36454f",
      placement: "left",
    },
    "&.MuiTooltip-popper": {
      zIndex: (props) => `${props.zIndex ?? 1502} !important`,
    },
  },
}));

const ShowTooltip = ({ children, classes: compClasses = {}, ...props }) => {
  const classes = useStyle(props);
  return (
    <Tooltip
      classes={{
        popper: classes.tooltip,
        ...(compClasses?.arrow && { arrow: compClasses?.arrow }),
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default ShowTooltip;
