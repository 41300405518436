import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardBox: {
    backgroundColor: "#fff",
    border: "2px solid #E1E3EA",
    borderRadius: 10,
    overflow: "hidden",
    padding: 15,
    "&:not(:last-child)": {
      marginBottom: 16,
    },
    // [theme.breakpoints.down('400')]: {
    //     padding: 15
    // },
  },
  card: {
    position: "relative",
  },
  fullHeight: {
    height: "100%",
    "&$bottomSpacing": {
      height: "calc(100% - 16px)",
    },
  },
  bottomSpacing: {
    marginBottom: 16,
  },
}));

const CardBox = ({
  children,
  blurBackground,
  cardClassName,
  fullHeight,
  bottomSpacing,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.cardBox}  ${blurBackground ? classes.card : ""} ${
        fullHeight ? classes.fullHeight : ""
      } ${bottomSpacing ? classes.bottomSpacing : ""} ${cardClassName}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default CardBox;
