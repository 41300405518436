/* eslint-disable */
import { floatWithTwoDecimal } from "./regex";
import moment from "moment-timezone";
import ReactGA from "react-ga4";
import { isLoggedIn } from "./authentication";
import { Parser } from "json2csv";

const login = isLoggedIn();
const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const sortData = (dataList, key, direction, type) => {
  const listData = [...dataList];
  if (type === "number") {
    if (direction === "ascending") {
      listData.sort((a, b) => {
        const nameA = Number(a && a[key] && a[key]);
        const nameB = Number(b && b[key] && b[key]);
        return parseFloat(nameB) - parseFloat(nameA);
      });
    } else if (direction === "descending") {
      listData.sort((a, b) => {
        const nameA = Number(a && a[key] && a[key]);
        const nameB = Number(b && b[key] && b[key]);
        return parseFloat(nameA) - parseFloat(nameB);
      });
    }
  } else if (type === "string") {
    if (direction === "ascending") {
      listData.sort((a, b) => {
        const nameA = a && a[key] && a[key].toLowerCase();
        const nameB = b && b[key] && b[key].toLowerCase();
        if (nameA > nameB) return -1;
        if (nameA < nameB) return 1;
        return 0;
      });
    } else if (direction === "descending") {
      listData.sort((a, b) => {
        const nameA = a && a[key] && a[key].toLowerCase();
        const nameB = b && b[key] && b[key].toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    }
  } else if (type === "date") {
    listData.sort((a, b) => {
      const nameA = a && a[key] && a[key];
      const x = nameA.split("T");
      const nameB = b && b[key] && b[key];
      const y = nameB.split("T");
      if (direction === "ascending") {
        return Date.parse(y[0]) - Date.parse(x[0]);
      } else if (direction === "descending") {
        return Date.parse(x[0]) - Date.parse(y[0]);
      }
    });
  }
  return listData;
};

export function dateFormat(date) {
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  const todayDAte = yyyy + "-" + mm + "-" + dd;
  return todayDAte;
}

export function dateFormatDDMMYY(getDate) {
  if (getDate) {
    const date = new Date(getDate);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    const todayDAte = dd + "." + mm + "." + yyyy;
    return todayDAte;
  } else {
    return "-";
  }
}

const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const monthNameDate = (date) => {
  var dd = date.getDate();
  var mm = date.getMonth();
  var yyyy = date.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  return `${monthNames[mm]} ${dd}, ${yyyy} ${formatAMPM(date)}`;
};

export const intToString = (value, isInteger) => {
  if (value) {
    if (Math.abs(Number(value)) >= 1.0e12) {
      // 12 Zeroes for Billions
      if (isInteger) {
        return parseInt(Math.abs(Number(value)) / 1.0e12) + "T";
      } else {
        return floatWithTwoDecimal(Math.abs(Number(value)) / 1.0e12) + "T";
      }
    } else if (Math.abs(Number(value)) >= 1.0e9) {
      // 9 Zeroes for Billions
      if (isInteger) {
        return parseInt(Math.abs(Number(value)) / 1.0e9) + "B";
      } else {
        return floatWithTwoDecimal(Math.abs(Number(value)) / 1.0e9) + "B";
      }
    } else if (Math.abs(Number(value)) >= 1.0e6) {
      // 6 Zeroes for Millions
      if (isInteger) {
        return parseInt(Math.abs(Number(value)) / 1.0e6) + "M";
      } else {
        return floatWithTwoDecimal(Math.abs(Number(value)) / 1.0e6) + "M";
      }
    } else if (Math.abs(Number(value)) >= 1.0e3) {
      // 3 Zeroes for Thousands
      if (isInteger) {
        return parseInt(Math.abs(Number(value)) / 1.0e3) + "K";
      } else {
        return floatWithTwoDecimal(Math.abs(Number(value)) / 1.0e3) + "K";
      }
    } else {
      // absolute value of a number
      return Math.abs(Number(value));
    }
  } else {
    return "-";
  }
};

export const getCurrentEstTime = () => {
  return moment().tz("America/New_York").format("dddd HH:mm");
};

export const getCurrentEstDateTime = () => {
  return moment().tz("America/New_York").format("LL h:mm A z");
};

export const getYesterdayEstDate = () => {
  const dayName = moment()
    .tz("America/New_York")
    .subtract(1, "days")
    .format("dddd");
  if (days.includes(dayName)) {
    return moment()
      .tz("America/New_York")
      .set({ hours: 15, minutes: 59 })
      .subtract(1, "days")
      .format("LL hh:mm A");
  } else if (dayName === "Saturday") {
    return moment()
      .tz("America/New_York")
      .set({ hours: 15, minutes: 59 })
      .subtract(1, "days")
      .format("LL hh:mm A");
  } else if (dayName === "Sunday") {
    return moment()
      .tz("America/New_York")
      .set({ hours: 15, minutes: 59 })
      .subtract(2, "days")
      .format("LL hh:mm A");
  }
};

export const isApiCall = () => {
  const currentTime = getCurrentEstTime();
  const currentTimeSplit = currentTime.split(" ");
  const currentDayName = currentTimeSplit[0];
  const timeSplit = currentTimeSplit[1].split(":");
  const currentTimeH = timeSplit[0];

  if (currentTimeH >= 9 && currentTimeH < 16 && days.includes(currentDayName)) {
    return currentTime;
  } else {
    return false;
  }
};

export const yearToDate = () => {
  //get year to date days
  var now = new Date();
  var start = new Date(now.getFullYear(), 0, 0);
  var diff = now - start;
  var oneDay = 1000 * 60 * 60 * 24;
  var day = Math.floor(diff / oneDay);
  return day;
};

export const getTimeStem = (date) => {
  return moment(date) /* .tz("America/New_York").unix() */;
};

export const dateFilter = (data, startDate, endDate) => {
  let dataList = [];
  if (data?.length > 1) {
    dataList = data.filter((item) => {
      return (
        getTimeStem(item.date) >= getTimeStem(startDate) &&
        getTimeStem(item.date) <= getTimeStem(endDate)
      );
    });
  }
  return dataList;
};

export const googleAnalytics = () => {
  // ReactGA.event({
  //   category: idToken.payload.email,
  //   action: 'Deleted Component',
  // });
  // ga('set', {
  //   'dimension5': 'custom dimension data',
  //   'metric5': 'custom metric data'
  // });
  if (login?.userInfo?.email) {
    ReactGA.set({ dimension1: login?.userInfo?.email });
    // ReactGA.set({ 'metric1': login?.userInfo?.name });
    ReactGA.set({
      userId: login?.userInfo?.email,
      // name: login?.userInfo?.name
    });
  }

  const pathName =
    window.location.pathname === "/" ? "/home" : window.location.pathname;
  ReactGA.send(pathName + window.location.search);
};

export const googleAnalyticsOnLogin = (email) => {
  if (email) {
    ReactGA.event({
      category: email,
      action: "Login User",
    });
    ReactGA.set({ dimension1: email });
    ReactGA.send(window.location.pathname + window.location.search);
  }
};

export const estDateFormat = (date) => {
  const dateSplit = date.split("T");
  const time = dateSplit[1].split(".")[0];
  const dateSpreader = dateSplit[0].split("-");
  var dd = dateSpreader && dateSpreader[2];
  var month = Number(dateSpreader && dateSpreader[1]);
  var yyyy = dateSpreader && dateSpreader[0];
  const timezone = moment(date).tz("America/New_York").format("z");
  return `${monthNames[month - 1]} ${dd}, ${yyyy} ${time} ${timezone}`;
};
export const onlyDate = (date) => {
  const dateSplit = date?.split("T");
  const time = dateSplit && dateSplit[1]?.split(".")[0];
  const dateSpreader = dateSplit && dateSplit[0]?.split("-");
  var dd = dateSpreader && dateSpreader[2];
  var month = Number(dateSpreader && dateSpreader[1]);
  var yyyy = dateSpreader && dateSpreader[0];
  const timezone = moment(date)?.tz("America/New_York")?.format("z");
  return `${monthNames[month - 1]?.substring(0, 3)} ${dd}, ${yyyy}`;
};
export const onlyDateMonth = (date) => {
  const dateSplit = date?.split("T");
  const time = dateSplit && dateSplit[1]?.split(".")[0];
  const dateSpreader = dateSplit && dateSplit[0]?.split("-");
  var dd = dateSpreader && dateSpreader[2];
  var month = Number(dateSpreader && dateSpreader[1]);
  const timezone = moment(date)?.tz("America/New_York")?.format("z");
  return `${monthNames[month - 1]?.substring(0, 3)} ${dd}`;
};

export const formatedOnlyDate = (date) => {
  const dateSplit = date?.split("T");
  const time = dateSplit && dateSplit[1]?.split(".")[0];
  const dateSpreader = dateSplit && dateSplit[0]?.split("-");
  let dd = dateSpreader && dateSpreader[2];
  dd = dd.charAt(0) === "0" ? dd.substring(1) : dd;
  var month = Number(dateSpreader && dateSpreader[1]);
  var yyyy = dateSpreader && dateSpreader[0];
  const timezone = moment(date)?.tz("America/New_York")?.format("z");
  return `${month} ${dd}`;
};

export const estDateFormat1 = (date) => {
  const dateSplit = date.split("T");
  const time = dateSplit[1].split(".")[0];
  const dateSpreader = dateSplit[0].split("-");
  var dd = dateSpreader && dateSpreader[2];
  var month = Number(dateSpreader && dateSpreader[1]);
  var yyyy = dateSpreader && dateSpreader[0];
  const timezone = moment(date).tz("America/New_York").format("z");
  // return `${monthNames[month - 1]} ${dd}, ${yyyy} ${time} ${timezone}`;
  return `${monthNames[month - 1]} ${dd}, ${yyyy} ${time} ${"EST"}`;
};

export const filterArray = (array, filters) => {
  const filterKeys = Object.keys(filters);
  return array.filter((item) => {
    // validates all filter criteria
    return filterKeys.every((key) => {
      // ignores non-function predicates

      if (typeof filters[key] !== "function") return true;
      return filters[key](item[key]);
    });
  });
};

export const isPremiumSubscription = (subscription) =>
  ["ACTIVE", "MANUALLY_CANCELLED", "TRIALING", "INCOMPLETE"].includes(
    subscription
  );
export const isProPlanSubscription = (subscription) =>
  ["PRO_TRIALING", "PRO", "PRO_CANCELLED", "PRO_INCOMPLETE"].includes(
    subscription
  );

export const getInvestmentScoreColor = (score, isBackgroundColor) => {
  if (score >= 91 && score <= 100)
    return isBackgroundColor ? "#000" : "rgb(48, 204, 90)";
  if (score >= 81 && score <= 90)
    return isBackgroundColor ? "#fff" : "rgb(53, 118, 78)";
  if (score >= 71 && score <= 80) return isBackgroundColor ? "#000" : "#e0e0e0";
  if (score >= 51 && score <= 70)
    return isBackgroundColor ? "#fff" : " #aa080b";
  if (score >= 11 && score <= 50)
    return isBackgroundColor ? "#fff" : "rgb(191, 64, 69)";
  if (score >= 0 && score <= 10)
    return isBackgroundColor ? "#fff" : "rgb(246, 53, 56)";

  return "gray";
};
export const getFundamentalScoreColor = (score, isBackgroundColor) => {
  if (score >= 9 && score <= 10)
    return isBackgroundColor ? "rgb(48, 204, 90)" : "#000";
  if (score >= 7 && score <= 8)
    return isBackgroundColor ? "rgb(53, 118, 78)" : "#fff";
  if (score >= 5 && score <= 6) return isBackgroundColor ? "#e0e0e0" : "#000";
  if (score >= 3 && score <= 4)
    return isBackgroundColor ? "rgb(191, 64, 69)" : "#fff";
  if (score >= 0 && score <= 2)
    return isBackgroundColor ? "rgb(246, 53, 56)" : "#fff";

  return "gray";
};
export const getRatingColor = getFundamentalScoreColor;

export const deepCompareObjects = (obj1, obj2) => {
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }

    if (!deepCompareObjects(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export function debounce(fn, delay = 250) {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export const downloadCSV = ({ data, options, fileName = "data" }) => {
  try {
    const parser = new Parser(options);
    const csv = parser.parse(data);

    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error converting JSON to CSV:", error);
  }
};

export const getAiScoreLabel = (score) => {
  if (score >= 9 && score <= 10) return "Excellent";
  if (score >= 7 && score <= 8) return "Above Average";
  if (score >= 5 && score <= 6) return "Average";
  if (score >= 3 && score <= 4) return "Below Average";
  if (score >= 0 && score <= 2) return "Poor";
};

export const getInvestmentScoreLabel = (score) => {
  if (score >= 91 && score <= 100) return "Strong Buy";
  if (score >= 81 && score <= 90) return "Buy";
  if (score >= 71 && score <= 80) return "Neutral";
  if (score >= 51 && score <= 70) return "Sell";
  if (score >= 11 && score <= 50) return "Strong Sell";
  if (score >= 0 && score <= 10) return "Not enough data";
};

export const getCurrentYear = () => new Date().getFullYear();

export const fetchFavicon = async (url) => {
  try {
    const response = await fetch(url);
    const html = await response.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Find the favicon link element
    const iconLink = doc.querySelector("link[rel~='icon']");
    let faviconUrl;
    if (iconLink) {
      faviconUrl = new URL(iconLink.getAttribute("href"), url).href;
    } else {
      // Default to favicon.ico in root directory if no icon link is found
      const urlObj = new URL(url);
      faviconUrl = `${urlObj.origin}/favicon.ico`;
    }

    // Verify if the favicon URL exists
    const iconResponse = await fetch(faviconUrl);
    if (iconResponse.ok) {
      return faviconUrl;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching favicon:", error);
    return null;
  }
};
