import React from "react";
import CloseIcon from "@material-ui/icons/Close";

const WithClose = ({ children, onClick }) => {
  return (
    <>
      <CloseIcon
        style={{
          position: "absolute",
          top: 11,
          right: 8,
          cursor: "pointer",
        }}
        onClick={onClick}
      />
      {children}
    </>
  );
};

export default WithClose;
