import axios from "axios";

export const getWatchListApi = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_SERVER_URL}/stock`,
    // `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_BASE_URL}/stock`,
    payload,
    config
  );
  return response;
};

export const getPortfolioListApi = async (idToken) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.get(
    `${process.env.REACT_APP_API_SERVER_URL}/watchlist`,
    config
  );
  return response;
};

export const getPortfolioWatchListApi = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_SERVER_URL}/stock`,
    // `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_BASE_URL}/stock`,
    payload,
    config
  );
  return response;
};

export const getAnalystApi = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_SERVER_URL}/forecast`,
    // `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_BASE_URL}/stock`,
    payload,
    config
  );
  return response;
};
export const getRecentlyViewedTickerData = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_SERVER_URL}/stock`,
    // `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_BASE_URL}/stock`,
    payload,
    config
  );
  return response;
};

export const getInstitutionApi = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.get(
    `${process.env.REACT_APP_INSTITUTION}/inst?method=${payload?.method}&start=${payload?.start}&limit=${payload?.limit}`,
    // `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_BASE_URL}/stock`,
    config
  );
  return response;
};
export const getPoliticsApi = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.get(
    `${process.env.REACT_APP_API_SERVER_URL}/politics?method=${payload?.method}`,
    config
  );
  return response;
};
export const getPoliticianApi = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.get(
    `${process.env.REACT_APP_API_SERVER_URL}/politics?method=profile&name=${payload}`,
    config
  );
  return response;
};
export const getInstitutionProfileApi = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.get(
    `${process.env.REACT_APP_INSTITUTION}/inst?method=${payload?.method}&cik=${payload?.cikValue}`,
    // `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_BASE_URL}/stock`,
    config
  );
  return response;
};
export const getInstitutionProfileRecentApi = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.get(
    `${process.env.REACT_APP_INSTITUTION}/inst?method=${payload?.method}&cik=${payload?.cikValue}&start=${payload?.start}&limit=${payload?.limit}`,
    // `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_BASE_URL}/stock`,
    config
  );
  return response;
};

export const addPortfolioItemApi = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_SERVER_URL}/watchlist`,
    payload,
    config
  );
  return response;
};
export const deletePortfolioItemApi = async (idToken, payload) => {
  const config = {
    data: payload,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.delete(
    `${process.env.REACT_APP_API_SERVER_URL}/watchlist`,
    config
  );
  return response;
};

export const addWatchListItemApi = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_SERVER_URL}/watchlist`,
    // `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_BASE_URL}/watchlist`,
    payload,
    config
  );
  return response;
};

export const removeWatchListItemApi = async (idToken, payload) => {
  const config = {
    data: payload,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.delete(
    `${process.env.REACT_APP_API_SERVER_URL}/watchlist`,
    // `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_BASE_URL}/watchlist`,
    config
  );
  return response;
};

export const updateWatchListItemApi = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.put(
    `${process.env.REACT_APP_API_SERVER_URL}/watchlist`,
    payload,
    config
  );
  return response;
};

export const getAllWatchListStocksApi = async (idToken) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.get(
    `${process.env.REACT_APP_API_SERVER_URL}/watchlist`,
    // `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_BASE_URL}/watchlist`,
    config
  );
  return response;
};

export const getInsiderTradesApi = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_SERVER_URL}/stock`,
    // `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_BASE_URL}/stock`,
    payload,
    config
  );

  return response;
};

export const refreshPortfolioAiRecommendations = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };

  const response = await axios.post(
    process.env.REACT_APP_PORTFOLIO_AI_RECOMMENDATIONS_API_URL,
    { method: "RECOMMENDATION", ...payload },
    config
  );

  return response;
};

export const refreshWatchListAiRecommendations = async (idToken, payload) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };

  const response = await axios.post(
    process.env.REACT_APP_WATCHLIST_AI_RECOMMENDATIONS_API_URL,
    { method: "RECOMMENDATION", ...payload },
    config
  );

  return response;
};
