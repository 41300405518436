import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper-bundle.min.css";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1440,
  },
  topSlider: {
    marginBottom: 40,
    paddingTop: 10,
    paddingBottom: 5,
    [theme.breakpoints.down("1440")]: {
      marginBottom: 20,
    },
    "& .swiper": {
      // padding:[[0,20]]
      "& .swiper-button-prev": {
        height: 30,
        width: 30,
        // border: "2px solid #C4C4C4",
        borderRadius: "100%",
        backgroundColor: "#fff",
        padding: 10,
        left: 0,
        transform: "translateY(4px)",
        [theme.breakpoints.down("575")]: {
          display: "none",
        },
        "&:after": {
          fontSize: 14,
          color: "#000",
          fontWeight: "bold",
        },
      },
      "& .swiper-button-next": {
        height: 30,
        width: 30,
        // border: "2px solid #C4C4C4",
        borderRadius: "100%",
        backgroundColor: "#fff",
        right: 0,
        transform: "translateY(4px)",
        [theme.breakpoints.down("575")]: {
          display: "none",
        },
        "&:after": {
          fontSize: 14,
          color: "#000",
          fontWeight: "bold",
        },
      },
      // "& .swiper-button-disabled": {
      //   opacity: 0,
      //   visibility: "hidden",
      // },
      "&:hover": {
        "& .swiper-button-prev:not(.swiper-button-disabled), & .swiper-button-next:not(.swiper-button-disabled)":
          {
            opacity: 1,
            visibility: "visible",
          },
      },
    },
  },
  topSliderInner: {
    "& .swiper": {
      [theme.breakpoints.up("575")]: {
        padding: [[0, 50]],
      },
      "&:before": {
        [theme.breakpoints.up("575")]: {
          content: '""',
          position: "absolute",
          top: 0,
          bottom: 0,
          width: 40,
          zIndex: 9,
          left: 0,
          backgroundColor: "#fff",
        },
      },
      "&:after": {
        [theme.breakpoints.up("575")]: {
          content: '""',
          position: "absolute",
          top: 0,
          bottom: 0,
          width: 40,
          zIndex: 9,
          right: 0,
          backgroundColor: "#fff",
        },
      },
    },
  },
  priceIndexWrap: {
    borderRight: "1px solid #ddd",
    [theme.breakpoints.down("575")]: {
      paddingRight: 15,
    },
    [theme.breakpoints.down("480")]: {
      paddingRight: 5,
    },
  },
  priceIndex: {
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("575")]: {
      display: "flex",
    },
  },
  firstT: {
    fontSize: 14,
    lineHeight: "16.94px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textTransform: "uppercase",
    textOverflow: "ellipsis",
    color: "#7C8086",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "bold",
    },
  },
  secondT: {
    fontSize: 14,
    fontWeight: 700,
    color: "#252424",
  },
  data: {
    fontSize: 14,
    color: "#ff0000",
    marginBottom: 0,
    [theme.breakpoints.down("575")]: {
      fontSize: 13,
      letterSpacing: "-0.2px",
    },
    "& span": {
      fontSize: 12,
      [theme.breakpoints.down("575")]: {
        fontSize: 11,
      },
    },
  },
  sliderLoadingBox: {
    minHeight: 78,
    [theme.breakpoints.down("575")]: {
      minHeight: 90,
    },
  },
}));

const IndexWidget = ({ index }) => {
  const classes = useStyles();
  return (
    <>
      {index.length !== 0 ? (
        <div className={classes.topSlider}>
          <Container className={classes.container}>
            <div className={classes.topSliderInner}>
              <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                slidesPerView={6}
                navigation
                breakpoints={{
                  320: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 10,
                  },
                  425: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 15,
                  },
                  // // when window width is >= 640px
                  575: {
                    slidesPerView: 2.9,
                    spaceBetween: 20,
                    slidesPerGroup: 3,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 3.9,
                    slidesPerGroup: 4,
                    spaceBetween: 20,
                  },
                  991: {
                    slidesPerView: 4.9,
                    slidesPerGroup: 5,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 5.9,
                    // slidesPerGroup: 6,
                    spaceBetween: 32,
                  },
                }}
              >
                {index.map((v, i) => (
                  <SwiperSlide key={i}>
                    <div key={i} className={classes.priceIndexWrap}>
                      {/* <div className={classes.priceIndex}> */}
                      <Typography className={classes.firstT}>
                        {v?.Name}
                      </Typography>
                      {/* </div> */}
                      <Typography className={classes.secondT}>
                        {v?.price}
                      </Typography>
                      {Number(v?.priceChangePerc) >= 0 ? (
                        <Typography
                          className={classes.data}
                          style={{ color: "#008000" }}
                        >
                          {v?.priceChangePerc}% <span>(+{v?.change})</span>
                        </Typography>
                      ) : (
                        <Typography className={classes.data} style={{}}>
                          {v?.priceChangePerc}% <span>({v?.change})</span>
                        </Typography>
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Container>
        </div>
      ) : (
        <Box className={classes.sliderLoadingBox}></Box>
      )}
    </>
  );
};

export default IndexWidget;
