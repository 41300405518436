import { Divider, List, ListItem, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { useChatStyles } from "./chat.style";

const RelatedQuestions = ({ questions, ownChat, onAskQuestion = () => {} }) => {
  const classes = useChatStyles();

  return (
    <>
      <Typography variant="h5" className={classes.subHeading}>
        Related
      </Typography>
      <List disablePadding style={{ maxWidth: "max-content" }}>
        {questions?.map((question, index) => (
          <Fragment key={index}>
            <Divider light />
            <ListItem
              button
              disableGutters
              disableRipple
              className={classes.relatedQuestion}
              onClick={() => ownChat && onAskQuestion({ query: question })}
            >
              {question}
            </ListItem>
          </Fragment>
        ))}
      </List>
    </>
  );
};

export default RelatedQuestions;
