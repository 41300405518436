import { Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import Markdown from "react-markdown";
import { useChatStyles } from "./chat.style";

const QueryAnswer = ({ answers, sourceNumber }) => {
  const classes = useChatStyles();

  return (
    <>
      <Typography variant="h5" className={classes.subHeading}>
        Answer
      </Typography>
      <div className={classes.answer}>
        {answers
          ? answers?.map((answer, index) => (
              <AnswerList key={index} {...answer} sourceNumber={sourceNumber} />
            ))
          : "No answer found."}
      </div>
    </>
  );
};

const AnswerList = ({ answer, source, sourceNumber }) => {
  const classes = useChatStyles();

  const sources = source ? (Array.isArray(source) ? source : [source]) : [];
  const uniqueSources = [...new Set(sources)]; // Sometimes BE returns duplicate sources.

  const sourceBadges = uniqueSources?.map((sourceUrl, index) => (
    <Fragment key={`${sourceUrl}-${index}`}>
      {!!sourceNumber[sourceUrl] && (
        <a
          href={sourceUrl}
          target="_blank"
          rel="noreferrer"
          className={classes.sourceNumber}
        >
          <span style={index === 0 ? {} : { marginLeft: 0 }}>
            {sourceNumber[sourceUrl]}
          </span>
        </a>
      )}
    </Fragment>
  ));

  // Finds the last element and appends the source badge. If the last element also has children, it work recursively and appends the source badge.
  const withSourceBadge = (children) => {
    if (Array.isArray(children)) {
      // Find the element from last that is not a "\n"
      let lastHtmlElementIndex;
      for (let i = children.length - 1; i >= 0; i--) {
        if (children[i] !== "\n") {
          lastHtmlElementIndex = i;
          break;
        }
      }

      return children.map((child, index) => {
        const Component = child?.type ?? Fragment;
        if (lastHtmlElementIndex === index) {
          if (Array.isArray(child?.props?.children)) {
            return (
              <Component key={index}>
                {withSourceBadge(child?.props?.children)}
              </Component>
            );
          }

          return (
            <Component key={index}>
              {child?.type ? child?.props?.children : child}
              {sourceBadges}
            </Component>
          );
        }

        return child;
      });
    }
  };
  return (
    <>
      {/* {chart && <AnswerTable data={chart} />} */}
      <Markdown
        components={{
          div: ({ children, node }) => {
            const isRootElement = node?.properties?.className === "root-answer";

            if (isRootElement) {
              if (Array.isArray(children)) {
                return withSourceBadge(children);
              }
              const Component = children.type ?? Fragment;
              if (children.props?.children) {
                const hasMultipleChild = Array.isArray(
                  children.props?.children
                );

                return (
                  <Component>
                    {hasMultipleChild
                      ? withSourceBadge(children.props?.children)
                      : children.props?.children}
                    {hasMultipleChild ? null : sourceBadges}
                  </Component>
                );
              }
            }

            return children;
          },
        }}
        className="root-answer"
      >
        {answer}
      </Markdown>
    </>
  );
};

export default QueryAnswer;
