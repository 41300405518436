import { Box, Typography, makeStyles } from "@material-ui/core";
import moment from "moment-timezone";
import React from "react";

const useStyles = makeStyles(() => ({
  notification: {
    cursor: "pointer",
    padding: [[18, 30, 18, 14]],
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "start",
    "&:hover": {
      backgroundColor: "#dbe9f9",
      borderColor: "#dbe9f9",
    },
  },
}));

const UserNotificationList = ({ notifications }) => {
  const classes = useStyles();

  if (!notifications?.length) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight={300}
      >
        <Typography color="textSecondary">No notifications found</Typography>
      </Box>
    );
  }

  return (
    <Box>
      {notifications.map(
        ({ id, message, alertDate, alertType, value, isRead, link }) => (
          <Box
            key={id}
            className={classes.notification}
            onClick={() => window.open(link)}
          >
            <Box
              height={7}
              width={7}
              bgcolor={isRead ? "transparent" : "error.main"}
              borderRadius="50%"
              mt="6px"
              mr={1}
            />
            <Box flexGrow={1}>
              <Box
                mb={0.5}
                display="flex"
                alignItems="center"
                gridColumnGap={6}
              >
                <Typography
                  variant="caption"
                  style={{ textTransform: "capitalize" }}
                >
                  {alertType}
                </Typography>
                {alertType !== "portfolio" && alertType !== "watchlist" && (
                  <>
                    <Typography variant="caption">•</Typography>
                    <Typography variant="caption">{value}</Typography>
                  </>
                )}
                <Typography
                  variant="caption"
                  component="p"
                  style={{ marginLeft: "auto" }}
                >
                  {moment(alertDate).fromNow()}
                </Typography>
              </Box>
              <Typography variant="body2">{message}</Typography>
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

export default UserNotificationList;
