import constants from "../constants";

export const setRedirectUrl = (url) => {
  localStorage.setItem("deepLinkingUrl", url);
  return {
    type: constants.SET_REDIRECT_URL,
    payload: url,
  };
};
export const resetRedirectUrl = () => {
  localStorage.removeItem("deepLinkingUrl");
  return {
    type: constants.RESET_REDIRECT_URL,
  };
};
