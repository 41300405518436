import { Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

const ChatCredits = (props) => {
  const { userInfo } = useSelector((state) => state.signIn.loggedUser);
  const usage = userInfo?.chatUsage || 0;
  const total = userInfo?.chatCredits || 0;

  return (
    <Typography
      color={usage >= total ? "error" : "textSecondary"}
      variant="body2"
      {...props}
    >
      Usage: {usage}/{total} credits
    </Typography>
  );
};

export default ChatCredits;
