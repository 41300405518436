import { Typography, makeStyles, useTheme } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    height: 55,
    paddingLeft: 15,
    paddingRight: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translateY(-100%)",
    borderBottom: "2px solid rgba(0, 0, 0, 0.15)",
    borderRadius: 0,
    fontSize: 18,
    overflowX: "auto",
    MsOverflowStyle: "none" /* IE and Edge */,
    scrollbarWidth: "none" /* Firefox */,
    transition:
      "transform 0.8s cubic-bezier(0.300, 0.000, 0.175, 1.000) !important;",
    [theme.breakpoints.down("1168")]: {
      justifyContent: "flex-start",
    },
    "& a": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      "& .MuiTypography-body1": {
        fontSize: 17,
      },
      "& svg": {
        "& path": {
          fill: "#fff",
        },
        verticalAlign: "middle",
      },
      textDecoration: "none",
    },
  },
  bannerText: {
    width: "100%",
    overflowX: "auto",
  },
}));

const NotificationBanner = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { show, message, link } = useSelector(
    (state) => state.notificationBanner
  );

  if (!show || !message) return null;

  const text = (
    <Typography
      variant="body1"
      className={classes.bannerText}
      style={{ color: "#fff" }}
      noWrap
    >
      <b>
        {message}
        <Typography
          style={{
            display: "inline-block",
            lineHeight: "12px",
            verticalAlign: "middle",
            marginLeft: 4,
          }}
        >
          →
        </Typography>
      </b>
    </Typography>
  );

  return (
    <div
      className={`${classes.root} bannerShow`}
      style={{ backgroundColor: theme.palette.primary.main }}
    >
      {link ? (
        <Link to={link} style={{ color: "#fff" }}>
          {text}
        </Link>
      ) : (
        text
      )}
    </div>
  );
};

export default NotificationBanner;
