import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { ClearToast } from "../../redux/toast/action";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  snackbarWrap: {
    zIndex: 99991
  }
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ToastSnackbar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { status, message, type } = useSelector((state) => state.toastReducer);

  return (
    <Fragment>
      {status && (
        <Snackbar
          classes={{ root: classes.snackbarWrap }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={status}
          autoHideDuration={2000}
          onClose={() => dispatch(ClearToast())}
        >
          <Alert onClose={() => dispatch(ClearToast())} severity={type}>{message}</Alert>
        </Snackbar>
      )}
    </Fragment>
  );
};
export default React.memo(ToastSnackbar);
