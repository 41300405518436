import * as actions from "../actions";
import {
  getWatchListApi,
  getAllWatchListStocksApi,
} from "../api/watchlistApis";
import { signInClearAction } from "../auth/action";

export const getWatchListAction = (idToken, body) => {
  return async (dispatch) => {
    dispatch(actions.loaderStatusChange(true));
    try {
      const apiResponse = await getAllWatchListStocksApi(idToken);
      if (apiResponse.status === 200) {
        dispatch(actions.getWatchList(apiResponse.data));
      }
      dispatch(actions.loaderStatusChange(false));
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(signInClearAction(true));
      }
      dispatch(actions.loaderStatusChange(false));
    }
  };
};

export const addWatchListItemAction = (body) => {
  return async (dispatch) => {
    dispatch(actions.addWatchListItem(body));
  };
};
export const pageNumberChangeAction = (page) => {
  return async (dispatch) => {
    dispatch(actions.pageNumberChange(page));
  };
};
export const pageNumberChangeVolumeAction = (page) => {
  return async (dispatch) => {
    dispatch(actions.pageNumberChangeVolume(page));
  };
};
export const pageNumberChangeSignalAction = (page) => {
  return async (dispatch) => {
    dispatch(actions.pageNumberChangeSignal(page));
  };
};
export const sectorSignalAction = (sectorList) => {
  return async (dispatch) => {
    dispatch(actions.sectorSignal(sectorList));
  };
};
export const getTradeSideAction = (data) => {
  return async (dispatch) => {
    dispatch(actions.tradeSizeOption(data));
  };
};

export const removeWatchListItemAction = (data) => {
  return async (dispatch) => {
    dispatch(actions.removeWatchListItem(data));
  };
};

export const removeWatchListFromStockAction = (data) => {
  return async (dispatch) => {
    dispatch(actions.removeWatchListFromStock(data));
  };
};

export const sortTableData = (data, category) => {
  return async (dispatch) => {
    dispatch(actions.sortData(data, category));
  };
};

export const setDataListAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      const {
        Investors,
        Influencers,
        Tweets,
        News,
        Description,
        Financials,
        Earnings,
        Address,
        analystPriceTarget,
        upgradeDowngrade,
        Institution,
        Insider,
        ceo,
        fullTimeEmployees,
        ipoDate,
        website,
        image,
      } = data;
      dispatch(
        actions.setDataList({
          ...data,
          investors: Investors,
          influencer: Influencers,
          tweets: Tweets,
          news: News,
          companyDescription: Description,
          financialsChartData: Financials,
          earningsChartData: Earnings,
          addressData: Address,
          analystPriceTarget: analystPriceTarget,
          upgradeDowngrade: upgradeDowngrade,
          institution: Institution,
          insiderStock: Insider,
          ceo: ceo,
          fullTimeEmployees: fullTimeEmployees,
          ipoDate: ipoDate,
          website: website,
          image: image,
        })
      );
    } catch (error) {}
  };
};
export const setStockSignalAction = (data, isLoad, apiName, resetPressed) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setStockSignal({
          signal: data?.[apiName],
          signalPagination: data?.pagination,
          signalFilter: resetPressed ? {} : data?.filters || {},
        })
      );
    } catch (error) {}
  };
};
export const setInstitutionAction = (data, isLoad, apiName) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setInstitution({
          institution: data,
        })
      );
    } catch (error) {}
  };
};
export const setInstitutionProfileAction = (data, isLoad, apiName) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setInstitutionProfile({
          // institutionProfile: data,
          institutionProfileChart: data?.Chart,
          institutionProfileRecentTrades: data?.RecentTrades,
          institutionProfileTopBuys: data?.TopBuys,
          institutionProfileTopHoldings: data?.TopHoldings,
          institutionProfileTopSells: data?.TopSells,
          institutionProfileOwnershipstats: data?.ownershipstats,
        })
      );
    } catch (error) {}
  };
};
export const setTopBuyProfileAction = (data, isLoad, apiName) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setTopBuyProfile({
          institutionTopBuyProfile: data,
        })
      );
    } catch (error) {}
  };
};
export const setProfileRecentTradesAction = (data, isLoad, apiName) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setRecentTradesProfile({
          institutionRecentTradesProfile: data,
        })
      );
    } catch (error) {}
  };
};
export const setStockInsiderAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setStockInsider({
          insider: data?.insider,
          insiderAggTable: data?.insiderAggTable,
          insiderChart: data?.insiderchart,
        })
      );
    } catch (error) {}
  };
};
export const setStockTranscriptAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setStockTranscript({
          transcript: data,
        })
      );
    } catch (error) {}
  };
};
export const setStockSECFilingsAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setStockSECFilings({
          SECFilingsData: data,
        })
      );
    } catch (error) {}
  };
};
export const setStockTweetsAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setStockTweets({
          stockTweets: data,
        })
      );
    } catch (error) {}
  };
};
export const setAnalystForecastAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setAnalystForecast({
          analystForecast: data,
        })
      );
    } catch (error) {}
  };
};
export const setStockNewsAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setStockNews({
          stockNews: data,
        })
      );
    } catch (error) {}
  };
};
export const setStockOwnershipAction = (
  data,
  ownershipStats,
  activistFilings,
  isLoad
) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setStockOwnership({
          ownership: data,
          ownershipStats: ownershipStats,
          activistFilings: activistFilings,
        })
      );
    } catch (error) {}
  };
};
export const setStockFilingsAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setStockFiling({
          filing: data,
        })
      );
    } catch (error) {}
  };
};
export const setTrandingDataAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setTrandingData({
          trandingData: data?.Trend,
          trandingMegaData: data?.TrendByMarketCap?.Mega,
          trandingLargeData: data?.TrendByMarketCap?.Large,
          trandingMidData: data?.TrendByMarketCap?.Mid,
          trandingSmallData: data?.TrendByMarketCap?.Small,
        })
      );
    } catch (error) {}
  };
};
export const setAnalystDataAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setAnalystPageData({
          analystPageData: {
            sectorGrades: data?.sectorGrades,
            recommendedStocks: data?.recommendedStocks,
            upgradeDowngrade: data?.upgradeDowngrade,
          },
        })
      );
    } catch (error) {}
  };
};
export const setNewsTrandingDataAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setNewsTrandingData({
          newsTrandingData: data,
        })
      );
    } catch (error) {}
  };
};
export const setInsiderDataAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setInsiderData({
          insiderData: data,
        })
      );
    } catch (error) {}
  };
};
export const setTweetsTrandingDataAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setTweetsTrandingData({
          tweetsTrandingData: data,
        })
      );
    } catch (error) {}
  };
};
export const setEarningsCalendarDataAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setEarningsCalendar({
          earningsCalendar: data,
        })
      );
    } catch (error) {}
  };
};

export const setStockStatementAction = (data, isLoad) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      dispatch(
        actions.setStockStatement({
          annualIncome: data?.AnnualIncomeStmt,
          quarterlyIncome: data?.QuarterlyIncomeStmt,
          annualBS: data?.AnnualBalanceSheet,
          quarterlyBS: data?.QuarterlyBalanceSheet,
          annualCF: data?.AnnualCashFlow,
          quarterlyCF: data?.QuarterlyFlow,
        })
      );
    } catch (error) {}
  };
};

export const getStocksListAction = (idToken, body, isLoad, type) => {
  return async (dispatch) => {
    if (isLoad) {
      dispatch(actions.loaderStatusChange(true));
    }
    try {
      const apiResponse = await getWatchListApi(idToken, body);
      const { negative, positive, topgainers, toplosers, list } =
        apiResponse?.data;
      var newObject = list.reduce(function (obj, value) {
        var key = value.category;
        if (obj[key] == null) obj[key] = [];
        obj[key].push(value);
        return obj;
      }, {});
      if (apiResponse.status === 200) {
        dispatch(
          actions.getStocksLists({
            data: newObject,
            negative: negative || [],
            positive: positive || [],
            topGainers: topgainers || [],
            topLosers: toplosers || [],
            type: type,
          })
        );
        dispatch(actions.loaderStatusChange(false));
      }
      dispatch(actions.loaderStatusChange(false));
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(signInClearAction(true));
      }
      dispatch(actions.loaderStatusChange(false));
    }
  };
};

export const searchStockTickerAction = (ticker) => {
  return async (dispatch) => {
    dispatch(actions.searchStockTicker(ticker));
  };
};
export const searchInstitutionAction = (cik) => {
  return async (dispatch) => {
    dispatch(actions.searchInstitution(cik));
  };
};
