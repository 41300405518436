import React from "react";
import Table from "../Common/Table";
import moment from "moment-timezone";

const NotificationsTable = (props) => {
  const columns = [
    {
      name: "alertType",
      heading: "Alert Type",
      sortable: true,
    },
    {
      name: "value",
      heading: "Ticker",
      sortable: true,
    },
    {
      name: "message",
      heading: "Alert",
    },
    {
      name: "alertDate",
      heading: "Date",
      sortable: true,
      renderCellBody: ({ value }) => moment(value).format("MM-DD-YYYY"),
    },
  ];

  return <Table columns={columns} style={{ marginTop: 6 }} {...props} />;
};

export default NotificationsTable;
