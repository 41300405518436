import React, { lazy, memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import NotFound from "../components/Common/NotFound";
import { isLoggedIn, setDeepLinkUrl } from "../utils/authentication";
import { getParam } from "../utils/javascript";
import { pathsToUntrack } from "../utils/constants";
import NewChat from "../components/Chat/NewChat";
import Chat from "../components/Chat/Chat";
import ChatHistory from "../components/Chat/ChatHistory";
import PublicChat from "../components/Chat/PublicChat";
import RedirectToSummary from "../components/StockPage/RedirectToSummary";
import AlertSettings from "../components/AlertSettings/AlertSettings";
import Notifications from "../components/Notifications/Notifications";

const SignIn = lazy(() => import("../components/SignIn/SignIn"));
const Index = lazy(() => import("../components/LandingPage/Index"));
const Disclosure = lazy(() => import("../components/Disclosure/Disclosure"));
const HomePage = lazy(() => import("../components/HomePage/Index"));
const PlanPage = lazy(() => import("../components/PlanPage/Index"));
const TopStockPage = lazy(() => import("../components/TopStockPage/Index"));
const TrendsPage = lazy(() => import("../components/TrendsPage/Index"));
const ChartPage = lazy(() => import("../components/ChartPage/Index"));
const AnalystsPage = lazy(() => import("../components/Analyst/Index"));
const InstitutionPage = lazy(() => import("../components/Institution/Index"));
const PortfolioWatchlistPage = lazy(() =>
  import("../components/PortfolioWatchlist/Index")
);
const Watchlist = lazy(() => import("../components/Watchlist/Index"));
const EconomyPage = lazy(() => import("../components/Economy/Index"));
const PoliticsPage = lazy(() => import("../components/Politics/Index"));
const PoliticianPage = lazy(() => import("../components/Politician/Index"));
const InsiderPage = lazy(() => import("../components/Insider/Index"));
const TweetsPage = lazy(() => import("../components/Tweets/Index"));
const NewsPage = lazy(() => import("../components/News/Index"));
const SignalsPage = lazy(() => import("../components/SignalsPage/Index"));
const StockPage = lazy(() => import("../components/StockPage/Index"));
const InstitutionProfilePage = lazy(() =>
  import("../components/InstitutionProfilePage/Index")
);
const AnalystPage = lazy(() => import("../components/AnalystPage/Index"));
const EarningsCalendarPage = lazy(() =>
  import("../components/EarningsCalendarPage/Index")
);
const SignUp = lazy(() => import("../components/SignUp/SignUp"));
const Dashboard = lazy(() => import("../components/Dashboard/Dashboard"));
const DashboardDetails = lazy(() =>
  import("../components/Dashboard/DashboardDetails")
);
const Account = lazy(() => import("../components/Account/Account"));
const Admin = lazy(() => import("../components/Admin/Admin"));
const ForgotPassword = lazy(() =>
  import("../components/ForgotPassword/ForgotPassword")
);
const ConfirmationCode = lazy(() =>
  import("../components/ConfirmationCode/ConfirmationCode")
);
const BillingFaq = lazy(() => import("../components/BillingFaq/BillingFaq"));
const Confirmation = lazy(() => import("../components/Dashboard/Confirmation"));
const PrivacyPolicy = lazy(() =>
  import("../components/PrivacyPolicy/PrivacyPolicy")
);
const TermAndCondition = lazy(() =>
  import("../components/TermAndCondition/TermAndCondition")
);
const ContactPage = lazy(() => import("../components/ContactPage/Index"));
const StockScreener = lazy(() => import("../components/StockScreener"));
const HeatMap = lazy(() => import("../components/HeatMap"));
const AdvertisePage = lazy(() => import("../components/AdvertisePage/Index"));
const SetNewPassword = lazy(() =>
  import("../components/SetNewPassword/SetNewPassword")
);
const ConfirmIntent = lazy(() =>
  import("../components/CardDetails/ConfirmIntent")
);
const Insights = lazy(() => import("../components/Insights/Insights"));
const Subscription = lazy(() => import("../components/Dashboard/Subscription"));
const Profile = lazy(() => import("../components/Insights/Profile"));
const Feed = lazy(() => import("../components/Feed/Feed"));
const News = lazy(() => import("../components/NewsPage/Index"));
const Signals = lazy(() => import("../components/Signals/Signals"));
const Trending = lazy(() => import("../components/Trending/Trending"));
const EarningsCalendar = lazy(() =>
  import("../components/EarningsCalendar/EarningsCalendar")
);
const DividendCalendar = lazy(() =>
  import("../components/DividendCalendar/DividendCalendar")
);
const UnusualVolume = lazy(() =>
  import("../components/UnusualVolume/UnusualVolume")
);
const InsiderTrades = lazy(() =>
  import("../components/InsiderTrades/InsiderTrades")
);
const AiTopPicks = lazy(() => import("../components/AiTopPicks/Index"));
const AiEtfPicks = lazy(() => import("../components/AiEtfPicks/Index"));
const PublicAiTopPicks = lazy(() =>
  import("../components/AiTopPicks/PublicPage")
);

const logoutRoutes = [
  "/about",
  "/plans",
  "/top-stock",
  "/trends",
  "/multichart",
  "/institution",
  "/economy",
  "/politics",
  "/politician",
  "/investor",
  "/forecast",
  "/insider",
  "/news",
  "/tweets",
  "/stocks",
  "/earnings",
  "/sign-in",
  "/sign-up",
  "/contact",
  "/advertise",
  "/quote",
  "/analyst",
  "/forgot-password",
  "/set-new-password",
  "/billing-faq",
  "/privacy-policy",
  "/term-and-condition",
  "/confirmation",
  "/calendar",
  "confirmPayment",
];

const admin = [
  {
    path: "/admin",
    isLogin: true,
    component: Admin,
  },
];

const user = [
  {
    path: "/home",
    isLogin: true,
    component: Dashboard,
  },
  {
    path: "/portfolio/:id",
    isLogin: true,
    component: PortfolioWatchlistPage,
  },
  {
    path: "/portfolio",
    isLogin: true,
    component: PortfolioWatchlistPage,
  },
  {
    path: "/watchlist",
    isLogin: true,
    component: Watchlist,
  },
  {
    path: "/payment-success",
    isLogin: true,
    component: Confirmation,
  },
  {
    // path: "/quote/:id",
    path: "/quote/:ticker/:id",
    isLogin: true,
    component: DashboardDetails,
  },
  {
    path: "/quote/:ticker",
    isLogin: true,
    component: DashboardDetails,
  },
  {
    path: "/fintwit",
    isLogin: true,
    component: Insights,
  },
  {
    path: "/subscription",
    isLogin: true,
    component: Subscription,
  },
  {
    path: "/analyst",
    isLogin: true,
    component: Profile,
  },
  {
    path: "/institution/:id",
    isLogin: true,
    component: InstitutionPage,
  },
  {
    path: "/institution",
    isLogin: true,
    component: InstitutionPage,
  },
  {
    path: "/economy",
    isLogin: true,
    component: EconomyPage,
  },
  {
    path: "/politics/:id/:subTab",
    isLogin: true,
    component: PoliticsPage,
  },
  {
    path: "/politics/:id",
    isLogin: true,
    component: PoliticsPage,
  },
  {
    path: "/politics",
    isLogin: true,
    component: PoliticsPage,
  },
  {
    path: "/politician",
    isLogin: true,
    component: PoliticianPage,
  },
  {
    path: "/investor/:cik/:id",
    isLogin: true,
    component: InstitutionProfilePage,
  },
  {
    path: "/feed/:id",
    isLogin: true,
    component: Feed,
  },
  {
    path: "/feed",
    isLogin: true,
    component: Feed,
  },
  {
    path: "/news",
    isLogin: true,
    component: News,
  },
  {
    path: "/stocks/:id",
    isLogin: true,
    component: Signals,
  },
  {
    path: "/stocks",
    isLogin: true,
    component: Signals,
  },
  {
    path: "/insider",
    isLogin: true,
    component: InsiderTrades,
  },
  {
    path: "/trends",
    isLogin: true,
    component: Trending,
  },
  {
    path: "/forecast/:id",
    isLogin: true,
    component: AnalystsPage,
  },
  {
    path: "/multichart",
    isLogin: true,
    component: ChartPage,
  },
  {
    path: "/forecast",
    isLogin: true,
    component: AnalystsPage,
  },
  {
    path: "/calendar/:id",
    isLogin: true,
    component: EarningsCalendar,
  },
  {
    path: "/calendar",
    isLogin: true,
    component: EarningsCalendar,
  },
  /*  {
    path: "/dividendcalendar",
    isLogin: true,
    component: DividendCalendar,
  }, */
  {
    path: "/unusualvolume",
    isLogin: true,
    component: UnusualVolume,
  },
  {
    path: "/account",
    isLogin: true,
    component: Account,
  },
  {
    path: "/toppicks",
    isLogin: true,
    component: AiTopPicks,
  },
  {
    path: "/topetfs",
    isLogin: true,
    component: AiEtfPicks,
  },
  {
    path: "/chat",
    exact: true,
    isLogin: true,
    component: NewChat,
  },
  {
    path: "/chat/history",
    isLogin: true,
    component: ChatHistory,
  },
  {
    path: "/chat/:chatId",
    isLogin: true,
    component: Chat,
  },
  {
    path: "/notification-settings",
    isLogin: true,
    component: AlertSettings,
  },
  {
    path: "/notifications",
    isLogin: true,
    component: Notifications,
  },
  {
    path: "/screener",
    isLogin: true,
    component: StockScreener,
  },
  {
    path: "/heatmap",
    isLogin: true,
    component: HeatMap,
  },
];

const notLogin = [
  {
    path: "/home",
    isLogin: true,
    component: Dashboard,
  },
  {
    path: "/confirmPayment",
    component: ConfirmIntent,
    exact: true,
  },
  {
    path: "/",
    component: HomePage,
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/about",
    component: Index,
    isLogin: false,
    // exact: true,
    // isAuth: true,
    // redirectUrl: "/home",
  },
  {
    path: "/disclosure",
    component: Disclosure,
    isLogin: false,
  },
  {
    path: "/plans",
    component: PlanPage,
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/top-stock",
    component: TopStockPage,
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/trends",
    component: TrendsPage,
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/multichart",
    component: ChartPage,
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/analyst",
    component: Profile,
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/institution/:id",
    component: InstitutionPage,
    exact: false,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/economy/:id",
    component: EconomyPage,
    exact: false,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/politics/:id",
    component: PoliticsPage,
    exact: false,
    isAuth: true,
    redirectUrl: "/home",
  },
  /* {
    path: "/politics",
    component: PoliticsPage,
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
  }, */
  {
    path: "/politician",
    component: PoliticianPage,
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
  },
  /*  {
    path: "/investor",
    component: InstitutionProfilePage,
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
  }, */
  {
    path: "/insider",
    component: InsiderPage,
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/news/:id",
    component: NewsPage,
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
  },
  /*  {
    path: "/news/RecentEarnings",
    component: NewsPage,
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
  }, */
  {
    path: "/tweets/:id",
    component: TweetsPage,
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/stocks/:id",
    component: SignalsPage,
    exact: false,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/calendar/:id",
    component: EarningsCalendarPage,
    exact: false,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/quote/:ticker/:id",
    component: StockPage,
    exact: false,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/quote/:ticker",
    component: StockPage,
    exact: false,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/investor/:cik/:id",
    component: InstitutionProfilePage,
    exact: false,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/forecast/:id",
    component: AnalystsPage,
    exact: false,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/sign-up",
    component: SignUp,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/sign-in",
    isAuth: true,
    component: SignIn,
    redirectUrl: "/home",
  },
  {
    path: "/forgot-password",
    isAuth: true,
    component: ForgotPassword,
    redirectUrl: "/home",
  },
  {
    path: "/confirmation",
    isAuth: true,
    component: ConfirmationCode,
    redirectUrl: "/home",
  },
  {
    path: "/set-new-password",
    isAuth: true,
    component: SetNewPassword,
  },
  {
    path: "/term-and-condition",
    isLogin: false,
    component: TermAndCondition,
  },
  {
    path: "/contact",
    isLogin: false,
    component: ContactPage,
  },
  {
    path: "/screener",
    isLogin: false,
    component: () => <StockScreener isPublicPage />,
  },
  {
    path: "/heatmap",
    isLogin: false,
    component: () => <HeatMap isPublicPage />,
  },
  {
    path: "/advertise",
    isLogin: false,
    component: AdvertisePage,
  },
  {
    path: "/privacy-policy",
    isLogin: false,
    component: PrivacyPolicy,
  },
  {
    path: "/billing-faq",
    isLogin: false,
    component: BillingFaq,
  },
  {
    path: "/toppicks",
    component: PublicAiTopPicks,
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
  },
  {
    path: "/chat",
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
    component: PublicChat,
  },
  {
    path: "/chat/:chatId",
    exact: true,
    isAuth: true,
    redirectUrl: "/home",
    component: Chat,
  },
  {
    path: "*",
    component: NotFound,
  },
];

const SelectComponent = (
  Component,
  props,
  redirectUrl,
  isAuth,
  isLogin,
  dimensions
) => {
  const FinalComponent = <Component {...{ ...props, dimensions }} />;
  const FinalRedirectUrl = <Redirect to={{ pathname: redirectUrl }} />;
  if (isAuth) return isLoggedIn() ? FinalRedirectUrl : FinalComponent;
  if (isLogin) return isLoggedIn() ? FinalComponent : FinalRedirectUrl;
  return FinalComponent;
};

const Routing = ({ dimensions }) => {
  const history = useHistory();
  const { userInfo } = useSelector((state) => state.signIn.loggedUser);
  let { idToken } = isLoggedIn();
  let urlParam = getParam(window.location.search, "ticker");
  let id = getParam(window.location.search, "id");
  const redirect = JSON.parse(localStorage.getItem("redirectTo"));
  const { redirectUrl: deepLinkingRedirectUrl } = useSelector(
    (state) => state.redirectTo
  );

  const ignoreHomeDeepLinking = localStorage.getItem("ignoreHomeDeepLinking");

  if (window.location.pathname === "/" && idToken) {
    history.replace("/home");
  }

  useEffect(() => {
    const isAuthRoute = ["/sign-in", "/sign-up"].includes(
      history.location.pathname
    );
    if (
      !idToken &&
      !isAuthRoute &&
      history.location.pathname !== "/confirmPayment"
    ) {
      if (!logoutRoutes.includes(history.location.pathname)) {
        const url1 = window.location.pathname;
        const search = getParam(window.location.search);
        const ticker = getParam(window.location.search, "ticker")
          ? "?ticker=" + getParam(window.location.search, "ticker")
          : null;
        const id = getParam(window.location.search, "id")
          ? "?id=" + getParam(window.location.search, "id")
          : null;
        if (url1 !== "/") {
          setDeepLinkUrl(`${url1}`, `${ticker ?? id ?? ""}`);
        }
        // history.replace("/");
      }
    }
  }, [idToken]);

  const routesList = (name) => {
    switch (name) {
      case "ADMIN":
        return [...admin, ...user, ...notLogin];
      case "USER":
        return [...user, ...notLogin];
      default: {
        return [...notLogin];
      }
    }
  };

  const routesData = routesList(userInfo?.userType);

  routesData.find((val) => {
    if (val.path === "/market") {
      val.component = urlParam ? DashboardDetails : Dashboard;
    }

    if (val.path === "/fintwit") {
      val.component = id ? Profile : Insights;
    }
  });
  return (
    <Switch>
      {(routesData || []).map(
        (
          { component: Component, redirectUrl, isLogin, isAuth, ...routes },
          index
        ) => (
          <Route
            key={index}
            {...routes}
            render={(props) =>
              SelectComponent(
                Component,
                props,
                ignoreHomeDeepLinking ||
                  ["/", ...pathsToUntrack].includes(routes.path)
                  ? deepLinkingRedirectUrl
                  : redirectUrl,
                isAuth,
                isLogin,
                dimensions
              )
            }
          />
        )
      )}
    </Switch>
  );
};

export default memo(Routing, () => (getParam("ticker") ? "" : false));
