import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Hidden,
  IconButton,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as ArrowRightIcon } from "../../assets/images/arrow-right.svg";
import CustomSelect from "../Common/CustomSelect";
import ShowTooltip from "../Common/ShowTooltip";
import useTypingEffect from "../../hooks/useTypingEffect";

const inputSources = [
  {
    label: "Auto",
    value: "auto",
  },
  {
    label: "10-K",
    value: "10k",
  },
  {
    label: "10-Q",
    value: "10q",
  },
  {
    label: "Transcript",
    value: "Transcript",
  },
  {
    label: "Web Search",
    value: "Search",
  },
  {
    label: "Market Data",
    value: "SQL",
  },
];

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "20px 30px",
    border: "1px solid rgb(217 215 208)",
    borderRadius: 8,
    maxWidth: ({ fullWidth }) => (fullWidth ? "100%" : 735),
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      padding: 16,
    },
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    gap: 12,
  },
  queryInput: {
    fontFamily: "Roboto",
    padding: "8px 10px 8px 0px",
    width: "100%",
    border: "transparent",
    fontSize: "16px",
    "&:placeholder": {
      fontSize: "16px",
    },
    "&:focus-visible": {
      outline: "none",
    },
  },
  arrowButton: {
    "&.MuiButtonBase-root": {
      padding: 8,
      "&:disabled": {
        backgroundColor: "rgba(61, 61, 59,0.1)",
        color: "rgba(0, 0, 0, 0.1)",
      },
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      fontSize: 20,
    },
  },
  sourceLabel: {
    marginBottom: 0,
    "& .MuiButtonBase-root": {
      padding: 6,
    },
  },
  sourcesLabel: {
    fontWeight: 500,
    fontSize: 18,
    marginBottom: 4,
    lineHeight: "normal",
    textWrap: "nowrap",
    display: "block",
    [theme.breakpoints.down("580")]: {
      marginTop: 4,
    },
  },
  sources: {
    display: "flex",
    alignItems: "center",
  },
  sourceSelect: {
    minWidth: 150,
    maxWidth: "max-content",
    "& .MuiSelect-root": {
      fontSize: 14,
    },
  },
}));

const QueryInput = ({ isLoading, onSubmit, fullWidth, ownChat }) => {
  const [query, setQuery] = useState("");
  const [source, setSource] = useState("auto");
  const placeholder = useTypingEffect("Ask your question", {
    typingSpeed: 50,
    erasingSpeed: 50,
    cycles: 1,
    isRunning: isLoading,
  });

  const classes = useStyles({ fullWidth });
  const { idToken } = useSelector((state) => state.signIn.loggedUser);

  const handleSubmit = async (e) => {
    if (isLoading || !ownChat) return;

    e.preventDefault();
    await onSubmit({ query, source, onSuccess: () => setQuery("") });
  };

  return (
    <Box className={classes.wrapper}>
      <Box
        component="form"
        className={classes.inputWrapper}
        onSubmit={handleSubmit}
      >
        <input
          value={query}
          onChange={(e) => !isLoading && setQuery(e.target.value)}
          fullWidth
          className={classes.queryInput}
          id="query-input"
          placeholder={placeholder}
        />
        <ShowTooltip
          title={
            idToken
              ? ownChat
                ? undefined
                : "Go to your private chat to ask queries"
              : "Sign in to ask query"
          }
          placement="bottom-end"
          open={!idToken || !ownChat ? undefined : false}
        >
          <div>
            <IconButton
              type="submit"
              disabled={!idToken || isLoading || !query || !ownChat}
              className={classes.arrowButton}
            >
              <ArrowRightIcon />
            </IconButton>
          </div>
        </ShowTooltip>
      </Box>
      <Box
        className={classes.sources}
        gridColumnGap={14}
        id="query-input-sources"
      >
        <Typography
          component="span"
          variant="subtitle1"
          className={classes.sourcesLabel}
        >
          Sources:
        </Typography>
        <Hidden smUp>
          <CustomSelect
            name="source"
            fullWidth
            value={source}
            onChange={(e) => {
              setSource(e.target.value);
            }}
            className={classes.sourceSelect}
          >
            {inputSources.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </CustomSelect>
        </Hidden>
        <Hidden xsDown>
          <FormGroup row>
            {inputSources.map(({ label, value }) => (
              <FormControlLabel
                className={classes.sourceLabel}
                key={label}
                name="source"
                control={<Checkbox checked={source === value} />}
                label={label}
                onChange={() => setSource(value)}
              />
            ))}
          </FormGroup>
        </Hidden>
      </Box>
    </Box>
  );
};

export default QueryInput;
