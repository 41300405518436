import { List, ListItem, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { fetchFavicon } from "../../utils/common";
import { useChatStyles } from "./chat.style";

const QuerySources = ({ sources }) => {
  const classes = useChatStyles();

  return (
    !!sources?.length && (
      <div>
        <Typography variant="h5" className={classes.subHeading}>
          Sources
        </Typography>
        <List style={{ maxWidth: "max-content" }}>
          {sources?.map((source, index) => {
            return (
              <ListItem
                key={index}
                className={classes.source}
                onClick={() => window.open(source)}
                divider
                button
                disableGutters
              >
                <a href={source} target="_blank" rel="noreferrer">
                  <Typography>{index + 1}.</Typography>
                  <Favicon source={source} />
                  <Typography noWrap>{source}</Typography>
                </a>
              </ListItem>
            );
          })}
        </List>
      </div>
    )
  );
};

const Favicon = ({ source }) => {
  const [favicon, setFavicon] = useState();

  useEffect(() => {
    (async () => {
      const favicon = await fetchFavicon(source);
      setFavicon(favicon);
    })();
  }, []);

  if (!favicon) return null;

  return <img src={favicon} alt="" height={20} width={20} />;
};

export default QuerySources;
