import React from "react";
import Table from "../Common/Table";
import CustomSelect from "../Common/CustomSelect";
import { alertTypes, conditions } from "./alertSettings.description";
import {
  IconButton,
  MenuItem,
  TableCell,
  TextField,
  makeStyles,
} from "@material-ui/core";
import TickerSearchSelect from "../Common/TickerSearchSelect";
import CustomSwitch from "../Common/CustomSwitch";
import { notificationChannels } from "../../utils/constants";
import { Check } from "@material-ui/icons";
import moment from "moment-timezone";
import ShowTooltip from "../Common/ShowTooltip";
import { LoaderAction } from "../../redux/loader/action";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toastMessage } from "../../redux/actions";

const useStyles = makeStyles(() => ({
  table: {
    marginTop: 1,
    "& .MuiTableHead-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
          minWidth: "155px",
        },
      },
    },
    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-body": {
          paddingTop: 6,
          paddingBottom: 6,
          "&:first-child": {
            fontSize: 15,
          },
        },
      },
    },
    "& .MuiSelect-root, & .MuiTextField-root .MuiInputBase-root": {
      background: "white",
      textTransform: "lowercase",
    },
    "& .MuiSelect-root, & .MuiTextField-root input": {
      paddingTop: 6,
      paddingBottom: 6,
    },
    "& .select__control": {
      minHeight: "auto",
    },
    "& .MuiSwitch-root": {
      margin: 0,
    },
  },
}));

const AlertSettingsTable = ({
  isEditMode,
  handleChange,
  onAlertUpdate,
  ...props
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { idToken } = useSelector((state) => state.signIn.loggedUser);

  const updateAlert = async (updatedAlert) => {
    if (
      updatedAlert.alertType === "price" ||
      updatedAlert.alertType === "insider"
    ) {
      if (!updatedAlert.condition) {
        dispatch(
          toastMessage({
            status: true,
            message: "Condition is required",
            type: "error",
          })
        );
        return;
      }
    }

    const url = `${process.env.REACT_APP_API_SERVER_URL}/user`;

    try {
      dispatch(LoaderAction(true));
      await axios.put(
        url,
        {
          method: "updateAlertSetting",
          ...updatedAlert,
        },
        {
          headers: {
            Authorization: idToken,
          },
        }
      );

      dispatch(
        toastMessage({
          status: true,
          message: "Alert updated",
          type: "success",
        })
      );

      onAlertUpdate(updatedAlert);
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: "Failed to update an alert. Please try again later.",
          type: "error",
        })
      );
    } finally {
      dispatch(LoaderAction(false));
    }
  };

  const columns = [
    {
      name: "alertType",
      heading: "Alert Type",
      sortable: true,
      renderCellBody: ({ value, row }) => {
        return isEditMode ? (
          <CustomSelect
            name="alertType"
            variant="outlined"
            value={value}
            onChange={(e) =>
              handleChange({
                id: row.id,
                name: e.target.name,
                value: e.target.value,
              })
            }
            fullWidth
          >
            {alertTypes.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </CustomSelect>
        ) : (
          value
        );
      },
    },
    {
      name: "ticker",
      heading: "Ticker",
      sortable: true,
      renderCellBody: ({ value, row }) => {
        return isEditMode ? (
          <TickerSearchSelect
            value={{ value }}
            onChange={({ value }) => {
              handleChange({
                id: row.id,
                name: "ticker",
                value,
              });
            }}
          />
        ) : (
          value
        );
      },
    },
    {
      name: "condition",
      heading: "Condition",
      centered: true,
      renderCellBody: ({ value, row }) => {
        return row.alertType === "price" || row.alertType === "insider" ? (
          isEditMode ? (
            <CustomSelect
              name="condition"
              variant="outlined"
              value={value}
              onChange={(e) =>
                handleChange({
                  id: row.id,
                  name: e.target.name,
                  value: e.target.value,
                })
              }
              fullWidth
            >
              {conditions.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </CustomSelect>
          ) : (
            value
          )
        ) : null;
      },
    },
    {
      name: "value",
      heading: "Value",
      renderCellBody: ({ value, row }) => {
        return row.alertType === "price" || row.alertType === "insider" ? (
          isEditMode ? (
            <TextField
              variant="outlined"
              size="small"
              name="value"
              type="number"
              value={value}
              onChange={(e) =>
                handleChange({
                  id: row.id,
                  name: e.target.name,
                  value: e.target.value,
                })
              }
            />
          ) : (
            value
          )
        ) : null;
      },
    },
    {
      name: "web",
      heading: "Web",
      centered: true,
      renderCellBody: ({ value, row }) => {
        return (
          <CustomSwitch
            name="web"
            size="small"
            disabled={!isEditMode}
            checked={
              value ??
              (row.channel === notificationChannels.WEB ||
                row.channel === notificationChannels.BOTH)
            }
            trackColor="gray"
            color="primary"
            onChange={(e) =>
              handleChange({
                id: row.id,
                name: "web",
                value: e.target.checked,
              })
            }
          />
        );
      },
    },
    {
      name: "email",
      heading: "Email",
      centered: true,
      renderCellBody: ({ value, row }) => {
        return (
          <CustomSwitch
            name="email"
            size="small"
            disabled={!isEditMode}
            checked={
              value ??
              (row.channel === notificationChannels.EMAIL ||
                row.channel === notificationChannels.BOTH)
            }
            trackColor="gray"
            color="primary"
            onChange={(e) =>
              handleChange({
                id: row.id,
                name: "email",
                value: e.target.checked,
              })
            }
          />
        );
      },
    },
    {
      name: "updatedDate",
      heading: "Date Added",
      sortable: true,
      colSpan: 2,
      renderCell: ({ value, row }) => {
        const { web, email, channel, ...rest } = row;

        return (
          <>
            <TableCell>{moment(value).format("MM-DD-YYYY")}</TableCell>
            {isEditMode && (
              <TableCell>
                <ShowTooltip title="Update alert" enterDelay={1000}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      const webChannel =
                        web === undefined
                          ? channel === notificationChannels.WEB ||
                            channel === notificationChannels.BOTH
                          : web;
                      const emailChannel =
                        email === undefined
                          ? channel === notificationChannels.EMAIL ||
                            channel === notificationChannels.BOTH
                          : email;
                      updateAlert({
                        ...rest,
                        value: value || 0,
                        channel:
                          webChannel && emailChannel
                            ? 3
                            : emailChannel
                            ? 2
                            : webChannel
                            ? 1
                            : 0,
                      });
                    }}
                  >
                    <Check />
                  </IconButton>
                </ShowTooltip>
              </TableCell>
            )}
          </>
        );
      },
    },
  ];

  return (
    <Table
      className={classes.table}
      hidePagination={isEditMode}
      noMatchText="No alerts found"
      {...props}
      columns={columns}
    />
  );
};

export default AlertSettingsTable;
