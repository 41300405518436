import axios from "axios";
import { isLoggedIn } from "../../utils/authentication";

export const userPost = async (idToken, data) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_SERVER_URL}/user`,
    // `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_BASE_URL}/user`,
    data ? data : null,
    config
  );
  return response;
};

export const getPost = async (idToken) => {
  const isLogin = isLoggedIn();
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken || isLogin.idToken,
    },
  };
  const response = await axios.get(
    `${process.env.REACT_APP_API_SERVER_URL}/user`,
    // `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_BASE_URL}/user`,
    config
  );
  return response;
};

export const deleteAccount = async (idToken, reason) => {
  const isLogin = isLoggedIn();
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken || isLogin.idToken,
    },
  };
  const response = await axios.delete(
    `${process.env.REACT_APP_API_SERVER_URL}/user`,
    config
  );
  return response;
};

export const updateAccount = async (idToken, body) => {
  const isLogin = isLoggedIn();
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken || isLogin.idToken,
    },
  };
  const response = await axios.put(
    `${process.env.REACT_APP_API_SERVER_URL}/user`,
    // `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_BASE_URL}/user`,
    body,
    config
  );
  return response;
};
