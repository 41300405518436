/* eslint-disable */
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Event from "@material-ui/icons/Event";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SettingsIcon from "@material-ui/icons/Settings";
import KeyIcon from "@material-ui/icons/VpnKey";
import WbIncandescentOutlinedIcon from "@material-ui/icons/WbIncandescentOutlined";
import React from "react";
import { ReactComponent as AIDotted } from "../../assets/images/ai-insight-dotted.svg";
import { ReactComponent as AI } from "../../assets/images/ai-insight.svg";
import { ReactComponent as EyeOutlinedIcon } from "../../assets/images/eye-outline.svg";
import { ReactComponent as HeatMapIcon } from "../../assets/images/heatmap.svg";
import { ReactComponent as Insider } from "../../assets/images/insider.svg";
import { ReactComponent as MagnifierIcon } from "../../assets/images/magnifier.svg";
import { ReactComponent as MultiChart } from "../../assets/images/multi-chart.svg";
import { ReactComponent as News } from "../../assets/images/news.svg";
import { ReactComponent as Politician } from "../../assets/images/politician.svg";
import { ReactComponent as Portfolio } from "../../assets/images/portfolio-icon.svg";
import { ReactComponent as ChatIcon } from "../../assets/images/chat-bars.svg";
import constants from "../constants";
import { NotificationsNone } from "@material-ui/icons";

const menus = [
  {
    title: "Home",
    icon: <DashboardIcon />,
  },
  {
    title: "Portfolio",
    icon: <Portfolio />,
  },
  // {
  //   title: "Fintwit",
  //   icon: <AlternateEmailIcon />,
  // },
  {
    title: "Stocks",
    icon: <WbIncandescentOutlinedIcon className="rottateIcon" />,
  },
  {
    title: "AI Top Stocks",
    icon: <AI />,
    url: "toppicks",
  },
  {
    title: "Multichart",
    icon: <MultiChart />,
  },
  // {
  //   title: "Trends",
  //   icon: <TrendingUp />,
  // },
  {
    title: "Politics",
    icon: <Politician />,
  },
  {
    title: "News",
    icon: <News />,
  },
  // {
  //   title: "Feed",
  //   icon: <TwitterIcon />,
  // },
  /* {
    title: "Unusual Volume",
    icon: <BarChartIcon />,
  }, */
  {
    title: "Calendar",
    icon: <Event />,
  },
  {
    title: "Insider",
    icon: <Insider />,
  },
  {
    title: "Forecast",
    icon: <ImageSearchIcon />,
  },
  {
    title: "Institution",
    icon: <AccountBalanceIcon />,
  },
  {
    title: "Economy",
    icon: <MonetizationOnIcon />,
  },
  {
    title: "Account",
    icon: <SettingsIcon />,
  },
];

const sidebarItems = [
  {
    title: "Home",
    icon: <DashboardIcon />,
  },
  {
    title: "AI Top Stocks",
    icon: <AI />,
    url: "toppicks",
    id: "ai-top-picks",
  },
  {
    title: "AI Top ETFs",
    icon: <AIDotted />,
    url: "topetfs",
    id: "ai-top-etfs",
  },
  {
    title: "AI Chat",
    icon: <ChatIcon />,
    url: "chat",
  },
  {
    title: "Portfolio",
    icon: <Portfolio />,
    id: "portfolio",
  },
  {
    title: "Watchlist",
    icon: <EyeOutlinedIcon />,
    id: "watchlist",
  },
  {
    title: "News",
    icon: <News />,
  },
  {
    title: "Heatmap",
    icon: <HeatMapIcon />,
  },
  {
    title: "Screener",
    icon: <MagnifierIcon />,
    url: "screener",
  },
  {
    title: "Multichart",
    icon: <MultiChart />,
    id: "multichart",
  },
  // {
  //   title: "Fintwit",
  //   icon: <AlternateEmailIcon />,
  // },
  {
    title: "Stocks",
    icon: <WbIncandescentOutlinedIcon className="rottateIcon" />,
    url: "stocks/top-gainers",
  },
  // {
  //   title: "Trends",
  //   icon: <TrendingUp />,
  // },
  // {
  //   title: "Feed",
  //   icon: <TwitterIcon />,
  // },
  {
    title: "Calendar",
    icon: <Event />,
  },
  {
    title: "Insider",
    icon: <Insider />,
  },
  {
    title: "Forecast",
    icon: <ImageSearchIcon />,
  },
  {
    title: "Institution",
    icon: <AccountBalanceIcon />,
  },
  {
    title: "Politics",
    icon: <Politician />,
  },
  {
    title: "Economy",
    icon: <MonetizationOnIcon />,
  },
  {
    title: "Notifications",
    icon: <NotificationsNone />,
  },
  {
    title: "Account",
    icon: <SettingsIcon />,
    id: "account-info",
  },
  {
    title: "Become an Affliate",
    icon: <LocalAtmIcon />,
    url: "https://fintwit-ai.getrewardful.com/signup",
    highlight: true,
    isExternalLink: true,
  },
];

const initialState = {
  isSuccess: false,
  loggedUser: {},
  menuList: menus,
  sidebarItems,
  innerWidth: 1900,
  subscribe: false,
  aboutStatus: [],
  stockTabValue: "",
  featuresStatus: [],
  plansStatus: [],
};

const signIn = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGIN_REQUEST_SUCCESS:
      const userType =
        action.payload &&
        action.payload.userInfo &&
        action.payload.userInfo.userType;
      let menu = [...state.menuList];
      const isAdded = state.menuList.find((l) => l.title === "Admin");
      if (!isAdded?.title && userType === "ADMIN") {
        menu = [...state.menuList, { title: "Admin", icon: <KeyIcon /> }];
      }
      return {
        ...state,
        isSuccess: true,
        loggedUser: action.payload,
        menuList: menu,
      };
    case constants.UPDATE_SUBSCRIPTION_STATUS:
      return {
        ...state,
        loggedUser: {
          ...state.loggedUser,
          userInfo: {
            ...state.loggedUser.userInfo,
            subscriptionStatus: action.payload && action.payload.status,
          },
        },
      };
    case constants.LOGIN_REQUEST_ERROR:
      return {
        ...state,
        isSuccess: false,
      };
    case constants.CLEAR_FLAG:
      return {
        ...state,
        loggedUser: {},
        menuList: menus,
        isSuccess: false,
      };
    case constants.WINDOW_WIDTH:
      return {
        ...state,
        innerWidth: action.payload && action.payload.width,
      };
    case constants.SUBSCRIBE_STATUS:
      return {
        ...state,
        subscribe: action.payload,
      };
    case constants.ABOUT_STATUS:
      return {
        ...state,
        aboutStatus: action.payload,
      };
    case constants.STOCK_TAB_CHANGES:
      return {
        ...state,
        stockTabValue: action.payload,
      };
    case constants.FEATURES_STATUS:
      return {
        ...state,
        featuresStatus: action.payload,
      };
    case constants.PLANS_STATUS:
      return {
        ...state,
        plansStatus: action.payload,
      };
    case constants.HIDE_IN_APP_GUIDE:
      return {
        ...state,
        loggedUser: {
          ...state.loggedUser,
          userInfo: {
            ...state.loggedUser.userInfo,
            showAppGuide: 0,
          },
        },
      };
    case constants.MARK_NOTIFICATIONS_AS_READ:
      return {
        ...state,
        loggedUser: {
          ...state.loggedUser,
          userInfo: {
            ...state.loggedUser.userInfo,
            alerts: state.loggedUser?.userInfo?.alerts?.map((alert) => ({
              ...alert,
              isRead: 1,
            })),
          },
        },
      };
    default:
      return state;
  }
};

export default signIn;
