import {
  Box,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import { Auth } from "aws-amplify";
import axios from "axios";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  default as logo,
  default as logoDark,
} from "../../assets/images/logo-dark.png";
import logoWhite from "../../assets/images/logo.png";
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import { getPopularData, getViewedData } from "../../redux/actions";
import { stripeBillingRedirect } from "../../redux/api/stripeApi";
import { getRecentlyViewedTickerData } from "../../redux/api/watchlistApis";
import {
  getAboutStatus,
  getFeaturesStatus,
  getPlansStatus,
  getSubscriptionStatus,
  signInClearAction,
  stockTabStatus,
} from "../../redux/auth/action";
import { LoaderAction } from "../../redux/loader/action";
import IndexWidget from "../../shared/IndexWidget";
import { setDeepLinkUrl } from "../../utils/authentication";
import CustomButton from "../Common/CustomButton";
import SearchTicker from "../Common/SearchTicker";
import UserNotifications from "../Notifications/UserNotifications";
import MoreDropdownMobile from "./MoreDropdownMobile";

export const useStyles = makeStyles((theme) => ({
  headerWrap: {
    flexGrow: 1,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1024,
    backgroundColor: "#fff",
    boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
    borderBottom: "1px solid #E1E3EA",
    "& button:not(.notification-button)": {
      "& svg": {
        fontSize: 26,
        height: 20,
      },
      [theme.breakpoints.down("960")]: {
        padding: 10,
      },
    },
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },
  },
  sticky: {
    backgroundColor: "#fff",
    boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
  },
  active: {
    color: theme.palette.primary.main,
    "& .MuiListItemText-root": {
      color: theme.palette.primary.main,
    },
  },
  loggedIn: {
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      padding: "0",
      borderBottom: "1px solid #e4e4e4",
      [theme.breakpoints.down("sm")]: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      },
    },
    "& .MuiToolbar-root": {
      minHeight: "auto",
      "& > a": {
        padding: 15,
        [theme.breakpoints.up("md")]: {
          height: 80,
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
          maxWidth: 230,
          flex: "0 0 230px",
          display: "flex",
          alignItems: "center",
        },
      },
      "& > div > span": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
  },
  logo: {
    textDecoration: "none",
    color: "#fff",
    fontSize: 24,
    fontWeight: "bold",
    flexGrow: 1,
    maxWidth: 160,
    "& img": {
      verticalAlign: "middle",
      [theme.breakpoints.down("sm")]: {
        maxWidth: 130,
        display: "block",
      },
    },
  },
  headerChange: {
    padding: [[0, 15]],
    textAlign: "end",
    cursor: "pointer",
    width: "max-content",
    "& button": {
      marginRight: "10px",
    },
    "& > span": {
      color: "#000",
      fontSize: 16,
      cursor: "pointer",
      fontWeight: "700",
      textTransform: "uppercase",
    },
  },
  // bottomHeader: {
  //   backgroundColor: '#eee'
  // },
  headerNav: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    margin: [[0, -20]],
    [theme.breakpoints.down("767")]: {
      display: "none",
    },
    "& > .MuiListItem-root": {
      padding: [[5, 0]],
      minWidth: "auto",
      width: "auto",
      "& .MuiButton-label": {
        fontWeight: 400,
      },
      "& ul": {
        "& li": {
          fontWeight: 400,
        },
      },
      "&:hover": {
        "& > ul": {
          opacity: 1,
          visibility: "visible",
        },
      },
    },
    "& > p": {
      textAlign: "end",
      width: "max-content",
      marginLeft: 10,
    },
    "& > span": {
      color: "#000",
      fontSize: 16,
      cursor: "pointer",
      fontWeight: "700",
      padding: [[0, 15]],
      textTransform: "uppercase",
    },
    "& p": {
      fontSize: 14,
      [theme.breakpoints.down("1200")]: {
        maxWidth: 436,
      },
      [theme.breakpoints.down("1020")]: {
        maxWidth: 238,
      },
    },
    "& button": {
      [theme.breakpoints.down("1199")]: {
        padding: 10,
      },
    },
  },
  headerBtn: {
    display: "flex",
    alignItems: "center",
    marginLeft: 10,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("767")]: {
      display: "none",
    },
    "& > .MuiListItem-root": {
      padding: [[14, 0]],
      minWidth: "auto",
      width: "auto",
      "&:last-child": {
        marginLeft: 16,
        [theme.breakpoints.down("1199")]: {
          marginLeft: 10,
        },
      },
    },
  },
  stockSubMenu: {
    minWidth: "35rem",
    width: "100%",
    position: "absolute",
    left: 0,
    top: "100%",
    backgroundColor: "#fff",
    boxShadow: "0 0 5px rgba(0,0,0,.2)",
    display: "flex",
    flexWrap: "wrap",
    opacity: 0,
    visibility: "hidden",
    padding: 0,
    margin: 0,
    transition: "all 0.3s ease-in-out",
    "& li": {
      listStyle: "none",
      whiteSpace: "normal",
    },
    "& > li": {
      width: "50%",
      fontWeight: "bold",
      padding: "12px 20px",
    },
  },
  stockMenuDarkTheme: {
    backgroundColor: "#1a1a1a",
    color: "#fff",
    boxShadow: "0px 0px 5px rgba(255,255,255,0.20)",
    "& .MuiListItem-button": {
      "&:hover": {
        background: "rgba(255,255,255,0.10)",
      },
    },
  },
  headerMenuDarkTheme: {
    "&.MuiButtonBase-root": {
      "&:hover": {
        "& .MuiButton-label": {
          color: "#000",
        },
      },
    },
  },
  rightMenu: {
    right: 0,
    left: "auto",
  },
  moreMenu: {
    minWidth: "15rem",
    "& li": {
      width: "100%",
    },
  },
  menuHover: {
    opacity: 1,
    visibility: "visible",
  },
  headerBar: {
    paddingLeft: 15,
    width: "100%",
    maxWidth: "725px",
    [theme.breakpoints.up("767")]: {
      display: "none",
    },
    [theme.breakpoints.down("1440")]: {
      maxWidth: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 15,
    },
    "& h6": {
      fontWeight: "bold",
    },
  },
  searchWidth: {
    maxWidth: "unset !important",
  },
  accountInfo: {
    textDecoration: "none",
    color: "black",
    "& h6": {
      display: "flex",
      alignItems: "center",
      "& span": {
        verticalAlign: "middle",
      },
      "& svg": {
        verticalAlign: "middle",
      },
    },
  },
  toggleBtn: {
    [theme.breakpoints.up("767")]: {
      display: "none",
    },
  },
  headerTool: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      minHeight: 64,
    },
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  sidebarBg: {
    paddingTop: 0,
  },
  sidebarLogo: {
    padding: "10px 15px",
    [theme.breakpoints.down("md")]: {
      padding: [[6, 10]],
      maxWidth: 150,
    },
  },
  deviderBg: {
    backgroundColor: "#f2f4f53d",
  },
  sidebarMain: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 0,
    "& svg": {
      fontSize: 32,
    },
  },
  menuBtn: {
    justifyContent: "center",
  },
  logoSidebar: {
    width: 160,
  },
  menu: {
    "& .MuiPopover-paper": {
      boxShadow: "0 0 10px rgba(0,0,0,0.1)",
      marginTop: 45,
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiListItem-root.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
    "& .MuiFormControlLabel-root": {
      marginBottom: 0,
    },
    "& li": {
      fontWeight: 500,
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.05) !important",
      },
    },
  },
  searchCustomWidth: {
    position: "relative",
    [theme.breakpoints.between("960", "1024")]: {
      maxWidth: "100%  !important",
      flexBasis: "100%  !important",
    },
    [theme.breakpoints.up("1024")]: {
      maxWidth: "50%",
      flexBasis: "50%",
    },
  },
  headerSearch: {
    "& .MuiFormControl-root": {
      [theme.breakpoints.down("sm")]: {
        opacity: 0,
        visibility: "hidden",
        transition: "all 0.6s ease-in-out",
      },
    },
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      right: 15,
      maxWidth: "100%",
      backgroundColor: "#fff",
      top: 5,
      width: 40,
      transition: "all 0.5s ease-in-out",
    },
  },
  headerMobileSearch: {
    display: "none",
    position: "absolute",
    right: 40,
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 9,
    [theme.breakpoints.down("767")]: {
      margin: [[0, 25, 0, "auto"]],
      display: "block",
    },
  },
  headerMobileSearchHide: {
    display: "none",
  },
  headerAccount: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    marginLeft: 10,
    justifyContent: "flex-end",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& p": {
      width: "max-content",
      [theme.breakpoints.down("1440")]: {
        display: "none",
      },
      "& button": {
        marginLeft: 10,
      },
    },
  },
  headerSearchDropdown: {
    maxWidth: "100%",
    flexBasis: "100%",
  },
  moreMenuDropdown: {
    padding: [[6, 0]],
    minWidth: "54rem",
    "& .MuiGrid-item": {
      borderRight: "1px solid #e2e5ea",
      "&:last-child": {
        borderRight: "none",
      },
      "& li": {
        paddingLeft: 0,
        "&:hover": {
          backgroundColor: "transparent",
          color: theme.palette.primary.main,
        },
      },
    },
  },
  moreMenuDropdownDarkTheme: {
    "& .MuiGrid-item": {
      borderRight: "1px solid #C4C4C4",
      "&:last-child": {
        borderRight: "none",
      },
      "& li": {
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  trandingWidget: {
    marginLeft: 230,
    marginTop: 80,
    backgroundColor: "#fff",
    padding: 20,
    "& > div": {
      overflowX: "auto",
      "& #tradingview_widget_wrapper": {
        minWidth: 1100,
        "&> div:nth-child(2)": {
          display: "none",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 56,
    },
  },
  bannerSearch: {
    margin: [[0, "auto", 0, 45]],
    maxWidth: 400,
    width: "100%",
    borderRadius: 25,

    [theme.breakpoints.down("767")]: {
      display: "none",
      maxWidth: "calc(100% - 50px)",
      left: 0,
      right: "auto",
    },
    [theme.breakpoints.down("480")]: {
      top: 9,
      margin: 0,
    },
    "& .MuiFormControl-root": {
      "& .MuiInputBase-root:not(.Mui-focused)": {
        maxWidth: "100%",
      },
      "& .MuiInputBase-input": {
        borderRadius: 50,
        height: "22px !important",
        paddingLeft: "45px !important",
        backgroundColor: "#EEEEEE",
        [theme.breakpoints.down("sm")]: {
          height: "26px !important",
          paddingRight: "45px",
          paddingLeft: "45px !important",
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: 0,
      },
    },
    "& label": {
      width: 36,
      height: 36,
      top: "50%",
      transform: "translateY(-50%)",
      lineHeight: "45px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      left: 9,
      "& svg": {
        width: 16,
        height: 20,
        "& path": {
          fill: "#898989",
        },
      },
    },
  },
  headerBannerSearchWrap: {
    position: "relative",
  },
  showHeaderSarchVisible: {
    display: "block",
    "& .MuiOutlinedInput-notchedOutline": {
      [theme.breakpoints.down("480")]: {
        opacity: "1 !important",
      },
    },
  },
  searchClose: {
    display: "none",
    position: "absolute",
    right: 45,
    top: 20,
    zIndex: 9,
    [theme.breakpoints.down("480")]: {
      right: 62,
    },
  },
  closeVisible: {
    display: "block",
  },
  headerSearch: {
    marginLeft: "auto",
    [theme.breakpoints.up("768")]: {
      display: "none",
    },
    [theme.breakpoints.down("480")]: {
      position: "absolute",
      right: 10,
      top: 7,
      left: 0,
      zIndex: 9,
      backgroundColor: "#fff",
      width: 40,
    },
  },
  stockMenubar: {
    left: "46%",
    top: "10%",
  },
  economyMenu: {
    minWidth: "21rem",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedMenuHeading: {
    fontWeight: 500,
    fontFamily: "Roboto",
    fontSize: 17,
    paddingBottom: 8,
  },
  darkThemeHeader: {
    backgroundColor: "#000",
    borderBottom: 0,
    boxShadow: "0 0 5px rgba(255, 255, 255, 0.3)",
  },
  headerDarkThemeBtn: {
    "& > .MuiListItem-root": {
      padding: [[14, 0]],
      minWidth: "auto",
      width: "auto",
    },
  },
  signUpButtonDarkTheme: {
    "&.MuiButtonBase-root": {},
  },
  headerSearchIconDarkTheme: {
    "& path": {
      fill: theme.palette.background.default,
    },
  },
  toggleIconDarkTheme: {
    "& svg": {
      color: theme.palette.background.default,
    },
  },
  responsiveMenubarDarktheme: {
    position: "static",
    "& .MuiPaper-root": {
      background: "#1a1a1a",
      "& .MuiList-root": {
        "& .MuiListItem-root": {
          color: theme.palette.background.default,
          "&:first-child": {
            "& svg": {
              color: theme.palette.background.default,
            },
          },
          "&:last-child": {
            "& .MuiButtonBase-root": {
              background: theme.palette.success.main,
            },
          },
        },
      },
    },
  },
  userNotificationsMobile: {
    marginLeft: -8,
    marginRight: 4,
    [theme.breakpoints.down("479.95")]: {
      marginRight: 50,
      marginLeft: 0,
      width: 40,
      "& svg": {
        color: "#000",
        height: 24,
      },
    },
  },
}));
const showSearchBar = [
  "/trends",
  "/multichart",
  "/institution",
  "/economy",
  "/politics",
  "/politician",
  "/analyst",
  "/insider",
  "/news",
  "/tweets",
  "/stocks",
  "/earnings",
  "/quote",
  "/plans",
  "/about",
  "/forecast",
  "/calendar",
  "/toppicks",
  "/chat",
  "/topetfs",
  "/screener",
  "/heatmap",
];

export const newsItem = [
  {
    name: "Trending News",
    newsFilter: { filterValue: "Trending", sector: false },
    url: "/news/trending",
  },
  {
    name: "Manufacturing Sector News",
    newsFilter: { filterValue: "MANUFACTURING", sector: true },
    url: "/news/manufacturing-sector-news",
  },
  {
    name: "Recent Earnings",
    newsFilter: { filterValue: "RecentEarnings", sector: false },
    url: "/news/RecentEarnings",
  },
  {
    name: "Real Estate Sector News",
    newsFilter: { filterValue: "REAL ESTATE & CONSTRUCTION", sector: true },
    url: "/news/real-estate-sector-news",
  },
  {
    name: "Technology Sector News",
    newsFilter: { filterValue: "TECHNOLOGY", sector: true },
    url: "/news/technology-sector-news",
  },
  {
    name: "Trade & Services Sector News",
    newsFilter: { filterValue: "TRADE & SERVICES", sector: true },
    url: "/news/trade-services-sector-news",
  },
  {
    name: "Finance Sector News",
    newsFilter: { filterValue: "FINANCE", sector: true },
    url: "/news/finance-sector-news",
  },
  {
    name: "Portfolio News",
  },
  {
    name: "Life Sciences Sector News",
    newsFilter: { filterValue: "LIFE SCIENCES", sector: true },
    url: "/news/life-sciences-sector-news",
  },
  {
    name: "Watchlist News",
  },
  {
    name: "Energy Sector News",
    newsFilter: { filterValue: "ENERGY & TRANSPORTATION", sector: true },
    url: "/news/energy-sector-news",
  },
];

export const tweetsItem = [
  {
    newsFilter: { filterValue: "Trending", sector: false },
    name: "Trending Tweets",
    url: "/tweets/trending",
  },
  {
    newsFilter: { filterValue: "ENERGY & TRANSPORTATION", sector: true },
    name: "Energy Sector Tweets",
    url: "/tweets/energy-sector-tweets",
  },
  {
    newsFilter: { filterValue: "Recent", sector: false },
    name: "Latest Tweets",
    url: "/tweets/latest",
  },
  {
    newsFilter: { filterValue: "MANUFACTURING", sector: true },
    name: "Manufacturing Sector Tweets",
    url: "/tweets/manufacturing-sector-tweets",
  },
  {
    newsFilter: { filterValue: "RecentEarnings", sector: false },
    name: "Recent Earnings",
    url: "/tweets/RecentEarnings",
  },
  {
    newsFilter: { filterValue: "REAL ESTATE & CONSTRUCTION", sector: true },
    name: "Real Estate Sector Tweets",
    url: "/tweets/real-estate-sector-tweets",
  },
  {
    newsFilter: { filterValue: "TECHNOLOGY", sector: true },
    name: "Technology Sector Tweets",
    url: "/tweets/technology-sector-tweets",
  },
  {
    newsFilter: { filterValue: "TRADE & SERVICES", sector: true },
    name: "Trade & Services Sector Tweets",
    url: "/tweets/trade-services-sector-tweets",
  },
  {
    newsFilter: { filterValue: "FINANCE", sector: true },
    name: "Finance Sector Tweets",
    url: "/tweets/finance-sector-tweets",
  },
  {
    name: "Portfolio Tweets",
  },
  {
    newsFilter: { filterValue: "LIFE SCIENCES", sector: true },
    name: "Life Sciences Sector Tweets",
    url: "/tweets/life-sciences-sector-tweets",
  },
  {
    name: "Watchlist Tweets",
  },
];

const Header = () => {
  const isLog = useSelector((state) => state.signIn.loggedUser);
  const isLanding = isLog?.userInfo?.email ? false : true;
  const { index, popular, recentlyViewedData } = useSelector(
    (state) => state.watchListReducer
  );
  const classes = useStyles();
  let history = useHistory();
  const location = useLocation();
  const isAiTopPicksPage = location.pathname === "/toppicks";
  const isDarkMode = isAiTopPicksPage || location.pathname === "/about";
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.signIn.loggedUser);
  const [displayOnBoarding, setDisplayOnBoarding] = React.useState(false);
  const [newClass, setNewClass] = React.useState("");
  const [name, setName] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElMore, setAnchorElMore] = React.useState(null);
  const [showHeader, setShowHeader] = React.useState(true);
  const [state, setState] = React.useState({
    top: false,
  });
  const [searchVisible, setSearchVisible] = React.useState(false);
  const theme = useTheme();
  const paths = ["/home"];
  const isSmallScreen = useMediaQuery("(max-width: 959.95px)");
  const isMobileScreen = useMediaQuery("(max-width: 767px)");

  const googleAddPath = [
    "/plans",
    "/sign-in",
    "/sign-up",
    "/about",
    "/term-and-condition",
    "/privacy-policy",
    "/contact",
    "/advertise",
  ];
  const pathname = window.location.pathname;
  const hideAlertSettings = isLog?.userInfo?.showAlert === 0;

  useEffect(() => {
    getPopularListData();
    getRecentlyViewedData();
    const header = document.querySelector("header");

    document.addEventListener("scroll", () => {
      var scroll_position = window.scrollY;
      if (scroll_position > 20) {
        header.classList.add(classes.sticky);
      } else {
        header.classList.remove(classes.sticky);
      }
    });
  }, []);

  React.useEffect(() => {
    const routes = [
      "/",
      "/about",
      "/plans",
      "/top-stock",
      "/trends",
      "/multichart",
      "/institution",
      "/economy",
      "/politics",
      "/politician",
      "/investor",
      "/analyst",
      "/news",
      "/tweets",
      "/insider",
      "/stocks",
      "/earnings",
      "/term-and-condition",
      "/disclosure",
      "/privacy-policy",
      "/contact",
      "/advertise",
      "/quote",
      "/forecast",
      "/calendar",
      "/toppicks",
      "/chat",
      "/topetfs",
      "/screener",
      "/heatmap",
    ];
    if (
      window.location.pathname === "/term-and-condition" ||
      window.location.pathname === "/privacy-policy" ||
      window.location.pathname === "/billing-faq"
    ) {
      setNewClass(classes.headerDark);
    } else {
      setDisplayOnBoarding(false);
    }
    // if (routes.includes(window.location.pathname)) {
    if (routes.includes(currentTab)) {
      setDisplayOnBoarding(true);
    } else {
      setDisplayOnBoarding(false);
    }

    setName(isLogin?.userInfo?.name);
  }, [isLogin.idToken, window.location.pathname]);

  // const { id } = useParams();
  const segments = pathname.split("/");
  let currentTab;
  if (segments.length >= 2) {
    currentTab = `/${segments[1]}`;
  }
  const subscriptionStatus =
    isLog && isLog.userInfo && isLog.userInfo.subscriptionStatus;
  const trialEndsIn = isLog && isLog.userInfo && isLog.userInfo.trialEndsIn;
  const isTrialAvailable =
    isLog && isLog.userInfo && isLog.userInfo.isTrialAvailable;
  const plan = isLog?.userInfo?.plan;

  const setbannerSarchVisible = () => {
    setSearchVisible(true);
  };

  let max = 100;

  const getPopularListData = async () => {
    const gitHubUrl = "https://public.fintwit.us/quote/mention";

    dispatch(LoaderAction(true));
    const response = await axios.get(gitHubUrl);
    if (response.status === 200) {
      dispatch(getPopularData(response.data));
      dispatch(LoaderAction(false));
    } else {
      dispatch(LoaderAction(false));
    }
  };
  const getRecentlyViewedData = async () => {
    if (!isLanding) {
      dispatch(LoaderAction(true));
      const response = await getRecentlyViewedTickerData(isLogin.idToken, {
        method: "recentlyviewed",
      });
      if (response.status === 200) {
        dispatch(getViewedData(response.data));
        // dispatch(getPopularData(response.data));
        dispatch(LoaderAction(false));
      } else {
        dispatch(LoaderAction(false));
      }
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const setAboutStatusPageInMobile = () => {
    dispatch(getAboutStatus(Math.floor(Math.random() * max) + 1, "mobile"));
    pageRedirect("/");
  };
  const setFeaturesStatusPageInMobile = () => {
    dispatch(getFeaturesStatus(Math.floor(Math.random() * max) + 1, "mobile"));
    pageRedirect("/");
  };
  const setPlansStatusPageInMobile = () => {
    dispatch(getPlansStatus(Math.floor(Math.random() * max) + 1, "mobile"));
    pageRedirect("/");
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, { [classes.fullList]: anchor === "top" })}
      role="presentation"
    >
      <List className={classes.sidebarBg}>
        <ListItem className={classes.sidebarMain} button>
          <img
            className={classes.sidebarLogo}
            src={
              location.pathname === "/"
                ? logo
                : isDarkMode
                ? logoWhite
                : logoDark
            }
            alt="logo"
          />
          <CloseIcon onClick={toggleDrawer(anchor, false)} />
        </ListItem>
        <ListItem
          button
          onClick={() => pageRedirect("/stocks/top-gainers")}
          className={
            location.pathname === "/stocks/top-gainers" ? classes.active : ""
          }
        >
          <ListItemText primary={"Stocks"} />
        </ListItem>
        <ListItem
          button
          onClick={() => pageRedirect("/toppicks")}
          className={location.pathname === "/toppicks" ? classes.active : ""}
        >
          <ListItemText primary="AI Top Picks" />
        </ListItem>
        <ListItem
          button
          onClick={() => pageRedirect("/chat")}
          className={location.pathname === "/chat" ? classes.active : ""}
        >
          <ListItemText primary="AI Chat" />
        </ListItem>
        <ListItem
          button
          onClick={() => pageRedirect("/screener")}
          className={location.pathname === "/screener" ? classes.active : ""}
        >
          <ListItemText primary="Screener" />
        </ListItem>
        <ListItem
          button
          onClick={() => pageRedirect("/news/trending")}
          className={
            location.pathname === "/news/trending" ? classes.active : ""
          }
        >
          <ListItemText primary={"News"} />
        </ListItem>
        <MoreDropdownMobile>
          <ListItem
            button
            onClick={() => pageRedirect("/multichart")}
            className={
              location.pathname === "/multichart" ? classes.active : ""
            }
          >
            <ListItemText primary={"Multichart"} />
          </ListItem>
          <ListItem
            button
            onClick={() => pageRedirect("/heatmap")}
            className={location.pathname === "/heatmap" ? classes.active : ""}
          >
            <ListItemText primary={"Heatmap"} />
          </ListItem>
          {/* <ListItem
          button
          onClick={() => pageRedirect("/tweets/trending")}
          className={
            location.pathname === "/tweets/trending" ? classes.active : ""
          }
        >
          <ListItemText primary={"Tweets"} />
        </ListItem> */}
          <ListItem
            button
            onClick={() => pageRedirect("/calendar/earnings-calendar")}
            className={
              location.pathname === "/calendar/earnings-calendar"
                ? classes.active
                : ""
            }
          >
            <ListItemText primary={"Calendar"} />
          </ListItem>
          <ListItem
            button
            onClick={() => pageRedirect("/insider")}
            className={location.pathname === "/insider" ? classes.active : ""}
          >
            <ListItemText primary={"Insider"} />
          </ListItem>
          <ListItem
            button
            onClick={() => pageRedirect("/forecast/most-recommended-stocks")}
            className={
              location.pathname === "/forecast/most-recommended-stocks"
                ? classes.active
                : ""
            }
          >
            <ListItemText primary={"Forecast"} />
          </ListItem>

          <ListItem
            button
            onClick={() => pageRedirect("/politics/overview")}
            className={
              location.pathname === "/politics/overview" ? classes.active : ""
            }
          >
            <ListItemText primary={"Politics"} />
          </ListItem>
          <ListItem
            button
            onClick={() => pageRedirect("/institution/top-gurus")}
            className={
              location.pathname === "/institution/top-gurus"
                ? classes.active
                : ""
            }
          >
            <ListItemText primary={"Institution"} />
          </ListItem>
          <ListItem
            button
            onClick={() => pageRedirect("/economy/sp500")}
            className={
              location.pathname === "/economy/sp500" ? classes.active : ""
            }
          >
            <ListItemText primary={"Economy"} />
          </ListItem>

          <ListItem
            button
            onClick={() =>
              window.open("https://fintwit-ai.getrewardful.com/signup")
            }
          >
            <ListItemText primary="Affiliate" />
          </ListItem>
          <ListItem
            button
            onClick={() =>
              window.open("https://fintwit.freshdesk.com/support/solutions")
            }
          >
            <ListItemText primary="Help Center" />
          </ListItem>
        </MoreDropdownMobile>

        <ListItem
          button
          onClick={() => pageRedirect("/plans")}
          className={location.pathname === "/plans" ? classes.active : ""}
        >
          <ListItemText primary={"Plans"} />
        </ListItem>
        <ListItem
          button
          onClick={() => pageRedirect("/about")}
          className={location.pathname === "/about" ? classes.active : ""}
        >
          <ListItemText
            style={{
              color:
                location.pathname === "/about"
                  ? undefined
                  : isDarkMode
                  ? "#fff"
                  : "#000",
            }}
            primary={"About"}
          />
        </ListItem>

        <ListItem button onClick={() => pageRedirect("/sign-in")}>
          <ListItemText
            style={{ color: isDarkMode ? "#fff" : "#000" }}
            primary={"Sign in"}
          />
        </ListItem>

        <ListItem button className={classes.menuBtn}>
          <CustomButton
            radius="5"
            btnPadding="10px 20px"
            variant="contained"
            onClick={() => pageRedirect("/sign-up")}
          >
            Sign Up
          </CustomButton>
        </ListItem>
      </List>
    </div>
  );

  const pageRedirect = (path) => {
    if (window.location.pathname === path) {
      setState({ ...state, ["top"]: false });
    } else {
      history.push(path);
      setDisplayOnBoarding(false);
      setState({ ...state, ["top"]: false });
    }
  };

  const signOutUser = async () => {
    try {
      history.push("/");
      setDeepLinkUrl(null);
      dispatch(signInClearAction());
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const moveTo = (url) => {
    setAnchorEl(null);
    history.push(url);
  };

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const subscriptionStatusResult = () => {
    dispatch(getSubscriptionStatus(true));
    history.push("/subscription");
  };
  const open = Boolean(anchorEl);
  const openMore = Boolean(anchorElMore);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMoreClick = (event) => {
    setAnchorElMore(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMoreClose = () => {
    setAnchorElMore(null);
  };
  const stockItem = [
    // {
    //   redirectUrl: "/stocks/fintwit-most-bought",
    //   name: "Fintwit Most Bought",
    // },
    // { redirectUrl: "/stocks/52-week-low", name: "52 Week Low" },

    // {
    //   redirectUrl: "/stocks/fintwit-most-sold",
    //   name: "Fintwit Most Sold",
    // },
    // { redirectUrl: "/stocks/most-volatile", name: "Most Volatile" },

    // {
    //   redirectUrl: "/stocks/fintwit-common-holding",
    //   name: "Fintwit Common Holding",
    // },
    { redirectUrl: "/stocks/top-gainers", name: "Top Gainers" },
    { redirectUrl: "/stocks/over-bought", name: "Over Bought" },
    { redirectUrl: "/stocks/top-losers", name: "Top Losers" },
    {
      redirectUrl: "/stocks/above-target-price",
      name: "Above Target Price",
    },
    { redirectUrl: "/stocks/most-active", name: "Most Active" },
    {
      redirectUrl: "/stocks/below-target-price",
      name: "Below Target Price",
    },
    { redirectUrl: "/stocks/unusual-volume", name: "Unusual Volume" },
    { redirectUrl: "/stocks/earnings-before", name: "Earnings Before" },
    { redirectUrl: "/stocks/most-volatile", name: "Most Volatile" },

    { redirectUrl: "/stocks/earnings-after", name: "Earnings After" },
    { redirectUrl: "/stocks/insider-buying", name: "Insider Buying" },
    {
      redirectUrl: "/stocks/top-performers-(1y)",
      name: "Top Performers (1Y)",
    },
    { redirectUrl: "/stocks/insider-selling", name: "Insider Selling" },
    {
      redirectUrl: "/stocks/low-performers-(1y)",
      name: "Low Performers (1Y)",
    },
    { redirectUrl: "/stocks/52-week-high", name: "52 Week High" },
    /*  {
      redirectUrl: "/stocks/highest-net-income",
      name: "Highest Net Income",
    }, */
    // { redirectUrl: "/stocks/highest-revenue", name: "Highest Revenue" },

    { redirectUrl: "/stocks/high-dividend", name: "High Dividend" },
    { redirectUrl: "/stocks/52-week-low", name: "52 Week Low" },
    { redirectUrl: "/stocks/largest-employer", name: "Largest Employer" },
    { redirectUrl: "/stocks/over-sold", name: "Over Sold" },
    { redirectUrl: "/stocks/highest-market-cap", name: "Largest Market Cap" },
  ];

  const institutionItem = [
    { redirectUrl: "/institution/top-gurus", name: "Top Gurus" },
    {
      redirectUrl: "/institution/new-position",
      name: "New Position",
    },
    {
      redirectUrl: "/institution/most-common-holding",
      name: "Most Common Holding",
    },
    {
      redirectUrl: "/institution/increase-position",
      name: "Increase Position",
    },
    { redirectUrl: "/institution/ownership", name: "Ownership" },
    { redirectUrl: "/institution/sold-out", name: "Sold Out" },
    {
      redirectUrl: "/institution/reduce-position",
      name: "Decrease Position",
    },
  ];

  const moreItem = [
    { redirectUrl: "/plans", name: "Plans" },
    // { redirectUrl: "/about", name: "About" },
  ];
  const calendarItem = [
    {
      redirectUrl: "/calendar/earnings-calendar",
      name: "Earnings Calendar",
    },
    {
      redirectUrl: "/calendar/dividend-calendar",
      name: "Dividend Calendar",
    },
  ];
  const insiderItem = [
    {
      filterValue: "purchase",
      name: "Latest Purchase",
    },
    {
      filterValue: "sale",
      name: "Latest Sale",
    },
    {
      // redirectUrl: "/calendar?tab=dividend-calendar",
      name: "CEO Trades",
    },
  ];
  const analystItem = [
    {
      redirectUrl: "/forecast/most-recommended-stocks",
      name: "Most Recommended Stocks",
    },
    {
      redirectUrl: "/forecast/upgrade-downgrade",
      name: "Latest Upgrade/Downgrade",
    },
  ];
  const trendsItem = [
    {
      scroll: false,
      name: "Top Trending Tickers",
    },
    {
      scroll: true,
      name: "Mega Cap Trending Tickers",
    },
    {
      scroll: true,
      name: "Large Cap Trending Tickers",
    },
    {
      scroll: true,
      name: "Mid Cap Trending Tickers",
    },
    {
      scroll: true,
      name: "Small Cap Trending Tickers",
    },
  ];
  const politicsItem = [
    {
      scroll: false,
      name: "Overview",
      redirectUrl: "/politics/overview",
    },
    {
      scroll: true,
      name: "Politician",
      redirectUrl: "/politics/politician",
    },
    {
      scroll: true,
      name: "Recent Trades",
      redirectUrl: "/politics/recent-trades",
    },
    {
      scroll: true,
      name: "Most Bought",
      redirectUrl: "/politics/most-bought",
    },
    {
      scroll: true,
      name: "Most Sold",
      redirectUrl: "/politics/most-sold",
    },
  ];

  const economyItem = [
    {
      scroll: "sp500",
      name: "S&P500",
      redirectUrl: "/economy/sp500",
    },
    {
      scroll: "treasury",
      name: "Treasury Yield Curve Rates",
      redirectUrl: "/economy/treasury",
    },
    {
      scroll: "",
      name: "30 Year Fixed Average Mortgage Rate",
      redirectUrl: "/economy/30YearFixedRateMortgageAverage",
    },
    {
      scroll: "federalFund",
      name: "Federal Fund Rate",
      redirectUrl: "/economy/federalFundRate",
    },
    {
      scroll: "inflationRate",
      name: "Inflation Rate",
      redirectUrl: "/economy/inflationRate",
    },
    {
      scroll: "cpi",
      name: "CPI",
      redirectUrl: "/economy/cpi",
    },
    {
      scroll: "unemploymentRate",
      name: "Unemployment Rate",
      redirectUrl: "/economy/unemploymentRate",
    },
    {
      scroll: "realGDP",
      name: "Real Gross Domestic Product",
      redirectUrl: "/economy/realGDP",
    },
    {
      scroll: "realGDPPerCapita",
      name: "Real Gross Domestic Product per Capita",
      redirectUrl: "/economy/realGDPPerCapita",
    },
  ];
  const companyItems = [
    {
      name: "About",
      redirectUrl: "/about",
    },
    {
      name: "Plans",
      redirectUrl: "/plans",
    },
    {
      name: "Help Center",
      redirectUrl: "https://fintwit.freshdesk.com/support/solutions",
      isExternalLink: true,
    },
    {
      name: "Affiliate",
      redirectUrl: "https://fintwit-ai.getrewardful.com/signup",
      isExternalLink: true,
    },
    {
      name: "Advertise",
      redirectUrl: "/advertise",
    },
    {
      name: "Contact",
      redirectUrl: "/contact",
    },
  ];

  const handleBilling = async () => {
    dispatch(LoaderAction(true));
    const response = await stripeBillingRedirect(isLog?.idToken, {
      customerId: isLog?.userInfo?.stripeCustomerId,
    });
    if (response.status === 201) {
      window.location.href = response?.data?.data?.url;
      dispatch(LoaderAction(false));
    } else {
      dispatch(LoaderAction(false));
    }
  };

  return (
    <>
      <header
        className={`${classes.headerWrap} ${
          !isLogin.idToken &&
          window.location.pathname !== "/" &&
          window.location.pathname !== "/about"
            ? classes.notLogged
            : ""
        } ${newClass}  ${isLogin.idToken ? classes.loggedIn : ""} ${
          isDarkMode ? classes.darkThemeHeader : ""
        }`}
      >
        <Container
          maxWidth={isLogin.idToken ? false : "lg"}
          disableGutters={isLogin.idToken ? true : false}
          style={{ maxWidth: isLogin.idToken ? undefined : "1440px" }}
        >
          <AppBar
            position={isLogin.idToken ? "fixed" : "static"}
            color="inherit"
            elevation={0}
            className="top-header"
          >
            <Toolbar className={classes.headerTool} disableGutters>
              <Link
                to={isLogin.idToken ? "/home" : "/"}
                variant="h6"
                className={classes.logo}
              >
                {isDarkMode && isLanding ? (
                  <img
                    className={classes.logoSidebar}
                    src={logoWhite}
                    alt="logo"
                  />
                ) : (
                  <img className={classes.logoSidebar} src={logo} alt="logo" />
                )}
              </Link>
              <SearchTicker
                dropdownClass={classes.bannerSearchDropdown}
                extraClass={`${classes.bannerSearch} ${
                  !isLanding ? classes.dashboardSearch : ""
                }`}
                isLanding={isLanding}
                isClickNeeded={true}
                id={isMobileScreen ? "" : "search-bar"}
              />
              {/*  {isLanding && pathname === "/" && (
                <Hidden mdUp>
                  <SearchTicker
                    dropdownClass={classes.bannerSearchDropdown}
                    isLanding
                    isClickNeeded={true}
                  />
                </Hidden>
              )} */}
              {displayOnBoarding &&
                !isLogin.accessToken &&
                showSearchBar.includes(currentTab) && (
                  <>
                    <SearchTicker
                      dropdownClass={classes.headerSearchDropdown}
                      extraClass={`${classes.headerSearch} ${
                        classes.bannerSearch
                      } ${searchVisible ? classes.showHeaderSarchVisible : ""}`}
                      isLanding={isLanding}
                      isClickNeeded={true}
                    />
                    <SearchIcon
                      className={`${classes.headerMobileSearch} ${
                        searchVisible ? classes.headerMobileSearchHide : ""
                      } ${isDarkMode ? classes.headerSearchIconDarkTheme : ""}`}
                      onClick={setbannerSarchVisible}
                    />
                    <div
                      className={`${
                        searchVisible ? classes.closeVisible : ""
                      } ${classes.searchClose}`}
                      onClick={() => setSearchVisible(false)}
                    >
                      <CloseIcon />
                    </div>
                  </>
                )}
              {displayOnBoarding && !isLogin.accessToken && (
                <div
                  className={`${classes.headerBtn} ${
                    isDarkMode ? classes.headerDarkThemeBtn : ""
                  }`}
                >
                  {moreItem?.map((v, i) => (
                    <ListItem>
                      <CustomButton
                        bgColor="transparent"
                        bgHoverColor="transparent"
                        radius="5"
                        // fontColor="#07846f"
                        fontColor={isDarkMode ? "#fff" : "#000"}
                        btnPadding="10px 20px"
                        variant="contained"
                        onClick={() => {
                          dispatch(stockTabStatus(v?.redirectUrl));
                          history.push(v?.redirectUrl);
                          handleClose();
                        }}
                      >
                        {v?.name}
                      </CustomButton>
                    </ListItem>
                  ))}
                  <ListItem>
                    <CustomButton
                      bgColor="transparent"
                      bgHoverColor="transparent"
                      radius="5"
                      // fontColor="#07846f"
                      fontColor={isDarkMode ? "#fff" : "#000"}
                      btnPadding="10px 20px"
                      variant="contained"
                      onClick={() => pageRedirect("/sign-in")}
                    >
                      Sign In
                    </CustomButton>
                  </ListItem>
                  <ListItem>
                    <CustomButton
                      radius="30"
                      fontColor={isDarkMode ? "#000" : "#fff"}
                      fontHoverColor="#fff"
                      btnPadding="10px 20px"
                      variant="contained"
                      className={
                        isDarkMode ? classes.signUpButtonDarkTheme : ""
                      }
                      bgColor={isDarkMode ? "#fff" : "#000"}
                      onClick={() => pageRedirect("/sign-up")}
                    >
                      Sign Up for Free
                    </CustomButton>
                  </ListItem>
                </div>
              )}
              {!isLogin.accessToken && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  className={`${classes.toggleBtn} ${
                    isDarkMode ? classes.toggleIconDarkTheme : ""
                  }`}
                  onClick={toggleDrawer("top", true)}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Drawer
                anchor={"top"}
                open={state["top"]}
                onClose={toggleDrawer("top", false)}
                className={isDarkMode ? classes.responsiveMenubarDarktheme : ""}
              >
                {list("top")}
              </Drawer>
              {isLogin.accessToken && (
                <>
                  <div className={`${classes.headerBar}`}>
                    <SearchTicker
                      extraClass={`${classes.searchWidth} ${classes.headerSearch}`}
                      dropdownClass={`${
                        subscriptionStatus === "TRIALING" &&
                        classes.searchCustomWidth
                      }`}
                      isClickNeeded={true}
                      isLanding={isLanding}
                      id={isMobileScreen ? "search-bar" : ""}
                    />
                  </div>
                  {isSmallScreen && (
                    <UserNotifications
                      className={classes.userNotificationsMobile}
                    />
                  )}
                  <div className={classes.headerAccount}>
                    <Typography>
                      {/* {trialEndsIn && subscriptionStatus === "TRIALING" && (
                        <>
                          Your Premium trial will end in{" "}
                          <b>
                            {trialEndsIn} {trialEndsIn === 1 ? "day" : "days"}
                          </b>
                          . Upgrade now for unlimited access
                        </>
                      )} */}
                      {isLog?.userInfo?.premiumText && (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: isLog?.userInfo?.premiumText,
                            }}
                            style={{ color: isLog?.userInfo?.premiumTextColor }}
                          />
                        </>
                      )}

                      {subscriptionStatus === "FREE" ? (
                        <CustomButton
                          className={classes.customBtnStyle}
                          radius="5"
                          btnPadding="11px 15px"
                          variant="contained"
                          onClick={
                            isTrialAvailable === 1 ||
                            (subscriptionStatus === "FREE" &&
                              plan &&
                              !isTrialAvailable)
                              ? subscriptionStatusResult
                              : handleBilling
                          }
                        >
                          {isTrialAvailable === 1
                            ? "Start Free Trial"
                            : "Subscribe"}
                        </CustomButton>
                      ) : subscriptionStatus === "ACTIVE" ||
                        subscriptionStatus === "TRIALING" ? (
                        <CustomButton
                          className={classes.customBtnStyle}
                          radius="5"
                          btnPadding="11px 15px"
                          variant="contained"
                          onClick={handleBilling}
                        >
                          Upgrade to PRO
                        </CustomButton>
                      ) : subscriptionStatus === "MANUALLY_CANCELLED" ||
                        subscriptionStatus === "PRO_CANCELLED" ||
                        subscriptionStatus === "INCOMPLETE" ||
                        subscriptionStatus === "PRO_INCOMPLETE" ? (
                        <CustomButton
                          className={classes.customBtnStyle}
                          radius="5"
                          btnPadding="11px 15px"
                          variant="contained"
                          onClick={handleBilling}
                        >
                          Update plan
                        </CustomButton>
                      ) : null}
                      {/* {(subscriptionStatus === "FREE" ||
                        (subscriptionStatus === "TRIALING" && trialEndsIn)) && (
                        <CustomButton
                          radius="5"
                          fontColor="#ffffff"
                          btnPadding="11px 15px"
                          variant="contained"
                          onClick={subscriptionStatusResult}
                        >
                          {isTrialAvailable === 1
                            ? "Start Free Trial"
                            : "Update plan"}
                        </CustomButton>
                      )} */}
                    </Typography>
                    {!isSmallScreen && <UserNotifications />}
                    <div className={classes.headerChange}>
                      {isLogin?.userInfo?.name && (
                        <>
                          <div
                            onClick={(e) => handleOpenMenu(e)}
                            className={classes.accountInfo}
                            id={
                              isSmallScreen
                                ? undefined /* Because this section gets hidden on small screen and react joyride is not working properly*/
                                : "account-info"
                            }
                          >
                            <Typography variant="h6" component="h6">
                              <PersonIcon />
                              <span>
                                {name?.substr(
                                  0,
                                  name?.indexOf(" ") !== -1
                                    ? name?.indexOf(" ")
                                    : name?.length
                                )}
                              </span>
                            </Typography>
                          </div>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            className={classes.menu}
                          >
                            <MenuItem onClick={() => moveTo("/account")}>
                              Account
                            </MenuItem>
                            {!hideAlertSettings && (
                              <MenuItem
                                onClick={() => moveTo("/notification-settings")}
                              >
                                Alert Settings
                              </MenuItem>
                            )}
                            <MenuItem onClick={signOutUser}>Sign Out</MenuItem>
                          </Menu>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Toolbar>
          </AppBar>
        </Container>
        <Box className={classes.bottomHeader}>
          <Container style={{ maxWidth: "1440px" }}>
            {displayOnBoarding && !isLogin.accessToken && (
              <div className={classes.headerNav}>
                {/* <ListItem>
                  <CustomButton
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      location.pathname === "/top-stock" ? "#07846f" : "#000"
                    }
                    btnPadding="10px 20px"
                    variant="contained"
                    aria-controls={openMore ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMore ? "true" : undefined}
                    onClick={() => {
                      history.push("/top-stock");
                      // dispatch(stockTabStatus(false));
                    }}
                  >
                    Top-Stocks
                  </CustomButton>
                </ListItem> */}
                <ListItem>
                  <CustomButton
                    id="basic-button"
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      location.pathname.split("/")[1] === "stocks"
                        ? theme.palette.primary.main
                        : isDarkMode
                        ? "#fff"
                        : "#000"
                    }
                    // fontColor="#fff"
                    btnPadding="10px 20px"
                    variant="contained"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={() => history.push("/stocks/top-gainers")}
                    // onMouseOver={handleClick}
                    // onMouseLeave={handleClose}
                    className={isDarkMode ? classes.headerMenuDarkTheme : ""}
                  >
                    Stocks <ExpandMoreIcon />
                  </CustomButton>
                  <Box
                    component="ul"
                    className={`${classes.stockSubMenu} ${
                      isDarkMode ? classes.stockMenuDarkTheme : ""
                    }`}
                  >
                    {stockItem?.map((v, i) => (
                      <MenuItem
                        onClick={() => {
                          dispatch(stockTabStatus(v?.redirectUrl));
                          history.push(v?.redirectUrl);
                          handleClose();
                        }}
                      >
                        {v?.name}
                      </MenuItem>
                    ))}
                  </Box>
                  {/* <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      style={{ left: "43%", top: "4%" }}
                      onClose={handleClose}
                      classes={{
                        paper: classes.stockSubMenu
                      }}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                        onMouseLeave: handleClose,
                      }}
                    >
                      {stockItem?.map((v, i) => (
                        <MenuItem
                          onClick={() => {
                            // pageRedirect(v?.redirectUrl);
                            dispatch(stockTabStatus(v?.redirectUrl));
                            history.push(v?.redirectUrl);
                            handleClose();
                          }}
                        >
                          {v?.name}
                        </MenuItem>
                      ))}
                    </Menu> */}
                </ListItem>
                {/* <CustomButton
                    bgColor="transparent"
                    bgHoverColor="transparent"
                    radius="5"
                    fontColor={
                      location.pathname === "/trends" ? "#07846f" : "#000"
                    }
                    btnPadding="10px 20px"
                    variant="contained"
                    onClick={() => pageRedirect("/trends")}
                  >
                    Benchmark
                  </CustomButton> */}
                {/* <ListItem>
                  <CustomButton
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      location.pathname === "/trends" ? "#07846f" : "#000"
                    }
                    btnPadding="10px 20px"
                    variant="contained"
                    aria-controls={openMore ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMore ? "true" : undefined}
                    onClick={() => {
                      history.push("/trends");
                      dispatch(stockTabStatus(false));
                    }}
                  >
                    Trends
                    <ExpandMoreIcon />
                  </CustomButton>
                  <Box
                    component="ul"
                    className={`${classes.moreMenu} ${classes.stockSubMenu}`}
                  >
                    {trendsItem?.map((v, i) => (
                      <MenuItem
                        onClick={() => {
                          dispatch(stockTabStatus(v?.scroll));
                          history.push("/trends");
                          handleClose();
                        }}
                      >
                        {v?.name}
                      </MenuItem>
                    ))}
                  </Box>
                </ListItem> */}
                <ListItem>
                  <CustomButton
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      isAiTopPicksPage
                        ? theme.palette.primary.main
                        : isDarkMode
                        ? "#fff"
                        : "#000"
                    }
                    // fontColor="#fff"
                    btnPadding="10px 20px"
                    className={isDarkMode ? classes.headerMenuDarkTheme : ""}
                    variant="contained"
                    aria-controls={openMore ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMore ? "true" : undefined}
                    onClick={() => {
                      history.push("/toppicks");
                      dispatch(stockTabStatus(false));
                    }}
                  >
                    AI Top Picks
                  </CustomButton>
                </ListItem>
                <ListItem>
                  <CustomButton
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      location.pathname === "/chat"
                        ? theme.palette.primary.main
                        : isDarkMode
                        ? "#fff"
                        : "#000"
                    }
                    // fontColor="#fff"
                    btnPadding="10px 20px"
                    className={isDarkMode ? classes.headerMenuDarkTheme : ""}
                    variant="contained"
                    aria-controls={openMore ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMore ? "true" : undefined}
                    onClick={() => {
                      history.push("/chat");
                      dispatch(stockTabStatus(false));
                    }}
                  >
                    AI Chat
                  </CustomButton>
                </ListItem>
                <ListItem>
                  <CustomButton
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      location.pathname === "/screener"
                        ? theme.palette.primary.main
                        : isDarkMode
                        ? "#fff"
                        : "#000"
                    }
                    className={classes.headerMenuDarkTheme}
                    btnPadding="10px 20px"
                    variant="contained"
                    aria-controls={openMore ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMore ? "true" : undefined}
                    onClick={() => {
                      history.push("/screener");
                      dispatch(stockTabStatus(false));
                    }}
                  >
                    Screener
                  </CustomButton>
                </ListItem>
                <ListItem>
                  <CustomButton
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      location.pathname === "/multichart"
                        ? theme.palette.primary.main
                        : isDarkMode
                        ? "#fff"
                        : "#000"
                    }
                    // fontColor="#fff"
                    className={classes.headerMenuDarkTheme}
                    btnPadding="10px 20px"
                    variant="contained"
                    aria-controls={openMore ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMore ? "true" : undefined}
                    onClick={() => {
                      history.push("/multichart");
                      dispatch(stockTabStatus(false));
                    }}
                  >
                    Multichart
                  </CustomButton>
                </ListItem>
                <ListItem>
                  <CustomButton
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      location.pathname === "/heatmap"
                        ? theme.palette.primary.main
                        : isDarkMode
                        ? "#fff"
                        : "#000"
                    }
                    className={classes.headerMenuDarkTheme}
                    btnPadding="10px 20px"
                    variant="contained"
                    aria-controls={openMore ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMore ? "true" : undefined}
                    onClick={() => {
                      history.push("/heatmap");
                      dispatch(stockTabStatus(false));
                    }}
                  >
                    Heatmap
                  </CustomButton>
                </ListItem>
                {/* <ListItem>
                  <CustomButton
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      location.pathname === "/trends" ? "#07846f" : isAiTopPicksPage ? '#fff' : "#000"
                    }
                    // fontColor="#fff"
                    btnPadding="10px 20px"
                    className={isAiTopPicksPage ? classes.headerMenuDarkTheme : ''}
                    variant="contained"
                    aria-controls={openMore ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMore ? "true" : undefined}
                    onClick={() => {
                      history.push("/trends");
                      dispatch(stockTabStatus(false));
                    }}
                  >
                    Trends
                    <ExpandMoreIcon />
                  </CustomButton>
                  <Box
                    component="ul"
                    className={`${classes.moreMenu} ${classes.stockSubMenu} ${isAiTopPicksPage ? classes.stockMenuDarkTheme : ''}`}
                  >
                    {trendsItem?.map((v, i) => (
                      <MenuItem
                        onClick={() => {
                          dispatch(stockTabStatus(v?.scroll));
                          history.push("/trends");
                          handleClose();
                        }}
                      >
                        {v?.name}
                      </MenuItem>
                    ))}
                  </Box>
                </ListItem> */}
                <ListItem>
                  <CustomButton
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      location.pathname.split("/")[1] === "news"
                        ? theme.palette.primary.main
                        : isDarkMode
                        ? "#fff"
                        : "#000"
                    }
                    // fontColor="#fff"
                    btnPadding="10px 20px"
                    className={isDarkMode ? classes.headerMenuDarkTheme : ""}
                    variant="contained"
                    aria-controls={openMore ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMore ? "true" : undefined}
                    // onClick={handleMoreClick}
                    onClick={() => history.push("/news/trending")}
                  >
                    News
                    <ExpandMoreIcon />
                  </CustomButton>
                  <Box
                    component="ul"
                    className={`${classes.stockSubMenu}  ${
                      isDarkMode ? classes.stockMenuDarkTheme : ""
                    }`}
                  >
                    {newsItem?.map((v, i) => (
                      <MenuItem
                        onClick={() => {
                          dispatch(stockTabStatus(v?.newsFilter));
                          history.push(v?.url);
                          // history.push("/news");
                          handleClose();
                        }}
                        disabled={i === 9 || i === 7}
                      >
                        {v?.name}
                      </MenuItem>
                    ))}
                  </Box>
                </ListItem>
                {/* <ListItem>
                  <CustomButton
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      location.pathname.split("/")[1] === "tweets"
                        ? "#07846f"
                        : isAiTopPicksPage
                        ? "#fff"
                        : "#000"
                    }
                    // fontColor="#fff"
                    btnPadding="10px 20px"
                    className={
                      isAiTopPicksPage ? classes.headerMenuDarkTheme : ""
                    }
                    variant="contained"
                    aria-controls={openMore ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMore ? "true" : undefined}
                    // onClick={handleMoreClick}
                    onClick={() => history.push("/tweets/trending")}
                  >
                    Tweets <ExpandMoreIcon />
                  </CustomButton>
                  <Box
                    component="ul"
                    className={`${classes.stockSubMenu} ${
                      isAiTopPicksPage ? classes.stockMenuDarkTheme : ""
                    }`}
                  >
                    {tweetsItem?.map((v, i) => (
                      <MenuItem
                        onClick={() => {
                          dispatch(stockTabStatus(v?.newsFilter));
                          // history.push("/tweets");
                          history.push(v?.url);
                          handleClose();
                        }}
                        disabled={i === 9 || i === 11}
                      >
                        {v?.name}
                      </MenuItem>
                    ))}
                  </Box>
                </ListItem> */}
                <ListItem>
                  <CustomButton
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      location.pathname.split("/")[1] === "calendar"
                        ? theme.palette.primary.main
                        : isDarkMode
                        ? "#fff"
                        : "#000"
                    }
                    // fontColor="#fff"
                    btnPadding="10px 20px"
                    variant="contained"
                    onClick={() => history.push("/calendar/earnings-calendar")}
                    aria-controls={openMore ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    className={isDarkMode ? classes.headerMenuDarkTheme : ""}
                    aria-expanded={openMore ? "true" : undefined}
                    // onClick={handleMoreClick}
                    // onMouseOver={handleMoreClick}
                    // onMouseLeave={handleMoreClose}
                  >
                    Calendar <ExpandMoreIcon />
                  </CustomButton>
                  <Box
                    component="ul"
                    className={`${classes.moreMenu} ${classes.stockSubMenu} ${
                      isDarkMode ? classes.stockMenuDarkTheme : ""
                    }`}
                  >
                    {calendarItem?.map((v, i) => (
                      <MenuItem
                        onClick={() => {
                          dispatch(stockTabStatus(v?.redirectUrl));
                          history.push(v?.redirectUrl);
                          handleClose();
                        }}
                      >
                        {v?.name}
                      </MenuItem>
                    ))}
                  </Box>
                </ListItem>
                <ListItem>
                  <CustomButton
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      location.pathname === "/insider"
                        ? theme.palette.primary.main
                        : isDarkMode
                        ? "#fff"
                        : "#000"
                    }
                    // fontColor="#fff"
                    btnPadding="10px 20px"
                    variant="contained"
                    onClick={() => history.push("/insider")}
                    aria-controls={openMore ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    className={isDarkMode ? classes.headerMenuDarkTheme : ""}
                    aria-expanded={openMore ? "true" : undefined}
                    // onClick={handleMoreClick}
                  >
                    Insider
                    <ExpandMoreIcon />
                  </CustomButton>
                  <Box
                    component="ul"
                    className={`${classes.moreMenu} ${classes.stockSubMenu} ${
                      isDarkMode ? classes.stockMenuDarkTheme : ""
                    }`}
                  >
                    {insiderItem?.map((v, i) => (
                      <MenuItem
                        onClick={() => {
                          dispatch(stockTabStatus(v?.filterValue));
                          history.push("/insider");
                          handleClose();
                        }}
                        disabled={i === 2}
                      >
                        {v?.name}
                      </MenuItem>
                    ))}
                  </Box>
                </ListItem>

                <ListItem>
                  <CustomButton
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      location.pathname.split("/")[1] === "forecast"
                        ? theme.palette.primary.main
                        : isDarkMode
                        ? "#fff"
                        : "#000"
                    }
                    // fontColor="#fff"
                    btnPadding="10px 20px"
                    variant="contained"
                    aria-controls={openMore ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    className={isDarkMode ? classes.headerMenuDarkTheme : ""}
                    aria-expanded={openMore ? "true" : undefined}
                    // onClick={handleMoreClick}
                    onClick={() =>
                      history.push("/forecast/most-recommended-stocks")
                    }
                  >
                    Forecast
                    <ExpandMoreIcon />
                  </CustomButton>
                  <Box
                    component="ul"
                    className={`${classes.moreMenu} ${classes.stockSubMenu} ${
                      isDarkMode ? classes.stockMenuDarkTheme : ""
                    }`}
                  >
                    {analystItem?.map((v, i) => (
                      <MenuItem
                        onClick={() => {
                          // dispatch(stockTabStatus(v?.redirectUrl));
                          history.push(v?.redirectUrl);
                          handleClose();
                        }}
                        // disabled={i === 2}
                      >
                        {v?.name}
                      </MenuItem>
                    ))}
                  </Box>
                </ListItem>
                <ListItem>
                  <CustomButton
                    id="basic-button"
                    bgColor="transparent"
                    bgHoverColor="#eee"
                    radius="5"
                    fontColor={
                      location.pathname.split("/")[1] === "institution"
                        ? theme.palette.primary.main
                        : isDarkMode
                        ? "#fff"
                        : "#000"
                    }
                    // fontColor="#fff"
                    className={isDarkMode ? classes.headerMenuDarkTheme : ""}
                    btnPadding="10px 20px"
                    variant="contained"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    minW="auto"
                    // onClick={() => history.push("/institution/top-gurus")}
                    // onClick={handleClick}
                  >
                    More... <ExpandMoreIcon />
                  </CustomButton>
                  <Grid
                    component="ul"
                    container
                    spacing={3}
                    className={`${classes.stockSubMenu} ${classes.rightMenu} ${
                      classes.moreMenuDropdown
                    } ${isDarkMode ? classes.stockMenuDarkTheme : ""} ${
                      isDarkMode ? classes.moreMenuDropdownDarkTheme : ""
                    }`}
                  >
                    <Grid component="li" item xs={2}>
                      <Typography
                        variant="h6"
                        className={classes.nestedMenuHeading}
                      >
                        Politics
                      </Typography>
                      <Box
                        component="ul"
                        p={0}
                        className={`${classes.rightMenu}`}
                      >
                        {politicsItem?.map((v, i) => (
                          <MenuItem
                            onClick={() => {
                              dispatch(stockTabStatus(v?.redirectUrl));
                              history.push(v?.redirectUrl);
                              handleClose();
                            }}
                          >
                            {v?.name}
                          </MenuItem>
                        ))}
                      </Box>
                    </Grid>
                    <Grid component="li" item xs={3}>
                      <Typography
                        variant="h6"
                        className={classes.nestedMenuHeading}
                      >
                        Institution
                      </Typography>
                      <Box
                        component="ul"
                        p={0}
                        className={`${classes.rightMenu}`}
                      >
                        {institutionItem?.map((v, i) => (
                          <MenuItem
                            onClick={() => {
                              dispatch(stockTabStatus(v?.redirectUrl));
                              history.push(v?.redirectUrl);
                              handleClose();
                            }}
                          >
                            {v?.name}
                          </MenuItem>
                        ))}
                      </Box>
                    </Grid>
                    <Grid component="li" item xs={5}>
                      <Typography
                        variant="h6"
                        className={classes.nestedMenuHeading}
                      >
                        Economy
                      </Typography>
                      <Box
                        component="ul"
                        p={0}
                        className={`${classes.rightMenu}`}
                      >
                        {economyItem?.map((v, i) => (
                          <MenuItem
                            onClick={() => {
                              dispatch(stockTabStatus(v?.redirectUrl));
                              history.push(v?.redirectUrl);
                              handleClose();
                            }}
                          >
                            {v?.name}
                          </MenuItem>
                        ))}
                      </Box>
                    </Grid>
                    <Grid component="li" item xs={2}>
                      <Typography
                        variant="h6"
                        className={classes.nestedMenuHeading}
                      >
                        Company
                      </Typography>
                      <Box
                        component="ul"
                        p={0}
                        className={`${classes.rightMenu}`}
                      >
                        {companyItems?.map((v, i) => (
                          <MenuItem
                            onClick={() => {
                              dispatch(stockTabStatus(v?.redirectUrl));
                              v?.isExternalLink
                                ? window.open(v?.redirectUrl)
                                : history.push(v?.redirectUrl);
                              handleClose();
                            }}
                          >
                            {v?.name}
                          </MenuItem>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </ListItem>
                {/*  <ListItem>
                    <CustomButton
                      bgColor="transparent"
                      bgHoverColor="transparent"
                      radius="5"
                      fontColor={
                        location.pathname === "/plans" ? "#07846f" : "#000"
                      }
                      btnPadding="10px 20px"
                      variant="contained"
                      onClick={() => pageRedirect("/plans")}
                    >
                      Plans
                    </CustomButton>
                  </ListItem> */}
                {/*  <ListItem>
                    <CustomButton
                      bgColor="transparent"
                      bgHoverColor="transparent"
                      radius="5"
                      fontColor={
                        location.pathname === "/about" ? "#07846f" : "#000"
                      }
                      btnPadding="10px 20px"
                      variant="contained"
                      onClick={() => history.push("/about")}
                    >
                      About
                    </CustomButton>
                  </ListItem> */}
              </div>
            )}
          </Container>
        </Box>
      </header>
      {isLog.idToken && paths.includes(pathname) && (
        <div className={classes.trandingWidget}>
          <IndexWidget index={index} />
        </div>
      )}
      {/* {!isLog.idToken && !googleAddPath.includes(pathname) && <GoogleAdsense />} */}
    </>
  );
};

export default Header;
