import { useTheme } from "@material-ui/core";

const useTourStyles = () => {
  const theme = useTheme();

  return {
    primaryButton: {
      backgroundColor: theme.palette.secondary.main,
      border: "0px",
      borderRadius: "20px",
      color: "rgb(255, 255, 255)",
      cursor: "pointer",
      fontSize: "16px",
      lineHeight: "1",
      padding: "10px 25px",
      appearance: "none",
    },
    secondaryButton: {
      backgroundColor: "transparent",
      border: "0px",
      borderRadius: "0px",
      color: theme.palette.secondary.main,
      cursor: "pointer",
      fontSize: "16px",
      lineHeight: "1",
      padding: "10px 15px",
      appearance: "none",
      marginLeft: "auto",
      marginRight: "5px",
    },
  };
};

export default useTourStyles;
