import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

const MoreDropdownMobile = ({ children }) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  return (
    <>
      <ListItem button onClick={() => setOpen((prev) => !prev)}>
        <ListItemText primary="...More" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.nested}>
          {children}
        </List>
      </Collapse>
    </>
  );
};

export default MoreDropdownMobile;
