const constants = {
  LOADER: "LOADER",
  SEARCH_STOCK_TICKER: "SEARCH_STOCK_TICKER",
  SEARCH_INSTITUTION: "SEARCH_INSTITUTION",

  TOAST: "TOAST",
  CLEAR_TOAST: "CLEAR_TOAST",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_REQUEST_SUCCESS: "LOGIN_REQUEST_SUCCESS",
  LOGIN_REQUEST_ERROR: "LOGIN_REQUEST_ERROR",
  CLEAR_FLAG: "CLEAR_FLAG",
  GET_USER_POST_REQUEST_SUCCESS: "GET_USER_POST_REQUEST_SUCCESS",
  GET_USER_POST_REQUEST_ERROR: "GET_USER_POST_REQUEST_ERROR",

  GET_WATCH_LIST_REQUEST_SUCCESS: "GET_WATCH_LIST_REQUEST_SUCCESS",
  ADD_WATCH_LIST_ITEM: "ADD_WATCH_LIST_ITEM",
  CHANGE_PAGE_NUMBER: "CHANGE_PAGE_NUMBER",
  CHANGE_PAGE_NUMBER_VOLUME: "CHANGE_PAGE_NUMBER_VOLUME",
  SECTOR_SIGNAL: "SECTOR_SIGNAL",
  TRADE_SIZE_OPTION: "TRADE_SIZE_OPTION",
  CHANGE_PAGE_NUMBER_SIGNAL: "CHANGE_PAGE_NUMBER_SIGNAL",
  REMOVE_WATCH_LIST_ITEM: "REMOVE_WATCH_LIST_ITEM",
  REMOVE_WATCH_LIST_FROM_STOCK: "REMOVE_WATCH_LIST_FROM_STOCK",

  GET_STOCK_LISTS: "GET_STOCK_LISTS",
  GET_PORTFOLIO_WATCHLIST: "GET_PORTFOLIO_WATCHLIST",
  GET_BANNER_DATA: "GET_BANNER_DATA",
  LANDING_PAGE_BANNER: "LANDING_PAGE_BANNER",
  GET_POPULAR_DATA: "GET_POPULAR_DATA",
  GET_VIEWED_DATA: "GET_VIEWED_DATA",
  SET_STOCK_SIGNAL: "SET_STOCK_SIGNAL",
  SET_INSTITUTION: "SET_INSTITUTION",
  SET_INSTITUTION_PROFILE: "SET_INSTITUTION_PROFILE",
  SET_TOP_BUY_PROFILE: "SET_TOP_BUY_PROFILE",
  SET_RECENT_TRADES_PROFILE: "SET_RECENT_TRADES_PROFILE",

  SORT_DATA: "SORT_DATA",

  UPDATE_SUBSCRIPTION_STATUS: "UPDATE_SUBSCRIPTION_STATUS",

  GET_ADMIN_LIST_REQUEST: "GET_ADMIN_LIST_REQUEST",
  GET_ADMIN_LISTS: "GET_ADMIN_LISTS",
  REMOVE_ADMIN_RECORD: "REMOVE_ADMIN_RECORD",
  EDIT_ADMIN_RECORD: "EDIT_ADMIN_RECORD",

  WINDOW_WIDTH: "WINDOW_WIDTH",
  SUBSCRIBE_STATUS: "SUBSCRIBE_STATUS",

  ABOUT_STATUS: "ABOUT_STATUS",
  STOCK_TAB_CHANGES: "STOCK_TAB_CHANGES",
  FEATURES_STATUS: "FEATURES_STATUS",
  PLANS_STATUS: "PLANS_STATUS",

  ANALYSTS: "ANALYSTS",
  PROFILE_ANALYST: "PROFILE_ANALYST",
  ADD_WATCH_LIST_ANALYST: "ADD_WATCH_LIST_ANALYST",
  REMOVE_WATCH_LIST_ANALYST: "REMOVE_WATCH_LIST_ANALYST",

  FEED: "FEED",
  AUTO: "AUTO",

  SET_DATA: "SET_DATA",
  SET_STOCK_INSIDER: "SET_STOCK_INSIDER",
  SET_STOCK_TRANSCRIPT: "SET_STOCK_TRANSCRIPT",
  SET_STOCK_SEC: "SET_STOCK_SEC",
  SET_STOCK_TWEETS: "SET_STOCK_TWEETS",
  SET_STOCK_ANALYST_FORECAST: "SET_STOCK_ANALYST_FORECAST",
  SET_STOCK_NEWS: "SET_STOCK_NEWS",
  SET_STOCK_OWNERSHIP: "SET_STOCK_OWNERSHIP",
  SET_STOCK_FILING: "SET_STOCK_FILING",
  SET_STOCK_STATEMENT: "SET_STOCK_STATEMENT",
  SET_TRANDING_DATA: "SET_TRANDING_DATA",
  SET_ANALYST_PAGE_DATA: "SET_ANALYST_PAGE_DATA",
  SET_NEWS_TRANDING_DATA: "SET_NEWS_TRANDING_DATA",
  SET_INSIDER_DATA: "SET_INSIDER_DATA",
  SET_TWEETS_TRANDING_DATA: "SET_TWEETS_TRANDING_DATA",
  SET_EARNINGS_CALENDAR_DATA: "SET_EARNINGS_CALENDAR_DATA",

  SET_REDIRECT_URL: "SET_REDIRECT_URL",
  RESET_REDIRECT_URL: "RESET_REDIRECT_URL",
  HIDE_IN_APP_GUIDE: "HIDE_IN_APP_GUIDE",
  MARK_NOTIFICATIONS_AS_READ: "MARK_NOTIFICATIONS_AS_READ",

  // App Guide
  UPDATE_DASHBOARD_GUIDE_STATE: "UPDATE_DASHBOARD_GUIDE_STATE",

  // Notification banner
  SHOW_NOTIFICATION_BANNER: "SHOW_NOTIFICATION_BANNER",
  HIDE_NOTIFICATION_BANNER: "HIDE_NOTIFICATION_BANNER",
};

export default constants;
