import {
  Box,
  Button,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { ReactComponent as ShareIcon } from "../../assets/images/share.svg";
import { ReactComponent as XIcon } from "../../assets/images/x.svg";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
import Modal, { DialogContent } from "../../shared/Modal";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toastMessage } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: "50%",
    marginRight: 16,
    width: 50,
    height: 50,
    [theme.breakpoints.down("xs")]: {
      width: 35,
      height: 35,
      marginRight: 8,
    },
  },
  copyBox: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    gap: 8,
    border: "1px solid lightgray",
    borderRadius: 40,
    padding: [[6, 8, 6, 14]],
  },
  copyButton: {
    borderRadius: 30,
    marginLeft: "auto",
    color: "#fff",
  },
  dialogContent: {
    "&.MuiDialogContent-root": {
      padding: [[30, 30, 40, 30]],
    },
  },
}));

const ShareChatButton = ({ makeShareable, shareable, query }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { copy, isCopied } = useCopyToClipboard();
  const { chatId } = useParams();
  const { idToken } = useSelector((state) => state.signIn.loggedUser);

  const url = window.location.href;

  const share = async () => {
    try {
      setIsLoading(true);
      await axios.post(
        process.env.REACT_APP_CHAT_API_URL,
        {
          method: "share",
          chatId,
        },
        {
          headers: {
            Authorization: idToken,
          },
        }
      );
      openModal();
      makeShareable();
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: "Something went wrong. Please try again later.",
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        startIcon={
          isLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <ShareIcon />
          )
        }
        disabled={isLoading}
        onClick={shareable ? openModal : share}
      >
        Share
      </Button>

      <Modal
        title="Share Chat"
        open={isModalOpen}
        onClose={closeModal}
        PaperProps={{
          style: { width: "100%", maxWidth: 500 },
        }}
      >
        <DialogContent dividers className={classes.dialogContent}>
          <Box display="flex" justifyContent="center">
            <FacebookShareButton url={url}>
              <FacebookIcon className={classes.icon} />
            </FacebookShareButton>
            <TwitterShareButton title={query} url={url} via={["FintwitAi"]}>
              <XIcon className={classes.icon} />
            </TwitterShareButton>
            <TelegramShareButton url={url}>
              <TelegramIcon className={classes.icon} />
            </TelegramShareButton>
            <WhatsappShareButton url={url}>
              <WhatsappIcon className={classes.icon} />
            </WhatsappShareButton>
            <LinkedinShareButton url={url}>
              <LinkedinIcon className={classes.icon} />
            </LinkedinShareButton>
            <EmailShareButton url={url}>
              <EmailIcon className={classes.icon} />
            </EmailShareButton>
          </Box>
          <Box className={classes.copyBox}>
            <Typography noWrap>{url}</Typography>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.copyButton}
              onClick={() => copy(url)}
            >
              {isCopied ? "Copied" : "Copy"}
            </Button>
          </Box>
        </DialogContent>
      </Modal>
    </>
  );
};

export default ShareChatButton;
