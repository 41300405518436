import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import axios from "axios";
import moment from "moment-timezone";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as LayerGroupIcon } from "../../assets/images/layer-group.svg";
import { ReactComponent as LayerPlusIcon } from "../../assets/images/layer-plus.svg";
import { toastMessage } from "../../redux/actions";
import Loader from "../Common/Loader";
import { useChatStyles } from "./chat.style";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 18,
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
    "& svg": {
      width: 18,
      height: 22,
      marginRight: 8,
    },
  },
  chatTitle: {
    "& .MuiTypography-root": {
      fontSize: 16,
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
    },
    color: "#000",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  footerInfo: {
    color: "gray",
    fontSize: 13,
    display: "flex",
    alignItems: "center",
    "& svg": {
      height: 16,
      width: 16,
      marginRight: 4,
    },
  },
  suggestion: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Mulish",
    color: "##3e3a3a",
  },
}));

const MAX_CHATS = 5;
const suggestions = [
  "What is the current price of Tesla?",
  "What is the annual revenue of Tesla?",
  "Show me the segment revenues of Apple from the last earnings",
  "How is Google's cloud business compared to AWS?",
  "Highlight key results from Google's latest earnings result",
  "What are the key factors influencing the growth in Google Cloud revenues?",
];

const RecentChatHistory = ({ createChat }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [recentChats, setRecentChats] = useState();

  const classes = useStyles();
  const chatClasses = useChatStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { idToken } = useSelector((state) => state.signIn.loggedUser);

  const totalChats = recentChats?.length || 0;

  const getChatHistory = async () => {
    const url = `${process.env.REACT_APP_CHAT_API_URL}?method=history&start=0&limit=20`;

    try {
      setIsLoading(true);
      const { data } = await axios.get(url, {
        headers: {
          Authorization: idToken,
        },
      });
      const recentChatHistory = data?.sort(
        (a, b) => new Date(b.lastQueryDate) - new Date(a.lastQueryDate)
      );
      setRecentChats(recentChatHistory);
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: "Could not fetch chat history. Please try again later.",
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getChatHistory();
  }, []);

  if (isLoading) return <Loader isLoad={isLoading} />;

  return (
    <Box maxWidth={735} mx="auto">
      <Typography variant="h5" className={classes.title}>
        <LayerGroupIcon />
        {totalChats === 0 ? "Suggested Queries" : "Recent Chats"}
      </Typography>
      <Divider light />

      {totalChats === 0 ? (
        <Box mt={-1}>
          <List>
            {suggestions.map((suggestion) => (
              <ListItem
                key={suggestion}
                className={classNames(
                  chatClasses.relatedQuestion,
                  classes.suggestion
                )}
                divider
                button
                disableRipple
                onClick={() => createChat({ query: suggestion })}
              >
                {suggestion}
              </ListItem>
            ))}
          </List>
        </Box>
      ) : (
        <Box>
          {recentChats?.length ? (
            recentChats
              .slice(0, MAX_CHATS)
              .map(({ chatId, chatName, snippet, lastQueryDate, qCount }) => (
                <Fragment key={chatId}>
                  <Divider light />
                  <Box py={1.5}>
                    <Box
                      display="flex"
                      alignItems="start"
                      justifyContent="space-between"
                      mb={0.5}
                    >
                      <Link
                        to={`/chat/${chatId}`}
                        className={classes.chatTitle}
                      >
                        <Typography variant="h5">{chatName}</Typography>
                      </Link>
                    </Box>
                    <Box display="flex" alignItems="center" gridColumnGap={14}>
                      <Typography className={classes.footerInfo}>
                        <LayerPlusIcon /> {qCount}
                      </Typography>
                      {!!lastQueryDate && (
                        <Typography className={classes.footerInfo}>
                          <AccessTimeIcon /> {moment(lastQueryDate).fromNow()}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Fragment>
              ))
          ) : (
            <>
              <Divider />
              <Typography
                color="textSecondary"
                style={{ marginTop: 30, textAlign: "center" }}
              >
                No chats found.
              </Typography>
            </>
          )}
          {recentChats?.length > 5 && (
            <Box mt={1} mb={3} textAlign="center">
              <Button
                variant="contained"
                disableElevation
                size="small"
                onClick={() => history.push("/chat/history")}
              >
                Show all
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default RecentChatHistory;
