import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";
const ReCaptcha = ({ onChange, className, widthSize }) => {
    return (
        <>
            <ReCAPTCHA
                className={className}
                name="reCapcha"
                sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                onChange={onChange}
                //size="normal"
                size='normal'
                badge="inline"
            />
        </>
    )
}

export default React.memo(ReCaptcha)
