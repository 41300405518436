import React from "react";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import TwitterIcon from "@material-ui/icons/Twitter";
import { getCurrentYear } from "../../utils/common";
import { useHistory } from "react-router-dom";
import ButtonWithArrow from "../Common/ButtonWithArrow";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: ({ isLogin }) => (isLogin ? "#fff" : "#000"),
    color: ({ isLogin }) => (isLogin ? "#000" : "#fff"),
    padding: ({ isLogin }) => (isLogin ? [[24, 12, 24, 12]] : [[60, 0, 60, 0]]),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: ({ isLogin }) => (isLogin ? 120 : 60),
    },
    marginTop: "auto",
    position: "relative",
    overflow: "hidden",
    borderTop: ({ isLogin }) => (isLogin ? "1px solid #E6E6E6" : 0),
    "& > p": {
      fontSize: 16,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: ({ isLogin }) => (isLogin ? 75 : 0),
    },
  },
  wrapper: {
    position: "relative",
    zIndex: 99,
    marginBottom: ({ isLogin }) => (isLogin ? 0 : 60),
    "& .MuiTypography-body1": {
      color: theme.palette.common.white,
      fontWeight: 400,
      lineHeight: "19.36px",
      textAlign: "center",
      marginBottom: 24,
    },
    "& .MuiTypography-h3": {
      fontFamily: `'Roboto', sans-serif`,
      fontSize: "40px",
      color: theme.palette.common.white,
      fontWeight: 700,
      lineHeight: "48.41px",
      textAlign: "center",
      marginBottom: 24,
    },
  },
  footerDarkTheme: {
    borderTop: "1px solid rgba(255,255,255,0.2)",
  },
  footerLink: {
    position: "relative",
    zIndex: 99,
    display: "flex",
    justifyContent: "center",
    marginBottom: ({ isLogin }) => (isLogin ? 0 : 40),
    flexWrap: "wrap",
    gridColumnGap: 30,
    gridRowGap: 20,
    [theme.breakpoints.down("sm")]: {
      gridRowGap: 20,
      justifyContent: "center",
    },
    "& .MuiListItem-root": {
      width: "auto",
      padding: "0 10px",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        padding: "0",
      },
      // [theme.breakpoints.down("xs")]: {
      //   "&:before": {
      //     content: '""',
      //     position: "absolute",
      //     left: 0,
      //     top: 6,
      //     width: 6,
      //     height: 6,
      //     backgroundColor: "#1FEE8B",
      //     borderRadius: "100%",
      //   },
      // },
      "& a": {
        color: ({ isLogin }) => (isLogin ? "#000" : "#fff"),
        fontSize: 16,
        textDecoration: "none",
        lineHeight: "16.94px",
        fontWeight: 400,
      },
    },
  },
  copyRight: {
    "& .MuiTypography-body1": {
      color: "#aab2be",
      fontWeight: 400,
      lineHeight: "16.94px",
      textAlign: "center",
    },
  },
  loggedIn: {
    [theme.breakpoints.up("md")]: { marginLeft: 230 },
  },
  isActive: {
    "& a": {
      color: `#3c6bf6 !important`,
    },
  },
  footerCol2: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  twitter: {
    verticalAlign: "middle",
    color: "#fff",
  },
  twitterLogin: {
    color: "#505050",
  },
}));

export default function Footer({ dimensions }) {
  const isLogin = useSelector((state) => state.signIn.loggedUser);
  const classes = useStyles({ isLogin: !!isLogin?.idToken });
  const isActive = window.location.pathname;
  const history = useHistory();

  return (
    <div
      className={`${classes.footer}  ${
        !isLogin.idToken ? "" : classes.loggedIn
      } ${classes.footerDarkTheme}`}
    >
      <Container
        maxWidth={isLogin.idToken ? "md" : "lg"}
        disableGutters={isLogin.idToken ? true : false}
        style={{ maxWidth: isLogin.idToken ? undefined : "1440px" }}
      >
        {!isLogin.idToken && (
          <Grid container>
            <Grid item xs={12} className={classes.wrapper}>
              <Typography variant="h3" component={"h3"}>
                Let&apos;s get started
              </Typography>
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <ButtonWithArrow
                  className={classes.homeMainSBtn}
                  radius="30"
                  btnPadding="15px 35px"
                  variant="contained"
                  fontSize="18"
                  onClick={() => history.push("/sign-up")}
                  hoverEffect
                >
                  <span>Sign Up for Free</span>
                </ButtonWithArrow>
              </Box>
            </Grid>
          </Grid>
        )}
        <Box>
          <List className={classes.footerLink} disablePadding>
            <ListItem>
              <Link to="/about">About</Link>
            </ListItem>
            <ListItem>
              <a
                href="https://fintwit.freshdesk.com/support/solutions"
                target="_blank"
              >
                Help Center
              </a>
            </ListItem>
            {!isLogin.idToken && (
              <ListItem>
                <a
                  href="https://fintwit-ai.getrewardful.com/signup"
                  target="_blank"
                >
                  Affiliate
                </a>
              </ListItem>
            )}
            <ListItem>
              <Link to="/disclosure">Disclosure</Link>
            </ListItem>
            <ListItem>
              <Link to="/term-and-condition">Terms of Service</Link>
            </ListItem>
            <ListItem>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </ListItem>
            <ListItem>
              <Link to="/contact">Contact</Link>
            </ListItem>
            <ListItem>
              <Link to="/advertise">Advertise</Link>
            </ListItem>
          </List>
        </Box>
        {!isLogin?.idToken && (
          <Box className={classes.copyRight}>
            <Typography variant="body1" component="p">
              Copyright {getCurrentYear()} Fintwit. All rights reserved.{" "}
              <a
                href="https://twitter.com/FintwitAi"
                target="_blank"
                className={`${classes.twitter}  ${
                  !isLogin?.idToken ? "" : classes.twitterLogin
                }`}
              >
                <TwitterIcon />
              </a>
            </Typography>
          </Box>
        )}
      </Container>
    </div>
  );
}
