import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastMessage } from "../../redux/actions";
import { LoaderAction } from "../../redux/loader/action";
import EditChatModal from "./EditChatModal";

const ChatOptions = ({ chatId, chatName, onDelete, onUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { idToken } = useSelector((state) => state.signIn.loggedUser);

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
    setIsEditModalOpen(true);
  };

  const handleDelete = async () => {
    const url = `${process.env.REACT_APP_CHAT_API_URL}?method=history&start=0&limit=20`;

    try {
      dispatch(LoaderAction(true));
      await axios.delete(url, {
        headers: {
          Authorization: idToken,
        },
        data: {
          chatId,
        },
      });
      onDelete(chatId);
      dispatch(
        toastMessage({
          status: true,
          message: "Chat deleted successfully.",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: "Could not delete chat. Please try again later.",
          type: "error",
        })
      );
    } finally {
      dispatch(LoaderAction(false));
    }
  };

  return (
    <>
      <IconButton color="default" size="small" onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>

      <EditChatModal
        chatId={chatId}
        chatName={chatName}
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onUpdate={onUpdate}
      />
    </>
  );
};

export default ChatOptions;
