import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { ReactComponent as ThumbDownOultinedIcon } from "../../assets/images/thumbs-down-outlined.svg";
import { ReactComponent as ThumbDownIcon } from "../../assets/images/thumbs-down.svg";
import ReportModal from "./ReportModal";

const ReportAnswer = ({ queryId }) => {
  const [isReported, setIsReported] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const onReportSuccess = () => setIsReported(true);

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        startIcon={isReported ? <ThumbDownIcon /> : <ThumbDownOultinedIcon />}
        onClick={openModal}
      >
        {isReported ? "Reported" : "Report"}
      </Button>

      <ReportModal
        open={isModalOpen}
        onClose={closeModal}
        queryId={queryId}
        onSuccess={onReportSuccess}
      />
    </>
  );
};

export default ReportAnswer;
