import { Box } from "@material-ui/core";
import CustomButton from "../components/Common/CustomButton";

export const getDashboardSteps = ({ helpers }) => [
  {
    heading: "Welcome to fintwit.ai!",
    content: (
      <>
        <p>
          This guide will walk you through the key features of your dashboard.
          Here, you'll see a quick snapshot of the stock market performance,
          including top movers in your portfolio/watchlist.
        </p>
        <Box mt={4}>
          <CustomButton
            radius="20"
            btnPadding="10px 25px"
            onClick={helpers?.next}
          >
            Take a tour
          </CustomButton>
        </Box>
      </>
    ),
    hideFooter: true,
    placement: "center",
    target: "body",
    showProgress: false,
    hideBackButton: true,
  },
  {
    heading: "Select Sector",
    content: (
      <p>
        Tailor your dashboard view by selecting specific sector to gain insights
        into how stocks within those sectors are performing.
      </p>
    ),
    placement: "right",
    floaterProps: {
      autoOpen: true,
      disableAnimation: true,
    },
    target: "#filter-container #all-sector-filter",
    spotlightPadding: 1,
  },
  {
    heading: "Search",
    content: (
      <p>
        The search bar allows you to quickly find specific stocks, ETFs or
        Institutions.
      </p>
    ),
    target: "#search-bar",
  },
  {
    heading: "See Full List",
    content: <p>Click on the card header to see the full list of stocks.</p>,
    target: "#view-all-top-gainers",
  },
  {
    heading: "Account Settings",
    content: (
      <p>
        Manage your account settings, including subscription plan, alerts and
        notification preferences.
      </p>
    ),
    floaterProps: {
      autoOpen: true,
      disableAnimation: true,
    },
    target: "#account-info",
  },
  {
    heading: "AI Top Stocks (Premium+)",
    content: (
      <p>
        Uncover top stocks recommended by AI for potential upside and downside,
        guiding your long and short investment strategies.
      </p>
    ),
    floaterProps: {
      autoOpen: true,
      disableAnimation: true,
    },
    target: "#ai-top-picks",
    disableOverlay: false,
    spotlightPadding: 0,
  },
  {
    heading: "AI Top ETFs (Premium+)",
    content: (
      <p>
        Discover top-performing ETFs recommended by our AI, offering diversified
        exposure to specific market segments.
      </p>
    ),
    floaterProps: {
      autoOpen: true,
      disableAnimation: true,
    },
    data: {
      next: "/chat",
    },
    target: "#ai-top-etfs",
    disableOverlay: false,
    spotlightPadding: 0,
  },
  {
    heading: "AI Chat",
    content: (
      <p>Ask questions to get insight about a company or equity market.</p>
    ),
    target: "#query-input",
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
    data: {
      previous: "/home",
    },
  },
  {
    heading: "Chat Sources",
    content: (
      <p>
        Select any source or keep auto for Fintwit AI to pick the best source
        for your question.
      </p>
    ),
    locale: {
      last: "Done",
    },
    target: "#query-input-sources",
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
];

export const accountSteps = [
  {
    heading: "Update Plan",
    content: (
      <p>
        Click "Update Plan" to explore plans & unlock Advanced AI tools, Deeper
        market insights and Personalized recommendations.
      </p>
    ),
    placement: "right",
    floaterProps: {
      disableAnimation: true,
    },
    target: "#update-plan-button",
    disableBeacon: true,
    disableScrolling: true,
    spotlightPadding: 8,
  },
  {
    heading: "Notification",
    content: (
      <p>
        Control how you receive updates with easy toggles to manage your daily
        and weekly email notifications.
      </p>
    ),
    locale: {
      last: "Done",
    },
    target: "#notification",
    hideBackButton: true,
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
    disableScrolling: true,
  },
];

export const aiTopPicksSteps = [
  {
    heading: "AI Top Stocks",
    content: (
      <p>
        AI Top Stocks showcases stocks ranked based on their Upside or downside
        potential analyzed by advanced AI language models.
      </p>
    ),
    placement: "center",
    target: "body",
    showProgress: false,
    hideBackButton: true,
  },
  {
    heading: "Time Horizon",
    content: (
      <p>
        Select a time horizon to see AI top stocks for 1 Year, 6 Months, 3
        Months, or current predictions.
      </p>
    ),
    target: "#term-tabs",
    spotlightPadding: 5,
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    heading: "AI Top Stocks Filters",
    content: (
      <p>
        Fine-tune your search with specific criteria such as Market Cap, Sector,
        Price and Position type (long/short).
      </p>
    ),
    target: "#filter-container .filter-grid-container",
    spotlightPadding: 5,
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    heading: "Reset Filters",
    content: (
      <p>
        Start fresh by clicking 'Reset All' to clear your current filter
        selections. This won't affect your saved filters.
      </p>
    ),
    target: "#reset-all",
    hideStepBelow: 600,
    spotlightPadding: 5,
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    heading: "Save Filters",
    content: (
      <p>
        After setting your preferred filters, click 'Save Filters' to keep your
        custom search criteria for future sessions.
      </p>
    ),
    target: "#save-filters",
    spotlightPadding: 5,
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    heading: "Remove Filters",
    content: <p>Click 'Remove Filters' to discard saved filters.</p>,
    target: "#remove-filters",
    spotlightPadding: 4,
    disableBeacon: true,
    locale: {
      last: "Done",
    },
    floaterProps: {
      disableAnimation: true,
    },
  },
];

export const aiEtfPicksSteps = [
  {
    heading: "AI Top ETFs",
    content: (
      <p>
        AI Top ETFs showcases ETFs ranked based on their Upside or downside
        potential analyzed by advanced AI language models.
      </p>
    ),
    placement: "center",
    target: "body",
    showProgress: false,
    hideBackButton: true,
  },
  {
    heading: "Time Horizon",
    content: (
      <p>
        Select a time frame to view AI's top ETF recommendations for 1 Year, 6
        Months, or 3 Months.
      </p>
    ),
    target: "#term-tabs",
    spotlightPadding: 5,
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    heading: "AI Top ETFs Filters",
    content: (
      <p>
        Refine your search with specific criteria: Region, Asset Class and Focus
        Area.
      </p>
    ),
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    target: "#filter-container .filter-grid-container",
    spotlightPadding: 5,
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    heading: "Reset Filters",
    content: (
      <p>
        Click 'Reset All' to clear your current filter selections and return to
        the full list of AI-recommended ETFs across all regions and asset
        classes.
      </p>
    ),
    hideStepBelow: 600,
    target: "#reset-all",
    spotlightPadding: 5,
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    heading: "Save Filters",
    content: (
      <p>
        After setting your preferred filters, click 'Save Filters' to keep your
        custom search criteria for future sessions.
      </p>
    ),
    target: "#save-filters",
    spotlightPadding: 5,
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    heading: "Remove Filters",
    content: <p>Click 'Remove Filters' to discard saved filters.</p>,
    target: "#remove-filters",
    spotlightPadding: 5,
    disableBeacon: true,
    locale: {
      last: "Done",
    },
    floaterProps: {
      disableAnimation: true,
    },
  },
];

export const heatmapSteps = [
  {
    heading: "Heatmap",
    content: (
      <p>
        Use the Heatmap to gain an instant visual overview of current stock
        performance within each sector. Larger blocks represent a higher market
        cap, while colors range from green (performance gain) to red
        (performance loss).
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    placement: "center",
    target: "body",
  },
  {
    heading: "Heatmap Filters",
    content: (
      <p>
        Select a technical metric or AI rating from the drop down to see stock
        performance based on specific criteria.
      </p>
    ),
    locale: {
      last: "Done",
    },
    floaterProps: {
      disableAnimation: true,
    },
    target: "#heatmap-filters",
  },
];

export const screenerSteps = [
  {
    heading: "Screener",
    content: (
      <p>
        Use the screener to find stocks that meet your specific criteria. You
        can filter stocks based on various criteria. Each tab presents different
        metrics associated with the stock.
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    placement: "center",
    target: "body",
  },
  {
    heading: "Screener Filters",
    content: (
      <p>
        Tap on the "Filters" button to open the filter menu and refine your
        stock search with our 400+ filters.
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    target: "#screener-filters",
    spotlightPadding: 5,
  },
  {
    heading: "Screener AI Filters (PRO)",
    content: (
      <p>
        Tap on the "AI Signals" button to further refine your stock search by AI
        insights such as investment scores, market sentiment, price targets and
        more (Only available to PRO users).
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    target: "#screener-ai-filters",
    spotlightPadding: 5,
  },
  {
    heading: "Screener Export (PRO)",
    content: <p>Export your screener results into a CSV file.</p>,
    floaterProps: {
      disableAnimation: true,
    },
    target: "#screener-export",
    spotlightPadding: 5,
  },
  {
    heading: "Save Screens",
    content: (
      <p>
        Tap "Save Screen" to save your screening criteria for quick access to
        your preferred stock filters. Modify or delete your saved screens by
        selecting them from the drop-down menu and tapping the pencil or cross
        icon to edit their name or remove them entirely.
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    target: "#save-screen",
    spotlightPadding: 5,
  },
  {
    heading: "Screener Presets",
    content: (
      <p>
        Tap on 'Presets' to get started quickly with predefined filter sets that
        help you explore popular screening strategies.
      </p>
    ),
    locale: {
      last: "Done",
    },
    floaterProps: {
      disableAnimation: true,
    },
    target: "#screener-presets",
    spotlightPadding: 5,
  },
];

export const multiChartSteps = [
  {
    heading: "Multichart (PRO)",
    content: (
      <p>
        Compare and analyze multiple stocks simultaneously with our Multichart
        feature.
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    placement: "center",
    target: "body",
  },
  {
    heading: "Select Tickers",
    content: (
      <p>
        Add up to 10 tickers in the search bar to display their price movements
        and metrics across various timeframes on one screen.
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    target: ".tickers-autocomplete",
  },
  {
    heading: "Select Metrics",
    content: (
      <p>
        Select and compare different metrics for your chosen tickers by
        searching or browsing through our extensive metrics library.
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    target: ".metrics-autocomplete",
  },
  {
    heading: "Single Chart",
    content: (
      <p>
        View the collective performance of all selected tickers on a single
        chart to spot correlations and trends across multiple stocks.
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    target: "#single-chart",
    spotlightPadding: 5,
  },
  {
    heading: "Chart per Ticker",
    content: (
      <p>
        Opt for 'Show Chart Per Ticker' to isolate and view individual metric
        charts for each selected ticker.
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    target: "#charts-per-ticker",
    spotlightPadding: 4,
  },
  {
    heading: "Chart per Metrics",
    content: (
      <p>
        Use 'Show Chart Per Metrics' to compare the same metric across all your
        selected tickers in one unified chart, perfect for benchmarking and
        relative performance analysis.
      </p>
    ),
    locale: {
      last: "Done",
    },
    floaterProps: {
      disableAnimation: true,
    },
    target: "#charts-per-metrics",
    spotlightPadding: 4,
  },
];

export const stocksSteps = [
  {
    heading: "Stock Signals",
    content: (
      <p>
        Stock Signals gives you a rapid overview of the market's movement,
        providing quick access to today's top-performing stocks, most traded
        stocks, stocks showing unusual activities and much more.
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    placement: "center",
    target: "body",
  },
  {
    heading: "Stock Signal Filters",
    content: (
      <p>
        Refine your view by applying filters. Select from market cap ranges,
        sectors, or price brackets to focus on stocks that match your investment
        strategy.
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    target: "#filter-container .filter-grid-container",
  },
  {
    heading: "Reset Filters",
    content: (
      <p>
        Start fresh by clicking 'Reset All' to clear your current filter
        selections. This won't affect your saved filters.
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    target: "#reset-all",
  },
  {
    heading: "Save Filters",
    content: (
      <p>
        After setting your preferred filters, click 'Save Filters' to keep your
        custom search criteria for future sessions.
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    target: "#save-filters",
  },
  {
    heading: "Remove Filters",
    content: <p>Click 'Remove Filters' to discard saved filters.</p>,
    locale: {
      last: "Done",
    },
    floaterProps: {
      disableAnimation: true,
    },
    target: "#remove-filters",
  },
];

export const newsSteps = [
  {
    heading: "Filters",
    content: (
      <p>
        Filter news by the day's top financial stories with 'Trending News',
        companies reporting quarterly/annual results with "Recent Earnings",
        sector specific news, or news from portfolio or watchlist stocks.
      </p>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    locale: {
      last: "Done",
    },
    target: "#filter-container #news-filter",
    disableBeacon: true,
  },
];

export const aiChatSteps = [
  {
    heading: "AI Chat",
    content: (
      <p>Ask questions to get insight about a company or equity market.</p>
    ),
    target: "#query-input",
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    heading: "Chat Sources",
    content: (
      <p>
        Select any source or keep auto for Fintwit AI to pick the best source
        for your question.
      </p>
    ),
    target: "#query-input-sources",
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
];
