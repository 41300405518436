import * as actions from "../../redux/actions";
import {
  isLoggedIn,
  loggedOutUser,
  setLoggedUser,
} from "../../utils/authentication";
import { getPost } from "../api/authApis";
import { LoaderAction } from "../loader/action";
import constants from "../constants";
import { resetRedirectUrl } from "../redirectTo/action";

export const signInSuccessAction = (payload) => {
  return async (dispatch) => {
    dispatch(actions.signInSuccess(payload));
  };
};

export const signInClearAction = (type) => {
  return async (dispatch) => {
    loggedOutUser();
    dispatch(actions.signInClear({}));
    dispatch(actions.loaderStatusChange(false));
    dispatch(resetRedirectUrl());
    if (type) {
      dispatch(
        actions.toastMessage({
          status: true,
          message: `Your session has expired please try to sign in again`,
          type: "warning",
        })
      );
    }
    dispatch(actions.logoutUser());
  };
};
export const landingSessionOutAction = () => {
  return async (dispatch) => {
    loggedOutUser();
    dispatch(actions.signInClear({}));
    dispatch(actions.loaderStatusChange(false));
    dispatch(actions.logoutUser());
  };
};

export const updateSubscriptionStatusAction = (payload) => {
  const getUserData = isLoggedIn();
  const updateData = {
    ...getUserData,
    userInfo: {
      ...getUserData.userInfo,
      subscriptionStatus: payload.status,
    },
  };
  setLoggedUser(updateData);
  return async (dispatch) => {
    dispatch(actions.updateSubscription(payload));
  };
};

export const getUserInformation = ({ hideLoader } = {}) => {
  return async (dispatch) => {
    if (!hideLoader) dispatch(LoaderAction(true));
    const getUserData = isLoggedIn();
    try {
      const res = await getPost(getUserData.idToken);
      if (res.status === 200) {
        const userDetails = res && res.data && res.data[0];
        const updateData = {
          ...getUserData,
          userInfo: userDetails,
        };
        if (userDetails?.username) {
          dispatch(actions.signInSuccess(updateData));
          setLoggedUser(updateData);
          dispatch(LoaderAction(false));
        } else {
          dispatch(LoaderAction(false));
        }
      } else {
        dispatch(LoaderAction(false));
      }
    } catch (error) {
      dispatch(LoaderAction(false));
    }
  };
};
export const getSubscriptionStatus = (data) => {
  return (dispatch) => {
    dispatch({
      type: constants.SUBSCRIBE_STATUS,
      payload: data,
    });
  };
};

export const getAboutStatus = (data, type) => {
  return (dispatch) => {
    dispatch({
      type: constants.ABOUT_STATUS,
      payload: [data, type],
    });
  };
};

export const stockTabStatus = (data) => {
  return (dispatch) => {
    dispatch({
      type: constants.STOCK_TAB_CHANGES,
      payload: data,
    });
  };
};

export const getFeaturesStatus = (data, type) => {
  return (dispatch) => {
    dispatch({
      type: constants.FEATURES_STATUS,
      payload: [data, type],
    });
  };
};

export const getPlansStatus = (data, type) => {
  return (dispatch) => {
    dispatch({
      type: constants.PLANS_STATUS,
      payload: [data, type],
    });
  };
};

export const hideInAppGuide = () => ({
  type: constants.HIDE_IN_APP_GUIDE,
});

export const markNotificationsAsRead = () => ({
  type: constants.MARK_NOTIFICATIONS_AS_READ,
});
