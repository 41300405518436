import { Button, Grid, TextField, makeStyles } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as AlertDiagonalIcon } from "../../assets/images/alert-hexagon.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/arrow-down-short-wide.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/images/arrow-up-short-wide.svg";
import { ReactComponent as HelpOffIcon } from "../../assets/images/help-off.svg";
import { ReactComponent as EmptyHourGlassIcon } from "../../assets/images/hourglass-empty.svg";
import { ReactComponent as OctagonalXIcon } from "../../assets/images/octagonal-x.svg";
import { toastMessage } from "../../redux/actions";
import { LoaderAction } from "../../redux/loader/action";
import Modal, { DialogActions, DialogContent } from "../../shared/Modal";

const useStyles = makeStyles(() => ({
  reportCauseButton: {
    "&.MuiButton-root": {
      textTransform: "unset",
      justifyContent: "flex-start",
      "& svg": {
        height: 20,
        width: 20,
      },
      "& .MuiButton-endIcon": {
        marginLeft: "auto",
      },
    },
  },
  dialogContent: {
    paddingBottom: 30,
  },
  input: {
    marginTop: 40,
  },
  submitButton: {
    "&:not(:disabled)": {
      color: "#fff",
    },
  },
}));

const feedbackLabels = [
  {
    name: "Answer is inaccurate",
    icon: OctagonalXIcon,
  },
  {
    name: "Not recent",
    icon: EmptyHourGlassIcon,
  },
  {
    name: "Answer is too short",
    icon: ArrowDownIcon,
  },
  {
    name: "Answer is too long",
    icon: ArrowUpIcon,
  },
  {
    name: "Harmful or offensive",
    icon: AlertDiagonalIcon,
  },
  {
    name: "Not helpful",
    icon: HelpOffIcon,
  },
];

const ReportModal = ({ open, onClose, onSuccess, queryId }) => {
  const [feedback, setFeedback] = useState({
    label: "",
    reason: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { idToken } = useSelector((state) => state.signIn.loggedUser);

  const handleSubmit = async () => {
    if (!feedback.label && !feedback.reason) return;

    try {
      setIsLoading(true);
      dispatch(LoaderAction(true));
      await axios.post(
        process.env.REACT_APP_CHAT_API_URL,
        {
          method: "report",
          queryId,
          ...feedback,
        },
        {
          headers: {
            Authorization: idToken,
          },
        }
      );
      onSuccess();
      onClose();
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: "Could not report query. Please try again later.",
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
      dispatch(LoaderAction(false));
    }
  };

  return (
    <Modal
      title="Report"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { width: "100%", maxWidth: 500 },
      }}
    >
      <DialogContent dividers className={classes.dialogContent}>
        <Grid container spacing={1}>
          {feedbackLabels.map(({ name, icon: Icon }) => (
            <Grid key={name} item xs={12} sm={6}>
              <Button
                variant="outlined"
                fullWidth
                size="large"
                className={classes.reportCauseButton}
                startIcon={<Icon />}
                endIcon={
                  feedback.label === name ? (
                    <CheckCircleIcon style={{ marginLeft: "auto" }} />
                  ) : null
                }
                color={feedback.label === name ? "primary" : "default"}
                onClick={() =>
                  setFeedback((prev) => ({ ...prev, label: name }))
                }
              >
                {name}
              </Button>
            </Grid>
          ))}
        </Grid>
        <TextField
          label="How can the response be improved?"
          fullWidth
          variant="outlined"
          placeholder="Your feedback..."
          className={classes.input}
          InputLabelProps={{
            shrink: true,
          }}
          value={feedback.reason}
          onChange={(e) =>
            setFeedback((prev) => ({ ...prev, reason: e.target.value }))
          }
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disableElevation onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={handleSubmit}
          disabled={isLoading || (!feedback.label && !feedback.reason)}
          className={classes.submitButton}
        >
          Submit
        </Button>
      </DialogActions>
    </Modal>
  );
};

export default ReportModal;
