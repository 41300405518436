import React, { useState, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import {
  Typography,
  makeStyles,
  FormGroup,
  Grid,
  useMediaQuery,
  useTheme,
  MenuItem,
} from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import CustomTextField from "../Common/CustomTextField";
import CustomButton from "../Common/CustomButton";
import { LoaderAction } from "../../redux/loader/action";
import logo from "../../assets/images/logo-dark.png";
import bannerImage from "../../assets/images/banner-bg.png";
import googleLogo from "../../assets/images/google_logo.png";
import SetHelmet from "../Common/SetHelmet";
import { signInSuccess, toastMessage } from "../../redux/actions";
import { getPost, userPost } from "../../redux/api/authApis";
import { setLoggedUser } from "../../utils/authentication";
import { addStripeCustomerApi } from "../../redux/api/stripeApi";
import ReactGA from "react-ga4";
import ReCaptcha from "../Common/ReCaptcha";
import CustomSelect from "../Common/CustomSelect";
import { DashboardStyle } from "../Dashboard/DashboardStyle";

export const useStyles = makeStyles((theme) => ({
  loginSignupWrap: {
    minHeight: "calc(100vh - 54px)",
    overflowY: "auto",
    backgroundColor: "#F8F8F8",
    backgroundImage: `url(${bannerImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom center",
    backgroundSize: "content",
    padding: [[30, 15]],
    display: "flex",
    alignItems: "center",
  },
  loginSignupBox: {
    maxWidth: 600,
    backgroundColor: "#fff",
    boxShadow: "6px 4px 20px 3px rgba(0, 0, 0, 0.1)",
    margin: "0px auto",
    display: "table",
    borderRadius: 10,
    padding: [[30, 90]],
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: [[40]],
    },
    [theme.breakpoints.down("xs")]: {
      padding: [[20]],
    },
    [theme.breakpoints.down("360")]: {
      display: "block",
    },
    "& .MuiFormGroup-root": {
      marginBottom: 15,
      "& p": {
        marginTop: 0,
      },
    },
    "& .MuiInputBase-input": {
      padding: [[5, 15]],
      height: 26,
      lineHeight: "26px",
      "&::placeholder": {
        color: "#999",
        opacity: 1,
      },
    },
  },
  logo: {
    textAlign: "center",
    display: "table",
    margin: [[0, "auto"]],
    maxWidth: 170,
  },
  title: {
    textAlign: "center",
    margin: "25px auto 30px",
    fontSize: 30,
    fontWeight: 700,
    padding: "0 20px 8px",
    position: "relative",
    display: "table",
    [theme.breakpoints.down("sm")]: {
      padding: "0 12px 8px",
    },
    "& .MuiDialogTitle-root": {
      padding: 0,
      "& h2": {
        fontWeight: "bold",
        fontSize: 22,
        [theme.breakpoints.down("xs")]: {
          fontSize: 17,
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
      margin: "20px auto 20px",
    },
    "&:before": {
      content: '""',
      position: "absolute",
      height: 3,
      width: 150,
      backgroundColor: theme.palette.primary.main,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "0 auto",
    },
  },
  loginSignupButton: {
    "& button": {
      "& img": {
        marginLeft: 10,
        height: 22,
      },
    },
  },
  loginSignupBottom: {
    marginTop: 10,
    textAlign: "center",
    "& button": {
      "& img": {
        marginLeft: 10,
      },
    },
    "& .MuiFormGroup-root": {
      marginBottom: 15,
    },
    "& p": {
      fontSize: 16,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
      "& a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
        fontWeight: "bold",
      },
    },
  },
  forgotLink: {
    color: "#000",
    textDecoration: "none",
    textAlign: "right",
    display: "block",
    marginBottom: 40,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  registerText: {
    textTransform: "capitalize",
    textAlign: "center",
    fontSize: "16px",
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    "& a": {
      marginLeft: 5,
    },
  },
  gRecaptchaIframe: {
    margin: "0 auto",
    position: "relative",
    transform: "scale(0.8)",
    [theme.breakpoints.down("380")]: {
      transform: "scale(0.8) translateX(-62%)",
      left: "50%",
    },
  },
  signUpModel: {
    position: "static",
    "& .MuiPaper-root": {
      padding: [[30]],
      borderRadius: 10,
      margin: 15,
      [theme.breakpoints.down("sm")]: {
        padding: 20,
      },
    },
  },
  closeIcon: {
    position: "absolute",
    right: "20px",
    top: "18px",
    cursor: "pointer",
  },
  metricsModelCloseIcon: {
    position: "absolute",
    right: "12px",
    top: "8px",
    cursor: "pointer",
  },
  metricsCatagory: {
    width: "38%",
    marginRight: 20,
    paddingRight: 20,
    borderRight: "1px solid #c2c2c2",
    [theme.breakpoints.down("600")]: {
      width: "100%",
      marginRight: 0,
      paddingRight: 0,
      border: 0,
      marginBottom: 20,
    },
    "& p.MuiTypography-root": {
      fontSize: 18,
      fontWeight: 500,
      display: "inline-block",
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      marginBottom: 15,
    },
    "& ul": {
      listStyle: "none",
      padding: 0,
      margin: 0,
      "& li": {
        textAlign: "left",
        fontSize: 16,
        fontWeight: 500,
        cursor: "pointer",
        padding: [[4, 4, 4, 8]],
        "&:hover": {
          background: "rgba(0,0,0,0.08)",
        },
      },
    },
  },
  metricsTypes: {
    display: "flex",
    height: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("600")]: {
      flexWrap: "wrap",
    },
  },
  metricsMetric: {
    width: "58%",
    [theme.breakpoints.down("600")]: {
      width: "100%",
      paddingLeft: 7,
    },
    "& p.MuiTypography-root": {
      fontSize: 18,
      fontWeight: 500,
      display: "inline-block",
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      marginBottom: 15,
    },
    "& ul": {
      listStyle: "none",
      padding: 0,
      margin: 0,
      "& .MuiFormGroup-root": {
        marginBottom: 0,
        "& label": {
          textAlign: "left",
          alignItems: "start",
          marginBottom: 12,
          "& span.MuiTypography-root": {
            [theme.breakpoints.down("600")]: {
              fontSize: 15,
            },
          },
          "& .MuiButtonBase-root": {
            padding: [[0, 10]],
          },
        },
      },
    },
  },
  metricsSearchListMain: {
    position: "relative",
    flexGrow: 1,
    [theme.breakpoints.down("600")]: {
      marginBottom: 10,
      width: "100%",
    },
    "& svg": {
      position: "absolute",
      top: "50%",
      left: 12,
      transform: "translateY(-50%)",
      "& path": {
        fill: "#898989",
      },
    },
    "& .MuiFormControl-root": {
      "& input": {
        paddingLeft: 38,
      },
    },
  },
  metricsSearchList: {
    position: "absolute",
    right: 0,
    left: 0,
    padding: [[8, 0]],
    top: "100%",
    backgroundColor: "#fff",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
    marginTop: 5,
    overflowY: "auto",
    maxHeight: 200,
    zIndex: 3,
  },
  searchSubTitle: {
    padding: [[4, 15]],
    fontSize: 16,
    cursor: "pointer",
    "&:hover": {
      background: "rgba(0,0,0,0.08)",
    },
  },
  resetButton: {
    "&.MuiButtonBase-root.MuiButton-root": {
      backgroundColor: "transparent",
      border: "none",
      "&:hover": {
        background: "transparent",
      },
      "& .MuiButton-label": {
        color: "#000",
        "& img": {
          width: 17,
          marginRight: 8,
        },
      },
    },
  },

  matricsModel: {
    "& .MuiPaper-root": {
      maxWidth: 800,
      width: "100%",
      paddingTop: 40,
      [theme.breakpoints.up("600")]: {
        minHeight: 672,
      },
      // overflowY:'hidden'
    },
  },
  metricsFormgroup: {
    overflowY: "auto",
    maxHeight: 500,
    [theme.breakpoints.down("600")]: {
      maxHeight: 200,
    },
  },
  metricsModelSearch: {
    display: "flex",
    [theme.breakpoints.down("600")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  metricsCardBox: {
    [theme.breakpoints.up("600")]: {
      height: 535,
    },
    "& > div": {
      paddingRight: 0,
      height: "100%",
    },
  },
}));

const aboutUsList = [
  "Google",
  "Twitter",
  "LinkedIn",
  "Facebook",
  "Podcast",
  "Youtube",
  "TikTok",
  "Instagram",
  "Friends",
  "Other",
];

const jobFunctionList = [
  "Investment Professional",
  "Personal Investor",
  "Day Trader",
  "Banker/Hedge Fund",
  "New to Investment",
];

const initialText = {
  Other: "Please share the source",
  Podcast: "Please share the podcast name",
  Youtube: "Please share the youtube video/channel",
};
const initialState = {
  name: "",
  email: "",
  // confirmEmail: "",
  password: "",
  // confirmPassword: "",
  aboutUs: "",
  // other: "",
  jobFunction: "",
};

const SignUp = () => {
  let history = useHistory();
  const classes = useStyles();
  const arrowClasses = DashboardStyle();
  const dispatch = useDispatch();
  const [otherValue, setOtherValue] = useState("");
  const [otherError, setOtherError] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [formError, setErrors] = useState(initialState);
  const [errorMessage, setErrorMsg] = useState("");
  const [capchaErr, setCapchaErr] = useState(false);
  const [showCapchaErr, setShowCapchaErr] = useState("");
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const { redirectUrl } = useSelector((state) => state.redirectTo);

  React.useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (event) => {
    event.persist();
    event.preventDefault();
    const { name, value } = event.target;
    if (name !== "other") {
      setFormData((formData) => ({
        ...formData,
        [name]: value,
      }));

      setErrors((formError) => ({
        ...formError,
        [name]: formValidate(name, value),
      }));
    } else {
      setOtherValue(value);
      setOtherError(false);
    }
  };
  React.useEffect(() => {
    if (formData.password || formData.email) {
      setErrors((formError) => ({
        ...formError,
        password: formValidate("password", formData.password),
        email: formValidate("email", formData.email),
      }));
    }
    /* if (formData.confirmPassword || formData.confirmEmail) {
      setErrors((formError) => ({
        ...formError,
        confirmPassword: formValidate(
          "confirmPassword",
          formData.confirmPassword
        ),
        confirmEmail: formValidate("confirmEmail", formData.confirmEmail),
      }));
    } */
  }, [formData]);

  const formValidate = (name, value) => {
    switch (name) {
      case "name":
        if (!value || value.trim() === "") {
          return "Name is required";
        } else {
          return "";
        }
      case "jobFunction":
        if (value?.length === 0) {
          return "Please select";
        } else {
          return "";
        }
      case "aboutUs":
        if (value?.length === 0) {
          return "Please select";
        } else {
          return "";
        }
      case "email":
        if (!value || value.trim() === "") {
          return "Email is required";
        } else if (
          !value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/)
          // !value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
        ) {
          return "Enter a valid email address";
        } else {
          return "";
        }
      case "confirmEmail":
        if (!value) {
          return "Confirm Email Required";
        } else if (value !== formData.email) {
          return "Email and Confirm Email Must be Same";
        } else {
          return "";
        }
      case "password":
        if (!value) {
          return "Password is Required";
        } else if (value.length < 8 || value.length > 15) {
          return "Please fill at least 8 character";
        } else if (!value.match(/[a-z]/g)) {
          return "Please enter at least lower character.";
        } else if (!value.match(/[A-Z]/g)) {
          return "Please enter at least upper character.";
        } else if (!value.match(/[0-9]/g)) {
          return "Please enter at least one digit.";
        } else {
          return "";
        }
      case "confirmPassword":
        if (!value) {
          return "Confirm Password Required";
        } else if (value !== formData.password) {
          return "New Password and Confirm Password Must be Same";
        } else {
          return "";
        }
      default: {
        return "";
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSignUp();
    }
  };

  const handleSignUp = async () => {
    let validationErrors = {};

    /*  if (formData?.aboutUs === "Other" && otherValue?.length === 0) {
      setOtherError(true);
    } */

    Object.keys(formData).forEach((name) => {
      const error = formValidate(name, formData[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else if (
      (formData?.aboutUs === "Other" && otherValue?.length === 0) ||
      (formData?.aboutUs === "Podcast" && otherValue?.length === 0) ||
      (formData?.aboutUs === "Youtube" && otherValue?.length === 0)
    ) {
      setOtherError(true);
      return;
    } else {
      dispatch(LoaderAction(true));
    }

    if (capchaErr === false) {
      setShowCapchaErr("ReCapcha is Required!!");
      return;
    }

    setErrors({});
    setErrorMsg("");
    try {
      await Auth.signUp({
        username: formData.email,
        password: formData.password,
        attributes: {
          name: formData.name,
          "custom:usersource":
            formData?.aboutUs === "Other"
              ? `Other-${otherValue}`
              : formData?.aboutUs === "Podcast"
              ? `Podcast-${otherValue}`
              : formData?.aboutUs === "Youtube"
              ? `Youtube-${otherValue}`
              : formData?.aboutUs,
          "custom:userrole": formData?.jobFunction,
        },
      });
      dispatch(LoaderAction(true));
      handleSignIn(formData.email, formData.password);
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: error.message || "",
          type: "error",
        })
      );
      dispatch(LoaderAction(false));
    }
  };

  const handleSignIn = async (email, password) => {
    dispatch(LoaderAction(true));
    try {
      const result = await Auth.signIn(email, password);
      const { accessToken, idToken, refreshToken } = result.signInUserSession;
      const token = {
        accessToken: accessToken && accessToken.jwtToken,
        idToken: idToken && idToken.jwtToken,
        refresh: refreshToken && refreshToken.token,
      };
      try {
        dispatch(LoaderAction(true));
        const res = await userPost(token.idToken);
        if (res.status === 200) {
          setUserPostData(token);
        }
      } catch (error) {
        const { data, body } = error && error.response;
        dispatch(LoaderAction(false));
        if (data.statusCode === 500) {
          setUserPostData(token);
        } else {
          dispatch(
            toastMessage({
              status: true,
              message: body || "Something went wrong please try again!",
              type: "error",
            })
          );
        }
      }
    } catch (error) {
      dispatch(LoaderAction(false));
      dispatch(
        toastMessage({
          status: true,
          message: error.message || "Something went wrong please try again!",
          type: "error",
        })
      );
    }
  };

  const addCustomer = async (token) => {
    dispatch(LoaderAction(true));
    try {
      await addStripeCustomerApi(token);
      dispatch(LoaderAction(false));
    } catch (error) {
      dispatch(LoaderAction(false));
    }
  };

  const setUserPostData = async (token) => {
    dispatch(LoaderAction(true));
    try {
      const res = await getPost(token.idToken);
      const userDetails = res && res.data && res.data[0];
      token.userInfo = userDetails;
      if (userDetails?.username) {
        dispatch(LoaderAction(false));
        setLoggedUser(token);
        dispatch(signInSuccess(token));
        history.push(redirectUrl);
      }
    } catch (error) {
      const { body } = error && error.response;
      dispatch(LoaderAction(false));
      dispatch(
        toastMessage({
          status: true,
          message: body || "Something went wrong please try again!",
          type: "error",
        })
      );
    }
  };

  const onChange = (e) => {
    setCapchaErr(true);
    setShowCapchaErr("");
  };

  return (
    <Fragment>
      <SetHelmet title="Fintwit - Sign Up" />
      <div className={classes.loginSignupWrap}>
        <div className={classes.loginSignupBox}>
          <Link to="/" variant="h6" className={classes.logo}>
            <img src={logo} alt="logo" />
          </Link>
          <Typography variant="h5" component="h5" className={classes.title}>
            <span>Create </span> An Account
          </Typography>
          <FormGroup>
            <CustomTextField
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Name"
              variant="outlined"
            />
            {formError.name && (
              <Typography variant="body2" component="p" color="error">
                {formError.name}
              </Typography>
            )}
          </FormGroup>
          <FormGroup>
            <CustomTextField
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
              variant="outlined"
            />
            {formError.email && (
              <Typography variant="body2" component="p" color="error">
                {formError.email}
              </Typography>
            )}
          </FormGroup>
          {/*  <FormGroup>
            <CustomTextField
              fullWidth
              name="confirmEmail"
              value={formData.confirmEmail}
              onChange={handleInputChange}
              onCopy={handleInputChange}
              onPaste={handleInputChange}
              placeholder="Confirm Email"
              variant="outlined"
            />
            {formError.confirmEmail && (
              <Typography variant="body2" component="p" color="error">
                {formError.confirmEmail}
              </Typography>
            )}
          </FormGroup> */}
          <FormGroup>
            <CustomTextField
              fullWidth
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Password"
              variant="outlined"
            />
            {formError.password && (
              <Typography variant="body2" component="p" color="error">
                {formError.password}
              </Typography>
            )}
          </FormGroup>
          {/* <FormGroup>
            <CustomTextField
              fullWidth
              name="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              placeholder="Confirm Password"
              variant="outlined"
              onCopy={handleInputChange}
              onPaste={handleInputChange}
            />
            {formError.confirmPassword && (
              <Typography variant="body2" component="p" color="error">
                {formError.confirmPassword}
              </Typography>
            )}
          </FormGroup> */}
          <FormGroup>
            {" "}
            <p>Job Function</p>
            <CustomSelect
              fullWidth
              displayEmpty
              variant="outlined"
              MenuProps={{
                className: classes.frameSelect,
              }}
              value={formData?.jobFunction}
              IconComponent={() => (
                <ExpandMoreRoundedIcon className={arrowClasses.selectArrow} />
              )}
              name="jobFunction"
              onChange={handleInputChange}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              {jobFunctionList.map((item, ind) => {
                return (
                  <MenuItem value={item} key={ind}>
                    {item}
                  </MenuItem>
                );
              })}
            </CustomSelect>
            {formError.jobFunction && (
              <Typography variant="body2" component="p" color="error">
                {formError.jobFunction}
              </Typography>
            )}
          </FormGroup>
          <FormGroup>
            <p>How did you hear about us?</p>
            <CustomSelect
              fullWidth
              displayEmpty
              variant="outlined"
              MenuProps={{
                className: classes.frameSelect,
              }}
              value={formData?.aboutUs}
              IconComponent={() => (
                <ExpandMoreRoundedIcon className={arrowClasses.selectArrow} />
              )}
              name="aboutUs"
              onChange={handleInputChange}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              {aboutUsList.map((item, ind) => {
                return (
                  <MenuItem value={item} key={ind}>
                    {item}
                  </MenuItem>
                );
              })}
            </CustomSelect>
            {formError.aboutUs && (
              <Typography variant="body2" component="p" color="error">
                {formError.aboutUs}
              </Typography>
            )}
          </FormGroup>

          {(formData?.aboutUs === "Other" ||
            formData?.aboutUs === "Podcast" ||
            formData?.aboutUs === "Youtube") && (
            <FormGroup>
              <CustomTextField
                fullWidth
                name="other"
                value={otherValue}
                onChange={handleInputChange}
                placeholder={initialText?.[formData?.aboutUs]}
                variant="outlined"
              />
              {otherError && (
                <Typography variant="body2" component="p" color="error">
                  Please mention
                </Typography>
              )}
            </FormGroup>
          )}

          <FormGroup>
            <ReCaptcha
              onChange={onChange}
              className={classes.gRecaptchaIframe}
            />
            <Typography variant="body2" component="p" color="error">
              {showCapchaErr}
            </Typography>
          </FormGroup>
          <div className={classes.loginSignupButton}>
            <Grid container spacing={xs ? 0 : 1}>
              <Grid item sm={6} xs={12}>
                <FormGroup>
                  <CustomButton
                    onClick={handleSignUp}
                    radius="50"
                    btnPadding="12px"
                    variant="contained"
                  >
                    Sign Up for Free
                  </CustomButton>
                  <Typography variant="body2" component="p" color="error">
                    {errorMessage}
                  </Typography>
                </FormGroup>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormGroup>
                  <CustomButton
                    bgColor="#fff"
                    bgHoverColor="#eee"
                    radius="50"
                    fontColor="#000000"
                    btnPadding="7px"
                    borderColor="#ACACAC"
                    variant="contained"
                    onClick={() => {
                      localStorage.setItem("ignoreHomeDeepLinking", "true");
                      Auth.federatedSignIn({ provider: "Google" });
                      localStorage.setItem("type", "sign-up");
                    }}
                  >
                    Sign Up with Google
                    <img src={googleLogo} alt="googleLogo" />
                  </CustomButton>
                </FormGroup>
              </Grid>
            </Grid>
          </div>
          <div className={classes.loginSignupBottom}>
            <Typography variant="body1" component="p">
              By Signing up, you agree to the{" "}
              <Link to="/term-and-condition">Terms of Service</Link> and{" "}
              <Link to="/privacy-policy">Privacy Policy</Link>.
            </Typography>
            <Typography
              className={classes.registerText}
              variant="body1"
              component="p"
            >
              Already have an account ? <Link to="/sign-in">Sign in Here</Link>
            </Typography>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(SignUp);
