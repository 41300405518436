import { Box, Divider, Typography, makeStyles } from "@material-ui/core";
import React, { Fragment } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as BarsIcon } from "../../assets/images/bars.svg";

const useStyles = makeStyles((theme) => ({
  query: {
    textDecoration: "none",
    color: "#000",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "& .MuiTypography-root": {
      fontSize: 20,
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      gap: 12,
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
      "& svg": {
        height: 22,
        width: 20,
        marginTop: 4,
        flexShrink: 0,
        [theme.breakpoints.down("sm")]: {
          height: 22,
          width: 20,
        },
      },
    },
  },
  snippet: {
    fontSize: 17,
    color: "#222",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
    },
  },
  sectionHeading: {
    fontSize: 28,
    fontWeight: 700,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 26,
      marginBottom: 12,
    },
  },
}));

const PublicChatList = ({ chats }) => {
  const classes = useStyles();

  return (
    <Box mt={14}>
      <Typography variant="h3" className={classes.sectionHeading}>
        Recent chats
      </Typography>
      <Divider />
      <Box>
        {chats?.map(({ chatId, chatName, snippet }) => (
          <Fragment key={chatId}>
            <Box py={2}>
              <Link to={`/chat/${chatId}`} className={classes.query}>
                <Typography variant="h4" gutterBottom={!!snippet}>
                  <BarsIcon /> {chatName}
                </Typography>
              </Link>
              {snippet && (
                <Typography className={classes.snippet}>{snippet}</Typography>
              )}
            </Box>
            <Divider />
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default PublicChatList;
