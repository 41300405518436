import {
  ETF_FILTER_OPTIONS_ERROR,
  ETF_FILTER_OPTIONS_LOADING,
  ETF_FILTER_OPTIONS_SUCCESS,
} from "./constants";

const initialState = {
  filterOptions: null,
  isLoadingFilterOptions: false,
  filterOptionsError: null,
};

const etfReducer = (state = initialState, action) => {
  switch (action.type) {
    case ETF_FILTER_OPTIONS_LOADING:
      return {
        ...state,
        isLoadingFilterOptions: true,
      };
    case ETF_FILTER_OPTIONS_SUCCESS:
      return {
        ...state,
        filterOptions: action.payload,
        isLoadingFilterOptions: false,
      };
    case ETF_FILTER_OPTIONS_ERROR:
      return {
        ...state,
        filterOptionsError: action.payload,
        isLoadingFilterOptions: false,
      };

    default:
      return state;
  }
};

export default etfReducer;
