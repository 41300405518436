import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { RightArrowFilled } from "../Icons";
import CustomButton from "./CustomButton";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiButton-endIcon": {
      marginLeft: 12,
      marginRight: 12,
      position: "relative",
      "& svg": {
        position: "absolute",
        inset: 0,
        marginTop: "auto",
        marginBottom: "auto",
        "& path": {
          fill: "currentColor",
        },
      },
    },
  },
}));

const ButtonWithArrow = ({ className, children, ...props }) => {
  const classes = useStyles();

  return (
    <CustomButton
      className={classNames(className, classes.root)}
      {...props}
      endIcon={<RightArrowFilled />}
    >
      {children}
    </CustomButton>
  );
};

export default ButtonWithArrow;
