import { Box, MenuItem, Typography, makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toastMessage } from "../../redux/actions";
import { stripeBillingRedirect } from "../../redux/api/stripeApi";
import { getSubscriptionStatus } from "../../redux/auth/action";
import { LoaderAction } from "../../redux/loader/action";
import { isProPlanSubscription } from "../../utils/common";
import {
  alertTypes,
  dummyAlertSettings,
} from "../AlertSettings/alertSettings.description";
import CardBox from "../Common/CardBox";
import CustomSelect from "../Common/CustomSelect";
import Loader from "../Common/Loader";
import TickerSearchSelect from "../Common/TickerSearchSelect";
import Reserved from "../Reserved";
import NotificationsTable from "./NotificationsTable";

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: 30,
    fontWeight: 700,
  },
  filter: {
    width: 200,
    "& .MuiSelect-root": {
      paddingTop: 9,
      paddingBottom: 9,
    },
  },
}));

const ROWS_PER_PAGE = 10;

const Notifications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState();
  const [sortConfig, setSortConfig] = useState({});
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { idToken, userInfo } = useSelector((state) => state.signIn.loggedUser);

  const start = page === 0 ? 0 : page * ROWS_PER_PAGE;

  const handlePageChange = (page) => {
    setPage(page);

    const start = page === 0 ? 0 : page * ROWS_PER_PAGE;
    getNotifications({ start });
  };

  const handleFilterChange = ({ name, value }) => {
    const filterValue =
      name === "alertType" ? (value === "all" ? "" : value) : value;
    const appliedFilters = { ...filters, [name]: filterValue };

    setFilters(appliedFilters);
    getNotifications(appliedFilters);
  };

  const getNotifications = async (config) => {
    const url = `${process.env.REACT_APP_API_SERVER_URL}/user`;

    try {
      setIsLoading(true);
      const { data } = await axios.post(
        url,
        {
          method: "alert",
          start,
          limit: ROWS_PER_PAGE,
          ...sortConfig,
          ...filters,
          ...config,
        },
        {
          headers: {
            Authorization: idToken,
          },
        }
      );
      setNotifications(data);
      const unreadNotifications = data?.filter(({ isRead }) => isRead === 0);
      if (unreadNotifications.length) {
        markNotificationsAsRead(unreadNotifications?.map(({ id }) => id));
      }
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: "Could not fetch notifications. Please try again later.",
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const markNotificationsAsRead = async (ids) => {
    const url = `${process.env.REACT_APP_API_SERVER_URL}/user`;

    try {
      await axios.put(
        url,
        {
          method: "updateAlert",
          id: ids || [],
        },
        {
          headers: {
            Authorization: idToken,
          },
        }
      );
    } catch (error) {}
  };

  const onSort = (config) => {
    setSortConfig(config);
    getNotifications(config);
  };

  const handleBilling = async () => {
    dispatch(LoaderAction(true));
    const response = await stripeBillingRedirect(idToken, {
      customerId: userInfo?.stripeCustomerId,
    });
    if (response.status === 201) {
      window.location.href = response?.data?.data?.url;
      dispatch(LoaderAction(false));
    } else {
      dispatch(LoaderAction(false));
    }
  };

  const subscriptionStatusResult = () => {
    if (
      userInfo?.isTrialAvailable === 1 &&
      userInfo?.subscriptionStatus === "FREE"
    ) {
      dispatch(getSubscriptionStatus(true));
      history.push("/subscription");
      return;
    }

    handleBilling();
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <Loader isLoad={isLoading} />
      <CardBox>
        <Typography component="h5" variant="h5" className={classes.heading}>
          Notifications
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 16,
          }}
        >
          <CustomSelect
            name="alertType"
            value={filters.alertType || "all"}
            onChange={(e) => handleFilterChange(e.target)}
            className={classes.filter}
            size="small"
            style={{ marginLeft: "auto" }}
          >
            <MenuItem value="all">All</MenuItem>
            {alertTypes.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </CustomSelect>
          <TickerSearchSelect
            className={classes.filter}
            value={filters.value ? { value: filters.value } : null}
            onChange={(selectedOption) => {
              handleFilterChange({
                name: "value",
                value: selectedOption?.value,
              });
            }}
            isClearable={!!filters?.value}
          />
        </Box>
        <Box position="relative" mt={1}>
          <NotificationsTable
            data={notifications}
            sortConfig={sortConfig}
            onSort={onSort}
            page={page}
            rowsPerPage={ROWS_PER_PAGE}
            onPageChange={handlePageChange}
          />
        </Box>
      </CardBox>
    </>
  );
};

export default Notifications;
