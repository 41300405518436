import constants from "../constants";

const initialState = {
  isLandingBanner: true,
  isSuccess: false,
  watchList: [],
  negative: [],
  positive: [],
  topGainers: [],
  topLosers: [],
  sectorList: [],
  tweets: [],
  investors: [],
  influencer: [],
  list: {},
  type: "",
  news: [],
  recentTweets: [],
  insider: [],
  insiderAggTable: [],
  insiderChart: [],
  transcript: [],
  SECFilingsData: [],
  stockTweets: [],
  analystForecast: [],
  stockNews: [],
  active: [],
  earnings: [],
  movers: [],
  moversWatchList: [],
  portfolio: [],
  insiderTrades: [],
  ownership: [],
  ownershipStats: [],
  activistFilings: [],
  filing: [],
  annualIncome: [],
  quarterlyIncome: [],
  annualBS: [],
  quarterlyBS: [],
  annualCF: [],
  quarterlyCF: [],
  companyDescription: "",
  financialsChartData: [],
  earningsChartData: [],
  upgradeDowngrade: [],
  institution: [],
  insiderStock: [],
  ceo: "",
  fullTimeEmployees: "",
  ipoDate: "",
  website: "",
  image: "",
  analystPriceTarget: [],
  addressData: "",
  trandingData: [],
  // analystPageData: [],
  analystSectorGrades: [],
  analystRecommendedStocks: [],
  analystUpgradeDowngrade: [],
  trandingMegaData: [],
  trandingLargeData: [],
  trandingMidData: [],
  trandingSmallData: [],
  newsTrandingData: [],
  insiderData: [],
  tweetsTrandingData: [],
  earningsCalendar: [],
  pageNumberInsiderTrade: 1,
  pageNumberVolume: 1,
  pageNumberSignal: 1,
  sectorListSignal: [],
  tradeSizeOption: [],
  signal: [],
  signalPagination: {},
  signalFilter: {},
  institutionPage: [],
  // institutionProfile: [],
  institutionProfileChart: [],
  institutionProfileRecentTrades: [],
  institutionProfileTopBuys: [],
  institutionProfileTopHoldings: [],
  institutionProfileTopSells: [],
  institutionProfileOwnershipstats: [],
  institutionTopBuyProfile: [],
  institutionRecentTradesProfile: [],
  searchStockTicker: "",
  searchInstitution: "",
  index: [],
  trending: [],
  unusualVolume: [],
  commonHolding: [],
  fintwitBuys: [],
  fintwitSells: [],
  recommendedStocks: [],
  upgradeDowngrade: [],
  priceTarget: [],
  landingInsiderTrades: [],
  popular: [],
  dividend: [],
  banner: {},
  popularData: [],
  recentlyViewedData: [],
};
const dataList = [
  "negative",
  "positive",
  "topGainers",
  "topLosers",
  "insider",
  "insiderAggTable",
  "insiderChart",
  "active",
  "movers",
  "tweets",
  "moversWatchList",
  "portfolio",
  "recentTweets",
  "earnings",
  "insiderTrades",
  "trandingData",
  // "analystPageData",
  "analystSectorGrades",
  "analystRecommendedStocks",
  "analystUpgradeDowngrade",
  "trandingMegaData",
  "trandingLargeData",
  "trandingMidData",
  "trandingSmallData",
  "newsTrandingData",
  "insiderData",
  "tweetsTrandingData",
  "earningsCalendar",
  "signal",
  "institutionPage",
  // "institutionProfile",
  "institutionProfileChart",
  "institutionProfileRecentTrades",
  "institutionProfileTopBuys",
  "institutionProfileTopHoldings",
  "institutionProfileTopSells",
  "institutionProfileOwnershipstats",
  "institutionTopBuyProfile",
  "institutionRecentTradesProfile",
  "index",
  "trending",
  "unusualVolume",
  "commonHolding",
  "fintwitBuys",
  "fintwitSells",
  "recommendedStocks",
  "upgradeDowngrade",
  "priceTarget",
  "landingInsiderTrades",
  "popular",
  "dividend",
];

const watchListReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_WATCH_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        watchList: action.payload,
      };
    case constants.GET_STOCK_LISTS:
      const payload = action?.payload || {};

      if (payload?.insiderTrades) {
        return {
          ...state,
          insiderTrades: payload?.insiderTrades,
        };
      } /* else if (
        payload?.portfolio &&
        payload?.moversWatchList &&
        !payload?.topGainers
      ) {
        return {
          ...state,
          moversWatchList: payload?.moversWatchList,
          portfolio: payload?.portfolio,
        };
      }  */ else {
        const trending = payload?.data?.Trending || [];
        const allData = [
          ...payload?.topLosers,
          ...payload?.negative,
          ...payload?.positive,
          ...payload?.topGainers,
          ...payload?.insider,
          ...payload?.active,
          ...payload?.earnings,
          ...payload?.watchList,
          ...payload?.portfolio,
          ...payload?.news,
          ...payload?.recentTweets,
          ...payload?.moversWatchList,
          ...payload?.index,
          ...payload?.trending,
          ...payload?.unusualVolume,
          ...payload?.commonHolding,
          ...payload?.fintwitBuys,
          ...payload?.fintwitSells,
          ...payload?.recommendedStocks,
          ...payload?.upgradeDowngrade,
          ...payload?.priceTarget,
          ...payload?.landingInsiderTrades,
          ...payload?.popular,
          ...payload?.dividend,
          ...trending,
        ];
        const uniqueSector = [...new Set(allData.map((item) => item.sector))];
        const sectorList = (uniqueSector || []).filter(
          (p) => !["null", "None", ""].includes(p)
        );

        return {
          ...state,
          isSuccess: true,
          list: payload?.data,
          negative: payload?.negative,
          positive: payload?.positive,
          topGainers: payload?.topGainers,
          topLosers: payload?.topLosers,
          sectorList: sectorList,
          news: payload?.news,
          insider: payload?.insider,
          active: payload?.active,
          earnings: payload?.earnings,
          movers: payload?.data,
          recentTweets: payload?.recentTweets,
          moversWatchList: payload?.moversWatchList,
          portfolio: payload?.portfolio,
          index: payload?.index,
          trending: payload?.trending,
          unusualVolume: payload?.unusualVolume,
          commonHolding: payload?.commonHolding,
          fintwitBuys: payload?.fintwitBuys,
          fintwitSells: payload?.fintwitSells,
          recommendedStocks: payload?.recommendedStocks,
          upgradeDowngrade: payload?.upgradeDowngrade,
          priceTarget: payload?.priceTarget,
          landingInsiderTrades: payload?.landingInsiderTrades,
          popular: payload?.popular,
          dividend: payload?.dividend,
          sp500: payload?.sp500,
          sectorStats: payload?.sectorStats,
          heatMap: payload?.heatMap,
          aiTitle: payload?.aiTitle,
          line1: payload?.line1,
          line1part2: payload?.line1part2,
          line1gradient: payload?.line1gradient,
          line2: payload?.line2,
          line3: payload?.line3,
          asSeen: payload?.asSeen,
          successRate: payload?.successRate,
          fintwitReturn: payload?.fintwitReturn,
          aiTargetPriceAccuracyError: payload?.aiTargetPriceAccuracyError,
          snp500Return: payload?.snp500Return,
          cumulativeReturn: payload?.cumulativeReturn,
          aiTopPicks: payload?.aiTopPicks,
          demoVideoUrl: payload?.demoVideoUrl,
        };
      }
    case constants.ADD_WATCH_LIST_ITEM:
      const { ticker, category, row } = action.payload;
      row[category] = 1;
      row[category?.toLowerCase()] = 1;
      let updatedArray = [];
      if (category === "portfolio") {
        updatedArray = [...state.portfolio, row];
      } else if (category === "Watchlist") {
        updatedArray = [...state.moversWatchList, row];
      }
      let stockList = JSON.parse(JSON.stringify(state.list));
      let stock = JSON.parse(JSON.stringify(state));
      (Object.keys(stockList) || []).forEach((key) => {
        if (stockList[key]) {
          const ind = stockList.findIndex((p) => p.ticker === ticker);
          if (ind > -1) {
            if (category === "portfolio") {
              stockList[ind].portfolio = 1;
              stockList[ind].watchlist = 0;
            } else if (category === "Watchlist") {
              stockList[ind].watchlist = 1;
              stockList[ind].portfolio = 0;
            }
          }
        }
      });
      dataList.forEach((key) => {
        const ind = stock[key]?.findIndex((p) => p?.ticker === ticker);
        if (ind > -1) {
          if (
            key === "insider" ||
            key === "insiderTrades" ||
            // key === "recommendedStocks" ||
            key === "trandingData"
          ) {
            const x = stock[key].map((v) => {
              if (v.ticker === ticker) {
                if (category === "portfolio") {
                  return { ...v, portfolio: 1, watchlist: 0 };
                } else if (category === "Watchlist") {
                  return { ...v, portfolio: 0, watchlist: 1 };
                }
              } else {
                return v;
              }
            });
            stock[key] = x;
          } else {
            if (category === "portfolio") {
              stock[key][ind].portfolio = 1;
              stock[key][ind].watchlist = 0;
            } else if (category === "Watchlist") {
              stock[key][ind].watchlist = 1;
              stock[key][ind].portfolio = 0;
            }
          }
        }
      });

      return {
        ...state,
        ...stock,
        list: stockList,
        moversWatchList:
          category === "Watchlist" ? updatedArray : state.moversWatchList,
        portfolio: category === "portfolio" ? updatedArray : state.portfolio,
      };
    case constants.REMOVE_WATCH_LIST_FROM_STOCK:
      let deepClone = JSON.parse(JSON.stringify(state.list));
      let deepTopHoldingsClone = [...state.topGainers];
      let deepRecommendedStocksClone = [...state.recommendedStocks];
      let deepUpgradeDowngradeClone = [...state.upgradeDowngrade];
      let deepLandingInsiderTradesClone = [...state.landingInsiderTrades];
      let deepDividendClone = [...state.landingInsiderTrades];
      let deepEarningsClone = [...state.earnings];
      let deepUnusualVolumeClone = [...state.unusualVolume];
      let deepHotStocksClone = [...state.topLosers];
      let deepPositiveClone = [...state.positive];
      let deepNegativeClone = [...state.negative];
      let deepActiveClone = [...state.active];
      let deepInsiderClone = [...state.insider];
      let deepInsiderTradesClone = [...state.insiderTrades];
      let deepWatchListClone = [...state.moversWatchList];
      let deepPortfolioClone = [...state.portfolio];
      let deepSignalClone = [...state.signal];
      let deepInstitutionRecentTradesProfileClone = [
        ...state.institutionRecentTradesProfile,
      ];
      let deepInstitutionProfileTopHoldingsClone = [
        ...state.institutionProfileTopHoldings,
      ];
      let deepInstitutionProfileTopSellsClone = [
        ...state.institutionProfileTopSells,
      ];
      let deepInstitutionProfileTopBuysClone = [
        ...state.institutionProfileTopBuys,
      ];
      let deepInstitutionPageClone = [...state.institutionPage];
      let deepEarningsCalendarClone = [...state.earningsCalendar];
      let deepInstitutionProfileRecentTradesClone = [
        ...state.institutionProfileRecentTrades,
      ];
      let deepCloneStock = JSON.parse(JSON.stringify(state));
      const tic = action?.payload?.ticker || action?.payload?.Ticker || "";
      const deepTopHoldingsIndex = deepTopHoldingsClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepTopHoldingsIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepTopHoldingsClone[deepTopHoldingsIndex].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepTopHoldingsClone[deepTopHoldingsIndex].watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepRecommendedStocksIndex = deepRecommendedStocksClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepRecommendedStocksIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepRecommendedStocksClone[deepRecommendedStocksIndex].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepRecommendedStocksClone[deepRecommendedStocksIndex].watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepUpgradeDowngradeIndex = deepUpgradeDowngradeClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepUpgradeDowngradeIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepUpgradeDowngradeClone[deepUpgradeDowngradeIndex].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepUpgradeDowngradeClone[deepUpgradeDowngradeIndex].watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepLandingInsiderTradesIndex =
        deepLandingInsiderTradesClone.findIndex((p) => p.ticker === tic);
      if (deepLandingInsiderTradesIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepLandingInsiderTradesClone[
            deepLandingInsiderTradesIndex
          ].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepLandingInsiderTradesClone[
            deepLandingInsiderTradesIndex
          ].watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepEarningsIndex = deepEarningsClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepEarningsIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepEarningsClone[deepEarningsIndex].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepEarningsClone[deepEarningsIndex].watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepInstitutionProfileRecentTradesIndex =
        deepInstitutionProfileRecentTradesClone.findIndex(
          (p) => p.ticker === tic
        );

      if (deepInstitutionProfileRecentTradesIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepInstitutionProfileRecentTradesClone[
            deepInstitutionProfileRecentTradesIndex
          ].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepInstitutionProfileRecentTradesClone[
            deepInstitutionProfileRecentTradesIndex
          ].watchlist = 0;
          deepInstitutionProfileRecentTradesClone[
            deepInstitutionProfileRecentTradesIndex
          ].Watchlist = 0;

          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepSignalIndex = deepSignalClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepSignalIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepSignalClone[deepSignalIndex].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepSignalClone[deepSignalIndex].watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepInstitutionRecentTradesProfileIndex =
        deepInstitutionRecentTradesProfileClone.findIndex(
          (p) => p.Ticker === tic
        );
      if (deepInstitutionRecentTradesProfileIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepInstitutionRecentTradesProfileClone[
            deepInstitutionRecentTradesProfileIndex
          ].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepInstitutionRecentTradesProfileClone[
            deepInstitutionRecentTradesProfileIndex
          ].watchlist = 0;
          deepInstitutionRecentTradesProfileClone[
            deepInstitutionRecentTradesProfileIndex
          ].Watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }

      const deepInstitutionProfileTopSellsIndex =
        deepInstitutionProfileTopSellsClone.findIndex((p) => p.Ticker === tic);
      if (deepInstitutionProfileTopSellsIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepInstitutionProfileTopSellsClone[
            deepInstitutionProfileTopSellsIndex
          ].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepInstitutionProfileTopSellsClone[
            deepInstitutionProfileTopSellsIndex
          ].watchlist = 0;
          deepInstitutionProfileTopSellsClone[
            deepInstitutionProfileTopSellsIndex
          ].Watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }

      const deepInstitutionProfileTopHoldingsIndex =
        deepInstitutionProfileTopHoldingsClone.findIndex(
          (p) => p.Ticker === tic
        );

      if (deepInstitutionProfileTopHoldingsIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepInstitutionProfileTopHoldingsClone[
            deepInstitutionProfileTopHoldingsIndex
          ].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepInstitutionProfileTopHoldingsClone[
            deepInstitutionProfileTopHoldingsIndex
          ].watchlist = 0;
          deepInstitutionProfileTopHoldingsClone[
            deepInstitutionProfileTopHoldingsIndex
          ].Watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepInstitutionProfileTopBuysIndex =
        deepInstitutionProfileTopBuysClone.findIndex((p) => p.Ticker === tic);
      if (deepInstitutionProfileTopBuysIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepInstitutionProfileTopBuysClone[
            deepInstitutionProfileTopBuysIndex
          ].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepInstitutionProfileTopBuysClone[
            deepInstitutionProfileTopBuysIndex
          ].watchlist = 0;
          deepInstitutionProfileTopBuysClone[
            deepInstitutionProfileTopBuysIndex
          ].Watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepInstitutionPageIndex = deepInstitutionPageClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepInstitutionPageIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepInstitutionPageClone[deepInstitutionPageIndex].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepInstitutionPageClone[deepInstitutionPageIndex].watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepEarningsCalendarIndex = deepEarningsCalendarClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepEarningsCalendarIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepEarningsCalendarClone[deepEarningsCalendarIndex].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepEarningsCalendarClone[deepEarningsCalendarIndex].watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepUnusualVolumeIndex = deepUnusualVolumeClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepUnusualVolumeIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepUnusualVolumeClone[deepUnusualVolumeIndex].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepUnusualVolumeClone[deepUnusualVolumeIndex].watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepDivedendIndex = deepDividendClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepDivedendIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepDividendClone[deepDivedendIndex].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepDividendClone[deepDivedendIndex].watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }

      const deepHotStockIndex = deepHotStocksClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepHotStockIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepHotStocksClone[deepHotStockIndex].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepHotStocksClone[deepHotStockIndex].watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepPositiveIndex = deepPositiveClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepPositiveIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepPositiveClone[deepPositiveIndex].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepPositiveClone[deepPositiveIndex].watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepNegativeIndex = deepNegativeClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepNegativeIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepNegativeClone[deepNegativeIndex].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepNegativeClone[deepNegativeIndex].watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepActiveIndex = deepActiveClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepActiveIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          deepActiveClone[deepActiveIndex].portfolio = 0;
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          deepActiveClone[deepActiveIndex].watchlist = 0;
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
      }
      const deepInsiderIndex = deepInsiderClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepInsiderIndex > -1) {
        if (action?.payload?.categoryType === "portfolio") {
          const filteredPortfolio = deepPortfolioClone.filter(
            (p) => p.ticker !== tic
          );
          deepPortfolioClone = filteredPortfolio;
        } else if (action?.payload?.categoryType === "Watchlist") {
          const filteredWatchlist = deepWatchListClone.filter(
            (p) => p.ticker !== tic
          );
          deepWatchListClone = filteredWatchlist;
        }
        const x = deepInsiderClone.map((v) => {
          if (v.ticker === tic) {
            if (action?.payload?.categoryType === "portfolio") {
              return { ...v, portfolio: 0 };
            } else if (action?.payload?.categoryType === "Watchlist") {
              return { ...v, watchlist: 0 };
            }
          } else {
            return v;
          }
        });
        deepInsiderClone = x;
      }
      const deepInsiderTradesIndex = deepInsiderTradesClone.findIndex(
        (p) => p.ticker === tic
      );
      if (deepInsiderTradesIndex > -1) {
        const x = deepInsiderTradesClone.map((v) => {
          if (v.ticker === tic) {
            if (action?.payload?.categoryType === "portfolio") {
              const filteredPortfolio = deepPortfolioClone.filter(
                (p) => p.ticker !== tic
              );
              deepPortfolioClone = filteredPortfolio;
              return { ...v, portfolio: 0 };
            } else if (action?.payload?.categoryType === "Watchlist") {
              const filteredWatchlist = deepWatchListClone.filter(
                (p) => p.ticker !== tic
              );
              deepWatchListClone = filteredWatchlist;
              return { ...v, watchlist: 0 };
            }
          } else {
            return v;
          }
        });
        deepInsiderTradesClone = x;
      }
      if (state.type === "moversWatchList") {
        (Object.keys(deepWatchListClone) || []).forEach((key) => {
          const ind = deepWatchListClone[key].findIndex(
            (p) => p.ticker === tic
          );
          if (ind > -1) {
            deepWatchListClone[key].splice(ind, 1);
          }
        });
      } else if (state.type === "portfolio") {
        (Object.keys(deepPortfolioClone) || []).forEach((key) => {
          const ind = deepPortfolioClone[key].findIndex(
            (p) => p.ticker === tic
          );
          if (ind > -1) {
            deepPortfolioClone[key].splice(ind, 1);
          }
        });
      } else {
        (Object.keys(deepClone) || []).forEach((key) => {
          const ind = deepClone.findIndex((p) => p.ticker === tic);
          if (ind > -1) {
            if (action?.payload?.categoryType === "portfolio") {
              deepClone[ind].portfolio = 0;
            } else if (action?.payload?.categoryType === "Watchlist") {
              deepClone[ind].watchlist = 0;
            }
          }
        });
        dataList.forEach((key) => {
          const ind = deepCloneStock[key].findIndex((p) => p.ticker === tic);
          if (ind > -1) {
            if (action?.payload?.categoryType === "portfolio") {
              deepCloneStock[key][ind].portfolio = 0;
            } else if (action?.payload?.categoryType === "Watchlist") {
              deepCloneStock[key][ind].watchlist = 0;
            }
          }
        });
      }
      return {
        ...state,
        ...deepCloneStock,
        list: deepClone,
        topGainers: deepTopHoldingsClone,
        recommendedStocks: deepRecommendedStocksClone,
        upgradeDowngrade: deepUpgradeDowngradeClone,
        landingInsiderTrades: deepLandingInsiderTradesClone,
        dividend: deepDividendClone,
        earnings: deepEarningsClone,
        unusualVolume: deepUnusualVolumeClone,
        topLosers: deepHotStocksClone,
        positive: deepPositiveClone,
        negative: deepNegativeClone,
        active: deepActiveClone,
        insider: deepInsiderClone,
        insiderTrades: deepInsiderTradesClone,
        moversWatchList: deepWatchListClone,
        portfolio: deepPortfolioClone,
        signal: deepSignalClone,
        institutionProfileRecentTrades: deepInstitutionProfileRecentTradesClone,
        institutionPage: deepInstitutionPageClone,
        institutionProfileTopBuys: deepInstitutionProfileTopBuysClone,
        institutionProfileTopHoldings: deepInstitutionProfileTopHoldingsClone,
        institutionProfileTopSells: deepInstitutionProfileTopSellsClone,
        earningsCalendar: deepEarningsCalendarClone,
        institutionRecentTradesProfile: deepInstitutionRecentTradesProfileClone,
      };
    case constants.REMOVE_WATCH_LIST_ITEM:
      const cloneWatchList = [...state.watchList];
      const stateKeys = Object.keys(state);
      stateKeys.forEach((key) => {
        if (key !== "isSuccess" && key !== "type" && key !== "list") {
          const ind = state[key].findIndex(
            (obj) => obj.ticker === action.payload
          );
          if (ind !== -1) {
            if (state[key][ind].hasOwnProperty("watchlist")) {
              state[key][ind].watchlist = 0;
            }
            if (state[key][ind].hasOwnProperty("portfolio")) {
              state[key][ind].portfolio = 0;
            }
          }
        }
        if (key === "list") {
          Object.entries(state[key]).forEach(([subKey, value]) => {
            const ind = value.findIndex((obj) => obj.ticker === action.payload);
            if (ind !== -1) {
              value[ind].watchlist = 0;
              value[ind].portfolio = 0;
            }
          });
        }
      });

      const index = cloneWatchList.findIndex((p) => p.value === action.payload);
      if (index > -1) {
        cloneWatchList.splice(index, 1);
      }
      return {
        ...state,
        isSuccess: true,
        watchList: cloneWatchList,
      };
    case constants.GET_USER_POST_REQUEST_ERROR:
      return {
        ...state,
        isSuccess: false,
      };
    case constants.GET_PORTFOLIO_WATCHLIST:
      return {
        ...state,
        moversWatchList: action.payload?.moversWatchList,
        portfolio: action.payload?.portfolio,
      };
    case constants.SORT_DATA:
      let stockDataClone = JSON.parse(JSON.stringify(state.list));
      let stockClone = JSON.parse(JSON.stringify(state));
      const catName = action.payload && action.payload.category;
      const sortedData = action.payload && action.payload.sortedList;
      stockDataClone = sortedData;
      stockClone = sortedData;
      return {
        ...state,
        ...stockClone,
        list: stockDataClone,
      };

    case constants.SET_DATA:
      return {
        ...state,
        investors: action.payload.investors,
        tweets: action.payload.tweets,
        influencer: action.payload.influencer,
        news: action.payload.news,
        companyDescription: action.payload.companyDescription,
        financialsChartData: action.payload.financialsChartData,
        earningsChartData: action.payload.earningsChartData,
        addressData: action.payload.addressData,
        analystPriceTarget: action.payload.analystPriceTarget,
        upgradeDowngrade: action.payload.upgradeDowngrade,
        institution: action.payload.institution,
        insiderStock: action.payload.insiderStock,
        ceo: action.payload.ceo,
        fullTimeEmployees: action.payload.fullTimeEmployees,
        ipoDate: action.payload.ipoDate,
        website: action.payload.website,
        image: action.payload.image,
        description: action.payload.description,
        inceptionDate: action.payload.inceptionDate,
      };
    case constants.SET_STOCK_SIGNAL:
      return {
        ...state,
        signal: action?.payload?.signal,
        signalPagination: action?.payload?.signalPagination,
        signalFilter: action?.payload?.signalFilter,
      };
    case constants.SET_INSTITUTION:
      return {
        ...state,
        institutionPage: action?.payload?.institution,
      };
    case constants.SET_INSTITUTION_PROFILE:
      return {
        ...state,
        // institutionProfile: action?.payload?.institutionProfile,
        institutionProfileChart: action?.payload?.institutionProfileChart,
        institutionProfileRecentTrades:
          action?.payload?.institutionProfileRecentTrades,
        institutionProfileTopBuys: action?.payload?.institutionProfileTopBuys,
        institutionProfileTopHoldings:
          action?.payload?.institutionProfileTopHoldings,
        institutionProfileTopSells: action?.payload?.institutionProfileTopSells,
        institutionProfileOwnershipstats:
          action?.payload?.institutionProfileOwnershipstats,
      };
    case constants.SET_TOP_BUY_PROFILE:
      return {
        ...state,
        institutionTopBuyProfile: action?.payload?.institutionTopBuyProfile,
      };
    case constants.SET_RECENT_TRADES_PROFILE:
      return {
        ...state,
        institutionRecentTradesProfile:
          action?.payload?.institutionRecentTradesProfile,
      };
    case constants.SET_STOCK_TWEETS:
      return {
        ...state,
        stockTweets: action?.payload?.stockTweets,
      };
    case constants.SET_STOCK_ANALYST_FORECAST:
      return {
        ...state,
        analystForecast: action?.payload?.analystForecast,
      };
    case constants.SET_STOCK_NEWS:
      return {
        ...state,
        stockNews: action?.payload?.stockNews,
      };
    case constants.SET_STOCK_INSIDER:
      return {
        ...state,
        insider: action?.payload?.insider,
        insiderAggTable: action?.payload?.insiderAggTable,
        insiderChart: action?.payload?.insiderChart,
      };
    case constants.SET_STOCK_TRANSCRIPT:
      return {
        ...state,
        transcript: action?.payload?.transcript,
      };
    case constants.SET_STOCK_SEC:
      return {
        ...state,
        SECFilingsData: action?.payload?.SECFilingsData,
      };
    case constants.SET_STOCK_OWNERSHIP:
      return {
        ...state,
        ownership: action?.payload?.ownership,
        ownershipStats: action?.payload?.ownershipStats,
        activistFilings: action?.payload?.activistFilings,
      };
    case constants.SET_STOCK_FILING:
      return {
        ...state,
        filing: action?.payload?.filing,
      };
    case constants.SET_STOCK_STATEMENT:
      return {
        ...state,
        annualIncome: action?.payload?.annualIncome,
        quarterlyIncome: action?.payload?.quarterlyIncome,
        annualBS: action?.payload?.annualBS,
        quarterlyBS: action?.payload?.quarterlyBS,
        annualCF: action?.payload?.annualCF,
        quarterlyCF: action?.payload?.quarterlyCF,
      };
    case constants.SET_TRANDING_DATA:
      return {
        ...state,
        trandingData: action?.payload?.trandingData,
        trandingMegaData: action?.payload?.trandingMegaData,
        trandingLargeData: action?.payload?.trandingLargeData,
        trandingMidData: action?.payload?.trandingMidData,
        trandingSmallData: action?.payload?.trandingSmallData,
      };
    case constants.SET_ANALYST_PAGE_DATA:
      return {
        ...state,
        analystSectorGrades: action?.payload?.analystPageData?.sectorGrades,
        analystRecommendedStocks:
          action?.payload?.analystPageData?.recommendedStocks,
        analystUpgradeDowngrade:
          action?.payload?.analystPageData?.upgradeDowngrade,
      };
    case constants.SET_NEWS_TRANDING_DATA:
      return {
        ...state,
        newsTrandingData: action?.payload?.newsTrandingData,
      };
    case constants.SET_INSIDER_DATA:
      return {
        ...state,
        insiderData: action?.payload?.insiderData,
      };
    case constants.SET_TWEETS_TRANDING_DATA:
      return {
        ...state,
        tweetsTrandingData: action?.payload?.tweetsTrandingData,
      };
    case constants.SET_EARNINGS_CALENDAR_DATA:
      return {
        ...state,
        earningsCalendar: action?.payload?.earningsCalendar,
      };
    case constants.CHANGE_PAGE_NUMBER:
      return {
        ...state,
        pageNumberInsiderTrade: action?.payload,
      };
    case constants.CHANGE_PAGE_NUMBER_VOLUME:
      return {
        ...state,
        pageNumberVolume: action?.payload,
      };
    case constants.SECTOR_SIGNAL:
      return {
        ...state,
        sectorListSignal: action?.payload,
      };
    case constants.TRADE_SIZE_OPTION:
      return {
        ...state,
        tradeSizeOption: action?.payload,
      };
    case constants.CHANGE_PAGE_NUMBER_SIGNAL:
      return {
        ...state,
        pageNumberSignal: action?.payload,
      };
    case constants.SEARCH_STOCK_TICKER:
      return {
        ...state,
        searchStockTicker: action?.payload,
      };
    case constants.SEARCH_INSTITUTION:
      return {
        ...state,
        searchInstitution: action?.payload,
      };
    case constants.GET_BANNER_DATA:
      return {
        ...state,
        banner: action?.payload,
      };
    case constants.LANDING_PAGE_BANNER:
      return {
        ...state,
        isLandingBanner: action?.payload,
      };
    case constants.GET_POPULAR_DATA:
      return {
        ...state,
        popularData: action?.payload,
      };
    case constants.GET_VIEWED_DATA:
      return {
        ...state,
        recentlyViewedData: action?.payload,
      };
    default:
      return state;
  }
};
export default watchListReducer;
