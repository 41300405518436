import { Box, Container, Typography, makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toastMessage } from "../../redux/actions";
import SetHelmet from "../Common/SetHelmet";
import ThreeDotsLoader from "../Common/ThreeDotsLoader";
import DashboardGuide from "../Dashboard/DashboardGuide";
import ChatCredits from "./ChatCredits";
import QueryInput from "./QueryInput";
import RecentChatHistory from "./RecentChatHistory";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontWeight: 600,
    letterSpacing: -1,
    fontSize: 36,
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      marginBottom: 20,
    },
  },
  wrapper: {
    padding: "20px 30px",
    border: "1px solid rgb(217 215 208)",
    borderRadius: 8,
    width: 735,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      padding: 16,
    },
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    gap: 12,
  },
  queryInput: {
    fontFamily: "Roboto",
    padding: "8px 10px 8px 0px",
    width: "100%",
    border: "transparent",
    fontSize: "16px",
    "&:placeholder": {
      fontSize: "16px",
    },
    "&:focus-visible": {
      outline: "none",
    },
  },
  arrowButton: {
    "&.MuiButtonBase-root": {
      padding: 8,
      "&:disabled": {
        backgroundColor: "rgba(61, 61, 59,0.1)",
        color: "rgba(0, 0, 0, 0.1)",
      },
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      fontSize: 20,
    },
  },
  sourceLabel: {
    marginBottom: 0,
    "& .MuiButtonBase-root": {
      padding: 6,
    },
  },
  sourcesLabel: {
    fontWeight: 500,
    fontSize: 18,
    marginBottom: 4,
    lineHeight: "normal",
    textWrap: "nowrap",
    display: "block",
    [theme.breakpoints.down("580")]: {
      marginTop: 4,
    },
  },
  sources: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("580")]: {
      alignItems: "flex-start",
    },
  },
}));

const NewChat = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { idToken } = useSelector((state) => state.signIn.loggedUser);

  const handleSubmit = async ({
    query,
    source = "auto",
    onSuccess = () => {},
  }) => {
    if (!query || isLoading) return;

    try {
      setIsLoading(true);
      const { data } = await axios.post(
        process.env.REACT_APP_CHAT_API_URL,
        {
          method: "chat",
          query,
          previous_queries: [""],
          source: [source],
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: idToken,
            Accept: "application/json",
          },
        }
      );
      onSuccess();
      if (data.chatId) history.replace(`/chat/${data.chatId}`);
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message:
            error.response?.data?.body ||
            "Failed to create chat. Please try again.",
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SetHelmet
        title="AI Stock Market Analyst - Fintwit Chat"
        description="AI Stock Market Analyst - Fintwit Chat"
      />
      <DashboardGuide page="chat" />
      <Container>
        <ChatCredits style={{ textAlign: "right" }} />
        <Box mt={3} display="flex" alignItems="center" justifyContent="center">
          <Box marginBottom="30px" width="100%">
            <Typography variant="h4" className={classes.title}>
              How can I help you today?
            </Typography>

            <QueryInput isLoading={isLoading} onSubmit={handleSubmit} ownChat />
            {isLoading && (
              <Box
                display="flex"
                alignItems="start"
                justifyContent="center"
                gridColumnGap={4}
                sx={{ opacity: isLoading ? 1 : 0 }}
                mt={1.5}
                bgcolor="#eee"
                maxWidth="max-content"
                mx="auto"
                pt={0.5}
                px={2}
                borderRadius={15}
              >
                Searching <ThreeDotsLoader />
              </Box>
            )}
          </Box>
        </Box>
        <RecentChatHistory createChat={handleSubmit} />
      </Container>
    </>
  );
};

export default NewChat;
