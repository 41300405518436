import { Box, MenuItem, Typography, makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toastMessage } from "../../redux/actions";
import { stripeBillingRedirect } from "../../redux/api/stripeApi";
import { getSubscriptionStatus } from "../../redux/auth/action";
import { LoaderAction } from "../../redux/loader/action";
import { isProPlanSubscription } from "../../utils/common";
import CardBox from "../Common/CardBox";
import CustomSelect from "../Common/CustomSelect";
import Loader from "../Common/Loader";
import TickerSearchSelect from "../Common/TickerSearchSelect";
import Reserved from "../Reserved";
import CreateAlert from "./CreateAlert";
import { alertTypes, dummyAlertSettings } from "./alertSettings.description";
import AlertSettingsTable from "./AlertSettingsTable";
import NotFound from "../Common/NotFound";

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: 30,
    fontWeight: 700,
  },
  actionButton: {
    fontFamily: '"Roboto", sans-serif',
    border: "none",
    outline: 0,
    fontSize: 14,
    background: "none",
    cursor: "pointer",
    padding: 0,
    fontWeight: 500,
    textTransform: "uppercase",
    "&:disabled": {
      cursor: "not-allowed",
    },
  },
  filter: {
    width: 200,
    "& .MuiSelect-root": {
      paddingTop: 9,
      paddingBottom: 9,
    },
  },
}));

const ROWS_PER_PAGE = 10;

const AlertSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alertSettings, setAlertSettings] = useState();
  const [sortConfig, setSortConfig] = useState({});
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [dataBeforeEdit, setDataBeforeEdit] = useState();

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { idToken, userInfo } = useSelector((state) => state.signIn.loggedUser);
  const hideAlertSettings = userInfo?.showAlert === 0;

  const isProUser = isProPlanSubscription(userInfo?.subscriptionStatus);
  const start = page === 0 ? 0 : page * ROWS_PER_PAGE;

  const handleChange = ({ id, name, value }) => {
    setAlertSettings((prev) =>
      prev.map((notification) =>
        notification.id === id
          ? { ...notification, [name]: value }
          : notification
      )
    );
  };

  const handlePageChange = (page) => {
    setPage(page);

    const start = page === 0 ? 0 : page * ROWS_PER_PAGE;
    getAlertSettings({ start });
  };

  const handleFilterChange = ({ name, value }) => {
    const filterValue =
      name === "alertType" ? (value === "all" ? "" : value) : value;
    const appliedFilters = { ...filters, [name]: filterValue };

    setFilters(appliedFilters);
    getAlertSettings(appliedFilters);
  };

  const getAlertSettings = async (config) => {
    if (!isProUser) return;

    const url = `${process.env.REACT_APP_API_SERVER_URL}/user`;

    try {
      setIsLoading(true);
      const { data } = await axios.post(
        url,
        {
          method: "getAlertSetting",
          start,
          limit: ROWS_PER_PAGE,
          ...sortConfig,
          ...filters,
          ...config,
        },
        {
          headers: {
            Authorization: idToken,
          },
        }
      );
      setAlertSettings(data);
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: "Could not fetch alert settings. Please try again later.",
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const addNewAlert = () => {
    getAlertSettings();
  };

  const onSort = (config) => {
    setSortConfig(config);
    getAlertSettings(config);
  };

  const onAlertUpdate = (updatedAlert) => {
    setDataBeforeEdit((prev) =>
      prev.map((alert) => {
        return alert.id === updatedAlert.id
          ? { ...alert, ...updatedAlert }
          : alert;
      })
    );
  };

  const handleBilling = async () => {
    dispatch(LoaderAction(true));
    const response = await stripeBillingRedirect(idToken, {
      customerId: userInfo?.stripeCustomerId,
    });
    if (response.status === 201) {
      window.location.href = response?.data?.data?.url;
      dispatch(LoaderAction(false));
    } else {
      dispatch(LoaderAction(false));
    }
  };

  const subscriptionStatusResult = () => {
    if (
      userInfo?.isTrialAvailable === 1 &&
      userInfo?.subscriptionStatus === "FREE"
    ) {
      dispatch(getSubscriptionStatus(true));
      history.push("/subscription");
      return;
    }

    handleBilling();
  };

  useEffect(() => {
    getAlertSettings();
  }, []);

  if (hideAlertSettings) return <NotFound />;

  return (
    <>
      <Loader isLoad={isLoading} />
      <CardBox>
        <Typography component="h5" variant="h5" className={classes.heading}>
          Alert Settings
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 16,
          }}
        >
          <button
            className={classes.actionButton}
            onClick={() => {
              if (isEditMode) {
                // Reset alert settings when cancelling edit mode
                setAlertSettings(dataBeforeEdit);
              }
              if (!isEditMode) {
                setDataBeforeEdit(alertSettings);
              }
              setIsEditMode((prev) => !prev);
            }}
            disabled={!isProUser}
          >
            {isEditMode ? "Cancel" : "Edit"}
          </button>
          <button
            className={classes.actionButton}
            onClick={() => setIsModalOpen(true)}
            disabled={isEditMode || !isProUser}
          >
            Add New Alert
          </button>

          <CustomSelect
            name="alertType"
            value={filters.alertType || "all"}
            onChange={(e) => handleFilterChange(e.target)}
            className={classes.filter}
            size="small"
            style={{ marginLeft: "auto" }}
            disabled={isEditMode || !isProUser}
          >
            <MenuItem value="all">All</MenuItem>
            {alertTypes.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </CustomSelect>
          <TickerSearchSelect
            className={classes.filter}
            value={filters.ticker ? { value: filters.ticker } : null}
            onChange={(selectedOption) => {
              handleFilterChange({
                name: "ticker",
                value: selectedOption?.value,
              });
            }}
            isClearable={!!filters?.ticker}
            isDisabled={isEditMode || !isProUser}
          />
        </Box>
        <Box position="relative" mt={1}>
          {!isProUser && (
            <Reserved
              isLanding={false}
              isPro
              SubscriptionStatusRes={subscriptionStatusResult}
            />
          )}

          <AlertSettingsTable
            data={isProUser ? alertSettings : dummyAlertSettings}
            isEditMode={isEditMode}
            handleChange={handleChange}
            sortConfig={sortConfig}
            onSort={onSort}
            page={page}
            rowsPerPage={ROWS_PER_PAGE}
            onPageChange={handlePageChange}
            isPaginationDisabled={!isProUser}
            onAlertUpdate={onAlertUpdate}
          />
        </Box>
      </CardBox>
      <CreateAlert
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAdd={addNewAlert}
      />
    </>
  );
};

export default AlertSettings;
