import { Typography, makeStyles } from "@material-ui/core";
import React from "react";
import CustomButton from "../Common/CustomButton";

const useStyles = makeStyles(() => ({
  filterBlurBg: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    zIndex: "7",
    height: "100%",
    backdropFilter: "blur(7px)",
    color: "#000000",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    "& h3": {
      fontSize: 14,
      marginBottom: 15,
      fontWeight: 600,
    },
  },
}));

const Reserved = ({
  classes: extraClasses,
  isStock,
  isTrialAvailable,
  SubscriptionStatusRes,
  isLanding = false,
  isPro,
}) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes?.filterBlurBg ?? extraClasses?.filterBlurBg} ${
        isStock?.length > 0 ? null : extraClasses?.filterBgNodata
      }`}
    >
      <Typography variant="body1" component="h3">
        {" "}
        {isLanding
          ? "Reserved for members only"
          : isPro
          ? "Reserved for Pro members only"
          : "Reserved for Premium members"}
      </Typography>
      <CustomButton
        radius="55"
        btnPadding="11px 15px"
        variant="contained"
        onClick={SubscriptionStatusRes}
      >
        {isLanding
          ? "Sign up for free"
          : isTrialAvailable === 1
          ? "Start free trial"
          : isPro
          ? "Upgrade to PRO"
          : "Upgrade to Premium"}
      </CustomButton>
    </div>
  );
};

export default Reserved;
