import { Box, Container, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toastMessage } from "../../redux/actions";

import Loader from "../Common/Loader";
import QueryList from "./QueryList";
import SetHelmet from "../Common/SetHelmet";
import { useChatStyles } from "./chat.style";
import ChatCredits from "./ChatCredits";

const Chat = () => {
  const [chat, setChat] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { idToken } = useSelector((state) => state.signIn.loggedUser);
  const { chatId } = useParams();
  const dispatch = useDispatch();
  const classes = useChatStyles();

  const addQuery = (newQuery) => {
    setChat((prev) => ({
      ...prev,
      queries: [...prev.queries, newQuery],
    }));
  };

  const makeShareable = () => {
    setChat((prev) => ({ ...prev, shareable: 1 }));
  };

  const fetchChat = async () => {
    if (!chatId) return;

    const url = idToken
      ? `${process.env.REACT_APP_CHAT_API_URL}?method=chat&chatId=${chatId}`
      : `${process.env.REACT_APP_PUBLIC_CHAT_API_URL}?chatId=${chatId}`;

    try {
      setIsLoading(true);
      const { data } = await axios.get(url, {
        headers: {
          Authorization: idToken,
        },
      });
      setChat(idToken ? data?.[0] : data);
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: "Could not fetch chat. Please try again later.",
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChat();
  }, []);

  if (isLoading) return <Loader isLoad={isLoading} />;

  if (!chat)
    return (
      <Box textAlign="center" pt={35}>
        <Typography variant="h5">No chat found.</Typography>
      </Box>
    );

  return (
    <>
      <SetHelmet title={chat.chatName} />
      <Container className={idToken ? "" : classes.container}>
        {!!idToken && <ChatCredits style={{ textAlign: "right" }} />}
        <QueryList
          addQuery={addQuery}
          makeShareable={makeShareable}
          queries={chat?.queries}
          shareable={chat.shareable || !idToken}
          chatOwner={chat?.username}
        />
      </Container>
    </>
  );
};

export default Chat;
