import React from "react";
import { Helmet } from "react-helmet";

const SetHelmet = ({
  title,
  description = "Fintwit - Stock Market Quotes, Stock analysis, Market News, Stock tweets, Trading signals, Trending tickers, Fintwit trades",
}) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default React.memo(SetHelmet);
