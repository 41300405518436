import { useTheme } from "@material-ui/core";

const useJoyrideStyles = () => {
  const theme = useTheme();

  return {
    tooltip: {
      borderRadius: 20,
      maxWidth: 420,
      width: "100%",
      padding: "10px 20px 20px 20px",
      textAlign: "left",
      // margin: "0 auto",
    },
    tooltipContent: { textAlign: "left", paddingBottom: 10 },
    buttonNext: {
      borderRadius: 20,
      padding: "10px 25px",
      backgroundColor: theme.palette.secondary.main,
    },
    buttonBack: {
      color: theme.palette.secondary.main,
      padding: "10px 15px",
      marginLeft: 0,
      paddingLeft: 0,
    },
    tooltipFooter: {
      justifyContent: "start",
      marginTop: 0,
    },
    tooltipFooterSpacer: {
      flex: "none",
      paddingLeft: 10,
    },
  };
};

export default useJoyrideStyles;
