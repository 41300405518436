import { useEffect, useState } from "react";

const useTypingEffect = (
  text,
  { typingSpeed, erasingSpeed, delay = 1000, cycles = Infinity } = {}
) => {
  const [displayedText, setDisplayedText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [index, setIndex] = useState(0);
  const [cycleCount, setCycleCount] = useState(0);

  useEffect(() => {
    if (cycleCount >= cycles) return; // Stop if the number of cycles is reached

    let timeoutId;

    if (!isDeleting && index < text.length) {
      timeoutId = setTimeout(() => {
        setDisplayedText((prev) => prev + text[index]);
        setIndex((prev) => prev + 1);
      }, typingSpeed);
    } else if (isDeleting && index > 0 && cycleCount < cycles - 1) {
      timeoutId = setTimeout(() => {
        setDisplayedText((prev) => prev.slice(0, -1));
        setIndex((prev) => prev - 1);
      }, erasingSpeed);
    } else if (index === text.length) {
      if (cycleCount < cycles - 1) {
        timeoutId = setTimeout(() => {
          setIsDeleting(true);
        }, delay);
      } else {
        setCycleCount((prev) => prev + 1); // Mark the cycle as complete
      }
    } else if (index === 0 && isDeleting) {
      setIsDeleting(false);
      setCycleCount((prev) => prev + 1);
    }

    return () => clearTimeout(timeoutId);
  }, [
    displayedText,
    isDeleting,
    index,
    text,
    typingSpeed,
    erasingSpeed,
    delay,
    cycleCount,
    cycles,
  ]);

  return displayedText;
};

export default useTypingEffect;
