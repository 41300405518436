import { Box, FormControlLabel, MenuItem, Typography } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../components/Common/CustomButton";
import CustomTextField from "../components/Common/CustomInputField";
import CustomCheckBox from "../components/Common/CustomRadio";
import CustomSelect from "../components/Common/CustomSelect";
import { DashboardStyle } from "../components/Dashboard/DashboardStyle";
import useStyles from "../components/LandingPage/css";
import { toastMessage } from "../redux/actions";
import { userPost } from "../redux/api/authApis";

const noLongerList = [
  "Very disappointed",
  "Somewhat disappointed",
  "Not disappointed",
];
const jobFunctionList = [
  "Investment Professional",
  "Personal Investor",
  "Day Trader",
  "Banker/Hedge Fund",
  "New to Investment",
];

/* const initialState = {
  noLonger: "",
  benefitFrom: "",
  mainBenefit: "",
  improvement: "",
  jobFunction: "",
}; */

const SurveyForm = ({
  handleFrameToggle,
  surveyData,
  setSurveyData,
  initialState,
}) => {
  const arrowClasses = DashboardStyle();
  const dispatch = useDispatch();
  const { idToken, userInfo } = useSelector((state) => state.signIn.loggedUser);
  const [isNoLongerError, setIsNoLongerError] = useState(false);
  const [isImprovementError, setIsImprovementError] = useState(false);
  const classes = useStyles();
  // const [surveyData, setSurveyData] = useState(initialState);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSurveyData({ ...surveyData, [name]: value });
    if (name === "noLonger") {
      setIsNoLongerError(false);
    }
    if (name === "improvement") {
      setIsImprovementError(false);
    }
  };
  const handleSubmit = async () => {
    if (surveyData?.noLonger?.length === 0) {
      setIsNoLongerError(true);
    }
    if (surveyData?.improvement?.length === 0) {
      setIsImprovementError(true);
    }
    if (
      surveyData?.noLonger?.length > 0 &&
      surveyData?.improvement?.length > 0
    ) {
      const res = await userPost(idToken, {
        method: "feedback",
        rating: surveyData?.noLonger,
        feedback: surveyData?.improvement,
        targetCustomer:
          surveyData?.benefitFrom?.length > 0 ? surveyData?.benefitFrom : "NA",
        benefit:
          surveyData?.mainBenefit?.length > 0 ? surveyData?.mainBenefit : "NA",
        ...(userInfo?.role === null && {
          role:
            surveyData?.jobFunction?.length > 0
              ? surveyData?.jobFunction
              : "NA",
        }),
      });
      handleFrameToggle();
      if (res.status === 200) {
        dispatch(
          toastMessage({
            status: true,
            message: "Thank you for your Feedback!",
            type: "success",
          })
        );
        setSurveyData(initialState);
      }
    }
  };
  return (
    <div className={classes.surveyFormInfo}>
      <p>
        1. How would you feel if you could no longer use Fintwit.ai?
        {isNoLongerError && (
          <Typography variant="body2" component="p" color="error">
            Required*
          </Typography>
        )}
      </p>
      <Box
        sx={{
          "& .MuiFormControlLabel-root": {
            margin: 0,
            width: "100%",
            "& .MuiButtonBase-root": { padding: 6 },
          },
        }}
      >
        {noLongerList.map((v, ind) => {
          return (
            <FormControlLabel
              className={classes.checkBoxLabel}
              name="noLonger"
              control={
                <CustomCheckBox
                  checked={v === surveyData?.noLonger}
                  onChange={handleChange}
                  value={v}
                />
              }
              label={v}
            />
          );
        })}
      </Box>
      <p>
        2. What type of people do you think would benefit most from Fintwit.ai?{" "}
      </p>
      <CustomTextField
        fullWidth
        multiline
        type="text"
        variant="outlined"
        name="benefitFrom"
        value={surveyData?.benefitFrom}
        onChange={handleChange}
        inputProps={{ maxLength: 100 }}
      />
      <p>3. What is the main benefit you receive from Fintwit.ai?</p>
      <CustomTextField
        fullWidth
        multiline
        type="text"
        variant="outlined"
        name="mainBenefit"
        value={surveyData?.mainBenefit}
        onChange={handleChange}
        inputProps={{ maxLength: 100 }}
      />
      <p>
        4. How can we improve Fintwit.ai for you?
        {isImprovementError && (
          <Typography variant="body2" component="p" color="error">
            Required*
          </Typography>
        )}
      </p>
      <CustomTextField
        fullWidth
        multiline
        type="text"
        variant="outlined"
        name="improvement"
        value={surveyData?.improvement}
        onChange={handleChange}
        inputProps={{ maxLength: 200 }}
      />
      {userInfo?.role === null && (
        <>
          <p>5. What is your job function?</p>
          <CustomSelect
            fullWidth
            displayEmpty
            variant="outlined"
            MenuProps={{
              className: classes.frameSelect,
            }}
            value={surveyData?.jobFunction}
            IconComponent={() => (
              <ExpandMoreRoundedIcon className={arrowClasses.selectArrow} />
            )}
            name="jobFunction"
            onChange={handleChange}
          >
            <MenuItem value="" disabled>
              Select
            </MenuItem>
            {jobFunctionList.map((item, ind) => {
              return (
                <MenuItem value={item} key={ind}>
                  {item}
                </MenuItem>
              );
            })}
          </CustomSelect>
        </>
      )}
      <CustomButton
        radius="5"
        btnPadding="10px 20px"
        variant="contained"
        onClick={handleSubmit}
      >
        Submit
      </CustomButton>
    </div>
  );
};

export default SurveyForm;
