import {
  Badge,
  Button,
  Divider,
  IconButton,
  Popover,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { NotificationsNone } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { markNotificationsAsRead } from "../../redux/auth/action";
import UserNotificationList from "./UserNotificationList";

const useStyles = makeStyles(() => ({
  popover: {
    "& .MuiPaper-root": {
      maxWidth: 370,
      width: "100%",
      maxHeight: 350,
    },
  },
  heading: {
    fontSize: 18,
    fontWeight: 600,
    padding: [[10, 12, 10, 28]],
    letterSpacing: 0.3,
  },
  notification: {
    cursor: "pointer",
    padding: 12,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "start",
    "&:hover": {
      backgroundColor: "#e0f0ff",
    },
  },
  viewAllButton: {
    borderRadius: 0,
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const MAX_NOTIFICATIONS = 10;

const UserNotifications = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showNotificationBadge, setShowNotificationBadge] = useState();

  const classes = useStyles();
  const dispatch = useDispatch();
  const { idToken, userInfo } = useSelector((state) => state.signIn.loggedUser);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleMarkNotificationsAsRead = async () => {
    const url = `${process.env.REACT_APP_API_SERVER_URL}/user`;
    const unreadNotifications =
      userInfo?.alerts
        ?.slice(0, MAX_NOTIFICATIONS)
        ?.filter(({ isRead }) => isRead === 0) || [];

    if (!unreadNotifications.length) return;

    try {
      await axios.put(
        url,
        {
          method: "updateAlert",
          id: unreadNotifications?.map((alert) => alert.id) || [],
        },
        {
          headers: {
            Authorization: idToken,
          },
        }
      );
      dispatch(markNotificationsAsRead());
    } catch (error) {}
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowNotificationBadge(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleMarkNotificationsAsRead();
  };

  useEffect(() => {
    setShowNotificationBadge(userInfo?.alerts?.some(({ isRead }) => !isRead));
  }, [userInfo?.alerts]);

  return (
    <>
      <IconButton
        className="notification-button"
        size="medium"
        onClick={handleClick}
        {...props}
        style={{ marginLeft: 8, ...props.style }}
      >
        <Badge
          variant="dot"
          badgeContent={userInfo?.alerts?.length}
          color="error"
          overlap="circular"
          invisible={!showNotificationBadge}
        >
          <NotificationsNone />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className={classes.popover}
      >
        <Typography variant="h6" className={classes.heading}>
          Notifications
        </Typography>
        <Divider />
        <UserNotificationList
          notifications={userInfo?.alerts?.slice(0, MAX_NOTIFICATIONS)}
          onClose={handleClose}
        />

        {userInfo?.alerts?.length > MAX_NOTIFICATIONS && (
          <Link to="/notifications" onClick={handleClose}>
            <Button fullWidth className={classes.viewAllButton}>
              View all
            </Button>
          </Link>
        )}
      </Popover>
    </>
  );
};

export default UserNotifications;
