import { makeStyles } from "@material-ui/core";

export const DashboardStyle = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
  marketPageContainer: {
    maxWidth: 1440,
  },
  searchBox: {
    position: "relative",
    float: "right",
    maxWidth: 210,
    marginBottom: 20,
    width: "100%",
    transition: "all 0.5s ease-in-out",
    "& input": {
      "&.MuiInputBase-input": {
        paddingLeft: 45,
      },
    },
    "& img": {
      position: "absolute",
      left: 18,
      top: 12,
      [theme.breakpoints.down("xs")]: {
        top: 12,
      },
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  checkBoxLabel: {
    margin: "0 10px 0 0 !important",
    "& .MuiCheckbox-root": {
      padding: "0 9px 0 0",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiFormControlLabel-label": {
        fontSize: 15,
      },
    },
  },
  serachList: {
    display: "none",
    position: "absolute",
    right: 0,
    left: 0,
    backgroundColor: "#fff",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 10,
    marginTop: 5,
    overflowY: "auto",
    maxHeight: 200,
    zIndex: 3,
  },
  searchOpen: {
    display: "block",
  },
  searchListItem: {
    display: "flex",
    alignItems: "center",
    padding: [[8, 20]],
    cursor: "pointer",
  },
  searchTitle: {
    width: 70,
    flex: "0 0 70px",
    fontWeight: "bold",
  },
  heading: {
    fontWeight: "bold",
    fontSize: 17,
    color: "#000",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    lineHeight: "1.75",
    marginBottom: 15,
    flexWrap: "wrap",
    // cursor: "pointer",
    /*  "&:hover": {
      textDecoration: "underline",
    }, */
    "& span": {
      verticalAlign: "middle",
      color: "#000",
    },
    "& img": {
      verticalAlign: "middle",
      marginLeft: 10,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  navHeading: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: theme.palette.primary.main,
    },
  },
  headingIcon: {
    display: "flex",
    alignItems: "center",
  },
  sentimentList: {
    borderRight: "1px solid #DEDEDE",
    paddingRight: 50,
  },
  filterTitle: {
    fontWeight: "bold",
    fontSize: 17,
    color: "#000",
    marginBottom: "15px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      marginBottom: 0,
    },
  },
  filterClearContainer: {
    display: "block",
    marginBottom: 10,
  },
  clearFiler: {
    cursor: "pointer",
    marginTop: 10,
    fontSize: 14,
    color: theme.palette.error.main,
    maxWidth: "fit-content",
    "& span": {
      verticalAlign: "middle",
      lineHeight: "normal",
    },
    "& svg": {
      verticalAlign: "middle",
      width: 15,
      height: 15,
    },
  },
  tickerClear: {
    marginLeft: "0 !important",
    marginBottom: 15,
    display: "inline-block",
  },
  filterSpacing: {
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        cursor: "pointer",
      },
    },
  },
  tableBtn: {
    paddingTop: 10,
    width: "fit-content",
    [theme.breakpoints.down("959")]: {
      left: 0,
      margin: "0 auto",
    },
    "& button": {
      padding: [[8, 10]],
      fontWeight: "bold",
      minWidth: "auto",
      "&:first-child img": {
        verticalAlign: "middle",
      },
      "&:last-child img": {
        verticalAlign: "middle",
      },
    },
    "& button img": {
      height: "0.875rem",
    },
  },
  imgDisable: {
    opacity: 0.3,
  },
  searchOrder: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      order: 1,
    },
  },
  filterOrder: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      order: 2,
      marginTop: 10,
    },
    [theme.breakpoints.down("440")]: {
      display: "block",
    },
  },
  tickersText: {
    [theme.breakpoints.down("440")]: {
      marginTop: 10,
    },
    [theme.breakpoints.up("md")]: {
      marginRight: 10,
    },
  },
  deskTopFilter: {
    position: "relative",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& .MuiGrid-container": {
      "& .MuiGrid-item": {
        width: "33.33%",
      },
    },
  },
  mobileFilter: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      "& .MuiAccordionSummary-root": {
        minHeight: 42,
        height: 42,
        padding: 0,
        flexDirection: "row-reverse",
        borderRadius: 10,
        maxWidth: "max-content",
        "& .Mui-expanded": {
          "& svg": {
            transform: "rotate(180deg)",
          },
        },
        "& .MuiIconButton-root": {
          marginRight: 0,
          marginLeft: -15,
        },
      },
      "& .MuiAccordionDetails-root": {
        paddingLeft: 0,
        paddingRight: 0,
        display: "block",
      },
      "& .MuiGrid-item": {
        width: "100%",
      },
    },
  },
  filterAccordion: {
    border: "1px solid #d4d6d8",
    minHeight: 42,
    height: 42,
  },
  filterHeader: {
    color: "#5b636a",
  },
  holdingsLeft: {
    paddingLeft: "30px !important",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px !important",
    },
  },
  holdingsRight: {
    paddingRight: "30px !important",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "16px !important",
    },
  },
  dropDownCheckBox: {
    marginLeft: 0,
    marginRight: 0,
  },
  holdingsContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    backgroundColor: "#d3d3d3",
  },
  mb0: {
    marginBottom: 0,
  },
  mt0: {
    marginTop: 0,
  },
  mobileFilterContainer: {
    display: "flex",
    alignItems: "center",
    color: "#000",
  },
  mlFilter: {
    marginRight: 5,
  },
  filterIconStyle: {
    width: 17,
  },
  blurContent: {
    "& .MuiTable-root": {
      WebkitFilter: "blur(5px)",
      filter: "blur(5px)",
    },
  },
  filterBlurBg: {
    position: "absolute",
    left: 0,
    top: 67,
    width: "100%",
    zIndex: "7",
    bottom: 0,
    color: "#ffffff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&:before": {
      content: "''",
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: "-1",
      backgroundColor: "rgba(0, 0, 0, 0.80)",
    },
    "& h3": {
      fontSize: 14,
      marginBottom: 15,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  arrow: {
    color: "white",
    border: "black",
  },
  dashboardDate: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
    "& .MuiButton-root": {
      minWidth: "auto",
      paddingRight: 0,
      "& svg": {
        [theme.breakpoints.down("xs")]: {
          fontSize: 20,
        },
      },
    },
  },
  filterLi: {
    backgroundColor: "transparent !important",
    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  tickersFrom: {
    display: "inline-block",
    [theme.breakpoints.down("600")]: {
      marginLeft: "5px",
    },
    [theme.breakpoints.down("440")]: {
      marginLeft: "-3px",
      marginTop: 5,
    },
  },
  premiumMsg: {
    [theme.breakpoints.up("960")]: {
      display: "none",
    },
    [theme.breakpoints.down("960")]: {
      textAlign: "center",
    },
    "& p": {
      marginBottom: 0,
    },
  },
  widgetBox: {
    borderBottom: "1px solid #ddd",
    marginBottom: 30,
    padding: [[0, 30, 30]],
    marginLeft: -20,
    marginRight: -20,
    "&:last-child": {
      marginBottom: 0,
      borderBottom: 0,
    },
  },
  customeTab: {
    justifyContent: "center",
    minHeight: 32,
    "& .MuiTabs-scrollable": {
      flexGrow: "0",
      "& .MuiTabs-flexContainer": {
        alignItems: "center",
        height: "100%",
        "& .MuiTab-root": {
          borderRadius: 30,
          marginRight: 0,
          padding: [[8, 20]],
          lineHeight: "normal",
          minHeight: "auto",
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            "& p": {
              color: "#fff",
            },
          },
        },
      },
    },
    "& .MuiTab-root": {
      textTransform: "capitalize",
      fontSize: 16,
      [theme.breakpoints.down("767")]: {
        minWidth: "auto",
      },
      [theme.breakpoints.down("380")]: {
        minWidth: "auto",
        padding: [[0, 6]],
      },
      "& p": {
        position: "relative",
        paddingBottom: 5,
        "&:before": {
          content: "''",
          position: "absolute",
          right: 0,
          left: 0,
          bottom: 0,
          height: 2,
          backgroundColor: "transparent",
          width: 30,
        },
      },
      "&.Mui-selected": {
        "& p": {
          "&:before": {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
    "& .MuiTabScrollButton-root": {
      height: 40,
      boxShadow: "0 0 8px rgba(0,0, 0, 0.3)",
      borderRadius: "100%",
      margin: 5,
    },
  },
  news: {
    display: "flex",
    marginTop: 20,
    paddingBottom: 20,
    borderBottom: "1px solid #ddd",
    "&:last-child": {
      borderBottom: 0,
      paddingBottom: 0,
    },
    [theme.breakpoints.down("640")]: {
      flexDirection: "column",
    },
  },
  newsInfo: {
    flex: 1,
    paddingRight: 20,
    [theme.breakpoints.down("640")]: {
      paddingRight: 0,
    },
    "& h6": {
      marginBottom: 10,
      fontSize: 18,
      fontWeight: "bold",
      [theme.breakpoints.down("640")]: {
        fontSize: 19,
      },
      "& a": {
        textDecoration: "none",
        color: "#051422",
        fontWeight: "bold",
        wordBreak: "break-word",
        [theme.breakpoints.down("640")]: {
          fontWeight: "700",
        },
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    },
    "& p": {
      wordBreak: "break-word",
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 3,
      color: "#787b86",
      [theme.breakpoints.down("640")]: {
        marginBottom: 25,
      },
    },
  },
  newsImage: {
    maxWidth: 200,
    width: "100%",
    [theme.breakpoints.down("640")]: {
      marginTop: 10,
      marginBottom: 20,
      maxWidth: "100%",
    },
    "& img": {
      display: "table",
      margin: "0 auto",
      borderRadius: 10,
    },
  },
  publisherNameDate: {
    color: "#787b86",
    marginBottom: 10,
    [theme.breakpoints.down("640")]: {
      marginBottom: 0,
      fontSize: 13,
    },
    "& span": {
      marginLeft: 10,
      paddingLeft: 10,
      borderLeft: "1px solid #ddd",
    },
  },
  desktopPublisherNameDate: {
    [theme.breakpoints.down("640")]: {
      display: "none",
    },
  },
  mobilePublisherNameDate: {
    [theme.breakpoints.up("640")]: {
      display: "none",
    },
  },
  newsWrap: {
    // marginTop: 20,
    // paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
    // borderTop: "1px solid #ddd",
  },
  analystsTab: {
    borderBottom: "1px solid #ddd",
    "& .MuiTab-root": {
      "&.MuiButtonBase-root": {
        minHeight: 43,
        color: "#000",
        textTransform: "capitalize",
        fontSize: 17,
        "&:first-child": {
          borderRadius: "5px 0 0 5px",
        },
        "&:last-child": {
          borderRadius: "0 5px 5px 0",
        },
        "& p": {
          margin: 0,
          padding: 0,
          color: "#000",
          "&:before": {
            content: "normal",
          },
          "&:hover": {
            [theme.breakpoints.up("md")]: {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
  },
  showTickerFilter: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.between("960", "1199")]: {
      display: "block",
    },
    [theme.breakpoints.down("960")]: {
      "& .MuiFormControlLabel-root": {
        marginRight: 10,
        "& .MuiTypography-root": {
          fontSize: 12,
          marginLeft: -10,
        },
        "& .MuiRadio-root": {
          transform: "scale(0.7)",
        },
      },
    },
    [theme.breakpoints.down("680")]: {
      display: "block",
    },
    [theme.breakpoints.down("375")]: {
      "& .MuiBox-root": {
        display: "block",
      },
      "& .MuiFormGroup-row": {
        justifyContent: "center",
      },
    },
  },
  tickersFilter: {
    paddingBottom: 0,
  },
  titleLeft: {
    "& > .MuiTypography-root": {
      float: "left",
      marginTop: 0,
      "&:nth-child(2)": {
        float: "right",
      },
    },
  },
  mobileTab: {
    "& .MuiTabs-root": {
      marginBottom: 15,
      borderBottom: "2px solid #E1E3EA",
    },
    "& .MuiTypography-root": {
      "& > .MuiTypography-root": {
        display: "none",
      },
    },
  },
  priceBetween: {
    display: "flex",
    margin: [[0, -15]],
    "& .MuiFormControl-root": {
      padding: [[0, 15]],
    },
  },
  unusualVolumeFilter: {
    "& .MuiGrid-root": {
      "& .MuiGrid-item": {
        width: "25%",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
      },
    },
  },
  priceFilter: {
    "& .MuiMenu-paper": {
      "& .MuiMenu-list": {
        padding: 0,
      },
    },
  },
  accordionWrap: {
    margin: "0 !important",
    "&.MuiAccordion-root": {
      "&:before": {
        display: "none",
      },
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    "&.MuiAccordion-root.Mui-expanded": {
      padding: "8px 0",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "inherit",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: 0,
    },
    "& .MuiAccordionDetails-root": {
      padding: "0 8px",
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "inherit",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#fff",
    },
  },
  priceFilterInput: {
    "& .MuiOutlinedInput-root": {
      position: "relative",
      "&:before": {
        position: "absolute",
        // content: "''",
        borderTop: "5px solid #000",
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent",
        right: 14,
      },
    },
    "& .MuiInputBase-input": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px !important",
      },
    },
  },
  boxMargin: {
    margin: "145px 0 100px",
    [theme.breakpoints.down("767")]: {
      margin: "80px 0 30px",
    },
  },
  landingCard: {
    boxShadow: "none",
    border: "2px solid #E1E3EA",
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    "& .MuiTabs-root": {
      "& .MuiTab-root": {
        "&.MuiButtonBase-root": {
          textTransform: "capitalize",
          "&.Mui-selected": {
            // backgroundColor: "#39b799",
            // borderColor: "#39b799",
          },
        },
      },
    },
    "& .MuiPaper-root": {
      flex: 1,
    },
  },
  topMoversLandingCard: {
    height: "auto",
  },
  selectArrow: {
    position: "absolute",
    right: 8,
    pointerEvents: "none",
  },
  tableTitle: {
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  firstTables: {
    paddingBottom: 30,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 16,
    },
  },
  tweetHeading: {
    paddingBottom: 15,
    borderBottom: "2px solid #E1E3EA",
  },
  earningsHeading: {
    marginBottom: 15,
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      justifyContent: "start",
      paddingLeft: 40,
      "& a": {
        width: "max-content",
        marginLeft: 0,
        marginTop: 10,
      },
    },
  },
  earningCalanderS: {
    padding: 0,
  },
  tweetMain: {
    marginBottom: 30,
  },
  trendingTickers: {
    "& .MuiTable-root": {
      "& .MuiTableHead-root": {
        display: "none",
      },
      "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
          "& .MuiTableCell-body": {
            "&:first-child": {
              width: "30%",
              "& .MuiBox-root": {
                maxWidth: 130,
                [theme.breakpoints.down("md")]: {
                  maxWidth: "50%",
                },
                [theme.breakpoints.down("sm")]: {
                  maxWidth: "40%",
                },
                [theme.breakpoints.down("480")]: {
                  maxWidth: "30%",
                },
                [theme.breakpoints.down("380")]: {
                  maxWidth: "20%",
                },
              },
            },
          },
        },
      },
    },
  },
  landingAnalystsTable: {
    "& .MuiTableHead-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
          "&:nth-child(2)": {
            textAlign: "left",
          },
          "&:nth-child(3)": {
            textAlign: "left",
            width: "18%",
          },
          "&:nth-child(6)": {
            width: "16%",
          },
          "&:nth-child(7)": {
            width: "16%",
          },
        },
      },
    },
    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-body": {
          "&:nth-child(2)": {
            "& a": {
              textAlign: "left",
            },
          },
          "&:nth-child(3)": {
            width: "18%",
            "& span": {
              marginLeft: 0,
              textAlign: "left",
            },
          },
          "&:nth-child(6)": {
            width: "16%",
          },
          "&:nth-child(7)": {
            width: "16%",
          },
        },
      },
    },
  },
  landingUpDownTable: {
    "& .MuiTableHead-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
          "&:nth-child(2)": {
            textAlign: "left",
          },
          "&:nth-child(3)": {
            marginLeft: 0,
            textAlign: "left",
            width: "20%",
          },
          "&:nth-child(7)": {
            marginLeft: 0,
            textAlign: "left",
          },
        },
      },
    },
    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-body": {
          "&:nth-child(2)": {
            "& a": {
              textAlign: "left",
            },
          },
          "&:nth-child(3)": {
            width: "20%",
            "& span": {
              marginLeft: 0,
              textAlign: "left",
            },
          },
          "&:nth-child(7)": {
            marginLeft: 0,
            textAlign: "left",
          },
        },
      },
    },
  },
  landingTargetTable: {
    "& .MuiTableHead-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
          "&:nth-child(2)": {
            textAlign: "left",
          },
          "&:nth-child(3)": {
            width: "20%",
            marginLeft: 0,
            textAlign: "left",
          },
          "&:nth-child(7)": {
            marginLeft: 0,
            textAlign: "left",
          },
        },
      },
    },
    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-body": {
          "&:nth-child(2)": {
            "& a": {
              textAlign: "left",
            },
          },
          "&:nth-child(3)": {
            width: "20%",
            "& span": {
              marginLeft: 0,
              textAlign: "left",
            },
          },
          "&:nth-child(7)": {
            marginLeft: 0,
            textAlign: "left",
          },
        },
      },
    },
  },
  landingInsiderTable: {
    "& .MuiTable-root": {
      "& .MuiTableHead-root": {
        "& .MuiTableRow-root": {
          "& .MuiTableCell-root": {
            "&:nth-child(2)": {
              textAlign: "left",
            },
            "&:nth-child(3)": {
              width: "20%",
              marginLeft: 0,
              textAlign: "left",
            },
            "&:nth-child(4)": {
              textAlign: "left",
              width: "25%",
            },
          },
        },
      },
      "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
          "& .MuiTableCell-body": {
            "&:nth-child(2)": {
              "& a": {
                textAlign: "left",
              },
            },
            "&:nth-child(3)": {
              width: "20%",
              "& span": {
                marginLeft: 0,
                textAlign: "left",
              },
            },
            "&:nth-child(4)": {
              textAlign: "left",
              width: "25%",
              "& span": {
                marginLeft: 0,
                minWidth: 250,
                maxWidth: 250,
              },
            },
          },
        },
      },
    },
  },
  insiderFilter: {
    "& .MuiGrid-root": {
      "& .MuiGrid-item": {
        [theme.breakpoints.up("sm")]: {
          width: "20%",
        },
      },
    },
  },
  insiderTradesFilter: {
    "& .MuiGrid-root": {
      "& .MuiGrid-item": {
        [theme.breakpoints.up("sm")]: {
          width: "20%",
        },
      },
    },
  },
  insiderPagination: {
    "& .MuiButtonBase-root": {
      "&:last-child": {
        cursor: "pointer",
        pointerEvents: "all",
        "& img": {
          opacity: 1,
        },
      },
    },
  },
  overviewFeaturedBox: {
    marginBottom: 20,
  },
  recentTradeHeading: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 0,
    },
  },
  dropdownHome: {
    maxWidth: 900,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  mobileFilterDarkThemeAccordian: {
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },
  },
}));
