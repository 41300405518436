import React, { useEffect } from "react";
import { ACTIONS, EVENTS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useJoyrideStyles from "../../hooks/useJoyrideStyles";
import { updateDashboardGuideState } from "../../redux/dashboardGuide/actions";
import { pages } from "../../utils/constants";
import { getDashboardSteps } from "../../utils/steps";
import Joyride from "../Tour/Joyride";
import { hideInAppGuide } from "../../redux/auth/action";

const DashboardGuide = ({ page }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const joyrideStyles = useJoyrideStyles();
  const { run, stepIndex, isTourActive } = useSelector(
    (state) => state.dashboardGuide
  );
  const { userInfo } = useSelector((state) => state.signIn.loggedUser);
  const { isSuccess } = useSelector((state) => state.watchListReducer);

  useEffect(() => {
    if (isTourActive) {
      dispatch(updateDashboardGuideState({ run: true }));
    }
    if (stepIndex === 6) {
      const aiTopPicks = document.getElementById("ai-top-etfs");
      aiTopPicks?.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    if (page === "home" && isSuccess && userInfo?.showAppGuide) {
      dispatch(updateDashboardGuideState({ run: true, isTourActive: true }));
    }
  }, [isSuccess, userInfo?.showAppGuide]);

  return (
    <>
      <Joyride
        disableScrollParentFix
        callback={async ({
          action,
          index,
          type,
          step: { data: { previous, next } = {} },
        }) => {
          const isPreviousAction = action === ACTIONS.PREV;
          const isNextAction = action === ACTIONS.NEXT;

          // Reset state on tour end
          if (type === EVENTS.TOUR_END) {
            dispatch(
              updateDashboardGuideState({
                run: false,
                stepIndex: 0,
                isTourActive: false,
              })
            );
            dispatch(hideInAppGuide());
            return;
          }

          // Move to next step if the target is not found
          if (type === EVENTS.TARGET_NOT_FOUND) {
            dispatch(
              updateDashboardGuideState({
                stepIndex: action === ACTIONS.NEXT ? index + 1 : index - 1,
              })
            );
            return;
          }

          if (type === EVENTS.STEP_AFTER) {
            if (action === ACTIONS.NEXT || action === ACTIONS.PREV) {
              dispatch(
                updateDashboardGuideState({
                  stepIndex:
                    action === ACTIONS.NEXT ? stepIndex + 1 : stepIndex - 1,
                })
              );
            }

            // If the tooltip is for AI Top ETFs, stop the tour and redirect to chat page. The tour will be started from the that page.
            if (index === 6) {
              if (isNextAction && next) {
                dispatch(updateDashboardGuideState({ run: false }));
                history.push(next);
              }
            }
            // If the tooltip is for AI Chat, stop the tour and redirect to Home page. The tour will be started from the that page.
            if (index === 7) {
              if (isPreviousAction && previous) {
                dispatch(
                  updateDashboardGuideState({ run: false, stepIndex: 6 })
                );
                history.push(previous);
              }
            }
          }

          // To scroll the element into view in mobile screens
          if (action === ACTIONS.PREV && index === 4) {
            const accountInfo = document.getElementById("account-info");
            accountInfo?.scrollIntoView();
          }
          if (
            (action === ACTIONS.NEXT || action === ACTIONS.PREV) &&
            index === 5
          ) {
            const aiTopPicks = document.getElementById("ai-top-picks");
            aiTopPicks?.scrollIntoView();
          }
        }}
        steps={getDashboardSteps}
        run={run}
        stepIndex={stepIndex}
        continuous
        styles={joyrideStyles}
        scrollOffset={400}
        page={pages.DASHBOARD}
        controlled
        hideManualTourButton={page === "chat"}
      />
    </>
  );
};

export default DashboardGuide;
