import {
  Box,
  Container,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import axios from "axios";
import moment from "moment-timezone";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as LayerGroupIcon } from "../../assets/images/layer-group.svg";
import { ReactComponent as LayerPlusIcon } from "../../assets/images/layer-plus.svg";
import { toastMessage } from "../../redux/actions";
import Loader from "../Common/Loader";
import ChatOptions from "./ChatOptions";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    letterSpacing: -1,
    fontSize: 22,
    marginBottom: 12,
    display: "flex",
    alignItems: "center",
    "& svg": {
      width: 20,
      height: 24,
      marginRight: 8,
    },
  },
  chatTitle: {
    "& .MuiTypography-root": {
      fontSize: 18,
      fontWeight: 700,
    },
    color: "#000",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  brief: {
    "& .MuiTypography-root": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
    },
    color: "#000",
    textDecoration: "none",
  },
  footerInfo: {
    color: "gray",
    fontSize: 13,
    display: "flex",
    alignItems: "center",
    "& svg": {
      height: 16,
      width: 16,
      marginRight: 4,
    },
  },
}));

const ChatHistory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState();

  const classes = useStyles();
  const dispatch = useDispatch();
  const { idToken } = useSelector((state) => state.signIn.loggedUser);

  const getChatHistory = async () => {
    const url = `${process.env.REACT_APP_CHAT_API_URL}?method=history&start=0&limit=20`;

    try {
      setIsLoading(true);
      const { data } = await axios.get(url, {
        headers: {
          Authorization: idToken,
        },
      });
      setHistory(data);
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: "Could not fetch chat history. Please try again later.",
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = (chatId) => {
    setHistory((prev) => prev.filter((chat) => chat.chatId !== chatId));
  };
  const onUpdate = ({ chatId, chatName }) => {
    setHistory((prev) => {
      const clonedHistory = [...prev];
      const index = clonedHistory.findIndex((chat) => chat.chatId === chatId);

      if (~index) {
        clonedHistory.splice(index, 1, { ...prev[index], chatName });
      }

      return clonedHistory;
    });
  };

  useEffect(() => {
    getChatHistory();
  }, []);

  if (isLoading) return <Loader isLoad={isLoading} />;

  return (
    <Container>
      <Typography variant="h5" className={classes.title}>
        <LayerGroupIcon /> History
      </Typography>

      <Box>
        {history?.length ? (
          history.map(
            ({ chatId, chatName, snippet, lastQueryDate, qCount }) => (
              <Fragment key={chatId}>
                <Divider light />
                <Box py={3}>
                  <Box
                    display="flex"
                    alignItems="start"
                    justifyContent="space-between"
                    mb={0.5}
                  >
                    <Link to={`/chat/${chatId}`} className={classes.chatTitle}>
                      <Typography variant="h5">{chatName}</Typography>
                    </Link>
                    <ChatOptions
                      chatId={chatId}
                      chatName={chatName}
                      onDelete={onDelete}
                      onUpdate={onUpdate}
                    />
                  </Box>
                  {snippet && (
                    <Link to={`/chat/${chatId}`} className={classes.brief}>
                      <Typography>{snippet}</Typography>
                    </Link>
                  )}
                  <Box
                    display="flex"
                    alignItems="center"
                    gridColumnGap={14}
                    mt={1.5}
                  >
                    <Typography className={classes.footerInfo}>
                      <LayerPlusIcon /> {qCount}
                    </Typography>
                    {!!lastQueryDate && (
                      <Typography className={classes.footerInfo}>
                        <AccessTimeIcon /> {moment(lastQueryDate).fromNow()}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Fragment>
            )
          )
        ) : (
          <>
            <Divider />
            <Typography
              color="textSecondary"
              style={{ marginTop: 30, textAlign: "center" }}
            >
              No chats found.
            </Typography>
          </>
        )}
      </Box>
    </Container>
  );
};

export default ChatHistory;
