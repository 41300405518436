import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { ReactComponent as ThumbUpOutlinedIcon } from "../../assets/images/thumbs-up-outlined.svg";
import { ReactComponent as ThumbUpIcon } from "../../assets/images/thumbs-up.svg";
import axios from "axios";
import { useSelector } from "react-redux";

const LikeQueryButton = ({ liked, queryId }) => {
  const [isLiked, setIsLiked] = useState(liked);

  const { idToken } = useSelector((state) => state.signIn.loggedUser);

  const likeQuery = async () => {
    if (isLiked) return;

    setIsLiked(true);

    try {
      await axios.post(
        process.env.REACT_APP_CHAT_API_URL,
        {
          method: "like",
          queryId,
        },
        {
          headers: {
            Authorization: idToken,
          },
        }
      );
    } catch (error) {
      setIsLiked(false);
    }
  };

  return (
    <Button
      variant="contained"
      disableElevation
      startIcon={isLiked ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
      disabled={isLiked}
      style={{ marginLeft: "auto" }}
      onClick={likeQuery}
    >
      {isLiked ? "Liked" : "Like"}
    </Button>
  );
};

export default LikeQueryButton;
