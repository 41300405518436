import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Amplify, { Auth, Hub } from "aws-amplify";
import React, { Suspense, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import Loader from "./components/Common/Loader";
import SignUpModel from "./components/Common/SignUpModel";
import ToastSnackbar from "./components/Common/ToastSnackbar";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import ClippedDrawer from "./components/Sidebar/ClippedDrawer";
import UserInfoModal from "./components/SignUp/UserInfoModal";
import {
  getBannerStatus,
  setWindowWidth,
  signInSuccess,
  toastMessage,
} from "./redux/actions";
import { getPost, userPost } from "./redux/api/authApis";
import { signInClearAction } from "./redux/auth/action";
import { LoaderAction } from "./redux/loader/action";
import { setRedirectUrl } from "./redux/redirectTo/action";
import Routing from "./routes/Routing";
import { isLoggedIn, setLoggedUser } from "./utils/authentication";
import { googleAnalyticsOnLogin } from "./utils/common";
import { pathsToUntrack } from "./utils/constants";
import NotificationBanner from "./shared/NotificationBanner";

const useStyles = makeStyles((theme) => ({
  mainContaint: {
    backgroundColor: "#fff",
    marginLeft: 230,
    padding: [[0, 20, 20, 20]],
    minHeight: "calc(100vh - 180px)",
    // overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down("400")]: {
      padding: [[0, 15, 15, 15]],
    },
  },
  withoutWidget: {
    marginTop: 80,
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      marginTop: 55,
      padding: [[15, 15, 15, 15]],
    },
  },
  topBannerFixed: {
    position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    height: 55,
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translateY(-100%)",
    borderBottom: "2px solid rgba(0, 0, 0, 0.15)",
    borderRadius: 0,
    fontSize: 16,
    transition:
      "transform 0.8s cubic-bezier(0.300, 0.000, 0.175, 1.000) !important;",
    [theme.breakpoints.down("576")]: {
      justifyContent: "flex-start",
    },
  },
  bannerCloseIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    width: 55,
    borderRadius: 0,
    height: 55,
    borderLeft: "2px solid rgba(0, 0, 0, 0.15)",
    color: "inherit",
  },
  bannerText: {
    [theme.breakpoints.down("576")]: {
      width: "80%",
    },
  },
}));

const theme = createTheme({
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      main: "#059e97",
    },
    secondary: {
      main: "#059e97",
      // main: "#00665e",
      light: "#ffffff",
      grey: "#909090",
    },
    error: {
      main: "#CD0909",
      dark: "#CD0909",
    },
    success: {
      main: "#0AB799",
    },
    green: { main: "#07846f" },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    h1: {
      fontFamily: '"Mulish", sans-serif',
    },
    h2: {
      fontFamily: '"Mulish", sans-serif',
    },
    h3: {
      fontFamily: '"Mulish", sans-serif',
    },
    h4: {
      fontFamily: '"Mulish", sans-serif',
    },
    h5: {
      fontFamily: '"Mulish", sans-serif',
    },
    h6: {
      fontFamily: '"Mulish", sans-serif',
    },
  },
});

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: process.env.REACT_APP_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_APP_ID,
  },
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
    responseType: "token",
  },
});

ReactGA.initialize("G-MCWJ8MZ6FH");
/* ReactGA.initialize("G-MCWJ8MZ6FH", {
  debug: false,
  titleCase: false,
  redactEmail: false,
}); */

const logoutRoutes = [
  "/sign-in",
  "/sign-up",
  "/about",
  "/plans",
  "/top-stock",
  "/trends",
  "/multichart",
  "/institution",
  "/economy",
  "/politics",
  "/politician",
  "/investor",
  "/analyst",
  "/insider",
  "/news",
  "/tweets",
  "/stocks",
  "/quote",
  "/forecast",
  "/contact",
  "/advertise",
  "/earnings",
  "/forgot-password",
  "/set-new-password",
  "/billing-faq",
  "/privacy-policy",
  "/term-and-condition",
  "/disclosure",
  "/confirmation",
  "/calendar",
  "confirmPayment",
  "/toppicks",
  "/topetfs",
  "/chat",
  "/screener",
  "/heatmap",
];
const bannerRoutes = [
  "/sign-in",
  "/sign-up",
  "/about",
  "/plans",
  "/top-stock",
  "/trends",
  "/multichart",
  "/institution",
  "/economy",
  "/politics",
  "/politician",
  "/investor",
  "/analyst",
  "/insider",
  "/news",
  "/tweets",
  "/stocks",
  "/quote",
  "/forecast",
  "/contact",
  "/advertise",
  "/earnings",
  "/billing-faq",
  "/privacy-policy",
  "/term-and-condition",
  "/disclosure",
  "/confirmation",
  "/calendar",
  "/",
];

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const isLog = useSelector((state) => state.signIn.loggedUser);
  const idToken = isLog?.idToken;
  const logInData = isLoggedIn();

  if (logInData && logInData.accessToken && !Object.keys(isLog).length) {
    dispatch(signInSuccess(logInData));
  }
  const { banner: landingBanner } = useSelector(
    (state) => state.watchListReducer
  );
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    const unregister = history.listen(({ pathname }) => {
      const ignoreHomeDeepLinking = localStorage.getItem(
        "ignoreHomeDeepLinking"
      );

      if (!pathsToUntrack.includes(pathname) && !idToken) {
        if (ignoreHomeDeepLinking) {
          if (pathname !== "/" && pathname !== "/home") {
            dispatch(setRedirectUrl(pathname));
          }
        } else {
          dispatch(setRedirectUrl(pathname));
        }
      }
    });

    return unregister;
  }, [idToken]);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => {
            if (userData?.username) {
              dispatch(LoaderAction(true));
              isLogin(userData);
            }
          });
          break;
        case "signOut":
          dispatch(signInClearAction());
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          dispatch(
            toastMessage({
              status: true,
              message: data.message || "SignIn fail. Try again signIn",
              type: "error",
            })
          );
          break;
        default:
          break;
      }
    });
    getUser().then((userData) => {
      if (userData?.username) {
        dispatch(LoaderAction(true));
        isLogin(userData);
      }
    });
    dispatch(setWindowWidth({ width: window.innerWidth }));

    const ignoreHomeDeepLinking = localStorage.getItem("ignoreHomeDeepLinking");

    if (!pathsToUntrack.includes(pathname) && !idToken) {
      if (ignoreHomeDeepLinking) {
        if (
          history.location.pathname !== "/" &&
          history.location.pathname !== "/home"
        ) {
          dispatch(setRedirectUrl(history.location.pathname));
        }
      } else {
        dispatch(setRedirectUrl(history.location.pathname));
      }
    }
  }, []);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
      dispatch(setWindowWidth({ width: window.innerWidth }));
    }, 50);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });
  const path = window.location.pathname;
  useEffect(() => {
    const { pathname } = window.location;
    const routesNames = [
      "/sign-in",
      "/sign-up",
      "/forgot-password",
      "/set-new-password",
    ];
    if (routesNames.includes(pathname)) {
      return setShowHeader(false);
    }
    return setShowHeader(true);
  }, [window.location.pathname]);

  const getUser = () => {
    const { pathname } = window.location;
    const segments = pathname.split("/");
    let finalPath = pathname;
    if (segments.length >= 2) {
      finalPath = `/${segments[1]}`;
    }
    if (!logInData?.accessToken && finalPath !== "/confirmPayment") {
      const type =
        localStorage.getItem("type") || logoutRoutes.includes(finalPath)
          ? false
          : "/";
      if (type) {
        history.push(type);
      }
    }
    dispatch(LoaderAction(true));
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch((err) => {
        if (err === "The user is not authenticated") {
          dispatch(signInClearAction());
        }
      });
  };

  const isLogin = async (data) => {
    const isGoogleLogin = data?.username?.includes("google");
    dispatch(LoaderAction(true));
    if (isGoogleLogin) {
      const { idToken, accessToken } = data.signInUserSession;
      const userInfo = {
        email: idToken?.payload?.email,
        name: idToken?.payload?.name,
      };
      if (idToken.jwtToken) {
        const token = {
          accessToken: accessToken && accessToken.jwtToken,
          idToken: idToken && idToken.jwtToken,
          loginType: "google",
          userData: userInfo,
        };
        postUserData(token);
      }
    } else {
      const { accessToken, idToken, refreshToken } = data.signInUserSession;
      const token = {
        accessToken: accessToken && accessToken.jwtToken,
        idToken: idToken && idToken.jwtToken,
        refresh: refreshToken && refreshToken.token,
        loginType: "normal",
        userData: data.attributes,
      };
      dispatch(LoaderAction(false));
      if (data?.attributes?.identities) {
        const identities = JSON.parse(data?.attributes?.identities).find(
          (p) => p.providerType === "Google"
        );
        const isGoogleAuth = identities ? identities : {};
        return setUserPostData(token, isGoogleAuth?.providerType);
      }
      return setUserPostData(token);
    }
  };

  const postUserData = async (token) => {
    dispatch(LoaderAction(true));

    try {
      dispatch(LoaderAction(true));
      const res = await getPost(token.idToken);
      if (res.status === 200) {
        const resData = res && res.data && res.data[0];
        if (!(resData && resData.userType)) {
          setUserPostData(token);
        } else {
          const updateData = {
            ...token,
            userInfo: resData,
          };
          dispatch(signInSuccess(updateData));
          setLoggedUser(updateData);
          if (
            window.location.pathname === "/sign-in" ||
            window.location.pathname === "/sign-up" ||
            window.location.pathname === "/"
          ) {
            localStorage.removeItem("type");
            dispatch(LoaderAction(false));
            history.push("/home");
          }
        }
      } else {
        dispatch(LoaderAction(false));
      }
    } catch (error) {
      const { data } = error && error.response;
      if (data.statusCode === 500) {
        setUserPostData(token);
      } else {
        dispatch(LoaderAction(false));
      }
    }
  };

  const setUserPostData = async (token, loginType) => {
    if (
      window.location.pathname !== "/sign-up" &&
      window.location.pathname !== "/account"
    ) {
      dispatch(LoaderAction(true));
      const routesName = ["/sign-up", "/sign-in"];
      try {
        const res = await getPost(token.idToken);
        if (res.status === 200) {
          const userDetails = res && res.data && res.data[0];
          const updateData = {
            ...token,
            userInfo: userDetails,
          };
          dispatch(signInSuccess(updateData));
          setLoggedUser(updateData);
          if (
            routesName.includes(window.location.pathname) &&
            loginType === "Google" &&
            userDetails.username
          ) {
            history.push("/home");
            localStorage.removeItem("type");
            dispatch(LoaderAction(false));
          } else {
            dispatch(LoaderAction(false));
          }
        } else {
          dispatch(LoaderAction(false));
        }
      } catch (error) {
        dispatch(LoaderAction(false));
        dispatch(signInClearAction());
      }
    }
  };

  const paths = ["/home"];
  const pathname = window.location.pathname;
  const [showModel, setShowModel] = useState(false);
  const handleClose = (value) => {
    setShowModel(false);
  };
  const isBanner = JSON.parse(localStorage.getItem("isBanner"));
  const { isLandingBanner } = useSelector((state) => state.watchListReducer);
  const [banner, setBanner] = useState(isBanner);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    const id = setTimeout(() => {
      if (
        !logInData?.idToken &&
        pathname !== "/sign-up" &&
        pathname !== "/sign-in" &&
        pathname !== "/account"
      ) {
        setShowModel(true);
      }
    }, 15000);

    setTimeoutId(id);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [pathname]);

  useEffect(() => {
    return history.listen(() => {
      clearTimeout(timeoutId);
    });
  }, [history, timeoutId]);

  const notAddGoogleAds = [
    "/plans",
    "/sign-in",
    "/sign-up",
    "/about",
    "/term-and-condition",
    "/privacy-policy",
    "/contact",
    "/advertise",
  ];

  useEffect(() => {
    setBanner(isBanner);
  }, [isBanner]);

  const onClose = () => {
    localStorage.setItem("isBanner", false);
    setBanner(false);
  };
  const onLandingBannerClose = () => {
    dispatch(getBannerStatus(false));
  };
  useEffect(() => {
    if (banner && isLog?.idToken?.length > 0 && isLog?.userInfo?.message) {
      document.body.classList.add("topBannerShow");
    } else {
      document.body.classList.remove("topBannerShow");
    }
  }, [banner, isLog?.idToken, isLog?.userInfo]);
  useEffect(() => {
    if (!isLog?.idToken) {
      if (
        isLandingBanner &&
        landingBanner?.length > 0 &&
        bannerRoutes.includes(path)
      ) {
        document.body.classList.add("topBannerShow");
      } else {
        document.body.classList.remove("topBannerShow");
        document.body.classList.add("removePadding");
      }
    }
  }, [isLandingBanner, landingBanner, path, isLog?.idToken]);

  //Auth.currentSession()
  useEffect(() => {
    const getCurrentSession = async () => {
      try {
        const result = await Auth.currentSession();
        const { accessToken, idToken, refreshToken } = result;
        const token = {
          accessToken: accessToken && accessToken.jwtToken,
          idToken: idToken && idToken.jwtToken,
          refresh: refreshToken && refreshToken.token,
        };
        if (idToken?.payload?.email) {
          googleAnalyticsOnLogin(idToken?.payload?.email);
        }
        dispatch(LoaderAction(false));
        setLoggedUser(token);
      } catch (error) {
        // console.log("Error retrieving current session:", error);
      }
    };

    getCurrentSession();
  }, []);

  return (
    <div className="main_content">
      <ThemeProvider theme={theme}>
        <ToastSnackbar />
        <Loader />
        {showHeader && <Header login={isLog.idToken} />}
        {showHeader && isLog.idToken && <ClippedDrawer />}
        <Suspense fallback={<Loader />}>
          <div
            className={
              isLog.idToken && showHeader
                ? `${classes.mainContaint} ${
                    !paths.includes(pathname) ? classes.withoutWidget : ""
                  }`
                : ""
            }
          >
            {landingBanner?.length > 0 && !isLog?.idToken && (
              <div
                className={`${classes.topBannerFixed} bannerShow`}
                style={{ backgroundColor: landingBanner[0]?.bgColor }}
              >
                {/*  <Typography
                  variant="body1"
                  className={classes.bannerText}
                  style={{ color: landingBanner[0]?.textColor }}
                >
                  {landingBanner[0]?.message}
                </Typography> */}
                <Typography
                  variant="body1"
                  className={classes.bannerText}
                  dangerouslySetInnerHTML={{
                    __html: landingBanner[0]?.message,
                  }}
                  style={{ color: landingBanner[0]?.textColor }}
                />
                <IconButton
                  aria-label="close"
                  className={classes.bannerCloseIcon}
                  onClick={onLandingBannerClose}
                  style={{ color: landingBanner[0]?.textColor }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
            {isLog?.idToken?.length > 0 && isLog?.userInfo?.message && (
              <div
                className={`${classes.topBannerFixed} bannerShow`}
                style={{ backgroundColor: isLog?.userInfo?.bgColor }}
              >
                <Typography
                  variant="body1"
                  className={classes.bannerText}
                  dangerouslySetInnerHTML={{
                    __html: isLog?.userInfo?.message,
                  }}
                  style={{ color: isLog?.userInfo?.textColor }}
                />
                <IconButton
                  aria-label="close"
                  className={classes.bannerCloseIcon}
                  onClick={onClose}
                  style={{ color: isLog?.userInfo?.textColor }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
            <NotificationBanner />

            <Routing dimensions={dimensions} />
          </div>
        </Suspense>
        {/* {!isLog.idToken && !notAddGoogleAds.includes(pathname) && (
          <Box pb={3}>
            <Container maxWidth="lg">
              <CardBox>
                <GoogleAdsense slotId="8044093042" />
              </CardBox>
            </Container>
          </Box>
        )} */}
        <SignUpModel
          open={showModel && pathname !== "/toppicks"}
          handleClose={handleClose}
        />
        <UserInfoModal />
        <Footer login={isLog.idToken} dimensions={dimensions} />
      </ThemeProvider>
    </div>
  );
}

export default withRouter(App);
