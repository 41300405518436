import { Button, TextField } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastMessage } from "../../redux/actions";
import { LoaderAction } from "../../redux/loader/action";
import Modal, { DialogActions, DialogContent } from "../../shared/Modal";

const EditChatModal = ({ chatId, chatName, open, onClose, onUpdate }) => {
  const [newChatName, setNewChatName] = useState(chatName);

  const dispatch = useDispatch();
  const { idToken } = useSelector((state) => state.signIn.loggedUser);

  const updateChat = async () => {
    if (!newChatName) return;

    const url = `${process.env.REACT_APP_CHAT_API_URL}?method=history&start=0&limit=20`;
    const data = { chatId, chatName: newChatName };
    try {
      dispatch(LoaderAction(true));
      await axios.put(url, data, {
        headers: {
          Authorization: idToken,
        },
      });
      onUpdate(data);
      onClose();
      dispatch(
        toastMessage({
          status: true,
          message: "Chat name updated successfully.",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: "Could not fetch chat history. Please try again later.",
          type: "error",
        })
      );
    } finally {
      dispatch(LoaderAction(false));
    }
  };

  return (
    <Modal
      title="Edit chat name"
      onClose={onClose}
      open={open}
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: 500,
        },
      }}
    >
      <DialogContent dividers style={{ paddingTop: 40, paddingBottom: 40 }}>
        <TextField
          autoFocus
          fullWidth
          variant="outlined"
          label="Chat name"
          placeholder="Enter new chat name"
          InputLabelProps={{
            shrink: true,
          }}
          value={newChatName}
          onChange={(e) => setNewChatName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={updateChat}
          disabled={!newChatName}
        >
          Update
        </Button>
      </DialogActions>
    </Modal>
  );
};

export default EditChatModal;
