import * as actions from '../../redux/actions';

export const ToastAction = (payload) => {
    return async (dispatch) => {
        dispatch(actions.toastMessage(payload))
    }
};
export const ClearToast = (payload) => {
    return async (dispatch) => {
        dispatch(actions.clearToast(payload))
    }
};
