import { Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import React from "react";
import { ReactComponent as ClipboardIcon } from "../../assets/images/clipboard.svg";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";

const CopyAnswerButton = ({ answer }) => {
  const { copy, isCopied } = useCopyToClipboard();

  return (
    <Button
      variant="contained"
      disableElevation
      startIcon={isCopied ? <CheckIcon /> : <ClipboardIcon />}
      onClick={() => copy(answer)}
    >
      {isCopied ? "Copied" : "Copy"}
    </Button>
  );
};

export default CopyAnswerButton;
