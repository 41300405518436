/* eslint-disable */
import React, { Fragment, useState } from "react";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import { signInClearAction } from "../../redux/auth/action";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Collapse, Hidden, IconButton, ListItemIcon } from "@material-ui/core";
import { ReactComponent as MenuIcon } from "../../assets/images/menu_icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/menu_close.svg";
import { ReactComponent as Politician } from "../../assets/images/politician.svg";
import CustomButton from "../Common/CustomButton";
import CustomSurveyModel from "../Common/CustomSurveyModel";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { setDeepLinkUrl } from "../../utils/authentication";

const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
  sidebarIcon: {
    minWidth: "52px",
  },
  root: {
    display: "flex",
    position: "relative",
    zIndex: 1502,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: 80,
    height: "calc(100% - 80px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      top: "auto",
      bottom: 0,
      marginTop: 55,
      height: "auto",
      borderRight: 0,
    },
  },
  drawerContainer: {
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
    "& .MuiList-padding": {
      padding: 0,
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
      "& .MuiButtonBase-root": {
        "&:nth-child(5)": {
          "& svg": {
            marginRight: 21,
            [theme.breakpoints.down("sm")]: {
              marginRight: 0,
              width: 35,
            },
          },
        },
      },
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuItem: {
    color: "#505050",
    "& .MuiTypography-root": {
      fontSize: 15,
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
      "& .MuiTypography-body1": {
        fontSize: 12,
        whiteSpace: "nowrap",
      },
    },
    "& svg": {
      verticalAlign: "middle",
      marginRight: 25,
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        width: 18,
        height: 18,
        margin: "0 auto",
      },
    },
  },
  activeMenuItem: {
    backgroundColor: "#e1e1e1",
    "&:hover": {
      backgroundColor: "#e1e1e1",
    },
  },
  highlightMenuItem: {
    "& .MuiListItemText-root": {
      color: theme.palette.primary.main,
    },
  },
  menuIcon: {
    height: 30,
    width: 30,
    padding: 0,
    position: "fixed",
    top: 15,
    left: 20,
    "& svg": {
      stroke: "#252525",
      "&:last-child": {
        display: "none",
      },
    },
    "&.open svg:last-child": {
      display: "block",
      fill: "#252525",
      strokeWidth: 0,
    },
    "&.open svg:first-child": {
      display: "none",
    },
  },
  mainMenuOpen: {
    "& .MuiDrawer-paper": {
      height: "auto",
      "@media (max-height: 400px)": {
        maxHeight: 300,
      },
    },
  },
  headerBtn: {
    padding: 10,
    textAlign: "center",
    position: "fixed",
    top: 0,
    right: 0,
    display: "none",
    "& span": {
      color: "#fff",
      fontSize: 16,
      cursor: "pointer",
      fontWeight: "700",
      padding: [[0, 15]],
      textTransform: "uppercase",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ExpandableLisItem = ({ item, activeMenu, handleMenu }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClick = () => setOpen((prev) => !prev);

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={item.title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.subItems.map((subItem, index) => (
            <ListItem
              key={index}
              button
              className={`${classes.menuItem} ${classes.nested} ${
                activeMenu === subItem.title?.replace(" ", "")?.toLowerCase()
                  ? classes.activeMenuItem
                  : null
              }`}
              onClick={() => handleMenu(subItem.title)}
            >
              <div className={classes.sidebarIcon}>{subItem.icon}</div>
              <ListItemText primary={subItem.title} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default function ClippedDrawer() {
  const classes = useStyles();
  const history = useHistory();
  const [activeMenu, setActive] = useState("fintwit");
  const { sidebarItems } = useSelector((state) => state.signIn);
  const [mainManu, setMainMenu] = React.useState(false);
  const dispatch = useDispatch();
  const signOutUser = async () => {
    try {
      setDeepLinkUrl(null);
      dispatch(signInClearAction());
      await Auth.signOut();
      history.push("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  React.useEffect(() => {
    const routeName =
      window.location.pathname &&
      window.location.pathname.replace("/", "").split("/")[0];
    setActive(routeName);
  }, [window.location.pathname]);

  const handleMenu = (text, url, isExternalLink) => {
    document.body.classList.remove("MainMenuOpen");
    setMainMenu(false);
    const route =
      url ?? (text ? text.replaceAll(" ", "-").toLocaleLowerCase() : "");
    if (isExternalLink) {
      window.open(route);
    } else {
      history.push(`/${route}`);
      setActive(route);
    }
  };

  const MainMenuOpen = () => {
    document.body.classList.toggle("MainMenuOpen");
    setMainMenu(!mainManu);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={`${
          mainManu
            ? `${classes.drawer} ${classes.mainMenuOpen}`
            : classes.drawer
        } sidebar-drawer`}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerContainer}>
          <List>
            {sidebarItems.map((text, index) => {
              const ListItemWrapper =
                text.title === "Account" ? Hidden : Fragment;
              const listItemWrapperProps =
                text.title === "Account" ? { mdUp: true } : {};

              return text.subItems ? (
                <ExpandableLisItem
                  key={index}
                  item={text}
                  activeMenu={activeMenu}
                  handleMenu={handleMenu}
                />
              ) : (
                <ListItemWrapper key={index} {...listItemWrapperProps}>
                  <ListItem
                    button
                    onClick={() =>
                      handleMenu(text.title, text.url, text.isExternalLink)
                    }
                    id={text.id}
                    className={`${classes.menuItem} ${
                      activeMenu ===
                      (text.url ?? text.title?.replace(" ", "")?.toLowerCase())
                        ? classes.activeMenuItem
                        : null
                    } ${text.highlight ? classes.highlightMenuItem : ""}`}
                  >
                    <div className={classes.sidebarIcon}>{text.icon}</div>
                    <ListItemText primary={text.title} />
                  </ListItem>
                </ListItemWrapper>
              );
            })}
          </List>

          <div className={classes.headerBtn}>
            <CustomButton
              radius="5"
              fontColor="#000"
              btnPadding="10px 20px"
              variant="contained"
              onClick={signOutUser}
            >
              Sign out
            </CustomButton>
          </div>
        </div>
      </Drawer>
      <CustomSurveyModel />
    </div>
  );
}
