import React from "react";
import { withStyles, Switch } from "@material-ui/core";
import { useSelector } from "react-redux";

export default function CustomSwich({
  children,
  bgColor,
  thumbColor,
  activeThumbColor,
  activeBgColor,
  trackColor = "#000",
  size,
  ...props
}) {
  const { userInfo } = useSelector((state) => state.signIn.loggedUser);
  const isLanding = userInfo?.email ? false : true;

  const StyledSwitch = withStyles((theme) => ({
    root: {
      width: size === "small" ? 44 : 48,
      height: size === "small" ? 22 : 26,
      padding: 0,
      margin: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        "& .MuiSvgIcon-root": {
          width: 16,
          height: 16,
        },
      },
    },
    switchBase: {
      padding: 3,
      color: thumbColor,
      "&$checked": {
        transform: "translateX(22px)",
        color: props.disabled ? undefined : "#fff",
        "& + $track": {
          background: props.disabled
            ? undefined
            : `linear-gradient(to right, ${
                isLanding
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main
              }, ${theme.palette.secondary.main})`,
          opacity: props.disabled ? undefined : 1,
          border: "0",
        },
      },
      "&$focusVisible $thumb": {
        color: thumbColor,
      },
    },
    thumb: {
      width: size === "small" ? 16 : 20,
      height: size === "small" ? 16 : 20,
      boxShadow: "none",
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: trackColor,
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {
      transform: "translateX(28px)",
      color: activeThumbColor,
      "& + $track": {
        backgroundColor: props.disabled ? undefined : "#fff",
        opacity: 1,
        border: "0",
      },
    },
  }))(Switch);

  return <StyledSwitch {...props}>{children}</StyledSwitch>;
}
