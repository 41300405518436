import constants from "../constants";

const initialState = {
  followers: [],
  activities: [],
  holdings: [],
  mentions: [],
  buys: [],
  sells: [],
  conversations: [],
  tweets: [],
  bio: [],
  profileTrades: [],
  trades: [],
  autoRefresh: { 5: true },
};

const analystReducer = (state = initialState, action) => {
  const payload = action?.payload;
  switch (action.type) {
    case constants.ANALYSTS:
      return {
        ...state,
        followers: payload?.followers,
        activities: payload?.activities,
        holdings: payload?.holdings,
        mentions: payload?.mentions,
        buys: payload?.buys,
        sells: payload?.sells,
        conversations: payload?.conversations,
      };

    case constants.PROFILE_ANALYST:
      return {
        ...state,
        bio: payload?.bio,
        holdings: payload?.holdings,
        mentions: payload?.mentions,
        tweets: payload?.tweets ?? payload?.conversations,
        profileTrades: payload?.trades,
      };

    case constants.ADD_WATCH_LIST_ANALYST:
      const { ticker, category, name } = payload;
      const object = state[name];

      object.forEach((val) => {
        if (val.ticker === ticker) {
          if (category === "Watchlist") {
            val.watchlist = 1;
            val.portfolio = 0;
          } else {
            val.portfolio = 1;
            val.watchlist = 0;
          }
        }
      });

      return {
        ...state,
        [name]: object,
      };

    case constants.REMOVE_WATCH_LIST_ANALYST:
      const { removeTicker, removeCategory, removeName } = payload;
      const removeObject = state[removeName];

      removeObject.forEach((val) => {
        if (val.ticker === removeTicker) {
          if (removeCategory === "Watchlist") {
            val.watchlist = 0;
          } else {
            val.portfolio = 0;
          }
        }
      });
      return {
        ...state,
        [removeName]: removeObject,
      };

    case constants.FEED:
      return {
        ...state,
        trades: payload,
      };
    case constants.AUTO:
      return {
        ...state,
        autoRefresh: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default analystReducer;
