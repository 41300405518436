import { createAction } from "redux-actions";
import constants from "./constants";

export const loaderStatusChange = createAction(constants.LOADER);
export const searchStockTicker = createAction(constants.SEARCH_STOCK_TICKER);
export const searchInstitution = createAction(constants.SEARCH_INSTITUTION);

export const signInRequest = createAction(constants.LOGIN_REQUEST);
export const signInSuccess = createAction(constants.LOGIN_REQUEST_SUCCESS);
export const signInError = createAction(constants.LOGIN_REQUEST_ERROR);
export const signInClear = createAction(constants.CLEAR_FLAG);

export const logoutUser = createAction(constants.CLEAR_FLAG);

export const getUserPost = createAction(
  constants.GET_USER_POST_REQUEST_SUCCESS
);

export const getWatchList = createAction(
  constants.GET_WATCH_LIST_REQUEST_SUCCESS
);
export const addWatchListItem = createAction(constants.ADD_WATCH_LIST_ITEM);
export const pageNumberChange = createAction(constants.CHANGE_PAGE_NUMBER);
export const pageNumberChangeVolume = createAction(
  constants.CHANGE_PAGE_NUMBER_VOLUME
);
export const sectorSignal = createAction(constants.SECTOR_SIGNAL);
export const tradeSizeOption = createAction(constants.TRADE_SIZE_OPTION);
export const pageNumberChangeSignal = createAction(
  constants.CHANGE_PAGE_NUMBER_SIGNAL
);
export const removeWatchListItem = createAction(
  constants.REMOVE_WATCH_LIST_ITEM
);
export const removeWatchListFromStock = createAction(
  constants.REMOVE_WATCH_LIST_FROM_STOCK
);

export const getStocksLists = createAction(constants.GET_STOCK_LISTS);
export const getPortfolioWatchLists = createAction(
  constants.GET_PORTFOLIO_WATCHLIST
);
export const getBannerData = createAction(constants.GET_BANNER_DATA);
export const getBannerStatus = createAction(constants.LANDING_PAGE_BANNER);
export const getPopularData = createAction(constants.GET_POPULAR_DATA);
export const getViewedData = createAction(constants.GET_VIEWED_DATA);

export const toastMessage = createAction(constants.TOAST);
export const clearToast = createAction(constants.CLEAR_TOAST);

export const sortData = createAction(constants.SORT_DATA);

export const updateSubscription = createAction(
  constants.UPDATE_SUBSCRIPTION_STATUS
);

export const getAdminListRequest = createAction(
  constants.GET_ADMIN_LIST_REQUEST
);
export const getAdminList = createAction(constants.GET_ADMIN_LISTS);
export const removeAdminListRecord = createAction(
  constants.REMOVE_ADMIN_RECORD
);
export const updateAdminListRecord = createAction(constants.EDIT_ADMIN_RECORD);

export const setWindowWidth = createAction(constants.WINDOW_WIDTH);

export const getAnalyst = createAction(constants.ANALYSTS);

export const getProfileAnalyst = createAction(constants.PROFILE_ANALYST);

export const getFeedData = createAction(constants.FEED);

export const updateAutoRefresh = createAction(constants.AUTO);

export const addWatchListAnalyst = createAction(
  constants.ADD_WATCH_LIST_ANALYST
);

export const removeWatchListAnalyst = createAction(
  constants.REMOVE_WATCH_LIST_ANALYST
);

export const setDataList = createAction(constants.SET_DATA);

export const setStockInsider = createAction(constants.SET_STOCK_INSIDER);
export const setStockTranscript = createAction(constants.SET_STOCK_TRANSCRIPT);
export const setStockSECFilings = createAction(constants.SET_STOCK_SEC);

export const setStockTweets = createAction(constants.SET_STOCK_TWEETS);

export const setAnalystForecast = createAction(
  constants.SET_STOCK_ANALYST_FORECAST
);

export const setStockNews = createAction(constants.SET_STOCK_NEWS);

export const setStockSignal = createAction(constants.SET_STOCK_SIGNAL);

export const setInstitution = createAction(constants.SET_INSTITUTION);

export const setInstitutionProfile = createAction(
  constants.SET_INSTITUTION_PROFILE
);
export const setTopBuyProfile = createAction(constants.SET_TOP_BUY_PROFILE);
export const setRecentTradesProfile = createAction(
  constants.SET_RECENT_TRADES_PROFILE
);

export const setStockOwnership = createAction(constants.SET_STOCK_OWNERSHIP);

export const setStockFiling = createAction(constants.SET_STOCK_FILING);

export const setStockStatement = createAction(constants.SET_STOCK_STATEMENT);

export const setTrandingData = createAction(constants.SET_TRANDING_DATA);

export const setAnalystPageData = createAction(constants.SET_ANALYST_PAGE_DATA);

export const setNewsTrandingData = createAction(
  constants.SET_NEWS_TRANDING_DATA
);
export const setInsiderData = createAction(constants.SET_INSIDER_DATA);

export const setTweetsTrandingData = createAction(
  constants.SET_TWEETS_TRANDING_DATA
);

export const setEarningsCalendar = createAction(
  constants.SET_EARNINGS_CALENDAR_DATA
);
