import { Box, Typography } from "@material-ui/core";
import React, { useRef, useState } from "react";
import ReactJoyride, { EVENTS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import StartTour from "./StartTour";
import WithClose from "./WithClose";
import { hideInAppGuide } from "../../redux/auth/action";
import { useWindowSize } from "../../hooks/useWindowSize";
import {
  cancelAppGuideApi,
  finishAppGuideApi,
  invokeAppGuideApi,
} from "../../redux/api/appGuide";
import useTourStyles from "../../hooks/useTourStyles";
import useJoyrideStyles from "../../hooks/useJoyrideStyles";
import { updateDashboardGuideState } from "../../redux/dashboardGuide/actions";

let tourHelpers = {};

const Joyride = ({
  steps,
  page,
  hideManualTourButton,
  controlled,
  ...props
}) => {
  const [run, setRun] = useState(false);
  const joyrideRef = useRef();
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.signIn.loggedUser);
  const isLanding = loggedUser?.userInfo?.email ? false : true;
  const { showAppGuide } = loggedUser?.userInfo || {};
  const { width } = useWindowSize();
  const tourStyles = useTourStyles();
  const joyrideStyles = useJoyrideStyles();

  const closeTour = () => {
    joyrideRef.current?.store?.skip();
    joyrideRef.current?.store?.cleanupPoppers();
  };

  const handleClose = (sequence) => {
    closeTour();
    setRun(true);
    dispatch(hideInAppGuide());
    cancelAppGuideApi({ idToken: loggedUser?.idToken, page, sequence });
  };

  const withClose = (steps) => {
    if (!steps) return steps;

    const filteredSteps = steps.filter(
      ({ hideStepBelow }) => !hideStepBelow || width >= hideStepBelow
    );
    const totalSteps = filteredSteps.length;

    return filteredSteps.map(({ content, heading, ...step }, index) => {
      const isLastGuide = index + 1 === totalSteps;

      return {
        content: (
          <WithClose onClick={() => handleClose(index + 1)}>
            <Box component="h2" mt={0} mb={0.2} fontSize={22}>
              {heading}
            </Box>
            <Typography
              style={{ color: "#979797", fontSize: 14, marginBottom: 15 }}
            >
              {step.showProgress !== false && `${index + 1}/${totalSteps}`}
            </Typography>
            {content}
            {/* Need this button for the last guide because, if we try to use joyride's Done button then it is causing issue when we try to invoke the in-app guide using help button */}
            {isLastGuide && (
              <Box mt={3} mb={-1}>
                <button
                  type="button"
                  style={tourStyles.primaryButton}
                  onClick={() => {
                    closeTour();
                    finishAppGuideApi({ idToken: loggedUser?.idToken, page });
                  }}
                >
                  Done
                </button>
              </Box>
            )}
          </WithClose>
        ),
        ...step,
        ...(isLastGuide ? { hideFooter: true } : {}),
      };
    });
  };

  const tourSteps =
    steps instanceof Function
      ? withClose(steps({ helpers: tourHelpers }))
      : withClose(steps);

  return (
    <>
      <ReactJoyride
        getHelpers={(helpers) => {
          tourHelpers = helpers;
        }}
        ref={joyrideRef}
        steps={tourSteps}
        hideCloseButton
        disableCloseOnEsc
        disableOverlayClose
        showProgress={false}
        disableOverlay={false}
        {...props}
        run={controlled ? props.run : !!showAppGuide && props.run}
      />
      {!isLanding && !hideManualTourButton && (
        <StartTour
          onClick={() => {
            invokeAppGuideApi({ idToken: loggedUser?.idToken, page });
            tourHelpers?.reset?.(true);
            if (controlled && page === "Dashboard") {
              dispatch(
                updateDashboardGuideState({
                  run: true,
                  stepIndex: 0,
                  isTourActive: true,
                })
              );
            }
          }}
        />
      )}

      {/* Guide for help button */}
      <ReactJoyride
        run={run}
        steps={[
          {
            content: <p>Click help anytime to see the in app guide.</p>,
            target: "#start-tour",
            locale: {
              last: "Done",
            },
            disableBeacon: true,
            disableScrolling: true,
            floaterProps: {
              disableAnimation: true,
            },
          },
        ]}
        styles={joyrideStyles}
        continuous
        hideCloseButton
        disableCloseOnEsc
        disableOverlayClose
      />
    </>
  );
};

export default Joyride;
