import { combineReducers } from "redux";
import loader from "./loader/reducer";
import signIn from "./auth/reducer";
import watchListReducer from "./watchList/reducer";
import toastReducer from "./toast/reducer";
import adminListReducer from "./adminList/reducer";
import analystReducer from "./analyst/reducer";
import redirectTo from "./redirectTo/reducer";
import etf from "./etf/reducer";
import dashboardGuide from "./dashboardGuide/reducer";
import notificationBanner from "./notificationBanner/reducer";

export default combineReducers({
  loader,
  signIn,
  watchListReducer,
  toastReducer,
  adminListReducer,
  analystReducer,
  redirectTo,
  etf,
  dashboardGuide,
  notificationBanner,
});
